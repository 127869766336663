import { MvdTypes } from '@platform/types';
import Highcharts from 'highcharts';
import moment from 'moment';
import React, { useMemo } from 'react';
import { ChartExportType, ChartType } from '../../../enums/widgetEnums';
import useWidget, { IHighchartsData } from '../../../hooks/useWidget';
import ExpandableGridItem, { GridPosition } from '../../shared/ExpandableGridItem';
import { useReadershipContext } from '../ReadershipContext';
import useReadershipData, { IDataPoint } from './useReadershipData';
import WidgetInfo from './WidgetInfo';
import { WidgetQueryKeys } from './WidgetQueryKeys';

const { FilterGroup } = MvdTypes;

interface Props {
  grid: GridPosition;
}

const InterestOverTime: React.FC<Props> = ({ grid }) => {
  const { queryEnabled, filters } = useReadershipContext();

  const { isLoading, data, title } = useReadershipData({
    filters,
    queryEnabled,
    filterGroupId: FilterGroup.INTEREST_OVER_TIME,
    queryKey: WidgetQueryKeys.INTEREST_OVER_TIME,
  });

  const highchartsData = useMemo(() => convertToHighchartsData(data ?? []), [data]);

  const overrideOptions: Highcharts.Options = useMemo(() => {
    return {
      xAxis: {
        categories: highchartsData.dates,
        gridLineWidth: 1,
        gridLineDashStyle: 'Dot',
      },
      yAxis: {
        visible: true,
        gridLineWidth: 1,
        gridLineDashStyle: 'Dot',
      },
      series: [
        {
          data: highchartsData.data,
          type: 'line',
        },
      ],
      colors: ['#6A9FAC'],
    };
  }, [highchartsData.data, highchartsData.dates]);

  const { chart, menu } = useWidget({
    title,
    highchartsData,
    initialType: ChartType.line,
    chartTypeOptions: [ChartType.line],
    exportTypeOptions: [ChartExportType.image],
    overrideOptions,
  });

  return (
    <ExpandableGridItem
      title={title}
      loading={isLoading}
      grid={grid}
      actions={menu}
      info={<WidgetInfo title={title} description="% of reader interest over selected period of time and filters" />}
    >
      {chart}
    </ExpandableGridItem>
  );
};
export default InterestOverTime;

function convertToHighchartsData(data: IDataPoint[]): IHighchartsData {
  // Sort data by visit_date
  data.sort((a, b) => new Date(a.name).getTime() - new Date(b.name).getTime());

  // Extract dates and counts
  const dates = data.map((item) => formatDate(item.name));

  return {
    dates,
    data: data.map((item) => ({ ...item, name: formatDate(item.name) })),
  };
}

const formatDate = (input: string) => moment(input).format('MMMM DD, YYYY');
