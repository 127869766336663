import { Inputs, Modal, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import usePasswordChangeForm from './usePasswordChangeForm';
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

type Inputs = {
  newPassword: string;
  confirmPassword: string;
};

const UpdatePasswordModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { notify } = useApplicationContext();
  const updatePassword = useMutation({
    mutationFn: (payload: MvdTypes.IChangePassword) => mvdApi.changePassword(payload),

    onSuccess: () => {
      onClose();
      notify({
        hideAfterSec: 5,
        content: 'Password has been updated successfully.',
        icon: <MUIcon name="check" />,
      });
    },
  });

  const { form, handleSubmit, isValid, watchedFormData } = usePasswordChangeForm();

  const handleChangePassword: SubmitHandler<Inputs> = (data) => {
    const updateData: MvdTypes.IChangePassword = {
      newPassword: data.newPassword,
    };
    updatePassword.mutate(updateData);
  };
  if (!isOpen) return null;

  return (
    <Modal
      title="Change Password"
      onCloseRequest={onClose}
      titleClasses="py-3 pl-6 pr-3 border-b border-primary-200 items-center"
      okLabel="Change password"
      okClassNames="rounded-full px-6 py-2.5 bg-primary-800 disabled:bg-primary-200 disabled:text-primary-500"
      cancelClassNames="rounded-full px-6 py-2.5 bg-transparent ring-0"
      onDone={handleSubmit(handleChangePassword)}
      okDisabled={!isValid || !watchedFormData.confirmPassword.length}
    >
      {form}
    </Modal>
  );
};

export default UpdatePasswordModal;
