import { Hooks } from '@platform/maplibre';
import { MapComponents } from '@platform/shared/ui';
import { NumberFormatter } from '@platform/utils';
import { LngLatLike, Map, MapGeoJSONFeature, Point2D } from 'maplibre-gl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PARTIES } from '../../useAudienceMapData';

const { TooltipPositioner } = MapComponents;

interface Props {
  map: Map;
  labelField: string;
  layerId: string;
  parentGeoLabelField?: string;
  audienceCountField?: string;
  audiencePercentageField?: string;
}

const Tooltip: React.FC<Props> = ({ labelField, parentGeoLabelField, map, layerId }) => {
  const [featureUnderMouse, setFeatureUnderMouse] = useState<
    | {
        feature: MapGeoJSONFeature;
        latLong: LngLatLike;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const moveStartHandler = () => {
      setFeatureUnderMouse(undefined);
    };
    map.on('movestart', moveStartHandler);
    return () => {
      map.off('movestart', moveStartHandler);
    };
  }, [map]);

  const memoizedLayerId = useMemo(() => [layerId], [layerId]);

  const handleFeatureUnderMouse = useCallback(
    (featuresInEvent: MapGeoJSONFeature[], latLong: LngLatLike) => {
      if (featuresInEvent.length) {
        setFeatureUnderMouse({ feature: featuresInEvent[0], latLong });
      } else {
        setFeatureUnderMouse(undefined);
      }
    },
    [memoizedLayerId]
  );

  Hooks.useFeaturesUnderMouse(map, 'mousemove', handleFeatureUnderMouse, memoizedLayerId);

  if (!featureUnderMouse?.feature) return null;

  const projectedAnchor = map.project(featureUnderMouse.latLong) as Point2D;

  const { properties } = featureUnderMouse.feature;
  const { count, percentage } = properties;

  const asPercentOfCount = asPercent(count);

  let title = properties[labelField];
  if (parentGeoLabelField) {
    title = `${title}, ${properties[parentGeoLabelField]}`;
  }

  return (
    <div className="pointer-events-none absolute inset-0 z-0">
      <TooltipPositioner anchorPoint={projectedAnchor} showAnchor={false}>
        {featureUnderMouse && (
          <div className="z-1 pointer-events-none flex min-w-[220px] max-w-[320px] flex-col gap-2 rounded-md border border-gray-100 bg-white p-3 py-2 text-xs shadow-xl">
            <div className="text-sm font-semibold">{title}</div>
            <Divider />
            <div className="flex flex-col gap-1">
              {PARTIES.map((p) => (
                <div key={p.column} className="flex justify-between">
                  <span>{p.label}</span>
                  <div>{asPercentOfCount(properties[p.column])}</div>
                </div>
              ))}
            </div>
            <Divider />
            <div className="flex justify-between italic">
              <span>Total voters</span>
              <div className="flex gap-1 text-right ">
                <div>{NumberFormatter.format(count, '0,000')}</div>
                <div>({NumberFormatter.format(percentage, '0,0[.]00%')})</div>
              </div>
            </div>
          </div>
        )}
      </TooltipPositioner>
    </div>
  );
};

export default Tooltip;

const Divider = () => <div className="-mx-3 h-[1px] bg-gray-200" />;
const asPercent = (total: number) => (value: number) => NumberFormatter.format((value / total) * 100, '0,0[.]0%');
