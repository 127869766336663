import React from 'react';
import Readership from './Readership';
import { ReadershipContextProvider } from './ReadershipContext';
interface Props {
  audeinceId?: string;
}
const ReadershipWrapper: React.FC<Props> = ({ audeinceId }) => (
  <ReadershipContextProvider audienceId={audeinceId}>
    <Readership />
  </ReadershipContextProvider>
);

export default ReadershipWrapper;
