import { MvdTypes } from '@platform/types';
import { MapGeoJSONFeature } from 'maplibre-gl';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import { ReactComponent as DatasetNotFoundSVG } from '../../../assets/dataset-not-found.svg';
import * as mvdApi from '../../../mvd.api';
import ErrorPage from '../shared/ErrorPage';
import Loader from '../shared/Loader';

const { FilterGroup } = MvdTypes;

const DATASET_ID = process.env['NX_ABACUS_VOTER_DATASET_ID'] as string;

const AUDIENCE_GEO_LEVELS = [
  MvdTypes.States,
  MvdTypes.Counties,
  MvdTypes.CongressionalDistricts,
  MvdTypes.StateSenateDistricts,
  MvdTypes.StateHouseDistricts,
  MvdTypes.DMA,
];

interface ContextState {
  filters: RuleGroupType;
  defaultFilters: RuleGroupType;
  audience: MvdTypes.IAudience | null;
  applyFilters: (filtersToApply: RuleGroupType) => void;
  datasetId: string;
  geoLevel: MvdTypes.GeoLevelType;
  geoSelection: MvdTypes.IGeoSelection[];
  availableGeoLevels: MvdTypes.GeoLevelType[];
  changeGeoLevel: (geoLevel: MvdTypes.GeoLevelType) => void;
  resetFilters: () => void;
  changeGeoSelection: (_: MapGeoJSONFeature[]) => void;
}

const DEFAULT_FILTERS: RuleGroupType = {
  combinator: 'and',
  rules: [],
};

const DEFAULT_VALUE: ContextState = {
  audience: null,
  filters: DEFAULT_FILTERS,
  defaultFilters: DEFAULT_FILTERS,
  datasetId: DATASET_ID,
  geoLevel: AUDIENCE_GEO_LEVELS[0],
  geoSelection: [],
  availableGeoLevels: AUDIENCE_GEO_LEVELS,
  applyFilters: (_: RuleGroupType) => ({}),
  changeGeoSelection: (_: MapGeoJSONFeature[]) => ({}),
  changeGeoLevel: (_: MvdTypes.GeoLevelType) => ({}),
  resetFilters: () => ({}),
};

const AudienceContext = React.createContext<ContextState>(DEFAULT_VALUE);

interface Props {
  children: React.ReactNode;
  audienceId?: string;
}

export const AudienceContextProvider: React.FC<Props> = ({ audienceId, children }) => {
  const [filters, setFilters] = useState<RuleGroupType>({ ...DEFAULT_FILTERS });
  const [activeGeoLevel, setActiveGeoLevel] = useState<MvdTypes.GeoLevelType>(AUDIENCE_GEO_LEVELS[0]);
  const [activeGeoSelection, setActiveGeoSelection] = useState<{ name: string; column: string }[]>([]);

  useEffect(() => {
    const geoFilterGroup: RuleGroupType = filters.rules.find((x) => x.id === FilterGroup.GEO) as RuleGroupType;
    if (!geoFilterGroup) return;

    const levelToUse = MvdTypes.extractGeoLevelBasedRuleGroup(geoFilterGroup, AUDIENCE_GEO_LEVELS);
    if (!levelToUse) return;

    setActiveGeoLevel(levelToUse);
  }, [filters]);

  const hasDefinedAudience = audienceId != null;

  const customAudienceQuery = useQuery(['audience', audienceId], () => mvdApi.getAudienceById(audienceId as string), {
    enabled: hasDefinedAudience,
  });

  useEffect(() => {
    if (!customAudienceQuery.data) return;

    const { query = DEFAULT_FILTERS } = customAudienceQuery.data;

    // 1. figure out the used geo level based on applied geo filters
    const geoFilterGroup: RuleGroupType = query.rules.find((x) => x.id === FilterGroup.GEO) as RuleGroupType;
    if (geoFilterGroup) {
      const levelToUse = MvdTypes.extractGeoLevelBasedRuleGroup(geoFilterGroup, AUDIENCE_GEO_LEVELS);
      if (levelToUse) {
        setActiveGeoLevel(levelToUse);
      }
    }

    setFilters(query);
  }, [customAudienceQuery.data]);

  const applyFilters = useCallback((newFilters: RuleGroupType) => setFilters(newFilters), []);

  const handleNewSelection = useCallback(
    (features: MapGeoJSONFeature[]) => {
      const name = activeGeoLevel.labelProp;
      const field = activeGeoLevel.field;
      setActiveGeoSelection(features.map((feature) => ({ name: feature.properties[name], column: field })));
    },
    [activeGeoLevel]
  );

  const handleGeoLevelChange = useCallback(
    (level: MvdTypes.GeoLevelType) => {
      const geoFilterGroup: RuleGroupType = filters.rules.find((x) => x.id === FilterGroup.GEO) as RuleGroupType;
      if (geoFilterGroup) {
        setFilters({ ...filters, rules: filters.rules.filter((x) => x.id !== FilterGroup.GEO) });
      }
      setActiveGeoLevel(level);
    },
    [filters]
  );

  if (customAudienceQuery.isError) {
    return (
      <ErrorPage
        imageComponent={<DatasetNotFoundSVG />}
        title="Audience not found"
        message="The audience you are trying to access does not exist, or you do not have the necessary permissions to view it."
      />
    );
  }
  if (hasDefinedAudience && (customAudienceQuery.isLoading || customAudienceQuery.isIdle)) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader message="Setting up filters" />
      </div>
    );
  }

  if (customAudienceQuery.isError) return <>Something went wrong</>;

  return (
    <AudienceContext.Provider
      value={{
        filters,
        applyFilters,
        audience: customAudienceQuery.data ?? null,
        defaultFilters: DEFAULT_FILTERS,
        datasetId: DATASET_ID,
        availableGeoLevels: AUDIENCE_GEO_LEVELS,
        geoLevel: activeGeoLevel,
        geoSelection: activeGeoSelection,
        resetFilters: () => setFilters(DEFAULT_FILTERS),
        changeGeoLevel: handleGeoLevelChange,
        changeGeoSelection: handleNewSelection,
      }}
    >
      {children}
    </AudienceContext.Provider>
  );
};

export const useAudienceContext = (): ContextState => {
  const context = useContext(AudienceContext);
  if (!context) {
    throw new Error(`useAudienceContext must be used within a AudienceContextProvider`);
  }
  return context;
};
