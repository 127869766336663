import { Inputs, MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export type PasswordChangeInputs = {
  newPassword: string;
  confirmPassword: string;
};
const usePasswordChangeForm = () => {
  const [newPasswordVisible, setNewPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);
  const { control, handleSubmit, watch } = useForm<PasswordChangeInputs>({
    defaultValues: {
      newPassword: '',
      confirmPassword: '',
    },
  });
  const watchedFormData = watch();
  const passwordValid = useMemo(() => {
    const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,})');
    return passwordRegex.test(watchedFormData.newPassword);
  }, [watchedFormData.newPassword]);
  const confirmPasswordNotValid = useMemo(
    () =>
      watchedFormData.confirmPassword !== '' &&
      watchedFormData.newPassword !== '' &&
      watchedFormData.confirmPassword !== watchedFormData.newPassword,
    [watchedFormData.confirmPassword, watchedFormData.newPassword]
  );
  const isValid = useMemo(() => {
    return passwordValid && !confirmPasswordNotValid && watchedFormData.confirmPassword.length > 0;
  }, [passwordValid, confirmPasswordNotValid, watchedFormData.confirmPassword.length]);

  const form = (
    <form className="inline-flex w-full flex-col items-start justify-start bg-white p-6">
      <Controller
        control={control}
        name="newPassword"
        rules={{ required: true }}
        render={({ field }) => (
          <div className="flex w-full flex-col items-start justify-end gap-1 px-12 py-3">
            <label htmlFor="newPassword" className="text-primary-800 text-xs font-semibold">
              New Password
            </label>
            <Inputs.Input
              type={newPasswordVisible ? 'text' : 'password'}
              placeholder="New Password"
              classes={classNames('h-11 w-[230px] ring-primary-300 focus-within:border-slate-500', {
                'border-slate-500': field.value,
              })}
              roundedClasses="rounded-lg"
              inputClasses="text-sm font-normal"
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                  disabled={!watchedFormData.newPassword.length}
                  data-rh={newPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={newPasswordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-primary-400': !watchedFormData.newPassword.length,
                      'text-primary-600': !!watchedFormData.newPassword.length,
                    })}
                  />
                </button>
              }
              data-cy="newPassword-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
            <div className={classNames('text-primary-400 text-xs font-normal', { 'text-green-600': passwordValid })}>
              {passwordValid
                ? 'Correct password'
                : 'Please enter a valid password with a minimum of 8 characters of uppercase lowercase, and special character.'}
            </div>
          </div>
        )}
      />

      <Controller
        control={control}
        name="confirmPassword"
        rules={{ required: true }}
        render={({ field }) => (
          <div className="flex w-full flex-col items-start justify-end gap-1 px-12 py-3">
            <label htmlFor="confirmPassword" className="text-primary-800 text-xs font-semibold">
              Confirm Password
            </label>
            <Inputs.Input
              type={confirmPasswordVisible ? 'text' : 'password'}
              placeholder="Confirm Password"
              classes={classNames('h-11 w-[230px] ring-primary-300 focus-within:border-slate-500', {
                'border-slate-500': field.value,
              })}
              roundedClasses="rounded-lg"
              inputClasses="text-sm font-normal"
              suffixComponent={
                <button
                  type="button"
                  className="flex items-center justify-center"
                  onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                  disabled={!watchedFormData.confirmPassword.length}
                  data-rh={confirmPasswordVisible ? 'Hide password' : 'Show password'}
                >
                  <MUIcon
                    name={confirmPasswordVisible ? 'visibility_off' : 'visibility'}
                    className={classNames({
                      'text-primary-400': !watchedFormData.confirmPassword.length,
                      'text-primary-600': !!watchedFormData.confirmPassword.length,
                    })}
                  />
                </button>
              }
              data-cy="confirmPassword-input"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            />
            {confirmPasswordNotValid && <p className="pt-1 text-xs text-red-600">Passwords must match</p>}
          </div>
        )}
      />
    </form>
  );
  return {
    form,
    isValid,
    watchedFormData,
    handleSubmit,
  };
};

export default usePasswordChangeForm;
