import { MvdTypes } from '@platform/types';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import { ReactComponent as DatasetNotFoundSVG } from '../../../assets/dataset-not-found.svg';
import * as mvdApi from '../../../mvd.api';
import { DEFAULT_TIME_RANGE_GROUP } from '../Filters/TimeRange.filter';
import ErrorPage from '../shared/ErrorPage';
import Loader from '../shared/Loader';

const { FilterGroup } = MvdTypes;

interface ContextState {
  filters: RuleGroupType;
  queryEnabled: boolean;
  applyFilters: (filtersToApply: RuleGroupType) => void;
  resetFilters: () => void;
  geoLevel: MvdTypes.GeoLevelType;
  availableGeoLevels: MvdTypes.GeoLevelType[];
  changeGeoLevel: (geoLevel: MvdTypes.GeoLevelType) => void;
}

export const READERSHIP_GEO_LEVELS = [MvdTypes.States, MvdTypes.Cities];

const DEFAULT_FILTERS: RuleGroupType = {
  combinator: 'and',
  rules: [DEFAULT_TIME_RANGE_GROUP],
};

const DEFAULT_VALUE: ContextState = {
  queryEnabled: true || process.env.NX_APP_ENV !== 'local',
  filters: DEFAULT_FILTERS,
  applyFilters: () => ({}),
  resetFilters: () => ({}),
  geoLevel: READERSHIP_GEO_LEVELS[0],
  availableGeoLevels: READERSHIP_GEO_LEVELS,
  changeGeoLevel: (_: MvdTypes.GeoLevelType) => ({}),
};

const ReadershipContext = React.createContext<ContextState>(DEFAULT_VALUE);

interface Props {
  children: React.ReactNode;
  audienceId?: string;
}

export const ReadershipContextProvider: React.FC<Props> = ({ children, audienceId }) => {
  const [activeGeoLevel, setActiveGeoLevel] = useState<MvdTypes.GeoLevelType>(READERSHIP_GEO_LEVELS[0]);
  const [filters, setFilters] = useState<RuleGroupType>(DEFAULT_VALUE.filters);

  const applyFilters = useCallback((newFilters: RuleGroupType) => setFilters(newFilters), []);
  const resetFilters = () => setFilters(DEFAULT_VALUE.filters);

  const hasDefinedAudience = audienceId != null;

  const customAudienceQuery = useQuery(['audience', audienceId], () => mvdApi.getAudienceById(audienceId as string), {
    enabled: hasDefinedAudience,
  });

  useEffect(() => {
    const geoFilterGroup: RuleGroupType = filters.rules.find((x) => x.id === FilterGroup.GEO) as RuleGroupType;
    if (geoFilterGroup) {
      const levelToUse = MvdTypes.extractGeoLevelBasedRuleGroup(geoFilterGroup, READERSHIP_GEO_LEVELS);
      if (levelToUse) {
        setActiveGeoLevel(levelToUse);
      }
    }
  }, [filters]);

  useEffect(() => {
    if (!customAudienceQuery.data) return;
    const { query = DEFAULT_FILTERS } = customAudienceQuery.data;
    const newFilters = { ...filters, rules: [...filters.rules, ...query.rules] };
    applyFilters(newFilters);
  }, [customAudienceQuery.data]);

  const queryEnabled = DEFAULT_VALUE.queryEnabled;

  if (customAudienceQuery.isError) {
    return (
      <ErrorPage
        imageComponent={<DatasetNotFoundSVG />}
        title="Audience not found"
        message="The audience you are trying to access does not exist, or you do not have the necessary permissions to view it."
      />
    );
  }

  if (customAudienceQuery.isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader message="Setting up filters" />
      </div>
    );
  }

  return (
    <ReadershipContext.Provider
      value={{
        filters,
        applyFilters,
        queryEnabled,
        resetFilters,
        availableGeoLevels: READERSHIP_GEO_LEVELS,
        geoLevel: activeGeoLevel,
        changeGeoLevel: (level) => setActiveGeoLevel(level),
      }}
    >
      {children}
    </ReadershipContext.Provider>
  );
};

export const useReadershipContext = (): ContextState => {
  const context = useContext(ReadershipContext);
  if (!context) {
    throw new Error(`useReadershipContext must be used within a ReadershipContextProvider`);
  }
  return context;
};
