import React from 'react';
import Overview from '../shared/Overview';
import { useReadershipContext } from './ReadershipContext';

const ReadershipOverview: React.FC = () => {
  const { applyFilters, filters } = useReadershipContext();
  return <Overview onApplyFilters={applyFilters} filters={filters} />;
};

export default ReadershipOverview;
