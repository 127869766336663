import { Abacus } from '@platform/shared/hooks';
import { MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { useEffect, useRef } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import * as api from '../../../mvd.api';
import ActivationWrapper from '../Activation/ActivationWrapper';
import Chip from '../shared/Chip';
import NoResults from '../shared/NoResults';
import Overview from '../shared/Overview';
import { useAudienceContext } from './AudienceContext';
import AudienceExport from './AudienceExport';
import AudienceFilters from './AudienceFilters';
import SelectedGeoLabel from './SelectedGeoLabel';
import AgeByParty from './Widgets/AgeByParty.widget';
import Education from './Widgets/Education.widget';
import EthnicityWidget from './Widgets/Ethnicity.widget';
import GenderByAgeByParty from './Widgets/GenderByAgeByParty.widget';
import GenderByParty from './Widgets/GenderByParty.widget';
import Generation from './Widgets/Generation.widget';
import GunOwnership from './Widgets/GunOwnership.widget';
import HispanicLanguage from './Widgets/HispanicLanguage.widget';
import HispanicOrigin from './Widgets/HispanicOrigin.widget';
import HomeOwnership from './Widgets/HomeOwnership.widget';
import HouseHoldIncome from './Widgets/HouseHoldIncome.widget';
import Language from './Widgets/Language.widget';
import AudienceMap from './Widgets/Map';
import MaritalStatus from './Widgets/MaritalStatus.widget';
import MaritalStatusByGender from './Widgets/MaritalStatusByGender';
import PartyAffiliation from './Widgets/PartyAffiliation.widget';
import PresenceOfChildren from './Widgets/PresenceOfChildren.widget';
import ProChoiceVoters from './Widgets/ProChoiceVoters.widget';
import ReligionsDescription from './Widgets/ReligionsDescription.widget';
import VoterFrequency from './Widgets/VoterFrequency.widget';

const widgetCategories = [
  {
    title: 'Voter Behavior',
    widgets: [PartyAffiliation, VoterFrequency],
  },
  {
    title: 'Demographics',
    widgets: [EthnicityWidget, GenderByAgeByParty, GenderByParty, AgeByParty, Generation, Education, HouseHoldIncome],
  },
  {
    title: 'Family and Housing',
    widgets: [PresenceOfChildren, MaritalStatus, MaritalStatusByGender, HomeOwnership],
  },
  {
    title: 'Ethnicity and Language',
    widgets: [HispanicOrigin, HispanicLanguage, Language],
  },
  {
    title: 'Values and Beliefs',
    widgets: [GunOwnership, ReligionsDescription, ProChoiceVoters],
  },
];

const Audience: React.FC = () => {
  const [categoryInView, setCategoryInView] = React.useState(0);

  const refs: React.RefObject<HTMLDivElement>[] = Array.from({ length: widgetCategories.length }).map(() =>
    React.createRef<HTMLDivElement>()
  );
  const categoryRefs = Array.from({ length: widgetCategories.length }).map(() => React.createRef<HTMLDivElement>());
  const parentRef = React.useRef<HTMLDivElement>(null);

  const {
    filters,
    datasetId,
    availableGeoLevels,
    changeGeoLevel,
    geoLevel,
    geoSelection,
    changeGeoSelection,
    resetFilters,
    applyFilters,
  } = useAudienceContext();

  const { data: totalCount } = Abacus.useAbacusRowsCount({
    datasetId,
    api: api.getRowCount,
    dataQuery: filters,
  });

  useEffect(() => {
    // Add overflow to root element because Highcharts tooltip overflows page and map flickers
    document.documentElement.style.overflow = 'hidden';

    return () => {
      document.documentElement.style.overflow = 'auto';
    };
  }, []);

  const scrollToRef = (
    ref: React.MutableRefObject<HTMLElement | null>,
    block: 'center' | 'end' | 'nearest' | 'start' | undefined
  ) => {
    if (!ref?.current) return;
    ref.current.scrollIntoView({ block, behavior: 'smooth', inline: 'nearest' });
  };

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleScroll = () => {
    try {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);

      timeoutRef.current = setTimeout(() => {
        const parentElementRect = parentRef.current?.getBoundingClientRect();
        const visibleCategoryIndex = refs.findIndex((categoryRef) => {
          const categoryRect = categoryRef.current?.getBoundingClientRect();
          return categoryRect && parentElementRect && categoryRect.top >= 0 && categoryRect.top <= 450;
        });
        if (visibleCategoryIndex > -1) {
          setCategoryInView(visibleCategoryIndex);
        }
      }, 100);
    } catch {
      //
    }
  };

  const hasNoResults = totalCount === 0;
  const geoRule = filters.rules.find((rule) => (rule as RuleGroupType).id === MvdTypes.FilterGroup.GEO) as
    | RuleGroupType
    | undefined;

  return (
    <div className="relative flex h-full w-full flex-col overflow-y-auto">
      <div className="transition-height sticky top-0 z-10 flex w-full justify-between border-b border-gray-200 bg-white px-8 pt-6 transition-all duration-300 ease-in">
        <div className="flex flex-col gap-10">
          <div className="text-xl font-semibold">Audience Insights</div>
          <AudienceFilters />
        </div>
        <div className="flex gap-4">
          <AudienceExport />
          <Overview onApplyFilters={applyFilters} filters={filters} />
        </div>
      </div>
      {hasNoResults ? (
        <NoResults
          title="No data matching your filters"
          message="Try clearing your filters to see results"
          onClear={resetFilters}
        />
      ) : (
        <div className="overflow-none relative flex min-h-0 flex-grow">
          <div
            className="relative z-[1] flex w-[540px] flex-col gap-4 overflow-auto p-2 pt-0 pb-4 shadow-md"
            onScroll={handleScroll}
            ref={parentRef}
          >
            <div className="flex flex-col gap-4 pt-4">
              <div className="flex items-center justify-start gap-2">
                <div className="flex h-6 w-6 items-center justify-center rounded-full bg-gray-200">
                  <MUIcon name="location_on" className="text-gray-600" />
                </div>
                <div className="inline-flex h-6 items-center justify-start gap-2">
                  <SelectedGeoLabel geoRule={geoRule} />
                  <div className="flex items-start justify-start gap-2.5">
                    <div className="h-[3px] w-[3px] rounded-full bg-gray-400"></div>
                  </div>
                  <div className="text-sm font-normal leading-normal text-gray-800">
                    {`${totalCount != null ? NumberFormatter.format(totalCount ?? 0, '0,0') : '?'} ${pluralize(
                      'voter',
                      totalCount ?? 0
                    )}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="sticky top-0 z-[2] border-b border-gray-200 bg-gray-50 py-2">
              <div className="min-h-[40px]">
                <div className="z-[10] flex max-w-[540px] flex-wrap items-center justify-start gap-2">
                  {widgetCategories.map(({ title }, index) => (
                    <div key={title} ref={categoryRefs[index]}>
                      <Chip
                        title={title}
                        className={classNames('cursor-pointer rounded-lg text-[13px] font-semibold text-gray-800', {
                          'bg-secondary-100': categoryInView === index,
                          'bg-white': categoryInView !== index,
                        })}
                        onClick={() => scrollToRef(refs[index], index === 0 ? 'center' : undefined)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {widgetCategories.map((category, index) => (
              <React.Fragment key={category.title}>
                <div className="inline-flex w-full items-center justify-start gap-3 py-3 pr-3">
                  <div className="flex shrink grow basis-0 items-end justify-start gap-1 px-2 py-1">
                    <div
                      className="shrink grow basis-0 scroll-mt-32 text-sm font-semibold text-gray-800"
                      ref={refs[index]}
                    >
                      {category.title}
                    </div>
                  </div>
                </div>
                {category.widgets.map((Widget, index) => (
                  <Widget key={category.title + index} />
                ))}
              </React.Fragment>
            ))}
          </div>
          <div className="flex flex-grow">
            <AudienceMap
              activeGeoSelection={geoSelection}
              datasetId={datasetId}
              filters={filters}
              availableGeoLevels={availableGeoLevels}
              activeGeoLevel={geoLevel}
              onChangeGeoLevel={changeGeoLevel}
              onChangeGeoSelection={changeGeoSelection}
            />
          </div>
        </div>
      )}
      <ActivationWrapper filters={filters} />
    </div>
  );
};

export default Audience;
