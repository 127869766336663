import React from 'react';
import Audience from './Audience';
import { AudienceContextProvider } from './AudienceContext';

interface Props {
  audienceId?: string;
}

const AudienceWrapper: React.FC<Props> = ({ audienceId }) => (
  <AudienceContextProvider audienceId={audienceId}>
    <Audience />
  </AudienceContextProvider>
);

export default AudienceWrapper;
