import { Buttons, Inputs, Menu, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React, { useMemo, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { userFriendlyAudienceCategoryLabel } from '../../helpers/helpers';
import AudiencesDrawer from '../shared/AudiencesDrawer';
import Chip from '../shared/Chip';
import { useFilterGroup } from './common';
import { IFilterOption } from './MvdMasterMapping';

const { FilterGroup } = MvdTypes;

interface IProps {
  filters: RuleGroupType;
  onDone: (value: RuleGroupType) => void;
}

const AudienceFilter: React.FC<IProps> = ({ filters, onDone }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const { title, options, resetToDefault, appliedFiltersCount, addOption } = useFilterGroup(
    FilterGroup.AUDIENCE_CODE,
    filters
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleResetClick = () => {
    const newFilters = resetToDefault();
    onDone(newFilters);
    setIsOpen(false);
  };

  const processedTitle =
    appliedFiltersCount === 1
      ? options.find((option) => option.checked)?.name ?? pluralize(title, 2)
      : pluralize(title, 2);

  const sortedAndGroupedOptions = useMemo(() => {
    const groupedOptions: { description: string; data: IFilterOption[] }[] = [];

    options.forEach((option: IFilterOption) => {
      const description = option.description === undefined ? 'UNCATEGORIZED' : option.description;
      const existingGroup = groupedOptions.find((group) => group.description === description);
      if (existingGroup) {
        existingGroup.data.push(option);
      } else {
        groupedOptions.push({ description, data: [option] });
      }
    });

    return groupedOptions.sort((a, b) => {
      const order: { [key in MvdTypes.AudienceCategoryEnum]: number } = {
        ISSUE: 1,
        'MULTI-ISSUE': 2,
        UNCATEGORIZED: 3,
      };

      const getOrderValue = (description: string) => {
        return order[description as keyof typeof order] ?? 4;
      };

      return getOrderValue(a.description) - getOrderValue(b.description);
    });
  }, [options]);

  const filteredOptions = useMemo(() => {
    return sortedAndGroupedOptions
      .map((group) => ({
        description: userFriendlyAudienceCategoryLabel(group.description),
        data: group.data
          .filter((option) => option.name.toLowerCase().includes(searchTerm.toLowerCase()))
          .sort((a, b) => a.name.localeCompare(b.name)),
      }))
      .filter((group) => group.data.length > 0);
  }, [sortedAndGroupedOptions, searchTerm]);

  const handleOptionClick = (option: IFilterOption) => {
    // as Audiences are single option filters, the change gets applied instantly (without need for Apply button)
    const updatedFilters = addOption(option, true, true);
    if (updatedFilters) onDone(updatedFilters);

    setIsOpen(false);
  };

  return (
    <>
      <Menu
        visible={isOpen}
        menuClasses="mt-4 shadow border border-grey-200"
        triggerClasses="cursor-pointer-none"
        trigger={
          <div data-rh={processedTitle}>
            <Chip
              title={processedTitle}
              className={classNames('select-none text-sm font-semibold', {
                'bg-secondary-100 pl-1': appliedFiltersCount,
                'bg-white-200': !appliedFiltersCount,
              })}
              leadingAction={
                appliedFiltersCount > 0 && (
                  <div
                    className="hover:bg-secondary-50 flex h-6 w-6 items-center justify-center rounded-full bg-transparent"
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      handleResetClick();
                    }}
                  >
                    <MUIcon name="close" className="text-gray-600" iconStyle="filled" />
                  </div>
                )
              }
            >
              <MUIcon
                name={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
                className="text-gray-600"
                iconStyle="filled"
              />
            </Chip>
          </div>
        }
        onToggleVisibility={setIsOpen}
      >
        <div className="relative flex w-80 flex-grow flex-col items-center">
          {options.length > 5 && (
            <div className="sticky top-0 flex w-full items-center justify-start border-b border-gray-200 bg-white">
              <Inputs.Input
                autoFocus
                placeholder="Search for audience"
                classes="flex inline-flex h-14 items-center justify-start ring-white px-4 py-3"
                roundedClasses="rounded-none"
                value={searchTerm}
                onChange={handleSearch}
                clearIcon={searchTerm ? <MUIcon name="close" /> : null}
                onClear={() => setSearchTerm('')}
              />
            </div>
          )}
          <div className="flex max-h-96 w-full flex-col gap-2 overflow-auto py-2">
            {filteredOptions.length > 0 && (
              <div
                className={classNames(
                  'hover:bg-primary-100 focus:bg-primary-100 flex h-[44px] w-full cursor-pointer items-center justify-between px-4',
                  {
                    'bg-primary-100': appliedFiltersCount === 0,
                  }
                )}
                onClick={() => handleResetClick()}
              >
                <div className="inline-flex flex-grow items-center justify-start gap-4 py-1">
                  {appliedFiltersCount === 0 ? (
                    <MUIcon name="check" className="text-primary-600" />
                  ) : (
                    <div className="h-5 w-5"></div>
                  )}
                  <div className="flex items-center justify-start text-sm font-normal text-gray-800">Any audience</div>
                </div>
              </div>
            )}
            <div className="flex h-[1px] w-full flex-shrink-0 bg-gray-200" />
            {filteredOptions.length > 0 ? (
              <div className="h-72 w-full select-none">
                {filteredOptions.map(({ description, data }) => (
                  <div className="w-full" key={description}>
                    <div className="inline-flex h-11 items-center justify-start gap-4 px-4 py-3">
                      <div className="h-5 w-5"></div>
                      <div className="font-['Inter'] text-xs font-semibold uppercase leading-none text-gray-500">
                        {description}
                      </div>
                    </div>
                    {data.map((opt) => {
                      const selected = opt.checked && appliedFiltersCount <= 1;
                      return (
                        <div
                          key={opt.name}
                          className={classNames(
                            'hover:bg-primary-100 focus:bg-primary-100 group flex h-12 w-full cursor-pointer items-center justify-between gap-4 px-4 py-3',
                            { 'bg-primary-100': selected }
                          )}
                          onClick={() => handleOptionClick(opt)}
                        >
                          <div className="inline-flex flex-grow items-center justify-start gap-4">
                            {selected ? (
                              <MUIcon name="check" className="text-primary-600" />
                            ) : (
                              <div className="h-5 w-5"></div>
                            )}
                            <div className="flex items-center justify-start text-sm font-normal text-gray-800">
                              {opt.name}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex h-12 w-full items-center justify-center px-4 py-3 font-['Inter'] text-sm font-normal leading-[18.20px] text-gray-800">
                No results
              </div>
            )}
          </div>
          <div className="flex w-full items-center justify-center border-t border-gray-200 p-3">
            <Buttons.Secondary
              className="rounded-full"
              icon={<MUIcon name={'search'} />}
              onClick={() => setDrawerOpen(true)}
            >
              Browse all audiences
            </Buttons.Secondary>
          </div>
        </div>
      </Menu>
      {drawerOpen && (
        <AudiencesDrawer
          isOpen={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
          }}
          onSelectAudience={onDone}
          filters={filters}
        />
      )}
    </>
  );
};
export default AudienceFilter;
