import { useOutsideClick } from '@platform/shared/hooks';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import MasterMapping from '../Filters/MvdMasterMapping';

interface ISelectedGeoLabelProps {
  geoRule: RuleGroupType | undefined;
}
const SelectedGeoLabel: React.FC<ISelectedGeoLabelProps> = ({ geoRule }) => {
  const ref = useOutsideClick<HTMLDivElement>(() => setVisibleInfo(false));
  const [visibleInfo, setVisibleInfo] = useState(false);

  const { title, values, levelToUse } = useMemo(() => {
    if (!geoRule) {
      return {
        title: '',
        values: [],
      };
    }

    const levelToUse = MvdTypes.extractGeoLevelBasedRuleGroup(geoRule, MvdTypes.AllAvailableGeos);
    const title = levelToUse ? MasterMapping.labelForGeoRule(geoRule, levelToUse) : '';
    const { values } = MasterMapping.groupToHumanReadableText(geoRule);

    return {
      title,
      values,
      levelToUse,
    };
  }, [geoRule]);

  if (!geoRule) {
    return <div className="text-sm font-normal leading-normal text-gray-800">United States</div>;
  }

  if (values.length === 1 && levelToUse?.id === MvdTypes.States.id) {
    return <div className="text-sm font-normal leading-normal text-gray-800">{title}</div>;
  }

  return (
    <div ref={ref} className="relative z-10">
      <div
        className="cursor-pointer text-sm font-semibold leading-normal text-gray-800 underline"
        onClick={() => setVisibleInfo(!visibleInfo)}
      >
        <div>{title}</div>
      </div>

      <div
        className={classNames(
          'absolute top-10 z-10 inline-flex max-h-[240px] max-w-[250px] flex-col items-start justify-start gap-4 overflow-auto rounded-md bg-white px-4 pt-4 pb-3 shadow',
          {
            hidden: !visibleInfo,
          }
        )}
      >
        <div className="inline-flex items-center justify-start gap-4 self-stretch pr-4">
          <div className="whitespace-nowrap text-xs font-semibold uppercase leading-none text-gray-500">
            Selected geographies
          </div>
        </div>

        <ul className="flex list-disc flex-col items-start justify-start gap-1 pl-4">
          {values.map((value) => (
            <li className="text-sm font-normal leading-normal text-gray-800" key={value}>
              {value}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SelectedGeoLabel;
