import { MvdTypes } from '@platform/types';
import moment from 'moment/moment';
import { useMemo } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';

interface IParams {
  filters: RuleGroupType;
  sql: string;
}

const useReadershipRequest = ({ filters, sql }: IParams) => {
  // by default, its one day of data (taken into account that data has a delay of one day)
  let startDate = moment().subtract(2, 'days').format('YYYY-MM-DD');

  let endDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

  const timeGroup = filters.rules.find((rule) => rule.id === MvdTypes.FilterGroup.TIME) as RuleGroupType;
  if (timeGroup?.rules?.length) {
    const [fromRule, toRule] = timeGroup.rules as RuleType[];
    startDate = fromRule.value;
    endDate = toRule.value;
  }

  return useMemo(
    () => ({
      sql,
      startDate,
      endDate,
      projectName: 'mvd_ga4_all_data',
    }),
    [sql, startDate, endDate]
  );
};

export default useReadershipRequest;
