export const JOB_FUNCTIONS = [
  { value: 'Account Management', name: 'Account Management' },
  { value: 'Administrative', name: 'Administrative' },
  { value: 'Analytics', name: 'Analytics' },
  { value: 'Audience Development/Circulation', name: 'Audience Development/Circulation' },
  { value: 'Brand Management', name: 'Brand Management' },
  { value: 'Business Development', name: 'Business Development' },
  { value: 'Digital/Online Media Management', name: 'Digital/Online Media Management' },
  { value: 'Ecommerce', name: 'Ecommerce' },
  { value: 'Editorial', name: 'Editorial' },
  { value: 'Education', name: 'Education' },
  { value: 'Event Management', name: 'Event Management' },
  { value: 'Finance', name: 'Finance' },
  { value: 'Human Resources', name: 'Human Resources' },
  { value: 'Information Technology', name: 'Information Technology' },
  { value: 'Innovation', name: 'Innovation' },
  { value: 'Management', name: 'Management' },
  { value: 'Marketing', name: 'Marketing' },
  { value: 'Media Buyer/Planning', name: 'Media Buyer/Planning' },
  { value: 'Operations', name: 'Operations' },
  { value: 'Product Management', name: 'Product Management' },
  { value: 'Production', name: 'Production' },
  { value: 'Public Relations', name: 'Public Relations' },
  { value: 'Research', name: 'Research' },
  { value: 'Sales', name: 'Sales' },
  { value: 'Other', name: 'Other' },
];

export const SENIORITY_LEVELS = [
  { value: 'C-Suite', name: 'C-Suite' },
  { value: 'Board/Chair', name: 'Board/Chair' },
  { value: 'Managing Director/General Manager', name: 'Managing Director/General Manager' },
  { value: 'Vice President', name: 'Vice President' },
  { value: 'Director', name: 'Director' },
  { value: 'Manager', name: 'Manager' },
  { value: 'Account Executive', name: 'Account Executive' },
  { value: 'Strategist', name: 'Strategist' },
  { value: 'Analyst', name: 'Analyst' },
  { value: 'Non-Manager (Associate, Coordinator)', name: 'Non-Manager (Associate, Coordinator)' },
  { value: 'Professor/Student', name: 'Professor/Student' },
  { value: 'Other', name: 'Other' },
];
