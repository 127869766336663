import { MvdTypes } from '@platform/types';
import { useQuery } from 'react-query';
import * as mvdApi from '../../mvd.api';
import MvdMasterMapping from '../components/Filters/MvdMasterMapping';
import { getAudienceMetaByCategory } from '../helpers/helpers';

const order: { [key in MvdTypes.AudienceCategoryEnum]: number } = {
  ISSUE: 1,
  'MULTI-ISSUE': 2,
  UNCATEGORIZED: 3,
};

const useAudienceQuery = (userInfo: MvdTypes.IUser | undefined, category: MvdTypes.AudienceCategoryType) => {
  return useQuery(
    ['audiences', userInfo?.id, category],
    () =>
      mvdApi.getAudiences({
        category,
        includeInactive: false,
        includeCustom: false,
      }),
    {
      onSuccess: (loadedAudiences) => {
        const { title, description } = getAudienceMetaByCategory(category);

        const audiencesGroup = {
          title,
          description,
          id: MvdTypes.FilterGroup.AUDIENCE_CODE,
          operator: '=',
          columns: loadedAudiences
            .sort(
              (a, b) => (order[a.category as keyof typeof order] || 3) - (order[b.category as keyof typeof order] || 3)
            )
            .map((x, idx) => ({
              column: x.code,
              value: 1,
              label: x.title,
              ordering: (idx + 1) * 1000,
              id: x.id.toString(),
              description: x.subCategory ?? 'UNCATEGORIZED',
            })),
        };

        // TODO:
        // @ts-ignore
        MvdMasterMapping.addGroup(audiencesGroup);
      },
      enabled: userInfo != null,
    }
  );
};

export default useAudienceQuery;
