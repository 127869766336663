import { NumberFormatter } from '@platform/utils';
import React from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { generateLink } from '../../LinkGenerator';

const Audiences: React.FC = () => {
  const { userInfo } = useApplicationContext();
  const audienceQuery = useQuery(['audiences', userInfo?.id, null], () =>
    mvdApi.getAudiences({
      category: null,
      includeInactive: false,
      includeCustom: false,
    })
  );

  return (
    <div className="flex h-full w-full flex-col p-8">
      <div className="flex w-full flex-col gap-2">
        <div className="text-xl">Audiences</div>
        <div className="flex flex-wrap gap-2">
          {(audienceQuery.isLoading || audienceQuery.isIdle) && <>Loading...</>}
          {audienceQuery.data?.map((x) => (
            <Link
              key={x.id}
              to={generateLink('audience', { audienceId: x.id })}
              className="m flex w-[400px] flex-col gap-3 rounded-md border bg-white p-4 hover:bg-gray-100"
            >
              <div className="flex items-center gap-2">
                <div className="font-semibold">{x.title}</div>
                <div className="rounded-md bg-gray-400 px-2 py-0.5 text-xs text-white">{x.category ?? '??'}</div>
              </div>
              <div className="text-gray-500">{x.description}</div>
              <span>{`${NumberFormatter.format(x.size, '0,000')} records`}</span>
              <div className="flex flex-wrap gap-2">
                {x.topics.map((topic) => (
                  <div key={topic} className="rounded-full bg-gray-100 px-2 px-1">
                    {topic}
                  </div>
                ))}
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};
export default Audiences;
