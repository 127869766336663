import 'react-hint/css/index.css';
import './app.css';

import { Routing } from '@platform/shared/ui';
import { NumberFormatter } from '@platform/utils';
import Highcharts from 'highcharts';
import exportingInit from 'highcharts/modules/exporting';
import React from 'react';
import ReactHintFactory from 'react-hint';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { useApplicationContext } from './ApplicationContext';
import DashboardLayout from './layouts/Dashboard.layout';
import { generateLink, linkFor } from './LinkGenerator';
import AccountPage from './pages/Account.page';
import ActivationsPage from './pages/Activations.page';
import AudiencePage from './pages/Audience.page';
import AudiencesPage from './pages/Audiences.page';
import ForgotPasswordPage from './pages/ForgotPassword.page';
import InfoPage from './pages/Info.page';
import LoginPage from './pages/Login.page';
import NewInvitePage from './pages/NewInvite';
import ReadershipPage from './pages/Readership.page';
import ResetPasswordPage from './pages/ResetPassword';
import SavedAudiencesPage from './pages/SavedAudiences.page';
import SignUpPage from './pages/SignUp.page';

// eslint-disable-next-line @typescript-eslint/no-var-requires
require('highcharts/modules/treemap')(Highcharts);

exportingInit(Highcharts);

Highcharts.setOptions({
  title: undefined,
  legend: {
    enabled: false,
    align: 'center',
    verticalAlign: 'bottom',
    layout: 'horizontal',
    itemStyle: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
    symbolHeight: 12,
    symbolWidth: 12,
    symbolRadius: 2,
  },
  credits: {
    enabled: false,
  },
  chart: {
    animation: false,
    height: null,
    width: null,
    numberFormatter: (number: number) => NumberFormatter.format(number, '0,0[.]00'),
  },
  exporting: {
    enabled: false,
  },
  xAxis: {
    title: undefined,
  },
  yAxis: {
    title: undefined,
  },
  plotOptions: {
    series: {
      animation: false,
      dataLabels: {
        formatter: function () {
          return NumberFormatter.format(this.point.percentage, '0,0[.]00%');
        },
      },
    },
    pie: {
      innerSize: '75%',
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
    line: {
      dataLabels: {
        enabled: true,
      },
    },
    bar: {
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: 'normal',
          fontSize: '12px',
          color: '#374151',
        },
      },
      pointPadding: 0.02,
      groupPadding: 0.02,
    },
    column: {
      dataLabels: {
        enabled: true,
      },
    },
  },
  tooltip: {
    useHTML: true,
    formatter: function () {
      const point = this.point as Highcharts.Point & {
        percentage: number;
        description: string;
      };

      return (
        `<div>` +
        `<div style="display: flex; align-items: center; justify-content: space-between; gap:50px">` +
        `<div style="display: flex; flex-direction: column;">
          <span>${point.name}</span>
          <span style="font-size: 90%; opacity: 0.7; display: ${point.description ? 'block' : 'none'}">${
          point.description
        }</span></div>` +
        `<span style="text-align: right;">` +
        `<b>${NumberFormatter.format(point.percentage, '0,0[.]00%')}</b><br>` +
        `</span>` +
        `</div>` +
        `</div>`
      );
    },
    backgroundColor: 'white',
    borderRadius: 16,
    padding: 12,
    shadow: true,
    borderWidth: 0,
    shape: 'square',
  },
  colors: ['#92C0CC', '#FED16D', '#E1AA94', '#CF8DA1', '#A5B4FC', '#6DB0FE'],
});

const { PublicRoute, ProtectedRoute } = Routing;
const ReactHint = ReactHintFactory(React);

export function App() {
  const { isAuthenticated } = useApplicationContext();

  return (
    <div className="h-full w-full">
      <ReactHint autoPosition events position="bottom" delay={{ show: 100, hide: 0 }} />
      <Routes>
        <Route
          path={generateLink('login')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={generateLink('home')}>
              <LoginPage />
            </PublicRoute>
          }
        />
        <Route
          path={generateLink('signup')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={generateLink('home')}>
              <SignUpPage />
            </PublicRoute>
          }
        />
        <Route
          path={generateLink('forgotPassword')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={generateLink('home')}>
              <ForgotPasswordPage />
            </PublicRoute>
          }
        />
        <Route
          path={generateLink('resetPassword')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={generateLink('home')}>
              <ResetPasswordPage />
            </PublicRoute>
          }
        />
        <Route
          path={generateLink('newInvite')}
          element={
            <PublicRoute isAllowed={!isAuthenticated} redirectPath={generateLink('home')}>
              <NewInvitePage />
            </PublicRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute isAllowed={isAuthenticated} redirectPath={generateLink('login')}>
              <Routes>
                <Route path="/" element={<DashboardLayout />}>
                  <Route path={linkFor('home')} element={<Navigate to={linkFor('readership')} />} />
                  <Route path={linkFor('audiences')} element={<AudiencesPage />} />
                  <Route path={linkFor('audience')} element={<AudiencePage />} />
                  <Route path={linkFor('readership')} element={<ReadershipPage />} />
                  <Route path={linkFor('activations')} element={<ActivationsPage />} />
                  <Route path={linkFor('myAudiences')} element={<SavedAudiencesPage />} />
                  <Route path={linkFor('myAudience')} element={<AudiencePage />} />
                  <Route path={linkFor('savedAudienceReadership')} element={<ReadershipPage />} />
                  <Route path={linkFor('info')} element={<InfoPage />} />
                  {/*<Route path={linkFor('help')} element={<HelpSupportPage />} />*/}
                  <Route path={linkFor('account')} element={<AccountPage />} />
                </Route>
                <Route
                  path="*"
                  element={
                    <div className="flex h-full flex-col items-center justify-center gap-4">
                      There is nothing here<Link to="/">Home</Link>
                    </div>
                  }
                />
              </Routes>
            </ProtectedRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
