import { NumberFormatter } from '@platform/utils';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useMemo } from 'react';

interface Props {
  totalCount: number;
  filteredCount: number;
  classes?: string;
}

const Donut: React.FC<Props> = ({ totalCount, filteredCount, classes = '' }) => {
  const filteredPercentage = (filteredCount / totalCount) * 100;

  const options: Highcharts.Options = useMemo(
    () => ({
      chart: {
        backgroundColor: 'transparent',
        height: 110,
        margin: 0,
        type: 'pie',
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          innerSize: '70%',
        },
      },
      series: [
        {
          type: 'pie',
          states: {
            hover: {
              enabled: false,
            },
          },
          enableMouseTracking: false,
          data: [
            { name: 'Filtered', y: filteredPercentage, color: '#A5B4FC' },
            { name: 'All voters', y: 100 - filteredPercentage, color: '#D2D5DA' },
          ],
        },
      ],
    }),
    [filteredPercentage]
  );

  return (
    <div className={`flex w-full items-center gap-4 rounded-lg border border-gray-100 bg-gray-50 px-4 py-2 ${classes}`}>
      <div className="relative w-[100px]">
        <HighchartsReact highcharts={Highcharts} options={options} />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs">
          {NumberFormatter.format(filteredPercentage, '0,0.0%')}
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <span className="font-semibold">{NumberFormatter.format(filteredCount, '0,0')}</span>
        <span className="text-xs text-sm">of {NumberFormatter.format(totalCount, '0,0')} voters</span>
      </div>
    </div>
  );
};

export default Donut;
