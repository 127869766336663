import { MvdTypes } from '@platform/types';
import React from 'react';
import useAudienceInsightsWidget from '../../../hooks/useAudienceInsightsWidget';
import MvdMasterMapping from '../../Filters/MvdMasterMapping';
import { useAudienceContext } from '../AudienceContext';
import Container from './Container';
import useAudienceData from './useAudienceData';

const { FilterGroup } = MvdTypes;

const GenderByAgeByParty: React.FC = () => {
  const { filters, datasetId } = useAudienceContext();

  const groupByEntries = MvdMasterMapping.getMappingsByFilterGroup(FilterGroup.GENDER);
  const options = [
    { label: 'All', value: '*' },
    ...groupByEntries.map((entry) => ({
      label: entry.label,
      value: entry.label,
    })),
    { label: 'Unknown', value: '' },
  ];

  const { isLoading, data } = useAudienceData({
    datasetId,
    filters,
    groupByFilterIds: [FilterGroup.AGE, FilterGroup.GENDER],
    filterGroupId: FilterGroup.PARTY,
  });

  const title = 'Gender by Age';

  const { chart, menu } = useAudienceInsightsWidget({
    title,
    data: data ?? [],
    groupingOptions: options,
  });

  return (
    <Container title={title} loading={isLoading} actions={menu}>
      {chart}
    </Container>
  );
};
export default GenderByAgeByParty;
