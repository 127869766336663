import { Buttons, Dropdowns, Inputs, MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { IKeywordsFilter } from '../MoreFilters';
import KeywordsFilterItem from './KeywordsFilterItem';

interface IProps {
  selectedSearchTerms: IKeywordsFilter[];
  onTermAdd: (terms: IKeywordsFilter[]) => void;
  onTermRemove: (terms: IKeywordsFilter) => void;
  onResetFilter: () => void;
}

const DROPDOWN_OPTIONS: Dropdowns.DropdownOption[] = [
  { name: 'Include', value: 'ilike' },
  { name: 'Exclude', value: 'not ilike' },
];

const KeywordsFilter: React.FC<IProps> = ({ selectedSearchTerms, onTermAdd, onTermRemove, onResetFilter }) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [operatorValue, setOperatorValue] = React.useState<string>(DROPDOWN_OPTIONS[0].value as string);

  const onSelect = (selectedItem: Dropdowns.DropdownOption) => {
    setOperatorValue(selectedItem.value as string);
  };

  const handleSearchTerm = () => {
    const values: string[] = searchTerm
      .split(',')
      .map((x) => x.trim())
      .filter((x) => x.length);
    const terms: IKeywordsFilter[] = values.map((value) => ({
      operator: operatorValue,
      value: value,
    }));
    setOperatorValue(DROPDOWN_OPTIONS[0].value as string);
    setSearchTerm('');
    onTermAdd(terms);
  };

  const handleSubjectKeyPress = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && searchTerm.length > 0) {
        handleSearchTerm();
      }
    },
    [searchTerm]
  );

  const keywords = selectedSearchTerms.length ? (
    <div className="flex flex-wrap gap-3">
      {selectedSearchTerms.map((term, idx) => (
        <KeywordsFilterItem term={term} key={`${term.operator}-${term.value}-${idx}`} onTermRemove={onTermRemove} />
      ))}
    </div>
  ) : null;

  return (
    <div className="mr-5 flex flex-col gap-5 border-b border-gray-200 py-2 pb-6">
      <div className="flex w-full items-center justify-between">
        <p className={'text-base font-semibold text-gray-800'}>Search term or keyword</p>
        {!!selectedSearchTerms.length && (
          <Buttons.Link
            className="rounded-full bg-transparent px-6 hover:bg-transparent"
            data-rh="Reset filter"
            icon={<MUIcon name="refresh" className="cursor-pointer" />}
            onClick={onResetFilter}
          />
        )}
      </div>
      {keywords}
      <div className="flex flex-col gap-3 pr-1">
        <Inputs.Input
          autoFocus
          classes="rounded-full bg-gray-100 ring-transparent py-2.5 h-[50px]"
          inputClasses="bg-transparent px-2"
          placeholder="Add a search term"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleSubjectKeyPress}
          suffixComponent={
            <Buttons.Primary
              icon={<MUIcon name="add" />}
              className={classNames('rounded-full hover:text-white', {
                'bg-transparent': !searchTerm.length,
                'text-gray-500': !searchTerm.length,
              })}
              size="sm"
              disabled={!searchTerm.length}
              onClick={() => handleSearchTerm()}
            />
          }
          prefixComponent={
            <div className="flex items-center justify-center gap-2">
              <Dropdowns.Dropdown
                value={operatorValue}
                options={DROPDOWN_OPTIONS}
                onSelect={onSelect}
                triggerClasses="w-[120px] h-[44px] text-sm font-semibold px-4 rounded-none ring-0 rounded-xl hover:bg-transparent"
              />
              <div className="h-[30px] w-[1px] bg-gray-300"></div>
            </div>
          }
        />
        <div className="flex w-full items-center justify-end px-2">
          {!!searchTerm.length && (
            <p className="text-xs text-gray-500">
              Press <span className="font-semibold">Enter</span> to add a keyword or phrase
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default KeywordsFilter;
