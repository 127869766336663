import { MvdTypes } from '@platform/types';
import moment from 'moment';
import { unparse } from 'papaparse';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import MvdMasterMapping from '../components/Filters/MvdMasterMapping';

interface CsvData {
  [key: string]: any;
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const generateAndDownloadCSV = (data: CsvData[], name: string) => {
  const csv = unparse(data, { header: false });

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);

  link.setAttribute('href', url);
  link.setAttribute('download', name);
  link.style.visibility = 'hidden';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const transformFiltersDataForCSV = (filterRules: RuleGroupType[]) => {
  const transformedData: { [key: string]: string }[] = [];

  const dateRangeRule = filterRules.find((rule) => rule.id === 'time');
  const fromRule = dateRangeRule?.rules[0] as RuleType | undefined;
  const toRule = dateRangeRule?.rules[1] as RuleType | undefined;

  if (fromRule && toRule) {
    const fromDate = moment(fromRule.value).format('MM-DD-YYYY');
    const toDate = moment(toRule.value).format('MM-DD-YYYY');
    transformedData.push({ col1: 'Date range', col2: `${fromDate} - ${toDate}`, col3: '' });
  }
  transformedData.push({ col1: 'Filters', col2: '', col3: '' });

  filterRules.forEach((rule) => {
    if (rule.id !== 'time') {
      const { values, title } = MvdMasterMapping.groupToHumanReadableText(rule);
      if (title !== 'Unknown') {
        transformedData.push({ col1: title, col2: values.join(', '), col3: '' });
      }
    }
  });
  transformedData.push({ col1: '', col2: '', col3: '' }, { col1: '', col2: '', col3: '' });

  return transformedData;
};

export const calculateDateDiff = (startDateString: string, endDateString: string): number => {
  // Convert date strings to Date objects
  const startDate: Date = new Date(startDateString);
  const endDate: Date = new Date(endDateString);

  // Check for invalid dates
  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw new Error('Invalid date string provided');
  }

  // Calculate the difference in milliseconds
  const diffInMilliseconds: number = endDate.getTime() - startDate.getTime();

  // Convert the difference from milliseconds to days
  return diffInMilliseconds / (1000 * 60 * 60 * 24);
};

export const prepareFiltersForAbacus = (inputFilters: RuleGroupType): RuleGroupType => {
  const ABACUS_APPLICABLE_FILTERS: string[] = [
    MvdTypes.FilterGroup.GEO,
    MvdTypes.FilterGroup.AUDIENCE_CODE,
    MvdTypes.FilterGroup.GENDER,
    MvdTypes.FilterGroup.AGE,
    MvdTypes.FilterGroup.EDUCATION_CONDENSED,
    MvdTypes.FilterGroup.ETHNICITY,
    MvdTypes.FilterGroup.HOUSEHOLD_INCOME,
    MvdTypes.FilterGroup.MARITAL_STATUS,
    MvdTypes.FilterGroup.PARTY,
    MvdTypes.FilterGroup.PRESENCE_OF_CHILDREN,
    MvdTypes.FilterGroup.LIKELY_VOTERS,
    MvdTypes.FilterGroup.GENERATION,
    MvdTypes.FilterGroup.HISPANIC_ORIGIN,
    MvdTypes.FilterGroup.LANGUAGE,
    MvdTypes.FilterGroup.HISPANIC_LANGUAGE,
    MvdTypes.FilterGroup.HOME_OWNERSHIP,
    MvdTypes.FilterGroup.VOTER_FREQUENCY,
    MvdTypes.FilterGroup.EDUCATION,
  ];

  return {
    combinator: 'and',
    rules: inputFilters.rules.filter((rule) => rule.id && ABACUS_APPLICABLE_FILTERS.includes(rule.id)),
  };
};

export const getAudienceMetaByCategory = (category: MvdTypes.AudienceCategoryType) => {
  let title, description;
  switch (category) {
    case MvdTypes.AudienceCategoryEnum.ISSUE:
      title = 'Issue-oriented';
      description = '% of readers within issue-oriented category by audience and applied criteria/filters';
      break;
    case MvdTypes.AudienceCategoryEnum['MULTI-ISSUE']:
      title = 'Multi-issue pyschographic';
      description =
        '% of readers within selected multi-issue psychographic category by audience and applied criteria/filters';
      break;
    default:
      title = 'Audience';
  }
  return { title, description };
};

export const getAudienceMetaBySubCategory = (subCategory: string) => {
  let title, description;
  switch (subCategory) {
    case 'Role of Government':
      title = 'Role of Government';
      description =
        '% of readers within selected multi-issue psychographic category by audience and applied criteria/filters';
      break;
    case 'Education':
      title = 'Education';
      description =
        '% of readers within selected multi-issue psychographic category by audience and applied criteria/filters';
      break;
    case 'Behavioral':
      title = 'Behavioral';
      description = '% of readers within issue-oriented category by audience and applied criteria/filters';
      break;
    case 'Issue Oriented':
      title = 'Issue Oriented';
      description = '% of readers within issue-oriented category by audience and applied criteria/filters';
      break;
    default:
      title = 'Audience';
  }
  return { title, description };
};

export const userFriendlyAudienceCategoryLabel = (title: string | undefined) => {
  if (!title) return;
  switch (title) {
    case MvdTypes.AudienceCategoryEnum['MULTI-ISSUE']:
      return 'Multi-issue pyschographic';
    case MvdTypes.AudienceCategoryEnum.ISSUE:
      return 'Single-issue';
    default:
      return title;
  }
};
