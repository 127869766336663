import { Modal, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { useReadershipContext } from '../Readership/ReadershipContext';
import { HEADLINE_FIELD } from '../Readership/Widgets/TopArticles.widget';
import Chip from '../shared/Chip';
import useFilterGroup from './common/useFilterGroup';
import KeywordsFilter from './Keywords/KeywordsFilter';
import MoreFiltersSection from './MoreFiltersSection';

const { FilterGroup } = MvdTypes;
const operatorOrder = ['ilike', 'not ilike'];

export interface IKeywordsFilter {
  operator: string;
  value: string;
}

const MoreFilters: React.FC = () => {
  const { filters, applyFilters } = useReadershipContext();
  const [selectedSearchTerms, setSelectedSearchTerms] = React.useState<IKeywordsFilter[]>([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const handleTermAdd = (terms: IKeywordsFilter[]) => {
    const updatedTerms = [...selectedSearchTerms, ...terms].sort((a, b) => {
      return operatorOrder.indexOf(a.operator) - operatorOrder.indexOf(b.operator);
    });
    setSelectedSearchTerms(updatedTerms);
  };

  const handleKeywordTermRemove = (term: IKeywordsFilter) => {
    setSelectedSearchTerms((prevSelectedTerms) => {
      // Filter out the term you want to remove
      return prevSelectedTerms.filter((x) => x.value !== term.value);
    });
  };

  const resetKeywordsFilter = () => {
    setSelectedSearchTerms([]);
  };
  // TODO: Try to integrate into useFilterGroup
  const handleKeywordFilterApply = (newFilters: RuleGroupType) => {
    // Remove any existing rules with the RULE_GROUP_ID
    const filteredRules = newFilters.rules.filter((rule) => rule.id !== FilterGroup.KEYWORDS);

    // If selectedSearchTerms is empty, just return the filtered rules
    if (selectedSearchTerms.length === 0) {
      return {
        ...newFilters,
        rules: filteredRules,
      };
    }

    // Create the new rule group with the updated selectedSearchTerms
    const rules = selectedSearchTerms.map((item) => ({
      field: HEADLINE_FIELD,
      operator: item.operator,
      value: item.value,
    }));

    const ruleGroup: RuleGroupType = {
      id: FilterGroup.KEYWORDS,
      combinator: 'or',
      rules: rules,
    };

    // Add the new rule group to the filtered rules
    return {
      ...newFilters,
      rules: [...filteredRules, ruleGroup],
    };
  };

  useEffect(() => {
    const keywordFilter = filters.rules.find((rule) => rule.id === FilterGroup.KEYWORDS) as RuleGroupType;
    if (keywordFilter) {
      const values = keywordFilter.rules.map((rule: any) => ({ operator: rule.operator, value: rule.value }));
      setSelectedSearchTerms(values);
    } else {
      setSelectedSearchTerms([]);
    }
  }, [filters.rules.length]);

  const moreFilters = [
    useFilterGroup(FilterGroup.AGE, filters),
    useFilterGroup(FilterGroup.GENDER, filters),
    useFilterGroup(FilterGroup.EDUCATION_CONDENSED, filters),
    useFilterGroup(FilterGroup.ETHNICITY, filters),
    useFilterGroup(FilterGroup.HOUSEHOLD_INCOME, filters),
    useFilterGroup(FilterGroup.MARITAL_STATUS, filters),
    useFilterGroup(FilterGroup.PARTY, filters),
    useFilterGroup(FilterGroup.PRESENCE_OF_CHILDREN, filters),
    useFilterGroup(FilterGroup.LIKELY_VOTERS, filters),
  ];

  const applyNewFilters = () => {
    let newFilters = moreFilters.reduce((p: RuleGroupType | undefined, c) => c.apply(p), undefined);
    if (newFilters) {
      newFilters = handleKeywordFilterApply(newFilters);
      applyFilters(newFilters);
    }
  };

  const handleDoneClick = () => {
    applyNewFilters();
    setModalVisible(false);
  };

  const handleCancelClick = () => {
    setModalVisible(false);
    moreFilters.forEach((filter) => filter.resetTempFilters());
  };

  const appliedKeywordFilterCount =
    (filters.rules.find((rule) => rule.id === FilterGroup.KEYWORDS) as RuleGroupType)?.rules.length ?? 0;
  const appliedFiltersCount = moreFilters.reduce((p, c) => p + c.appliedFiltersCount, appliedKeywordFilterCount);

  return (
    <>
      <Chip
        leadingAction={<MUIcon name="tune" />}
        title={'More filters'}
        className={classNames('text-sm font-semibold', appliedFiltersCount > 0 ? 'border border-gray-800' : '')}
        onClick={() => setModalVisible(true)}
      >
        {appliedFiltersCount > 0 ? (
          <>
            <div className="w-1"></div>
            <div className="absolute -top-[5px] -right-[5px] inline-flex h-5 w-5 flex-col items-center justify-center gap-2.5 rounded-full bg-gray-800">
              <div className="text-[9px] font-semibold leading-3 text-white">{appliedFiltersCount}</div>
            </div>
          </>
        ) : null}
      </Chip>
      {modalVisible && (
        <Modal
          title="Filters"
          okLabel="Done"
          titleClasses="border-b pb-2"
          onDone={handleDoneClick}
          onCloseRequest={handleCancelClick}
          className="w-[700px]"
        >
          <div className="-mx-6 flex max-h-[600px] flex-col gap-2 overflow-auto pr-1 pl-6">
            <KeywordsFilter
              onTermAdd={handleTermAdd}
              selectedSearchTerms={selectedSearchTerms}
              onTermRemove={handleKeywordTermRemove}
              onResetFilter={() => resetKeywordsFilter()}
            />
            <div className="pt-3 font-semibold text-gray-800">Demographics</div>
            <div className="flex flex-col gap-3 text-sm text-gray-800">
              {moreFilters.map((x) => (
                <MoreFiltersSection
                  key={x.title}
                  title={x.title}
                  options={x.options}
                  onOptionClick={(opt) => x.addOption(opt)}
                />
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default MoreFilters;
