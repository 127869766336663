import classNames from 'classnames';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Dashboard/Sidebar/Sidebar';

const DashboardLayout: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  return (
    <div className="flex h-full w-full bg-slate-50">
      <div
        className={classNames('border-right flex h-full border border-gray-200', {
          'w-[120px]': !isExpanded,
          'w-[320px]': isExpanded,
        })}
      >
        <Sidebar isExpanded={isExpanded} expandedHandler={() => setIsExpanded(!isExpanded)} />
      </div>
      <div className="flex h-full w-full flex-grow overflow-auto">
        <div className="h-full w-full">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
