import { Buttons, Modal, MUIcon } from '@platform/shared/ui';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import FeedbackForm from './FeedbackForm';

const Info: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const infoObjects = [
    {
      title: 'Single Issue Methodology',
      paragraphs: [
        'Single Issue Audiences gauge whether a voter is likely to support a specific issue or agree with a particular sentiment (increased gun control policies, for example). The models are constructed by linking demographic and behavioral data with survey responses and training a predictive algorithm on the resulting dataset. The model assigns a score from 0 to 100 to every voter in the electorate, and a score cutoff value is used to determine that voter’s membership in the audience.',
        'We begin by deploying custom surveys on our websites - either across all topics or targeted to relevant content - or among members of our proprietary research panel for approximately a week, or until we gather enough responses. The responses are collected and matched, in a privacy-compliant manner, with over 750 attributes on the voter file. Predictive models are then built by comparing respondents who match the Q&A pairing with those who do not.',
        'The modeling methodology varies depending which method generates the best results. We also apply appropriate cutoffs to generate high lift when the model is tested in the holdout population. The model is then applied to the entirety of the voter file, so every single voter has a score. Voters with the appropriate scores for each model are then grouped and accessible as a custom, issue-based audience.',
      ],
    },
    {
      title: 'Multi-Issue/ Personas Methodology',
      paragraphs: [
        'Multi-Issue Psychographic Audience models (or Personas) categorize voters into cohorts based on their opinions on various political, economic, and social issues. We begin with the wealth of information about individual voters available on the L2 voter database.',
        'The models utilize a clustering technique that can take into consideration dozens of voter attitudes and motivations. We train the model on a sample of 2 million voters (about 1% of the entire voting population) and once we reach a viable clustering technique, we apply the model to the full voter file (approximately 213 million records). Each voter is categorized into one mutually exclusive cohort, as opposed to the scoring approach used for the Single-Issue Audience models.',
      ],
    },
  ];

  return (
    <div className="relative flex h-full w-full flex-col overflow-y-auto">
      <div className="transition-height sticky top-0 z-10 flex w-full justify-between border-b border-gray-200 bg-white px-8 py-4 pt-6 transition-all duration-300 ease-in">
        <div className="flex flex-col gap-10">
          <div className="text-xl font-semibold">Info</div>
        </div>
        <div className="flex gap-2">
          <Buttons.Secondary
            icon={<MUIcon name="text_ad" />}
            onClick={() => setShowModal(true)}
            className="border-primary-200 inline-flex items-center rounded-full border bg-white py-2.5 pl-4 pr-6 shadow-none ring-0"
          >
            Submit feedback
          </Buttons.Secondary>
          <Buttons.Primary
            icon={<MUIcon name="help" />}
            onClick={() => window.open('mailto:support@cinqdi.com', '_self')}
            className="bg-primary-800 inline-flex items-center justify-center gap-2 rounded-full py-2.5 pl-4 pr-6 text-center text-sm font-semibold text-white"
          >
            Need help
          </Buttons.Primary>
        </div>
      </div>
      <div className=" flex flex-grow flex-col gap-4 p-8">
        <div className=" inline-flex flex-col items-start justify-start gap-6 bg-gray-50">
          <div className="inline-flex h-12 items-center justify-start gap-4 pt-4 pb-2">
            <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
              <div className="self-stretch text-base font-normal text-gray-900">Methodology</div>
            </div>
          </div>
        </div>

        {infoObjects.map((infoObject, index) => (
          <div
            key={index}
            className="inline-flex flex-col items-start justify-start gap-2.5 rounded-2xl border border-gray-200 bg-white p-6"
          >
            <div className="inline-flex items-center justify-start gap-4 self-stretch">
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-1">
                <div className="self-stretch text-sm font-semibold text-gray-900">{infoObject.title}</div>
              </div>
            </div>
            <div className="inline-flex items-center justify-start gap-4 self-stretch">
              <div className="inline-flex shrink grow basis-0 flex-col items-start justify-center gap-1">
                <div className="flex flex-col self-stretch">
                  {infoObject.paragraphs.map((paragraph, index) => (
                    <p className="pt-6 text-sm font-normal text-gray-900" key={index}>
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="inline-flex flex-col items-start justify-start gap-2.5 px-8 pb-6">
        <div className="inline-flex items-center justify-start gap-4 self-stretch pt-4 pb-2">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
            <div className="self-stretch text-base font-normal leading-normal text-gray-900">Data and Privacy</div>
          </div>
        </div>
        <div className="flex w-full flex-col items-start justify-start gap-2 rounded-2xl border border-gray-200 bg-gray-100 px-6 py-4">
          <div className="inline-flex items-center justify-start gap-1 self-stretch">
            <div className="text-sm font-normal leading-normal text-gray-800">
              Use of and/or registration on any portion of this site constitutes acceptance of our
            </div>
            <Link
              to={'https://www.cinqdi.com/privacy-policy/'}
              target="_blank"
              className="text-sm font-semibold leading-[18.20px] text-gray-800 underline"
            >
              Privacy Policy
            </Link>
            <div className="text-sm font-normal leading-[18.20px] text-gray-800">and</div>
            <Link
              to={'https://www.cinqdi.com/terms-of-use/'}
              target="_blank"
              className="text-sm font-semibold leading-[18.20px] text-gray-800 underline"
            >
              Terms & Conditions.
            </Link>
          </div>
          <div className="self-stretch">
            <span className="text-sm font-normal leading-[18.20px] text-gray-800">
              The material on this site may not be reproduced, distributed, transmitted, cached or otherwise used,
              except with the prior written permission of MV Digital Group. © 2024 MV Digital Group. All rights
              reserved.
            </span>
            <span className="text-[13px] font-normal text-gray-800"> </span>
            <Link
              to={'https://www.advancelocal.com/about/'}
              target="_blank"
              className="text-sm font-semibold text-gray-800 underline"
            >
              About Us
              <br />
            </Link>
          </div>
        </div>
      </div>
      {showModal && (
        <Modal
          title="Submit feedback"
          okDisabled={true}
          hideActions={true}
          isVisible={true}
          onCloseRequest={() => setShowModal(false)}
        >
          <FeedbackForm onDone={() => setShowModal(false)} />
        </Modal>
      )}
    </div>
  );
};

export default Info;
