import { MUIcon } from '@platform/shared/ui';
import { NumberFormatter } from '@platform/utils';
import Highcharts, { merge } from 'highcharts';
import { useMemo, useState } from 'react';
import ChartOptionsMenu from '../components/shared/ChartOptionsMenu';
import useResizableChart from '../components/shared/useResizableChart';
import { ChartExportType, ChartType } from '../enums/widgetEnums';

const defaultChartTypeOptions = [ChartType.pie, ChartType.bar];
const defaultExportTypeOptions = [ChartExportType.image, ChartExportType.csv];

export interface Props {
  title: string;
  highchartsData: IHighchartsData;
  initialType?: ChartType;
  chartTypeOptions?: ChartType[];
  exportTypeOptions?: ChartExportType[];
  overrideOptions?: Highcharts.Options;
}

const useWidget = ({
  title,
  highchartsData,
  initialType = ChartType.pie,
  chartTypeOptions = defaultChartTypeOptions,
  exportTypeOptions = defaultExportTypeOptions,
  overrideOptions,
}: Props) => {
  const [chartType, setChartType] = useState<ChartType>(initialType);

  const exportChart = () => {
    if (chartComponentRef.current) {
      chartComponentRef.current.exportChart(
        {
          filename: `${title.replace(/\s/g, '_').toLowerCase()}_chart`,
        },
        {
          ...options,
          title: { text: title },
          chart: { marginTop: 40 },
          plotOptions: {
            pie: {
              dataLabels: {
                enabled: true, // Ensure data labels are enabled
                formatter: function () {
                  return NumberFormatter.format(this.percentage, '0,0[.]00%');
                },
                distance: 20, // label's position
                style: {
                  fontSize: '12px',
                  color: '#000',
                },
                crop: false,
                padding: 2,
                softConnector: true,
              },
            },
          },
        }
      );
    }
  };

  const DEFAULT_MENU_OPTIONS: MenuGroup[] = [
    {
      groupName: 'View as',
      options: [
        { type: ChartType.bar, label: 'Bar chart', onClick: () => setChartType(ChartType.bar) },
        { type: ChartType.pie, label: 'Pie chart', onClick: () => setChartType(ChartType.pie) },
      ],
    },
    {
      groupName: 'Export',
      options: [
        { type: ChartExportType.image, label: 'Image (.png)', icon: <MUIcon name="image" />, onClick: exportChart },
      ],
    },
  ];

  const filteredMenuOptions = DEFAULT_MENU_OPTIONS.map((group) => {
    const filteredOptions = group.options.filter((option) => {
      if (group.groupName === 'View as') {
        return chartTypeOptions.includes(option.type as ChartType);
      } else if (group.groupName === 'Export') {
        return exportTypeOptions.includes(option.type as ChartExportType);
      }
      return false;
    });

    return filteredOptions.length > 0 ? { ...group, options: filteredOptions } : null;
  }).filter((group): group is MenuGroup => group !== null);

  const options: Highcharts.Options = useMemo(() => {
    const defaultOptions: Highcharts.Options = {
      chart: {
        type: chartType,
      },
      legend: {
        enabled: chartType === 'pie',
      },
      xAxis: {
        categories: highchartsData.categories,
      },
      yAxis: {
        visible: false,
      },
      series: [
        {
          type: chartType,
          data:
            chartType === 'pie'
              ? highchartsData.data.map((item) => ({
                  ...item,
                  color: item.categorized === false ? '#D2D5DA' : undefined,
                }))
              : highchartsData.data,
        },
      ],
    };
    return merge({}, defaultOptions, overrideOptions);
  }, [chartType, highchartsData.categories, highchartsData.data, overrideOptions]);

  const [chart, chartComponentRef] = useResizableChart(options);

  const menu = <ChartOptionsMenu groupedOptions={filteredMenuOptions} selectedChartType={chartType} />;

  return { chart, menu };
};

export default useWidget;

export interface IHighchartsData {
  categories?: string[];
  data: IDataPoint[];
  dates?: string[];
}

interface ChartTypeOption {
  type: ChartType;
  label: string;
  icon?: JSX.Element;
  onClick: () => void;
}

interface ChartExportTypeOption {
  type: ChartExportType;
  label: string;
  icon?: JSX.Element;
  onClick: () => void;
}

export interface IDataPoint {
  name: string;
  y: number;
  count: number;
  percentage: number;
  categorized?: boolean;
}

export type MenuOption = ChartTypeOption | ChartExportTypeOption;

interface MenuGroup {
  groupName: string;
  options: MenuOption[];
}
