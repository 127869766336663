import { MvdTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';
import React from 'react';
import { ChartType } from '../../../enums/widgetEnums';
import useAudienceInsightsWidget from '../../../hooks/useAudienceInsightsWidget';
import { useAudienceContext } from '../AudienceContext';
import Container from './Container';
import useAudienceData from './useAudienceData';

const { FilterGroup } = MvdTypes;
const COLORS = ['#d0d6de', '#e4b3af', '#a4bcdf'];
const PartyAffiliation: React.FC = () => {
  const { filters, datasetId } = useAudienceContext();

  const { isLoading, data } = useAudienceData({
    datasetId,
    filters,
    filterGroupId: FilterGroup.PARTY,
    groupByFilterIds: [],
  });

  const overrideOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 98,
    },
    legend: {
      enabled: true,
      reversed: true,
    },
    xAxis: {
      categories: [''],
    },
    yAxis: {
      title: undefined,
      gridLineWidth: 0,
      labels: {
        enabled: false,
      },
      max: 100,
    },
    series: data?.reverse().map((item: any, index) => {
      return {
        type: 'bar',
        name: item.name,
        color: COLORS[index],
        data: [
          {
            y: item.data[0]?.y,
            name: item.name,
            percentage: item.data[0]?.percentage,
            description: '',
            count: item.data[0]?.count,
          },
        ],
        dataLabels: {
          enabled: true,
          style: {
            color: '#000',
            textShadow: 'none',
            textOutline: 'none',
          },
        },
        pointWidth: 32,
        pointPadding: 0.1,
        groupPadding: 0.1,
      };
    }),
    plotOptions: {
      series: {
        stacking: 'percent',
        events: {
          legendItemClick: function () {
            return false;
          },
        },
      },
    },
    tooltip: {
      useHTML: true,
      formatter: function () {
        const point = this.point as Highcharts.Point & {
          percentage: number;
          count: number;
          description: string;
        };
        return (
          `<div style=" display: flex; flex-direction: column; justify-content: start; align-items: start; gap: 12px;">` +
          `<div style="color: #1F2937; font-size: 12px; font-weight: 600; font-family: 'Inter', sans-serif; line-height: 1;">${point.name}</div>` +
          `<div style="display: flex; flex-direction: column; justify-content: start; align-items: start; gap: 4px;">` +
          `<div style="color: #4B5563; font-size: 11px; font-weight: 400; font-family: 'Inter', sans-serif; line-height: 1.3;">${point.description}</div>` +
          `<div style="text-align: right; color: #1F2937; font-size: 14px; font-weight: 600; font-family: 'Inter', sans-serif; line-height: 1.3;">${NumberFormatter.format(
            point.percentage,
            '0,0[.]00%'
          )}</div>` +
          `<div style="color: #4B5563; font-size: 11px; font-weight: 400; font-family: 'Inter', sans-serif; line-height: 1.3;">(${NumberFormatter.format(
            point.count,
            '0,0'
          )})</div>` +
          `</div>` +
          `</div>`
        );
      },
    },
  };

  const exportOptions: Highcharts.Options = {
    chart: {
      type: 'bar',
      height: 120,
      marginTop: 40,
    },
  };

  const title = 'Party Affiliation';
  const { chart, menu } = useAudienceInsightsWidget({
    title,
    data: data ?? [],
    chartTypeOptions: [ChartType.bar],
    initialType: ChartType.bar,
    overrideOptions,
    useDefaultOptions: false,
    exportOptions,
    groupingOptions: [],
  });

  return (
    <Container title={title} loading={isLoading} actions={menu}>
      {chart}
    </Container>
  );
};

export default PartyAffiliation;
