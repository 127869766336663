import { Menu, MUIcon, Placeholder, Spinner } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { useQuery } from 'react-query';
import * as api from '../../../../mvd.api';
import MvdMasterMapping from '../../Filters/MvdMasterMapping';

const { FilterGroup } = MvdTypes;

interface Props {
  url: string;
  title: string;
  count: string;
  idx: number;
  mode?: 'exclude' | 'single' | 'default';
  expanded?: boolean;
  topicsRaw?: string;
  onArticleExclude?: (url: string) => void;
  onSingleArticleSelect?: (url: string) => void;
}

const TopArticleItem: React.FC<Props> = ({
  title,
  url,
  expanded,
  count,
  idx,
  mode,
  onArticleExclude,
  topicsRaw = '',
  onSingleArticleSelect,
}) => {
  const menuOptions: {
    prefixIcon: React.ReactNode;
    title: string;
    clickHandler: string;
    additionalStyle?: string;
  }[] = [
    {
      prefixIcon: <MUIcon name="playlist_remove" />,
      title: 'Exclude this article',
      clickHandler: 'exclude',
    },
    {
      prefixIcon: <MUIcon name="article_shortcut" />,
      title: 'Only this article',
      clickHandler: 'single-article',
      additionalStyle: 'border-b border-gray-200',
    },
    {
      prefixIcon: <MUIcon name="link" />,
      title: 'Go to article',
      clickHandler: 'go-to-article',
    },
  ];
  const [menuOpen, setMenuOpen] = React.useState(false);
  const headlineDetailsQuery = useQuery(['headline-details', url], () => api.getHeadlineDetails(url), {
    onSuccess: async (details: MvdTypes.IHeadlineDetails) => {
      if (details) return;
      try {
        // we do not have details available so go, fetch it
        await api.fetchArticleMetadata(url);
        headlineDetailsQuery.refetch();
      } catch (e) {
        console.error(e);
      }
    },
    retry: 2,
  });

  const { img, description, publishedAt, title: detailsTitle, url: detailsUrl } = headlineDetailsQuery.data ?? {};
  const urlToUse = detailsUrl || url;
  const titleToUse = detailsTitle || title;
  const topicCategories = MvdMasterMapping.getMappingsByFilterGroup(FilterGroup.TOPIC);
  const filteredMenuOptions =
    mode === 'single' ? menuOptions.filter((option) => option.clickHandler === 'go-to-article') : menuOptions;

  const menuOptionClickHandler = (option: string) => {
    switch (option) {
      case 'exclude':
        onArticleExclude && onArticleExclude(url);
        break;
      case 'single-article':
        onSingleArticleSelect && onSingleArticleSelect(url);
        break;
      case 'go-to-article':
        window.open(urlToUse, '_blank');
        break;
      default:
        console.error('No option');
    }

    setMenuOpen(false);
  };

  const categories = new Set(
    topicsRaw.split('|').reduce((p: string[], topic: string) => {
      const category = topicCategories.find((category) => category.value?.toString().includes(topic));
      if (!category) return p;
      return [...p, category.label];
    }, [])
  );
  const categoriesSize = categories.size - 1 === 0 ? null : `+${categories.size - 1}`;
  return (
    <div className="flex w-full items-center gap-6 border-b px-4 py-3.5">
      <div className="w-0.5/12 text-xs font-semibold text-gray-800">{idx + 1}</div>
      <div className="flex w-9/12 min-w-0 flex-grow flex-col gap-3">
        <div className="truncate text-sm font-semibold text-gray-900">{titleToUse}</div>
        {expanded && <span className="text-sm text-gray-500">{description ?? <Placeholder classNames="w-32" />}</span>}
        <div className="flex items-center gap-3.5 text-xs text-gray-500">
          {publishedAt && (
            <span className="flex items-center gap-1 overflow-hidden">
              {moment(publishedAt).format('MMM DD, YYYY')}
            </span>
          )}
          {categories.size > 0 && (
            <div
              className="flex max-w-[120px] items-center rounded-xl border bg-gray-100 px-3 py-1 text-xs font-semibold text-gray-800 hover:cursor-pointer"
              data-rh={`${Array.from(categories).join(', ')}`}
            >
              <p className={classNames('w-full truncate', { 'w-10/12': categoriesSize != null })}>
                {Array.from(categories)[0]}
              </p>
              {categoriesSize && <p className="w-2/12 pr-3">{categoriesSize}</p>}
            </div>
          )}
        </div>
      </div>

      <div
        className={classNames('flex flex-shrink-0 items-center justify-center overflow-hidden rounded-lg bg-gray-400', {
          'h-[48px] w-[80px]': !expanded,
          'h-[110px] w-[180px]': expanded,
        })}
      >
        {img ? (
          <div style={{ backgroundImage: `url(${img})` }} className="h-full w-full bg-cover bg-center" />
        ) : (
          <Spinner width={42} height={42} fillColor="rgba(255, 255,255,0.4)" />
        )}
      </div>

      <div className="w-0.5/12 flex items-center justify-center">
        <Menu
          trigger={<MUIcon name="more_vert" className="text-gray-600" />}
          visible={menuOpen}
          onToggleVisibility={(v) => setMenuOpen(v)}
          menuClasses="shadow-lg"
        >
          <div className="flex flex-col rounded-lg text-sm text-gray-800">
            {filteredMenuOptions.map((item, idx) => (
              <button
                key={idx}
                onClick={() => menuOptionClickHandler(item.clickHandler)}
                className={`flex w-full items-center gap-3 py-4 px-6 last:border-none hover:bg-gray-100 ${item.additionalStyle}`}
              >
                <div className="flex items-center justify-center text-gray-600">{item.prefixIcon}</div>
                {item.title}
              </button>
            ))}
          </div>
        </Menu>
      </div>
    </div>
  );
};

export default TopArticleItem;
