import { MvdTypes } from '@platform/types';
import React from 'react';
import useWidget from '../../../hooks/useWidget';
import ExpandableGridItem, { GridPosition } from '../../shared/ExpandableGridItem';
import { useReadershipContext } from '../ReadershipContext';
import useReadershipData, { IDataPoint } from './useReadershipData';
import WidgetInfo from './WidgetInfo';
import { WidgetQueryKeys } from './WidgetQueryKeys';

const { FilterGroup } = MvdTypes;

interface Props {
  grid: GridPosition;
}

const PartyWidget: React.FC<Props> = ({ grid }) => {
  const { filters, queryEnabled } = useReadershipContext();
  const { isLoading, data, title } = useReadershipData({
    filters,
    queryEnabled,
    filterGroupId: FilterGroup.PARTY,
    queryKey: WidgetQueryKeys.PARTY,
  });

  const highchartsData = convertToHighchartsData(data ?? []);
  const { chart, menu } = useWidget({ title, highchartsData });

  return (
    <ExpandableGridItem
      title={title}
      loading={isLoading}
      grid={grid}
      actions={menu}
      info={<WidgetInfo title={title} description="% of readers by political party within selected criteria/filters" />}
    >
      {chart}
    </ExpandableGridItem>
  );
};

export default PartyWidget;

interface IHighchartsData {
  categories: string[];
  data: IDataPoint[];
}

const convertToHighchartsData = (data: IDataPoint[]): IHighchartsData => ({
  data,
  categories: data.map((item) => item.name),
});
