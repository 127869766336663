import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from './app/app';
import { ApplicationContextProvider } from './app/ApplicationContext';

import * as Sentry from '@sentry/react';

// initialize Sentry
Sentry.init({
  enabled: ['production', 'development'].includes(process.env.NX_APP_ENV as string),
  environment: process.env.NX_APP_ENV,
  dsn: 'https://d3c977084b9b7d90fd96a982a0c9fb0f@o107988.ingest.us.sentry.io/4508064150519808',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', process.env.NX_PE_API as string],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.NX_APP_ENV === 'production' ? 0.5 : 1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      retryDelay: 0,
      staleTime: 5 * (60 * 1000), // 5 min
      cacheTime: 10 * (60 * 1000), // 10 min
    },
  },
});

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ApplicationContextProvider>
          <App />
        </ApplicationContextProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </StrictMode>
);
