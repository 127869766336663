import { MvdTypes } from '@platform/types';
import produce from 'immer';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import MvdMasterMapping, { IFilterOption } from '../MvdMasterMapping';

const useFilterGroup = (
  groupId: MvdTypes.FilterGroup,
  filters: RuleGroupType,
  combinator = 'or'
): {
  title: string;
  appliedFiltersCount: number;
  options: IFilterOption[];
  allOptionsSelected: boolean;
  someOptionsSelected: boolean;
  hasNotAppliedChanges: boolean;
  appliedOptionsNames: string[];
  addOption: (optionToAdd: IFilterOption, only?: boolean, applyImmediately?: boolean) => RuleGroupType | undefined;
  addAllOptions: () => void;
  apply: (filters?: RuleGroupType, group?: RuleGroupType) => RuleGroupType;
  resetToDefault: () => RuleGroupType;
  resetTempFilters: () => void;
} => {
  const defaultGroup = useMemo(
    () => ({
      id: groupId,
      combinator,
      rules: [],
    }),
    [groupId, combinator]
  );

  const defTempGroup = (filters.rules.find((r) => r.id === groupId) as RuleGroupType) || defaultGroup;
  const [tempGroup, setTempGroup] = useState<RuleGroupType>(defTempGroup);

  useEffect(() => setTempGroup(defTempGroup), [filters]);

  const resetToDefault = useCallback(() => {
    setTempGroup(defaultGroup);

    return produce(filters, (draft) => {
      const ruleGroupIndex = draft.rules.findIndex((rule) => rule?.id === groupId);
      if (ruleGroupIndex > -1) {
        draft.rules.splice(ruleGroupIndex, 1);
      }
    });
  }, [filters, groupId, defaultGroup]);

  const resetTempFilters = useCallback(() => {
    const group = (filters.rules.find((r) => r.id === groupId) as RuleGroupType) || defaultGroup;
    setTempGroup(group);
    return group;
  }, [filters, groupId, defaultGroup]);

  const apply = (filtersToUpdate: RuleGroupType = filters, groupToApply = tempGroup) =>
    produce(filtersToUpdate, (draft) => {
      const ruleGroupIndex = draft.rules.findIndex((rule) => rule?.id === groupId);

      if (ruleGroupIndex === -1) {
        // group doesn't exist yet, so add it only if there are rules
        if (groupToApply.rules.length) {
          draft.rules.push(groupToApply);
        }
      } else {
        // group exits already, check if it needs to be updated or removed depending on number of temp rules
        if (groupToApply.rules.length) {
          // there are rules so update it
          draft.rules[ruleGroupIndex] = groupToApply;
        } else {
          // all rules removed so remove the group, too
          draft.rules.splice(ruleGroupIndex, 1);
        }
      }
    });

  const addOption = (optionToAdd: IFilterOption, only = false, applyImmediately = false): RuleGroupType | undefined => {
    const newRule: RuleType = {
      id: optionToAdd.id?.toString(),
      value: optionToAdd.value,
      field: optionToAdd.field,
      operator: optionToAdd.operator,
    };

    const updatedTempGroup: RuleGroupType = produce(tempGroup, (draft) => {
      const existingRuleIndex = draft.rules.findIndex(
        (rule) =>
          (rule as RuleType).field === newRule.field && (rule as RuleType).value.toString() === newRule.value.toString()
      );
      if (only) {
        draft.rules = [newRule];
        return;
      }
      if (existingRuleIndex === -1) {
        draft.rules = [...draft.rules, newRule];
      } else {
        draft.rules.splice(existingRuleIndex, 1);
      }
    });

    setTempGroup(updatedTempGroup);

    if (applyImmediately) {
      return apply(filters, updatedTempGroup);
    }
  };

  const addAllOptions = () => {
    const rules = options.map((x) => ({
      value: x.value,
      field: x.field,
      operator: x.operator,
    }));
    const updatedTempGroup: RuleGroupType = produce(tempGroup, (draft) => {
      draft.rules = rules;
    });
    setTempGroup(updatedTempGroup);
  };

  const hasNotAppliedChanges = useMemo(() => {
    const appliedRuleGroup = (filters.rules.find((rule) => rule.id === groupId) as RuleGroupType) || defaultGroup;
    const appliedRules = new Set(appliedRuleGroup.rules.map((rule) => JSON.stringify(rule)));
    const temporaryRules = new Set(tempGroup.rules.map((rule) => JSON.stringify(rule)));

    return appliedRules.size !== temporaryRules.size || [...temporaryRules].some((rule) => !appliedRules.has(rule));
  }, [filters.rules, defaultGroup, tempGroup?.rules, groupId]);

  const options = MvdMasterMapping.getMappingsByFilterGroup(groupId).map((x): IFilterOption => {
    const checked =
      tempGroup.rules.find(
        (rule) => (rule as RuleType).field === x.field && (rule as RuleType).value.toString() === x.value?.toString()
      ) != null;

    return {
      checked,
      id: x.id,
      name: x.label,
      value: x.value,
      field: x.field,
      group: groupId,
      operator: x.operator ?? '',
      description: x.description,
    };
  });

  const appliedOptionsNames = useMemo(
    () =>
      (defTempGroup.rules as RuleType[]).map(
        (rule) =>
          options.find(
            (opt) => opt.field === rule.field && (rule as RuleType).value.toString() === opt.value?.toString()
          )?.name as string
      ),
    [options, defTempGroup.rules]
  );

  const appliedFiltersCount =
    ((filters.rules.find((r) => r.id === groupId) as RuleGroupType)?.rules as RuleType[])?.length ?? 0;

  const title = MvdMasterMapping.getGroupTitle(groupId);

  return {
    title,
    options,
    appliedFiltersCount,
    allOptionsSelected: tempGroup.rules.length === options.length,
    someOptionsSelected: tempGroup.rules.length > 0 && tempGroup.rules.length !== options.length,
    hasNotAppliedChanges,
    appliedOptionsNames,
    addOption,
    addAllOptions,
    resetToDefault,
    resetTempFilters,
    apply,
  };
};

export default useFilterGroup;
