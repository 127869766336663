import { MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React from 'react';
import { IKeywordsFilter } from '../MoreFilters';

interface IProps {
  term: IKeywordsFilter;
  onTermRemove: (terms: IKeywordsFilter) => void;
}

const KeywordsFilterItem: React.FC<IProps> = ({ term, onTermRemove }) => {
  const isInclude = term?.operator === 'ilike';
  return (
    <div
      className={classNames(
        'flex max-w-[140px] items-center justify-between gap-3 rounded-full border border-gray-200 px-2 py-2 text-sm hover:cursor-pointer',
        { 'bg-indigo-100': isInclude }
      )}
    >
      <div className="flex w-9/12 items-center justify-center gap-1">
        {!isInclude && <div className="h-[1px] w-[10px] w-1/12 bg-red-600"></div>}
        <p className={classNames('truncate pl-2 font-semibold', { 'w-11/12': !isInclude })} data-rh={term.value}>
          {term.value}
        </p>
      </div>

      <button onClick={() => onTermRemove(term)} className="flex items-center justify-center">
        <MUIcon name="close" className="text-gray-700 hover:cursor-pointer" />
      </button>
    </div>
  );
};

export default KeywordsFilterItem;
