import { ButtonGroup } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import React from 'react';
import ActivationDataType from './ActivationDataType';
import ActivationPlatformType from './ActivationPlatformType';
import { useActivationsContext } from './ActivationsContext';

interface IProps {
  platformRef: React.Ref<{ triggerSubmit: () => Promise<void> } | null>;
  dataRef: React.Ref<{ triggerSubmit: () => Promise<void> } | null>;
}

const ActivationType: React.FC<IProps> = ({ platformRef, dataRef }) => {
  const { activation, setActivation } = useActivationsContext();
  const changeActivationType = (activationType: string) => {
    if (activationType === 'platform') {
      setActivation({ ...activation, downloadType: null, activationType });
    } else {
      setActivation({ ...activation, platform: null, activationType });
    }
  };

  return (
    <div className="flex w-full flex-col gap-8 pb-3">
      <ButtonGroup
        options={MvdTypes.ActivationOptions}
        value={activation.activationType}
        onChange={(v) => changeActivationType(v as string)}
      />
      {activation.activationType === MvdTypes.ActivationOptions[0].value ? (
        <ActivationPlatformType ref={platformRef} />
      ) : (
        <ActivationDataType ref={dataRef} />
      )}
    </div>
  );
};

export default ActivationType;
