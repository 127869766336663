import React, { useMemo } from 'react';
import { ChartType } from '../../../../enums/widgetEnums';
import useWidget from '../../../../hooks/useWidget';
import ExpandableGridItem, { GridPosition } from '../../../shared/ExpandableGridItem';
import { IDataPoint } from '../useReadershipData';
import WidgetInfo from '../WidgetInfo';

interface Props {
  grid: GridPosition;
  data: IDataPoint[];
  title: string;
  subtitle?: string;
  description?: string;
  limit?: number;
  isLoading?: boolean;
}

const AudienceWidget: React.FC<Props> = ({ grid, limit, data, title, isLoading, description, subtitle }) => {
  const highchartsData = useMemo(() => convertToHighchartsData(data ?? [], limit), [data, limit]);
  const { chart, menu } = useWidget({ title, highchartsData, initialType: ChartType.bar, chartTypeOptions: [] });

  return (
    <ExpandableGridItem
      subtitle={subtitle}
      title={title}
      loading={isLoading}
      grid={grid}
      actions={menu}
      info={<WidgetInfo title={title} description={description} />}
    >
      {chart}
    </ExpandableGridItem>
  );
};

export default AudienceWidget;

interface IHighchartsData {
  categories: string[];
  data: IDataPoint[];
}

const convertToHighchartsData = (data: IDataPoint[], limit?: number): IHighchartsData => {
  const categories: string[] = [];
  const chartData: IDataPoint[] = [];

  data
    .sort((a, b) => {
      if (a.categorized === b.categorized) {
        return b.count - a.count;
      } else {
        return Number(b.categorized) - Number(a.categorized);
      }
    })
    .slice(0, limit || data.length)
    .forEach((item) => {
      categories.push(item.name);
      chartData.push(item);
    });

  return {
    categories,
    data: chartData,
  };
};
