import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import * as api from '../../../../mvd.api';
import MvdMasterMapping, { MappedFilterValue } from '../../Filters/MvdMasterMapping';
import useReadershipRequest from './useReadershipRequest';
import useReadershipWhereClause from './useReadershipWhereClause';

interface IParams {
  filterGroupId: string;
  filters: RuleGroupType;
  queryEnabled: boolean;
  queryKey: string;
  uncategorizedLabel?: string;
  excludeNotAvailableData?: boolean;
  limit?: number;
  offset?: number;
}

const useReadershipData = ({
  filterGroupId,
  filters,
  queryEnabled,
  queryKey,
  limit,
  offset,
  uncategorizedLabel = 'Unknown',
  excludeNotAvailableData = false,
}: IParams): {
  title: string;
  description?: string;
  data: IDataPoint[] | undefined;
  isLoading: boolean;
  isError: boolean;
  unknownCount: number;
  knownCount: number;
  totalCount: number;
} => {
  const [rawData, setRawData] = useState<IResponseRawData[] | null>(null);
  const whereClause = useReadershipWhereClause(filters);
  const group = MvdMasterMapping.getGroup(filterGroupId);

  const sql = useMemo(() => {
    let selectCases: string[] = [];
    const entries = MvdMasterMapping.getMappingsByFilterGroup(filterGroupId);

    const filterGroup = filters.rules.find((group) => group.id === filterGroupId) as RuleGroupType;
    if (filterGroup) {
      // filter is applied, select a subset according to filter
      const isMultiColumn = new Set(entries.map((x) => x.field)).size > 1;
      selectCases = filterGroup.rules.map((x) => {
        const rule = x as RuleType;
        const entry = entries.find((entry) => entry.field === rule.field) as MappedFilterValue;
        let column, alias;
        if (isMultiColumn) {
          column = rule.field;
          alias = entry.label;
        } else {
          column = rule.field;
          alias = entry.label;
        }
        const typedValues = getValues(entry);
        return `WHEN "${column}" ${entry.operator} ${typedValues} THEN '${alias}'`;
      });
    } else {
      // select all
      selectCases = entries.map((entry) => {
        const typedValues = getValues(entry);
        return `WHEN "${entry.field}" ${entry.operator} ${typedValues} THEN '${entry.label}'`;
      });
    }

    const isSingleEntryGroup = entries.length === 1 && entries[0].operator == null;
    const cases = `CASE ${selectCases.join(' ')} ELSE '${uncategorizedLabel}' END`;
    const selectsAsSql = isSingleEntryGroup ? `${entries[0].field} as name` : `${cases} as name`;

    const groupByAsSql = isSingleEntryGroup ? `GROUP BY ${entries[0].field}` : `GROUP BY ${cases}`;
    const limitAsSql = limit ? `LIMIT ${limit}` : '';
    const offsetAsSql = offset ? `OFFSET ${offset}` : '';

    return `
        SELECT ${selectsAsSql}, COUNT(DISTINCT visitorid) AS count
        FROM
        WHERE ${whereClause} and "geo.region" != '' ${groupByAsSql} ${limitAsSql} ${offsetAsSql};`;
  }, [filters.rules, uncategorizedLabel, limit, offset, whereClause, filterGroupId]);

  const pqsQuery = useReadershipRequest({ filters, sql });

  const rawDataQuery = useQuery([queryKey, pqsQuery], () => api.getDataPqs<IResponseRawData>(pqsQuery, filterGroupId), {
    staleTime: Infinity,
    enabled: queryEnabled,
  });

  useEffect(() => {
    if (!rawDataQuery.data) return;
    setRawData(rawDataQuery.data);
  }, [rawDataQuery.data]);

  const unknownCount = Number(rawData?.find((x) => x.name === uncategorizedLabel)?.count ?? 0);
  const knownCount: number =
    rawData?.filter((x) => x.name !== uncategorizedLabel).reduce((acc: number, cur) => acc + Number(cur.count), 0) ?? 0;
  const totalCount = unknownCount + knownCount;

  const data: IDataPoint[] | undefined = useMemo(
    () =>
      rawData
        ?.filter((x: IResponseRawData) => (excludeNotAvailableData ? x.name !== uncategorizedLabel : true))
        ?.sort((a: IResponseRawData, b: IResponseRawData) => {
          const orderingA = group?.columns.find((x) => x.label === a.name)?.ordering ?? Number.MAX_SAFE_INTEGER;
          const orderingB = group?.columns.find((x) => x.label === b.name)?.ordering ?? Number.MAX_SAFE_INTEGER;
          return orderingA - orderingB;
        })
        ?.map((rawData) => ({
          name: rawData.name,
          y: totalCount ? (Number(rawData.count) * 100) / totalCount : 0,
          count: Number(rawData.count),
          percentage: totalCount ? (Number(rawData.count) * 100) / totalCount : 0,
          categorized: rawData.name !== uncategorizedLabel,
        })),
    [group, excludeNotAvailableData, rawData, totalCount, uncategorizedLabel]
  );

  const isLoading = rawDataQuery.isIdle || rawDataQuery.isLoading;
  const isError = rawDataQuery.isError;
  return {
    unknownCount,
    knownCount,
    totalCount,
    data,
    isLoading,
    isError,
    title: group.title ?? '',
    description: group.description,
  };
};

export default useReadershipData;

const getValues = (entry: MappedFilterValue) => {
  switch (entry.operator) {
    case 'in':
      return `(${Array.from(entry.value as string)
        .map((value) => wrapValue(value))
        .join(',')})`;
    default:
      return wrapValue(entry.value);
  }
};

const wrapValue = (value: unknown) => (typeof value === 'string' ? `'${value}'` : value);

export interface IDataPoint {
  name: string;
  y: number;
  count: number;
  percentage: number;
  categorized: boolean;
}

interface IResponseRawData {
  name: string;
  count: string;
}
