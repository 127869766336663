import { Buttons, Inputs } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link } from 'react-router-dom';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { EMAIL_REGEX } from '../../helpers/helpers';
import { generateLink } from '../../LinkGenerator';
import Footer from './Footer';
import Header from './Header';

type Inputs = {
  email: string;
};
const ForgotPassword: React.FC = () => {
  const { notify } = useApplicationContext();
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      email: '',
    },
  });
  const watchedFormData = watch();
  const forgotPasswordMutation = useMutation({
    mutationFn: (payload: MvdTypes.IForgotPassword) => mvdApi.forgotPassword(payload),

    onSuccess: () => {
      notify({
        hideAfterSec: 5,
        content: `An email has been sent to ${watchedFormData.email} with instructions to reset your password`,
      });
    },
  });
  const isValid = EMAIL_REGEX.test(watchedFormData.email);
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const payload: MvdTypes.IForgotPassword = {
      email: data.email,
    };

    forgotPasswordMutation.mutate(payload);
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-between bg-gray-50">
      <Header />
      <div className="flex w-[576px] flex-col items-center justify-center">
        <form className="inline-flex  w-[576px] flex-col items-start justify-start rounded-2xl border border-gray-200 bg-white px-12 pb-6 shadow">
          <div className="flex h-[68px] flex-col items-start justify-start gap-6 self-stretch pt-8">
            <div className="self-stretch text-2xl font-bold text-gray-800">Forgot password?</div>
          </div>
          <div className="text-sm font-normal leading-normal text-black">
            Enter your account's email address and we'll send you a link to reset your password.
          </div>
          <Controller
            control={control}
            name="email"
            rules={{
              required: 'Email is required',
              pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email address' },
            }}
            render={({ field }) => (
              <div className="flex w-full flex-col items-start justify-end gap-1 py-3 pt-9">
                <label htmlFor="email" className="text-primary-800 text-xs font-semibold">
                  Email
                </label>
                <Inputs.Input
                  type="email"
                  classes={classNames(
                    'h-[42px] px-0 font-normal ring-gray-300 active:ring-gray-600 active:border-gray-600 focus-within:border-gray-600',
                    { 'ring-gray-600 border-gray-600': field.value }
                  )}
                  roundedClasses="rounded-md"
                  inputClasses="text-sm font-normal"
                  id="email"
                  placeholder="Email"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  data-cy="email-input"
                />
                {errors.email && <div className="text-xs font-normal text-red-500">{errors.email.message}</div>}
              </div>
            )}
          />
          <div className="w-full py-4">
            <Buttons.Primary
              type="submit"
              disabled={!isValid || forgotPasswordMutation.isLoading}
              data-cy="submit-button"
              className="bg-primary-800 inline-flex w-full items-center justify-center gap-2 px-6 py-2.5 text-center text-sm font-semibold disabled:bg-gray-200 disabled:text-gray-500"
              onClick={handleSubmit(onSubmit)}
            >
              Send reset link
            </Buttons.Primary>
          </div>
          <div className="inline-flex w-full items-center justify-center gap-1">
            <Link to={generateLink('login')} className="text-center text-[13px] font-semibold text-teal-500">
              Return to login
            </Link>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};
export default ForgotPassword;
