import { MvdTypes } from '@platform/types';
import React from 'react';
import useAudienceInsightsWidget from '../../../hooks/useAudienceInsightsWidget';
import { useAudienceContext } from '../AudienceContext';
import Container from './Container';
import useAudienceData from './useAudienceData';

const { FilterGroup } = MvdTypes;

const AgeByParty: React.FC = () => {
  const { filters, datasetId } = useAudienceContext();

  const { isLoading, data } = useAudienceData({
    datasetId,
    filters,
    filterGroupId: FilterGroup.PARTY,
    groupByFilterIds: [FilterGroup.AGE],
  });

  const { chart, menu } = useAudienceInsightsWidget({
    title: 'Age',
    data: data ?? [],
  });

  const title = 'Age';

  return (
    <Container title={title} loading={isLoading} actions={menu}>
      {chart}
    </Container>
  );
};
export default AgeByParty;
