import { MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CinqLogo from '../../../../assets/cinqdi-logo.png';
import CinqLogoSmall from '../../../../assets/cinqdi-small.png';
import { useApplicationContext } from '../../../ApplicationContext';
import { generateLink } from '../../../LinkGenerator';
import Navigation from './Navigation';

interface Props {
  isExpanded: boolean;
  expandedHandler: () => void;
}

const Sidebar: React.FC<Props> = ({ isExpanded, expandedHandler }) => {
  const { logout } = useApplicationContext();
  const location = useLocation();
  return (
    <div className="relative flex h-full w-full flex-col justify-between py-4">
      <div className="flex flex-col gap-8">
        <div className="relative flex items-center justify-between">
          {isExpanded ? (
            <img src={CinqLogo} width={80} height={24} className={'mx-5 my-3'} alt="CinQ DI Logo" />
          ) : (
            <img src={CinqLogoSmall} width={40} height={24} className={'mx-8 my-1'} alt="CinQ DI Logo" />
          )}
        </div>
        <button
          className="absolute -right-3 top-6 z-20 flex h-[24px] w-[24px] items-center justify-center rounded-full border border-gray-300 bg-white shadow-lg"
          onClick={() => expandedHandler()}
        >
          <MUIcon name={isExpanded ? 'chevron_left' : 'chevron_right'} />
        </button>
        <Navigation isExpanded={isExpanded} />
      </div>
      <div className="flex flex-col px-4">
        <Link
          to={generateLink('info')}
          className={classNames(
            'hover:bg-primary-200 flex w-full select-none items-center gap-4 rounded-full py-3 px-3 text-sm text-gray-600 hover:cursor-pointer',
            {
              'bg-gray-800 hover:bg-gray-800': location.pathname.endsWith('info'),
              'text-white': location.pathname.endsWith('info'),
              'justify-center': !isExpanded,
            }
          )}
        >
          <MUIcon className="text-[24px]" name="info" />
          {isExpanded && <p className="font-semibold">Info</p>}
        </Link>
        <Link
          to={generateLink('account')}
          className={classNames(
            'hover:bg-primary-200 flex w-full select-none items-center gap-4 rounded-full py-3 px-3 text-sm text-gray-600 hover:cursor-pointer',
            {
              'bg-gray-800 hover:bg-gray-800': location.pathname.endsWith('account'),
              'text-white': location.pathname.endsWith('account'),
              'justify-center': !isExpanded,
            }
          )}
        >
          <MUIcon className="text-[24px]" name="person" />
          {isExpanded && <p className="font-semibold">Account</p>}
        </Link>
        <button
          className={classNames(
            'flex w-full select-none items-center gap-4 rounded-full py-3 px-3 text-sm text-gray-600 hover:cursor-pointer hover:bg-gray-200',
            { 'justify-center': !isExpanded }
          )}
          onClick={logout}
        >
          <MUIcon className="text-[24px]" name="logout" />
          {isExpanded && <p className="font-semibold">Log out</p>}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
