import { Buttons, MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React from 'react';
import { userFriendlyAudienceCategoryLabel } from '../../helpers/helpers';
import Spinner from '../Readership/Widgets/Spinner';

type Position = [number, number]; // [start, span]

export type GridPosition = {
  row: Position;
  col: Position;
  expanded?: {
    row: Position;
    col: Position;
  };
};

interface Props {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  grid: GridPosition;
  info?: React.ReactNode;
  loading?: boolean;
  actions?: React.ReactNode;
  actionsClassNames?: string;
  full?: boolean;
}

const ExpandableGridItem: React.FC<Props> = ({
  title,
  subtitle,
  children,
  full,
  info,
  grid,
  loading,
  actions,
  actionsClassNames,
}) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const position = isExpanded && grid.expanded ? grid.expanded : grid;
  const canExpand = grid.expanded != null;
  const [gridRowStart, gridRowSpan] = position.row;
  const [gridColumnStart, gridColSpan] = position.col;

  return (
    <div
      style={{
        gridRowStart,
        gridColumnStart,
        gridRowEnd: gridRowStart + gridRowSpan,
        gridColumnEnd: gridColumnStart + gridColSpan,
        zIndex: isExpanded ? 9 : 'inherit',
      }}
    >
      <div
        className="relative flex h-full w-full flex-col gap-2 rounded-[16px] border bg-white p-4 transition-all duration-700 ease-in-out"
        style={{
          pointerEvents: loading ? 'none' : 'initial',
          boxShadow: isExpanded ? 'rgba(0, 0, 0, 0.15) 0px 7px 14px 0px' : 'inherit',
        }}
      >
        <div
          className={classNames('pointer-events-none flex items-center justify-between', {
            'absolute top-4 left-4 right-4 z-[1]': full,
          })}
        >
          <div className="flex flex-col items-start gap-2">
            <div className="pointer-events-auto flex items-center gap-1 px-2 pt-2">
              <div className="text-sm font-semibold">{title}</div>
              {info}
            </div>
            <div className={'px-2 text-xs text-gray-500'}>{userFriendlyAudienceCategoryLabel(subtitle)}</div>
          </div>
          <div className="pointer-events-auto flex items-center gap-1 px-3">
            {loading ? (
              <Spinner />
            ) : (
              <div className={`flex items-center justify-center gap-3 ${actionsClassNames}`}>
                {canExpand && (
                  <Buttons.Soft
                    icon={<MUIcon name={isExpanded ? 'unfold_less' : 'unfold_more'} />}
                    onClick={() => setIsExpanded((p) => !p)}
                    className="h-[36px] w-[36px] rotate-45 rounded-full border"
                    data-rh={isExpanded ? 'Collapse' : 'Expand'}
                  />
                )}
                {actions && <div>{actions}</div>}
              </div>
            )}
          </div>
        </div>
        <div
          className={classNames('flex h-full min-h-0 w-full px-1 pt-2 transition-all', {
            'absolute top-0 left-0 bottom-0 right-0 overflow-hidden rounded-xl': full,
          })}
          style={{ filter: loading ? 'grayscale(100%)' : 'initial' }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ExpandableGridItem;
