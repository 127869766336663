import { Buttons, Inputs, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';

type Inputs = {
  page: string;
  userComment: string;
  level: number;
};

interface IProps {
  onDone: () => void;
}

const FeedbackForm: React.FC<IProps> = ({ onDone }) => {
  const { notify } = useApplicationContext();
  const levelMessages = [
    { level: 1, message: 'Just letting you know!' },
    { level: 2, message: 'A nice to have' },
    { level: 3, message: "I hope the change is made but it's not a blocker" },
    { level: 4, message: 'It will considerably improve the system' },
    { level: 5, message: "I won't come back until it's done!" },
  ];

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      page: '',
      userComment: '',
      level: 0,
    },
  });
  const submitFedbackMutation = useMutation({
    mutationFn: (payload: MvdTypes.IFeedback) => mvdApi.submitFeedback(payload),
    onSuccess: () => {
      notify({
        hideAfterSec: 5,
        content: 'Feedback submitted successfully.',
      });
      onDone();
    },
  });

  const formData = watch();
  const isDisabled =
    submitFedbackMutation.isLoading || formData.level === 0 || formData.userComment === '' || formData.page === '';

  const onSubmitFeedback: SubmitHandler<Inputs> = (data) => {
    submitFedbackMutation.mutate(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitFeedback)} className="flex flex-col gap-8">
      <Controller
        name="page"
        control={control}
        render={({ field }) => (
          <div className="flex flex-col gap-2">
            <label htmlFor="page" className="text-sm font-normal text-gray-800">
              The page you are commenting on
            </label>
            <Inputs.Input
              id="page"
              placeholder="e.g Readership"
              roundedClasses="rounded-lg"
              value={field.value}
              className="w-full py-1 outline-none"
              onChange={(opt) => field.onChange(opt.target.value)}
            />
          </div>
        )}
      />

      <Controller
        name="userComment"
        control={control}
        render={({ field }) => (
          <div className="flex flex-col gap-2">
            <label htmlFor="userComment" className="text-sm font-normal text-gray-800">
              Please let us know what you think
            </label>
            <textarea
              id="userComment"
              rows={5}
              className="h-[120px] w-full rounded-lg border border-gray-300 py-1 outline-none focus:border-gray-300 active:border-gray-300"
              onChange={(opt) => field.onChange(opt.target.value)}
            />
          </div>
        )}
      />
      <Controller
        name="level"
        control={control}
        render={({ field }) => (
          <div className="flex flex-col gap-2">
            <label htmlFor="level" className="text-sm font-normal text-gray-800">
              How critical is this change request?
            </label>
            <div className="flex gap-1">
              {Array.from({ length: 5 }).map((_, i) => (
                <button
                  key={i}
                  type="button"
                  onClick={() => field.onChange(i + 1)}
                  className={classNames('flex items-center justify-center ')}
                >
                  <MUIcon
                    name="star"
                    iconStyle="filled"
                    className="color-yellow-800"
                    style={{
                      color: i < field.value ? '#FACA15' : '#D1D5DB',
                    }}
                  />
                </button>
              ))}
            </div>
            {field.value > 0 && (
              <>
                <div className="font-['Inter'] text-sm font-semibold leading-[18.20px] text-black">
                  Critical Level {field.value}
                </div>
                <div className="font-['Inter'] text-sm font-normal leading-normal text-black">
                  {levelMessages.find((msg) => msg.level === field.value)?.message}
                </div>
              </>
            )}
          </div>
        )}
      />
      <div className="flex w-full items-end justify-end">
        <Buttons.Primary
          disabled={isDisabled}
          type="submit"
          className="bg-primary-800 inline-flex w-32 items-center justify-center gap-2 rounded-full py-2.5 pl-4 pr-6 text-center text-sm font-semibold text-white"
        >
          Submit
        </Buttons.Primary>
      </div>
    </form>
  );
};

export default FeedbackForm;
