import { Buttons, Inputs, Menu, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classnames from 'classnames';
import produce from 'immer';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { useGeoJsonQuery } from '../../../hooks';
import Chip from '../../shared/Chip';
import FilterActions from '../common/FilterActions';
import GeoLevelPicker from './GeoLevelPicker';
import TopLevelGeo from './TopLevelGeo';
import { createRuleGroupFromObject, reduceRulesToObject } from './utils';

const { FilterGroup } = MvdTypes;

interface IProps {
  filters: RuleGroupType;
  onDone: (value: RuleGroupType) => void;
  availableGeoLevels: MvdTypes.GeoLevelType[];
  activeGeoLevel: MvdTypes.GeoLevelType;
  onChangeGeoLevel?: (newGeoLevel: MvdTypes.GeoLevelType) => void;
}

const GEO_LEVEL_MAP_KEYS: { [key: string]: string } = {
  'geo.region': 'state',
  sl050: 'county',
  sl500: 'us_congressional_district',
  sl610: 'state_senate_district',
  sl620: 'state_house_district',
  dma: 'dma',
};

const GeoFilter: React.FC<IProps> = ({ onDone, filters, availableGeoLevels, activeGeoLevel, onChangeGeoLevel }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [tempGeoLevel, setTempGeoLevel] = useState<MvdTypes.GeoLevelType>(activeGeoLevel);
  const [showGeoSelection, setShowGeoSelection] = useState<boolean>(false);
  const [originalSelection, setOriginalSelection] = useState<string>('');
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  const [selection, setSelection] = useState<Record<string, string[]>>(
    reduceRulesToObject(filters, tempGeoLevel.parent || tempGeoLevel.field, tempGeoLevel.field)
  );

  useEffect(() => {
    // since we want to have all checked when we open geo filter, we do it this way and also save the original selection state, so we can compare with the new one
    let selection = reduceRulesToObject(filters, tempGeoLevel.parent || tempGeoLevel.field, tempGeoLevel.field);
    if (Object.keys(selection).length === 0) {
      selection = tempGeoLevel.parent.length === 0 ? { '*': [] } : transformGeographiesIntoSelection(parentOptions);
    }
    setOriginalSelection(JSON.stringify(selection));
    setSelection(selection);
    setShowGeoSelection(false);
    setSearchTerm('');
  }, [isOpen]);

  useEffect(() => {
    setTempGeoLevel(activeGeoLevel);
  }, [activeGeoLevel, isOpen]);

  const topLevelGeography = tempGeoLevel.parent
    ? (availableGeoLevels.find((x) => x.field === tempGeoLevel.parent) as MvdTypes.GeoLevelType)
    : tempGeoLevel;

  const topLevelGeoJson = useGeoJsonQuery(topLevelGeography.geoJson);

  const nestedGeoJson = useGeoJsonQuery(tempGeoLevel.geoJson);
  const hasNesting = tempGeoLevel.parent.length > 0;

  const parentOptions: ParentOption[] = useMemo(
    () =>
      (topLevelGeoJson.data?.features ?? [])
        .map((f) => {
          const parentGeoName = f.properties?.[topLevelGeography.labelProp];

          let nestedItems: NestedOption[] = [];

          if (tempGeoLevel.parent) {
            // if nesting is enabled (looking at a geo level with is nested under some parent like States)
            // read into geo json of nested geo
            nestedItems =
              (nestedGeoJson.data?.features.filter((f) => f.properties?.['state'] === parentGeoName) ?? []) // ALERT: hardcoded to state as parent, not case for DMA
                .map((feature) => {
                  const nestedGeoName = feature.properties?.[tempGeoLevel.labelProp].toString();
                  const checked =
                    selection[parentGeoName] != null &&
                    (selection[parentGeoName].toString() === '*' || selection[parentGeoName].includes(nestedGeoName));

                  return { name: nestedGeoName, checked, id: feature.id };
                })
                .sort((a, b) => a.name.localeCompare(b.name)) ?? [];
          }

          const checked = !!selection[parentGeoName] || selection['*'] != null;

          return { id: f.id, name: parentGeoName, checked, nestedItems };
        })
        .sort((a, b) => a.name.localeCompare(b.name)),
    [
      topLevelGeoJson.data?.features,
      topLevelGeography.labelProp,
      tempGeoLevel.parent,
      tempGeoLevel.labelProp,
      selection,
      nestedGeoJson.data?.features,
    ]
  );

  useEffect(() => {
    // when changing geo level we want to persist the selected geos, but the difference is that non nested geos if all are selected then
    // selection should be {'*': []}, otherwise map them all, but in nested geos we want to map every geo when all are selected for example {Alabama: ['*'], Alaska: ['*']}
    // otherwise just map the selected ones
    let newSelection;

    if (!hasNesting && Object.keys(selection).length === parentOptions.length) {
      newSelection = { '*': [] };
    } else if (hasNesting && Object.keys(selection).length === 1 && Object.keys(selection)[0] === '*') {
      newSelection = parentOptions.reduce((acc, { name }) => {
        acc[name] = ['*'];
        return acc;
      }, {} as { [key: string]: string[] });
    } else {
      newSelection = Object.keys(selection).reduce(
        (prev, key) => ({
          ...prev,
          [key]: ['*'],
        }),
        {}
      );
    }
    setSelection(newSelection);
  }, [tempGeoLevel]);

  const transformGeographiesIntoSelection = (options: ParentOption[]) => {
    // used to transform geos when switching to nested
    return options.reduce((acc, { name }) => {
      acc[name] = ['*'];
      return acc;
    }, {} as { [key: string]: string[] });
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value);
  const handleDoneClick = () => {
    // turn selection into geo filter group
    const updatedGeoFilterGroup = createRuleGroupFromObject(
      selection,
      tempGeoLevel.parent || tempGeoLevel.field,
      tempGeoLevel.field
    );

    // apply the geo group into other filters
    const newFilters = produce(filters, (draft) => {
      const ruleGroupIndex = draft.rules.findIndex((rule) => rule?.id === FilterGroup.GEO);

      if (ruleGroupIndex === -1) {
        // group doesn't exist yet, so add it only if there are rules
        if (updatedGeoFilterGroup.rules.length) {
          draft.rules.push(updatedGeoFilterGroup);
        }
      } else {
        // group exits already, check if it needs to be updated or removed depending on number of temp rules
        if (updatedGeoFilterGroup.rules.length) {
          // there are rules so update it
          draft.rules[ruleGroupIndex] = updatedGeoFilterGroup;
        } else {
          // all rules removed so remove the group, too
          draft.rules.splice(ruleGroupIndex, 1);
        }
      }
    });

    onChangeGeoLevel && onChangeGeoLevel(tempGeoLevel);
    onDone(newFilters);
    handleClose();
  };
  const handleClose = () => {
    setSearchTerm('');
    setIsOpen(false);
  };

  const checkIfNestedGeosAreChecked = () => {
    // go through all nested geos and see if any of those are checked, function used for partial selection in nested geos
    for (const key in selection) {
      if (selection[key][0] !== '*') {
        return false;
      }
    }
    return true;
  };

  const handleResetClick = () => {
    // filter out the geo rule and reset selection
    const newFilters = { ...filters, rules: filters.rules.filter((r) => r.id !== FilterGroup.GEO) };
    onDone(newFilters);
    setSelection({});
    handleClose();
  };

  const pluralizedTitle = pluralize(activeGeoLevel.name);

  useEffect(() => {
    // If there are geo filters applied set applied filters count
    let count = 0;
    const filterRule = filters.rules.find((f) => f.id === FilterGroup.GEO);
    if (filterRule) {
      count = (filterRule as RuleGroupType).rules.length;
    }
    setAppliedFiltersCount(count);
  }, [filters]);

  // compare original selection to new selection, so we can disable Apply button
  const disableApplyChangesButton =
    JSON.stringify(selection) !== originalSelection || activeGeoLevel.id !== tempGeoLevel.id;
  // filter options based on search term, and also filter based on nested items
  const filteredOptions = parentOptions.filter(
    (x) =>
      x.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      x.nestedItems.some((c) => c.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
  // difference in how we manage all selected in non nesting geos, and nested geos
  const allParentsSelected = !hasNesting
    ? Object.keys(selection).length === 1 && Object.keys(selection)[0] === '*'
    : Object.keys(selection).length === parentOptions.length;

  // difference in how we manage partial selection in non nesting geos, and nested geos
  const someParentsSelected = !hasNesting
    ? Object.keys(selection).length >= 1 && !allParentsSelected && Object.keys(selection).length < parentOptions.length
    : (Object.keys(selection).length >= 1 &&
        !allParentsSelected &&
        Object.keys(selection).length < parentOptions.length) ||
      !checkIfNestedGeosAreChecked();
  // nested geos should be expanded by default if the search term matches any of nested items
  const expandedByDefault =
    searchTerm.length > 0 &&
    parentOptions.filter((x) => x.nestedItems.some((x) => x.name.toLowerCase().includes(searchTerm.toLowerCase())))
      .length > 0;

  const handleGeoSelect = (level: MvdTypes.GeoLevelType) => {
    let shouldClearGeo;
    if (level.field === tempGeoLevel.parent) {
      // tempGeoLevel is a child of the selected level (e.g., level = state, tempGeoLevel = county)
      shouldClearGeo = false;
    } else if (tempGeoLevel.field === level.parent) {
      // Selected level is a child of tempGeoLevel (e.g., level = county, tempGeoLevel = state)
      shouldClearGeo = false;
    } else if (tempGeoLevel.parent && level.parent && tempGeoLevel.parent === level.parent) {
      // Selected level has the same parent as tempGeoLevel (e.g., level = county, tempGeoLevel = congressional districts)
      shouldClearGeo = false;
    } else {
      // No parent-child relationship between level and tempGeoLevel
      shouldClearGeo = true;
    }

    if (shouldClearGeo) {
      setSelection({ '*': [] });
    }
    setTempGeoLevel(level);
    setShowGeoSelection(false);
  };

  const handleTriState = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newSelection = {};
    if (e.target.checked) {
      if (hasNesting) {
        newSelection = transformGeographiesIntoSelection(parentOptions);
      } else {
        newSelection = { '*': tempGeoLevel.parent ? ['*'] : [] };
      }
    }
    setSelection(newSelection);
  };

  const handleTopLevelGeoOptionClick = (parentOption: ParentOption, nestedOption?: NestedOption, only?: boolean) => {
    let newSelection = { ...selection };
    const handlingNestedGeographies = Boolean(tempGeoLevel.parent); // Determine if we are dealing with nested geographies

    // Function to toggle the selection of a nested option
    const toggleNestedOption = (nestedOption: NestedOption) => {
      if (!newSelection[parentOption.name]) {
        newSelection[parentOption.name] = []; // Initialize array if parent option isn't already selected
      }

      if (nestedOption.checked) {
        const currentSelection = newSelection[parentOption.name];
        if (currentSelection.includes('*')) {
          // If wildcard is selected, replace it with all nested options except the one being unchecked
          newSelection[parentOption.name] = parentOption.nestedItems
            .filter((item) => item.name !== nestedOption.name)
            .map((item) => item.name);
        } else {
          // Remove the nested option from the selection
          const index = currentSelection.indexOf(nestedOption.name);
          if (index !== -1) {
            currentSelection.splice(index, 1);
          }
          // If no nested options are selected, remove the parent option key
          if (currentSelection.length === 0) {
            delete newSelection[parentOption.name];
          }
        }
      } else {
        // Add the nested option to the selection
        newSelection[parentOption.name].push(nestedOption.name);

        // If all nested options are selected, optimize by using the wildcard
        const allNestedSelected = newSelection[parentOption.name].length === parentOption.nestedItems.length;
        if (allNestedSelected) {
          newSelection[parentOption.name] = ['*'];
        }
      }
    };
    const allTopLevelGeosSelected = Object.keys(newSelection).length === 1 && Object.keys(newSelection)[0] === '*';

    // Function to toggle the selection of the parent option
    const toggleParentOption = () => {
      if (newSelection[parentOption.name]) {
        delete newSelection[parentOption.name]; // Deselect the parent option
      } else {
        newSelection[parentOption.name] = ['*'];
        if (Object.keys(newSelection).length === parentOptions.length && !allTopLevelGeosSelected && !hasNesting) {
          newSelection = { '*': [] };
        }
      }

      if (allTopLevelGeosSelected && !tempGeoLevel.parent) {
        const filteredGeoOptions = parentOptions.filter((item) => item.name !== parentOption.name);
        newSelection = transformGeographiesIntoSelection(filteredGeoOptions);
      }
    };

    const toggleParentOnlyOption = () => {
      newSelection = { [parentOption.name]: ['*'] };
    };

    const toggleNestedOnlyOption = (nestedOption: NestedOption) => {
      newSelection = { [parentOption.name]: [nestedOption.name] };
    };

    // Handle the option click based on whether we are dealing with nested options
    if (handlingNestedGeographies) {
      if (nestedOption) {
        if (only) {
          toggleNestedOnlyOption(nestedOption); // Toggle only a specific nested option and remove the rest
        } else {
          toggleNestedOption(nestedOption); // Toggle a specific nested option
        }
      } else {
        if (only) {
          toggleParentOnlyOption(); // Toggle only the parent option and remove the rest
        } else {
          toggleParentOption(); // Toggle the entire parent option
        }
      }
    } else {
      if (only) {
        toggleParentOnlyOption(); // Toggle only the parent option and remove the rest
      } else {
        toggleParentOption(); // If no nested geographies, treat it as a parent option click
      }
    }

    setSelection(newSelection); // Update the selection state with the new selection
  };

  return (
    <Menu
      visible={isOpen}
      menuClasses="mt-4 shadow border border-grey-200 relative overflow-y-hidden"
      trigger={
        <Chip
          title={pluralizedTitle}
          className={classnames('text-sm font-semibold', {
            'bg-secondary-100 pl-1': appliedFiltersCount,
            'bg-white-200': !appliedFiltersCount,
          })}
          leadingAction={
            appliedFiltersCount > 0 && (
              <div
                className="hover:bg-secondary-50 flex h-6 w-6 items-center justify-center rounded-full bg-transparent"
                onMouseDown={(e) => {
                  e.stopPropagation();
                  handleResetClick();
                }}
              >
                <MUIcon name="close" className="text-gray-600" iconStyle="filled" />
              </div>
            )
          }
        >
          <MUIcon name={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'} className="text-gray-600" iconStyle="filled" />
        </Chip>
      }
      onToggleVisibility={setIsOpen}
    >
      {showGeoSelection ? (
        <GeoLevelPicker
          availableGeoLevels={availableGeoLevels}
          selectedGeoLevel={tempGeoLevel}
          onSelect={handleGeoSelect}
          onClick={() => setShowGeoSelection(false)}
        />
      ) : (
        <>
          <div className="relative flex w-96 flex-grow flex-col items-center">
            <div className="flex w-full flex-wrap items-center items-center justify-center gap-1 border-b py-3">
              <Buttons.Secondary
                icon={<MUIcon name="arrow_drop_down" />}
                className="flex-row-reverse rounded-full border-none shadow-none ring-0 hover:bg-gray-100"
                onClick={() => setShowGeoSelection(true)}
              >{`Select ${tempGeoLevel.name}`}</Buttons.Secondary>
            </div>
            <div className="sticky top-0 flex w-full items-center justify-start border-b border-gray-200 bg-white">
              <Inputs.Input
                placeholder={`Search`}
                autoFocus
                classes="flex inline-flex h-14 items-center justify-start ring-white py-3"
                roundedClasses="rounded-none"
                value={searchTerm}
                onChange={handleSearchTermChange}
                clearIcon={searchTerm ? <MUIcon name="close" /> : null}
                onClear={() => setSearchTerm('')}
              />
            </div>
            <div className="flex w-full flex-col overflow-auto">
              <div className="flex w-full flex-col gap-2 py-2">
                {hasNesting && <div className="w-full items-start px-4 pt-3 text-xs text-gray-600">WITHIN...</div>}
                <div
                  className="hover:bg-primary-100 focus:bg-primary-100 flex w-full flex-shrink-0 cursor-pointer select-none items-center justify-between py-4 px-4"
                  onClick={() =>
                    handleTriState({ target: { checked: !allParentsSelected } } as React.ChangeEvent<HTMLInputElement>)
                  }
                >
                  <div className="flex h-full w-full justify-start text-sm">
                    <div className="flex gap-3">
                      <Inputs.TriStateCheckBox
                        classes="flex items-center justify-start"
                        checked={allParentsSelected}
                        className="text-primary-600"
                        intermediate={someParentsSelected}
                        onChange={handleTriState}
                      />
                      {`All ${pluralize(GEO_LEVEL_MAP_KEYS[tempGeoLevel.parent] ?? tempGeoLevel.name.toLowerCase())}`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex h-[1px] w-full flex-shrink-0 bg-gray-200" />
              <div className="border-top flex max-h-[230px] min-h-[230px] w-full flex-col  py-2">
                {filteredOptions?.map((option, index) => (
                  <TopLevelGeo
                    key={`${option.id}_${index}`}
                    option={option}
                    onClick={handleTopLevelGeoOptionClick}
                    searchTerm={searchTerm}
                    expandedByDefault={expandedByDefault}
                  />
                ))}
                {filteredOptions.length === 0 && (
                  <div className="mt-6 flex items-center justify-center text-xs text-gray-600">
                    No results, please change your search term.
                  </div>
                )}
              </div>
            </div>
          </div>
          <FilterActions
            onReset={handleResetClick}
            onDone={handleDoneClick}
            onCancel={handleClose}
            disabledApply={!disableApplyChangesButton}
            disabledReset={appliedFiltersCount === 0}
          />
        </>
      )}
    </Menu>
  );
};

export default GeoFilter;

export type ParentOption = {
  id: string | number | undefined;
  checked: boolean;
  name: string;
  nestedItems: NestedOption[];
};

export type NestedOption = {
  id: string | number | undefined;
  name: string;
  checked: boolean;
};
