import { Dropdowns } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AudienceSummary from '../Audience/AudienceSummary';
import { useActivationsContext } from './ActivationsContext';

type ActivationFormInputs = {
  downloadType: string | null;
};

const ActivationDataType = forwardRef((_, ref) => {
  const { activation, setActivation, exportFormats } = useActivationsContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ActivationFormInputs>({
    defaultValues: {
      downloadType: activation.downloadType,
    },
  });

  const onSubmit = (data: ActivationFormInputs) => {
    const newActivation: MvdTypes.IActivation = {
      ...activation,
      downloadType: data.downloadType,
    };
    setActivation(newActivation);
  };

  useImperativeHandle(ref, () => ({
    triggerSubmit: () =>
      new Promise<void>((resolve) => {
        handleSubmit((data) => {
          onSubmit(data);
          resolve();
        })();
      }),
  }));

  const dropdownOptions: Dropdowns.DropdownOption[] = MvdTypes.DownloadTypes.sort((a, b) =>
    a.label.localeCompare(b.label)
  ).map((p) => ({ value: p.value, name: p.label, disabled: exportFormats[p.value] }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex h-full w-full w-8/12 flex-col justify-center gap-8">
      <AudienceSummary />
      <div className="flex flex-col gap-3">
        <p className="font-regular text-sm">Export type</p>
        <Controller
          name="downloadType"
          control={control}
          rules={{ required: 'Export type is required' }}
          render={({ field }) => (
            <Dropdowns.Dropdown
              placeholder="Select an export type"
              triggerClasses="flex w-full h-[52px] ring-0 border border-gray-200 font-normal text-sm"
              menuWidth="full"
              options={dropdownOptions}
              value={field.value ?? ''}
              onSelect={(option) => {
                field.onChange(option.value);
              }}
            />
          )}
        />
        {errors.downloadType && <span className="text-xs text-red-400">{errors.downloadType.message}</span>}
      </div>
    </form>
  );
});

export default ActivationDataType;
