export enum ChartType {
  pie = 'pie',
  bar = 'bar',
  line = 'line',
}

export enum ChartExportType {
  image = 'image',
  csv = 'csv',
}
