import { Datepicker, Menu, Modal, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import produce from 'immer';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import Chip from '../shared/Chip';

const { FilterGroup } = MvdTypes;

const FILTER_GROUP_ID = FilterGroup.TIME;
const FROM = 'from';
const TO = 'to';
const FIELD = 'visited_date';

interface IProps {
  filters: RuleGroupType;
  onDone: (value: RuleGroupType) => void;
}

interface TimeRuleType extends RuleType {
  name?: string;
  isCustom?: boolean;
}

const getDateBefore = (n: number, unit: moment.DurationInputArg2) => moment().subtract(n, unit).format('YYYY-MM-DD');

const TimeRangeFilter: React.FC<IProps> = ({ filters, onDone }) => {
  const today = getDateBefore(0, 'hours');
  // last available data is from yesterday so we need to add 1 day to have n points on the chart
  const TIME_OPTIONS: TimeRuleType[][] = [
    [
      {
        id: FROM,
        field: FIELD,
        operator: '>=',
        name: 'Past day',
        value: moment()
          .subtract(1 + 1, 'days')
          .format('YYYY-MM-DD'),
      },
      { id: TO, field: FIELD, operator: '<=', value: today },
    ],
    [
      {
        id: FROM,
        field: FIELD,
        operator: '>=',
        name: 'Past 3 days',
        value: moment()
          .subtract(3 + 1, 'days')
          .format('YYYY-MM-DD'),
      },
      { id: TO, field: FIELD, operator: '<=', value: today },
    ],
    [
      {
        id: FROM,
        field: FIELD,
        operator: '>=',
        name: 'Past 7 days',
        value: getDateBefore(7 + 1, 'days'),
      },
      { id: TO, field: FIELD, operator: '<=', value: today },
    ],
    [
      {
        id: FROM,
        field: FIELD,
        operator: '>=',
        name: 'Past 14 days',
        value: getDateBefore(14 + 1, 'days'),
      },
      { id: TO, field: FIELD, operator: '<=', value: today },
    ],
    [
      {
        id: FROM,
        field: FIELD,
        operator: '>=',
        name: 'Past 30 days',
        value: getDateBefore(30 + 1, 'days'),
      },
      { id: TO, field: FIELD, operator: '<=', value: today },
    ],
    [
      {
        id: FROM,
        field: FIELD,
        operator: '>=',
        name: 'Past 90 days',
        value: getDateBefore(90 + 1, 'days'),
      },
      { id: TO, field: FIELD, operator: '<=', value: today },
    ],
    [
      {
        id: FROM,
        field: FIELD,
        operator: '>=',
        name: 'Custom range',
        value: null,
        isCustom: true,
      },
      { id: TO, field: FIELD, operator: '<=', value: null, isCustom: true },
    ],
  ];

  const [datePickerValue, setDatePickerValue] = useState<Datepicker.DateValueType>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);

  const currentTimeRuleGroup = useMemo(() => {
    return filters.rules.find((rule) => rule.id === FILTER_GROUP_ID) as RuleGroupType;
  }, [filters.rules]);

  const handleTimeRangeChange = (timeRange: TimeRuleType[]) => {
    const [fromRule] = timeRange;
    if (fromRule.isCustom) {
      setModalVisible(true);
    } else {
      applyNewFilters(timeRange);
    }
    setIsOpen(false);
  };

  const handleDoneClick = () => {
    if (!datePickerValue?.startDate || !datePickerValue?.endDate) return;

    const startDate = moment(datePickerValue.startDate).format('YYYY-MM-DD');
    const endDate = moment(datePickerValue.endDate).format('YYYY-MM-DD');
    const dateRangeName = `${moment(datePickerValue.startDate).format('MMM DD')} - ${moment(
      datePickerValue.endDate
    ).format('MMM DD')}`;

    const newOption: TimeRuleType[] = [
      {
        id: FROM,
        name: dateRangeName,
        field: FIELD,
        operator: '>=',
        value: startDate,
        isCustom: true,
      },
      {
        id: TO,
        field: FIELD,
        operator: '<=',
        value: endDate,
        isCustom: true,
      },
    ];

    applyNewFilters(newOption);
    setModalVisible(false);
    setIsOpen(false);
  };

  const handleOpenModal = () => {
    const [fromRule, toRule] = currentTimeRuleGroup.rules as TimeRuleType[];
    if (fromRule.isCustom) {
      setDatePickerValue({
        startDate: fromRule.value,
        endDate: toRule.value,
      });
    }
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setDatePickerValue(null);
    setModalVisible(false);
  };

  const applyNewFilters = (range: TimeRuleType[]) => {
    const [fromRule, toRule] = range;
    const startDate = fromRule.value;
    const endDate = toRule.value;
    if (!startDate || !endDate) return;
    const newFilters = produce(filters, (draft) => {
      const ruleGroup = draft.rules.find((rule) => rule?.id === FILTER_GROUP_ID) as RuleGroupType;
      ruleGroup.rules = [fromRule, toRule];
    });
    onDone(newFilters);
  };

  return (
    <>
      <Menu
        visible={isOpen}
        menuClasses="mt-4 shadow border border-grey-200 relative overflow-y-hidden"
        trigger={<TimeRangeChip timeRange={currentTimeRuleGroup} isOpen={isOpen} setModalVisible={handleOpenModal} />}
        onToggleVisibility={setIsOpen}
      >
        <div className="flex w-60 flex-col pt-2">
          <div className="flex flex-col rounded-lg">
            {TIME_OPTIONS.map((timeOption, idx) => {
              const [selectedFrom, selectedTo] = currentTimeRuleGroup.rules as TimeRuleType[];
              const [optionFrom, optionTo] = timeOption;
              const name = optionFrom.name || optionTo.name;
              const isSelected =
                (optionFrom.value === selectedFrom.value && optionTo.value === selectedTo.value) ||
                (optionFrom.isCustom && selectedFrom.isCustom === optionTo.isCustom);
              return (
                <div key={`item-${idx}`} className="w-full">
                  {optionFrom.isCustom && <div key={`divider-${idx}`} className="my-2 h-[1px] w-full bg-gray-200" />}
                  <button
                    className={classNames('flex h-11 w-full gap-4 p-3 hover:bg-gray-100', {
                      'bg-gray-100': isSelected,
                    })}
                    onClick={() => handleTimeRangeChange(timeOption)}
                  >
                    {isSelected ? (
                      <MUIcon name="check" className="text-primary-600" />
                    ) : (
                      <div className="h-5 w-5"></div>
                    )}
                    <div className="flex items-center">
                      <div
                        className={classNames(
                          "flex items-center gap-2 font-['Inter'] text-sm font-normal leading-[18.20px] text-gray-800",
                          { 'font-semibold text-gray-800': isSelected }
                        )}
                      >
                        {name}
                      </div>
                    </div>
                  </button>
                </div>
              );
            })}
            <div className="mt-2 inline-flex h-[58px] items-start justify-start gap-2 bg-gray-100 px-4 py-3">
              <p className="font-['Inter'] text-xs leading-none text-gray-700">
                <span className="text-primary-600 font-semibold">Note: </span>
                {`The most recent timestamp is ${moment().format('MMM DD')} - one day prior.`}
              </p>
            </div>
          </div>
        </div>
      </Menu>
      {modalVisible && (
        <Modal
          className="w-fit"
          title="Custom range"
          titleClasses="h-[60px] py-3 justify-start items-center gap-6 inline-flex"
          okLabel="Done"
          okClassNames="self-stretch px-6 py-2.5 justify-center bg-primary-800 text-white rounded-full"
          onDone={handleDoneClick}
          okDisabled={!datePickerValue}
          onCloseRequest={handleCloseModal}
          cancelClassNames="self-stretch px-6 py-2.5 justify-center rounded-full"
          additionalButtons={
            datePickerValue && (
              <button
                onClick={() => {
                  setDatePickerValue(null);
                }}
                className="text-center font-['Inter'] text-sm font-semibold text-gray-800 "
              >
                Clear selection
              </button>
            )
          }
        >
          <div className="inline-flex h-full flex-col items-start justify-start gap-6 py-3">
            <Datepicker.DateRangePicker
              value={datePickerValue}
              maxDate={moment().toDate()}
              onChange={(value) => {
                setDatePickerValue(value);
              }}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default TimeRangeFilter;

export const DEFAULT_TIME_RANGE_GROUP = {
  id: FILTER_GROUP_ID,
  combinator: 'and',
  rules: [
    {
      id: FROM,
      field: FIELD,
      operator: '>=',
      name: 'Past 3 days',
      value: moment()
        .subtract(3 + 1, 'days')
        .format('YYYY-MM-DD'),
    },
    { id: TO, field: FIELD, operator: '<=', value: moment().format('YYYY-MM-DD') },
  ],
};

interface TimeRangeChipProps {
  timeRange: RuleGroupType;
  isOpen: boolean;
  setModalVisible: (v: boolean) => void;
}

const TimeRangeChip: React.FC<TimeRangeChipProps> = ({ timeRange, isOpen, setModalVisible }) => {
  const [fromRule, toRule] = timeRange.rules as TimeRuleType[];
  const customName = `${moment(fromRule.value).format('MMM DD')} - ${moment(toRule.value).format('MMM DD')}`;

  if (!fromRule.isCustom) {
    return (
      <Chip title={fromRule.name || toRule.name || customName} className="text-sm font-semibold">
        <MUIcon name={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'} className="text-gray-600" iconStyle="filled" />
      </Chip>
    );
  } else {
    return (
      <Chip
        className="pr-0 text-sm font-semibold"
        title={
          <div
            onMouseDown={(e) => {
              e.stopPropagation();
              setModalVisible(true);
            }}
          >
            {customName}
          </div>
        }
      >
        <div
          className={classNames('inline-flex h-fit items-center justify-start rounded-r-full', {
            'bg-primary-100': isOpen,
          })}
        >
          <div className="h-9 w-0.5 border-r border-gray-200"></div>
          <MUIcon
            name={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
            className="p-2 text-gray-600"
            iconStyle="filled"
          />
        </div>
      </Chip>
    );
  }
};
