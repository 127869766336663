import { MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classnames from 'classnames';
import React from 'react';

interface IProps {
  availableGeoLevels: MvdTypes.GeoLevelType[];
  selectedGeoLevel: MvdTypes.GeoLevelType;
  onSelect: (geoLevel: MvdTypes.GeoLevelType) => void;
  onClick: () => void;
}

const GeoLevelPicker: React.FC<IProps> = ({ availableGeoLevels, selectedGeoLevel, onSelect, onClick }) => {
  return (
    <div className="relative flex w-96 flex-grow flex-col">
      <div className="flex w-full p-4">
        <button className="flex w-1/12  items-center" onClick={onClick}>
          <MUIcon name="arrow_back" />
        </button>
        <div className="flex w-11/12 items-center justify-center">
          <p className="text-sm font-semibold">Change geography level</p>
        </div>
      </div>
      <div className="flex w-full flex-col">
        {availableGeoLevels.map((level) => (
          <button
            className={classnames(
              'flex w-full items-center gap-3 rounded-none py-3 px-4 shadow-none ring-0 hover:cursor-pointer hover:bg-gray-100',
              {
                'bg-gray-100 font-semibold': selectedGeoLevel.id === level.id,
                'pointer-events-none opacity-50': level.disabled,
              }
            )}
            key={level.id}
            disabled={level.disabled}
            onClick={() => onSelect(level)}
          >
            <div className="flex w-[22px] items-center justify-center">
              {selectedGeoLevel.id === level.id && <MUIcon name="check" />}
            </div>
            <p className="text-sm">{level.name}</p>
          </button>
        ))}
      </div>
    </div>
  );
};

export default GeoLevelPicker;
