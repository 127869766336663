import { Buttons, Menu, MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React, { useState } from 'react';
import { ChartExportType, ChartType } from '../../enums/widgetEnums';
import { MenuOption } from '../../hooks/useWidget';

interface TestProps {
  groupedOptions: {
    options: MenuOption[];
    groupName: string;
  }[];
  selectedChartType?: ChartType;
}

const ChartOptionsMenu: React.FC<TestProps> = ({ groupedOptions, selectedChartType }) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  return (
    <Menu
      trigger={<Buttons.Link icon={<MUIcon name="more_vert" />} className="h-[36px] w-[36px] rounded-full border" />}
      visible={isMenuVisible}
      onToggleVisibility={() => setIsMenuVisible(!isMenuVisible)}
      menuMargin={3}
      hAlignment="right"
      menuClasses="shadow-lg"
    >
      <div className="inline-flex w-64 flex-col items-start justify-start rounded-lg bg-white py-2 shadow">
        <div className="flex w-full flex-col">
          {groupedOptions.map((group: any, i: number) => (
            <React.Fragment key={group.groupName}>
              {i !== 0 && <div className="bg-primary-200 my-2 inline-flex h-[1px] w-full"></div>}
              <div className="text-primary-500 inline-flex h-8 items-center justify-start gap-4 px-4 py-2 font-['Inter'] text-xs font-semibold uppercase leading-none">
                {group.groupName}
              </div>

              {group.options.map((option: MenuOption) => (
                <button
                  key={option.label}
                  className="hover:bg-primary-100 inline-flex h-11 w-full items-center justify-start gap-4 px-4 py-3"
                  onClick={() => {
                    option.onClick();
                    setIsMenuVisible(!isMenuVisible);
                  }}
                >
                  {selectedChartType === option.type ? (
                    <MUIcon name="check" className="text-primary-600" />
                  ) : (
                    Object.values(ChartType).includes(option.type as ChartType) && <div className="h-5 w-5"></div>
                  )}
                  {Object.values(ChartExportType).includes(option.type as ChartExportType) && option.icon}

                  <p
                    className={classNames("text-primary-800 font-['Inter'] text-sm font-normal leading-[18.20px]", {
                      'font-semibold': selectedChartType === option.type,
                    })}
                  >
                    {option.label}
                  </p>
                </button>
              ))}
            </React.Fragment>
          ))}
        </div>
      </div>
    </Menu>
  );
};

export default ChartOptionsMenu;
