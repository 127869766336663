import { MvdTypes } from '@platform/types';
import React from 'react';

import { NumberFormatter } from '@platform/utils';
import { RuleGroupType } from 'react-querybuilder';
import MvdMasterMapping from '../Filters/MvdMasterMapping';
import ActivationMenu from './ActivationMenu';
import FiltersInfoTooltip from './FiltersInfoTooltip';

const COLUMNS = [
  'Audience',
  'Custom name',
  'Status',
  'Record count',
  'Platform / Download',
  'Account ID',
  'Audience Filters',
  'Campaign Dates',
  'Billed Rate',
  'Total Cost',
  'Transaction Date',
  'Account',
  'Ref ID',
  'Notes',
];

interface Props {
  activationRequests: MvdTypes.IAudienceActivationRequest[];
}

const ActivationsTable: React.FC<Props> = ({ activationRequests }: Props) => {
  const getColor = (request: MvdTypes.IAudienceActivationRequest) => {
    const status = getStatus(request);
    if (!status) return;
    let color = 'bg-white';
    switch (status) {
      case MvdTypes.ActivationStatus.PENDING:
        color = 'bg-yellow-100';
        break;
      case MvdTypes.ActivationStatus.SUBMITTED:
        color = 'bg-blue-100';
        break;
      case MvdTypes.ActivationStatus.CANCELLED:
      case MvdTypes.ActivationStatus.EXPIRED:
        color = 'bg-red-100';
        break;
      case MvdTypes.ActivationStatus.ACTIVE:
      case MvdTypes.ActivationStatus.COMPLETE:
      case MvdTypes.ActivationStatus.DOWNLOADED:
        color = 'bg-green-100';
        break;
    }
    return color;
  };

  const getStatus = (request: MvdTypes.IAudienceActivationRequest) => {
    if (request.requestType === MvdTypes.ActivationType.MULTI_FORMAT) {
      return request.userAudienceStatus;
    }
    return request.activationStatus;
  };
  // ToDo add pagination
  return (
    <div className="max-w-100 mx-10 mt-8 overflow-y-hidden overflow-x-scroll bg-white px-4">
      <div className="ml-[-15px]">
        <div className="inline-block min-w-full  align-middle  ">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="w-full bg-gray-50">
              <tr>
                {COLUMNS.map((item) => (
                  <th
                    key={item}
                    scope="col"
                    className="min-w-[150px] py-3.5 px-2 text-left text-sm font-semibold text-gray-900"
                  >
                    {item}
                  </th>
                ))}
                <th className="min-w-[50px]" />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {activationRequests.map((request, idx) => {
                const mappedFilters = request.filterQuery.rules
                  .filter((f) => f.id !== MvdTypes.FilterGroup.AUDIENCE_CODE)
                  .map((x) => MvdMasterMapping.groupToHumanReadableText(x as RuleGroupType));

                return (
                  <tr key={idx}>
                    <td className="min-w-[200px] break-words py-4 px-2 text-sm font-medium text-gray-900">
                      {request.baseAudienceTitle}
                    </td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">{request.title}</td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">
                      <span className={`rounded-full ${getColor(request)} px-4 py-2`}>{getStatus(request)}</span>
                    </td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">
                      {NumberFormatter.format(request.recordsUploaded, '0,0')}
                    </td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm capitalize text-gray-500">
                      {request.platformDesc}
                    </td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">{request.accountId}</td>
                    <FiltersInfoTooltip mappedFilters={mappedFilters} />
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">
                      {request.startDate &&
                        request.endDate &&
                        `${new Date(request.startDate).toLocaleDateString()} - ${new Date(
                          request.endDate
                        ).toLocaleDateString()}`}
                    </td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">
                      {`${NumberFormatter.format(request.cost ?? 0, 'USD0,000.00')}`}
                    </td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">
                      {`${NumberFormatter.format(request.totalCost ?? 0, 'USD0,000.00')}`}
                    </td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">
                      {new Date(request.created).toLocaleDateString()}
                    </td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">{request.accountName}</td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">{request.referenceId}</td>
                    <td className="min-w-[200px] break-words px-2 py-4 text-sm text-gray-500">{request.note}</td>
                    <td>
                      <ActivationMenu item={request} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ActivationsTable;
