import { MvdTypes } from '@platform/types';
import React, { useMemo } from 'react';
import { ChartType } from '../../../enums/widgetEnums';
import useWidget from '../../../hooks/useWidget';
import ExpandableGridItem, { GridPosition } from '../../shared/ExpandableGridItem';
import { useReadershipContext } from '../ReadershipContext';
import useReadershipData, { IDataPoint } from './useReadershipData';
import WidgetInfo from './WidgetInfo';
import { WidgetQueryKeys } from './WidgetQueryKeys';

const { FilterGroup } = MvdTypes;

interface Props {
  grid: GridPosition;
}

const MediaSources: React.FC<Props> = ({ grid }) => {
  const { filters, queryEnabled } = useReadershipContext();
  const { isLoading, data } = useReadershipData({
    filters,
    queryEnabled,
    filterGroupId: FilterGroup.MEDIA_SOURCE,
    queryKey: WidgetQueryKeys.MEDIA_SOURCE,
    uncategorizedLabel: 'Other',
  });

  const title = 'Top 5 media sources';
  const highchartsData = useMemo(() => convertToHighchartsData(data ?? []), [data]);
  const { chart, menu } = useWidget({ title, highchartsData, initialType: ChartType.bar });

  return (
    <ExpandableGridItem
      title={title}
      loading={isLoading}
      grid={grid}
      actions={menu}
      info={<WidgetInfo title={title} description="% of readers by media source within selected criteria/filters" />}
    >
      {chart}
    </ExpandableGridItem>
  );
};

export default MediaSources;

interface IHighchartsData {
  categories: string[];
  data: IDataPoint[];
}

const convertToHighchartsData = (data: IDataPoint[]): IHighchartsData => {
  const categories: string[] = [];
  const chartData: IDataPoint[] = [];

  data
    .filter((x) => x.categorized)
    .sort((a: IDataPoint, b: IDataPoint) => b.count - a.count)
    .splice(0, 5)
    .forEach((item) => {
      categories.push(item.name);
      chartData.push(item);
    });

  const uncategorizedData = data.filter((x) => !x.categorized);
  if (uncategorizedData.length) {
    categories.push(...uncategorizedData.map((x) => x.name));
    chartData.push(...uncategorizedData);
  }

  return {
    categories,
    data: chartData,
  };
};
