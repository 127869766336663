import { MvdTypes } from '@platform/types';
import _ from 'lodash';
import pluralize from 'pluralize';
import { RuleGroupType, RuleType } from 'react-querybuilder';

const { FilterGroup } = MvdTypes;

const ENTRIES: IEntryGroup[] = [
  {
    id: FilterGroup.GENDER,
    operator: '=',
    title: 'Gender',
    columns: [
      {
        column: 'c3811',
        value: 1,
        label: 'Female',
        ordering: 1000,
      },
      {
        column: 'c3812',
        value: 1,
        label: 'Male',
        ordering: 2000,
      },
    ],
  },
  {
    id: FilterGroup.AGE,
    operator: '=',
    title: 'Age',
    columns: [
      {
        column: 'c3804',
        value: 1,
        label: '18 to 29',
        ordering: 1000,
      },
      {
        column: 'c3806',
        value: 1,
        label: '30 to 39',
        ordering: 2000,
      },
      {
        column: 'c3808',
        value: 1,
        label: '40 to 49',
        ordering: 3000,
      },
      {
        column: 'c3809',
        value: 1,
        label: '50 to 64',
        ordering: 4000,
      },
      {
        column: 'c3810',
        value: 1,
        label: '65 and over',
        ordering: 5000,
      },
    ],
  },
  {
    id: FilterGroup.EDUCATION_CONDENSED,
    operator: '=',
    title: 'Education Condensed',
    columns: [
      {
        column: 'c3805',
        value: 1,
        label: 'College Degree+',
        ordering: 1000,
      },
      {
        column: 'c3818',
        value: 1,
        label: 'No College Degree',
        ordering: 2000,
      },
    ],
  },
  {
    id: FilterGroup.ETHNICITY,
    operator: '=',
    title: 'Ethnicity',
    columns: [
      {
        column: 'c3861',
        value: 1,
        label: 'European',
        ordering: 1000,
      },
      {
        column: 'c3864',
        value: 1,
        label: 'Hispanic and Portuguese',
        ordering: 2000,
      },
      {
        column: 'c3862',
        value: 1,
        label: 'Likely African-American',
        ordering: 3000,
      },
      {
        column: 'c3863',
        value: 1,
        label: 'East and South Asian',
        ordering: 4000,
      },
      {
        column: 'c3865',
        value: 1,
        label: 'Other',
        ordering: 5000,
      },
    ],
  },
  {
    id: FilterGroup.HOUSEHOLD_INCOME,
    operator: '=',
    title: 'Household Income',
    columns: [
      {
        column: 'c3813',
        value: 1,
        label: '< $50,000',
        ordering: 1000,
      },
      {
        column: 'c3815',
        value: 1,
        label: '$50,000 - $74,999',
        ordering: 2000,
      },
      {
        column: 'c3816',
        value: 1,
        label: '$75,000 - $99,999',
        ordering: 3000,
      },
      {
        column: 'c3814',
        value: 1,
        label: '$100,000 - $149,999',
        ordering: 4000,
      },
      {
        column: 'c3817',
        value: 1,
        label: '$150,000 & up',
        ordering: 5000,
      },
    ],
  },
  {
    id: FilterGroup.MARITAL_STATUS,
    operator: '=',
    title: 'Marital Status',
    columns: [
      {
        column: 'c3819',
        value: 1,
        label: 'Married',
        ordering: 1000,
      },
      {
        column: 'c3820',
        value: 1,
        label: 'Not Married',
        ordering: 2000,
      },
    ],
  },
  {
    id: FilterGroup.PARTY,
    operator: '=',
    title: 'Party',
    columns: [
      {
        column: 'c3844',
        value: 1,
        label: 'Democratic',
        ordering: 1000,
      },
      {
        column: 'c3845',
        value: 1,
        label: 'Republican',
        ordering: 2000,
      },
      {
        column: 'c3846',
        value: 1,
        label: 'Independent',
        ordering: 3000,
      },
    ],
  },
  {
    id: FilterGroup.PRESENCE_OF_CHILDREN,
    operator: '=',
    title: 'Presence of children',
    columns: [
      {
        column: 'c3821',
        value: 1,
        label: 'Has children',
        ordering: 1000,
      },
      {
        column: 'c3822',
        value: 1,
        label: 'No children',
        ordering: 2000,
      },
    ],
  },
  {
    id: FilterGroup.LIKELY_VOTERS,
    operator: '=',
    title: 'Likely voters',
    columns: [
      {
        column: 'c3847',
        value: 1,
        label: 'Likely general election voters',
        ordering: 1000,
      },
      {
        column: 'c3829',
        value: 1,
        label: 'Likely primary voters',
        ordering: 2000,
      },
      {
        column: 'c3830',
        value: 1,
        label: 'Likely presidential primary voters',
        ordering: 2000,
      },
    ],
  },
  {
    id: FilterGroup.TOPIC_DETAILS,
    operator: null,
    title: 'Topic details',
    columns: [
      {
        column: 'topic',
        value: '',
        label: '',
        ordering: 1000,
      },
    ],
  },
  {
    id: FilterGroup.INTEREST_OVER_TIME,
    operator: null,
    title: 'Interest over time',
    columns: [
      {
        column: 'visited_date',
        value: '',
        label: '',
        ordering: 1000,
      },
    ],
  },
  {
    id: FilterGroup.TOPIC,
    operator: 'in',
    title: 'Topic',
    columns: [
      {
        column: 'topic',
        value: [
          'Art',
          'Attractions',
          'Books and Literature',
          'Books, Comics and other Literature',
          'Entertainment',
          'Events',
          'Fine Art',
          'Genres',
          'Music',
          'Theater, Dance and Spoken Word',
          'Video Gaming',
          'Video, TV and Movies',
        ],
        label: 'Arts & Entertainment',
        ordering: 1000,
      },
      {
        column: 'topic',
        value: [
          'Agriculture',
          'Banking and Finance Industries',
          'Business and Finance',
          'Business & Finance',
          'Companies',
          'Insurance Industry',
          'Manufacturing',
          'Markets',
          'Personal Finance',
          'Real Estate',
          'Strategy and Management',
          'Strategy & Management',
          'Taxes',
          'Trade and Commerce',
          'Trade & Commerce',
          'Transportation and Shipping',
          'Transportation & Shipping',
        ],
        label: 'Business & Finance',
        ordering: 2000,
      },
      {
        column: 'topic',
        value: [
          'Bars and Clubs',
          'Bars & Clubs',
          'Consumer goods and services',
          'Fashion',
          'Food and Drink',
          'Food & Drink',
          'Food, Drink and Recipes',
          'Luxury',
          'Restaurants',
          'Shopping',
          'Wellness',
        ],
        label: 'Consumers & Brands',
        ordering: 3000,
      },
      {
        column: 'topic',
        value: [
          'Careers',
          'Career Development',
          'Early Development Education',
          'Education',
          'Education Methodology and Innovation',
          'Graduate Education',
          'K-12 Education',
          'Labor',
          'Undergraduate Education',
        ],
        label: 'Jobs & Education',
        ordering: 4000,
      },
      {
        column: 'topic',
        value: [
          'Budgets & Funding',
          'Budgets and Funding',
          'Civil Law',
          'Constitutional Law',
          'Crime',
          'Criminal Law',
          'Defense and Cybersecurity',
          'Defense & Cybersecurity',
          'Departments and Agencies',
          'Infrastructure',
          'International Relations',
          'Law',
          'Law Enforcement',
          'Lobbying and Advocacy',
          'Lobbying & Advocacy',
          'Policy and Regulation',
          'Policy & Regulation',
          'State Courts',
          'State Governments',
        ],
        label: 'Law & Government',
        ordering: 5000,
      },
      {
        column: 'topic',
        value: [
          'Advice and Relationships',
          'Alcohol, Drugs, and Gambling',
          'Awards and Distinctions',
          'Celebrity',
          'Children and Teens',
          'Communities and Subcultures',
          'Culture and Tradition',
          'Elderly',
          'Family',
          'Family and Relationships',
          'Handicapped and Disabled',
          'Health Care',
          'Healthy Living',
          'Hobbies & Interests',
          'Holidays',
          'Home and Garden',
          'Immigrants',
          'Non Profits',
          'Parks and Museums',
          'Personal Celebrations & Life Events',
          'Pets',
          'Philosophy, Ethics and Values',
          'Pop Culture',
          'Poverty, Welfare and Charity',
          'Race and Ethnicity',
          'Recreation',
          'Religion and Belief',
          'Religion & Spirituality',
          'Rights, Discrimination and Inequality',
          'Sex and Gender',
          'Sexual Orientation',
          'Style & Fashion',
          'Veterans',
        ],
        label: 'Lifestyle & Culture',
        ordering: 6000,
      },
      {
        column: 'topic',
        value: [
          'Apps and Software',
          'Communication',
          'Computers',
          'Gaming',
          'Internet',
          'Media and Advertising',
          'Mobile and Gadgets',
          'Productivity',
          'Social Media',
          'Technology & Computing',
          'Technology Industry',
        ],
        label: 'Technology & Innovation',
        ordering: 7000,
      },
      {
        column: 'topic',
        value: [
          'Disaster, Accident and Tragedy',
          'Disasters',
          'Economy',
          'Innovations',
          'Medical',
          'Medical Health',
          'Military',
          'National Security',
          'Physical Science',
          'Research',
          'Scandal and Misconduct',
          'Science',
          'Sensitive Topics',
          'War and Conflicts',
          'Wars, Conflicts and Terrorism',
          'Weather',
        ],
        label: 'News',
        ordering: 8000,
      },
      {
        column: 'topic',
        value: [
          'Campaigns and Campaign Finance',
          'Congress',
          'Congressional Elections',
          'Conservative',
          'Democrats',
          'Federal Courts and SCOTUS',
          'Independent',
          'Liberal',
          'Local Governments',
          'Politics',
          'President and Administration',
          'Presidential Elections',
          'Republicans',
          'State and Local Elections',
        ],
        label: 'Politics',
        ordering: 9000,
      },
      {
        column: 'topic',
        value: [
          'Baseball',
          'Basketball',
          'College Sports',
          'Football',
          'Golf',
          'High School Sports',
          'Hockey',
          'National Sports',
          'Olympics',
          'Soccer',
          'Sports',
          'Tennis',
        ],
        label: 'Sports',
        ordering: 10000,
      },
      {
        column: 'topic',
        value: ['Aerospace and Aviation', 'Automotive', 'Maps & Navigation', 'Travel', 'Travel and Tourism'],
        label: 'Travel & Transportation',
        ordering: 11000,
      },
    ],
  },
  {
    id: FilterGroup.MEDIA_SOURCE,
    operator: 'in',
    title: 'Media Source',
    columns: [
      {
        column: 'source',
        value: [
          'google',
          'news.google.com',
          'google-ads',
          'accounts.google.com',
          'mail.google.com',
          'classroom.google.com',
        ],
        label: 'Google',
        ordering: 1000,
      },
      {
        column: 'source',
        value: ['facebook', 'lm.facebook.com', 'm.facebook.com', 'l.facebook.com', 'fb', 'facebook.com'],
        label: 'Facebook',
        ordering: 2000,
      },
      {
        column: 'source',
        value: ['lehighvalleylive.com'],
        label: 'lehighvalleylive.com',
        ordering: 3000,
      },
      {
        column: 'source',
        value: ['advancelocal.arcpublishing.com'],
        label: 'advancelocal.arcpublishing.com',
        ordering: 4000,
      },
      {
        column: 'source',
        value: ['annarbor.com'],
        label: 'annarbor.com',
        ordering: 5000,
      },
      {
        column: 'source',
        value: ['theoregonian.com'],
        label: 'theoregonian.com',
        ordering: 6000,
      },
      {
        column: 'source',
        value: ['njam'],
        label: 'nj.com',
        ordering: 7000,
      },
      {
        column: 'source',
        value: ['aol', 'ao', 'search.aol.com', 'aol.com'],
        label: 'aol.com',
        ordering: 8000,
      },
      {
        column: 'source',
        value: ['appleid.apple.com'],
        label: 'Apple',
        ordering: 9000,
      },
      {
        column: 'source',
        value: ['bing'],
        label: 'Bing',
        ordering: 10000,
      },
      {
        column: 'source',
        value: ['cnn.com'],
        label: 'cnn.com',
        ordering: 11000,
      },
      {
        column: 'source',
        value: ['duckduckgo'],
        label: 'DuckDuckGo',
        ordering: 12000,
      },
      {
        column: 'source',
        value: ['Sailthru', 'sailthru'],
        label: 'Email Newsletter',
        ordering: 13000,
      },
      {
        column: 'source',
        value: ['flipboard', 'flipboard.com'],
        label: 'Flipboard',
        ordering: 14000,
      },
      {
        column: 'source',
        value: ['instagram', 'l.instagram.com'],
        label: 'Instagram',
        ordering: 15000,
      },
      {
        column: 'source',
        value: ['legacy.com', 'm.legacy.com'],
        label: 'Legacy',
        ordering: 16000,
      },
      {
        column: 'source',
        value: ['linkedin.com'],
        label: 'LinkedIn',
        ordering: 17000,
      },
      {
        column: 'source',
        value: ['msn.com'],
        label: 'MSN',
        ordering: 18000,
      },
      {
        column: 'source',
        value: ['newsbreakapp.com'],
        label: 'NewsBreak',
        ordering: 19000,
      },
      {
        column: 'source',
        value: ['pinterest.com'],
        label: 'Pinterest',
        ordering: 20000,
      },
      {
        column: 'source',
        value: ['politico.com'],
        label: 'Politico',
        ordering: 21000,
      },
      {
        column: 'source',
        value: ['reddit.com', 'out.reddit.com', 'old.reddit.com'],
        label: 'Reddit',
        ordering: 22000,
      },
      {
        column: 'source',
        value: ['247sports.com'],
        label: '247sports.com',
        ordering: 23000,
      },
      {
        column: 'source',
        value: ['tiderinsider.com'],
        label: 'tiderinsider.com',
        ordering: 24000,
      },
      {
        column: 'source',
        value: ['detroitlions.com'],
        label: 'detroitlions.com',
        ordering: 25000,
      },
      {
        column: 'source',
        value: ['foxsports.com'],
        label: 'foxsports.com',
        ordering: 26000,
      },
      {
        column: 'source',
        value: ['rutgers.forums.rivals.com'],
        label: 'rutgers.forums.rivals.com',
        ordering: 27000,
      },
      {
        column: 'source',
        value: ['rollbamaroll.com'],
        label: 'rollbamaroll.com',
        ordering: 28000,
      },
      {
        column: 'source',
        value: ['syracusefan.com'],
        label: 'syracusefan.com',
        ordering: 29000,
      },
      {
        column: 'source',
        value: ['tidefans.com'],
        label: 'tidefans.com',
        ordering: 30000,
      },
      {
        column: 'source',
        value: ['fanrecap.com'],
        label: 'fanrecap.com',
        ordering: 31000,
      },
      {
        column: 'source',
        value: ['espn.com'],
        label: 'espn.com',
        ordering: 32000,
      },
      {
        column: 'source',
        value: ['njschoolsports.com'],
        label: 'njschoolsports.com',
        ordering: 33000,
      },
      {
        column: 'source',
        value: ['auburn.forums.rivals.com'],
        label: 'auburn.forums.rivals.com',
        ordering: 34000,
      },
      {
        column: 'source',
        value: ['twitter', 't.co'],
        label: 'Twitter',
        ordering: 35000,
      },
      {
        column: 'source',
        value: ['en.wikipedia.org', 'en.m.wikipedia.org'],
        label: 'Wikipedia',
        ordering: 36000,
      },
      {
        column: 'source',
        value: ['yahoo', 'sports.yahoo.com', 'us.search.yahoo.com', 'my.yahoo.com'],
        label: 'Yahoo',
        ordering: 37000,
      },
      {
        column: 'source',
        value: ['accounts.youtube.com', 'youtube.com'],
        label: 'YouTube',
        ordering: 38000,
      },
      {
        column: 'source',
        value: ['drudgereport.com'],
        label: 'drudgereport.com',
        ordering: 39000,
      },
      {
        column: 'source',
        value: ['nextdoor.com'],
        label: 'nextdoor.com',
        ordering: 40000,
      },
      {
        column: 'source',
        value: ['ground.news'],
        label: 'ground.news',
        ordering: 41000,
      },
      {
        column: 'source',
        value: ['citizenfreepress.com'],
        label: 'citizenfreepress.com',
        ordering: 42000,
      },
      {
        column: 'source',
        value: ['on3.com'],
        label: 'on3.com',
        ordering: 43000,
      },
      {
        column: 'source',
        value: ['smartnews.com'],
        label: 'smartnews.com',
        ordering: 44000,
      },
    ],
  },
  {
    id: FilterGroup.GENERATION,
    operator: '=',
    title: 'Generation',
    columns: [
      {
        column: 'generation',
        value: 'Gen Z',
        label: 'Gen Z',
        ordering: 1000,
      },
      {
        column: 'generation',
        value: 'Millennials',
        label: 'Millennials',
        ordering: 2000,
      },
      {
        column: 'generation',
        value: 'Gen X',
        label: 'Gen X',
        ordering: 3000,
      },
      {
        column: 'generation',
        value: 'Baby Boomers',
        label: 'Baby Boomers',
        ordering: 4000,
      },
      {
        column: 'generation',
        value: 'Silent Generation',
        label: 'Silent Generation',
        ordering: 5000,
      },
    ],
  },
  {
    id: FilterGroup.HISPANIC_ORIGIN,
    operator: '=',
    title: 'Hispanic Origin',
    columns: [
      {
        column: 'hispanic_origin',
        value: 'Not Hispanic',
        label: 'Not Hispanic',
        ordering: 1000,
      },
      {
        column: 'hispanic_origin',
        value: 'Hispanic',
        label: 'Hispanic',
        ordering: 2000,
      },
    ],
  },
  {
    id: FilterGroup.HISPANIC_LANGUAGE,
    operator: '=',
    title: 'Hispanic Language',
    columns: [
      {
        column: 'hispanic_language',
        value: 'Not Hispanic',
        label: 'Not Hispanic',
        ordering: 1000,
      },
      {
        column: 'hispanic_language',
        value: 'Hispanic/Not Likely Spanish Speaker',
        label: 'Hispanic/Not Likely Spanish Speaker',
        ordering: 2000,
      },
      {
        column: 'hispanic_language',
        value: 'Hispanic/Spanish Speaker',
        label: 'Hispanic/Spanish Speaker',
        ordering: 3000,
      },
    ],
  },
  {
    id: FilterGroup.HOME_OWNERSHIP,
    operator: '=',
    title: 'Home Ownership',
    columns: [
      {
        column: 'home_ownership',
        value: 'Likely Homeowner',
        label: 'Likely Homeowner',
        ordering: 1000,
      },
      {
        column: 'home_ownership',
        value: 'Likely Renter',
        label: 'Likely Renter',
        ordering: 2000,
      },
    ],
  },
  {
    id: FilterGroup.VOTER_FREQUENCY,
    operator: '=',
    title: 'Voter Frequency',
    columns: [
      {
        column: 'voter_frequency',
        value: 'Low Propensity',
        label: 'Low Propensity',
        ordering: 1000,
      },
      {
        column: 'voter_frequency',
        value: 'Mid Propensity',
        label: 'Mid Propensity',
        ordering: 2000,
      },
      {
        column: 'voter_frequency',
        value: 'High Propensity',
        label: 'High Propensity',
        ordering: 3000,
      },
    ],
  },
  {
    id: FilterGroup.EDUCATION,
    operator: '=',
    title: 'Education',
    columns: [
      {
        column: 'education_full',
        value: 'High School Graduate or Less',
        label: 'High School Graduate or Less',
        ordering: 2000,
      },
      {
        column: 'education_full',
        value: 'Bachelors Degree',
        label: "Bachelor's Degree",
        ordering: 3000,
      },
      {
        column: 'education_full',
        value: 'Some College',
        label: 'Some College',
        ordering: 4000,
      },
      {
        column: 'education_full',
        value: 'Grad/Prof Degree',
        label: 'Grad/Prof Degree',
        ordering: 6000,
      },
      {
        column: 'education_full',
        value: 'Attended Vocational',
        label: 'Attended Vocational',
        ordering: 8000,
      },
    ],
  },
  {
    id: FilterGroup.LANGUAGE,
    operator: '=',
    title: 'Language',
    columns: [
      {
        column: 'language',
        value: 'Likely English Speaker',
        label: 'Likely English Speaker',
        ordering: 1000,
      },
      {
        column: 'language',
        value: 'Likely Spanish Speaker',
        label: 'Likely Spanish Speaker',
        ordering: 2000,
      },
      {
        column: 'language',
        value: 'Other',
        label: 'Other',
        ordering: 3000,
      },
    ],
  },
  {
    id: FilterGroup.GUN_OWNERSHIP,
    operator: '=',
    title: 'Gun Ownership',
    columns: [
      {
        column: 'gun_owner',
        value: true,
        label: 'Yes',
        ordering: 1000,
      },
      {
        column: 'gun_owner',
        value: false,
        label: 'No',
        ordering: 2000,
      },
    ],
  },
  {
    id: FilterGroup.RELIGIONS_DESCRIPTION,
    operator: '=',
    title: 'Religions Description',
    columns: [
      {
        column: 'religions_description',
        value: 'Orthodox, Protestant and other Christian',
        label: 'Orthodox, Protestant and other Christian',
        ordering: 2000,
      },
      {
        column: 'religions_description',
        value: 'Catholic',
        label: 'Catholic',
        ordering: 3000,
      },
      {
        column: 'religions_description',
        value: 'Jewish',
        label: 'Jewish',
        ordering: 4000,
      },
      {
        column: 'religions_description',
        value: 'Other',
        label: 'Other',
        ordering: 5000,
      },
      {
        column: 'religions_description',
        value: 'None',
        label: 'Unknown',
        ordering: 6000,
      },
    ],
  },
  {
    id: FilterGroup.PRO_CHOICE_VOTERS,
    operator: '=',
    title: 'Pro Choice Voters',
    columns: [
      {
        column: 'c3839',
        value: 1,
        label: 'Yes',
        ordering: 1000,
      },
      {
        column: 'c3839',
        value: 0,
        label: 'No',
        ordering: 2000,
      },
    ],
  },
  // {
  //   id: FilterGroup.ARTICLES,
  //
  //   operator: '=',
  //   title: 'Articles',
  //   columns: [
  //     {
  //       label: '',
  //       value: '',
  //       column: 'headline_fixed',
  //       ordering: 1000,
  //     },
  //   ],
  // },
];

class MasterMapping {
  private static entries: IEntryGroup[] = ENTRIES;

  static getGroup(filterGroupId: string): IEntryGroup {
    return this.entries.find((group) => group.id === filterGroupId) as IEntryGroup;
  }

  static addGroup(group: IEntryGroup) {
    // remove if exists
    this.entries = this.entries.filter((x) => x.id !== group.id);
    this.entries.push(group);
  }

  static getMappingsByFilterGroup(filterGroupId: string): MappedFilterValue[] {
    const group = this.getGroup(filterGroupId);
    if (!group) return [];

    return group.columns
      .map((entry, idx) => ({
        operator: group.operator,
        title: group.title,
        label: entry.label,
        value: entry.value,
        field: entry.column,
        ordering: entry.ordering,
        description: entry.description,
        id: entry.id ?? idx.toString(),
      }))
      .sort((a, b) => a.ordering - b.ordering);
  }

  static getGroupTitle(filterGroupId: string): string {
    return this.getGroup(filterGroupId)?.title || '?';
  }

  static groupToHumanReadableText(filterGroup: RuleGroupType): { title: string; values: string[] } {
    const filterGroupId = filterGroup?.id ?? '';

    if (filterGroupId === FilterGroup.GEO) {
      // geographies require special handling....
      const levelToUse = MvdTypes.extractGeoLevelBasedRuleGroup(filterGroup, MvdTypes.AllAvailableGeos);

      return {
        title: levelToUse?.name ?? 'Unknown Geography',
        values: levelToUse ? this.interpretGeoRule(filterGroup, levelToUse) : [],
      };
    }

    const entries = this.getMappingsByFilterGroup(filterGroupId);

    const isMultiColumn = new Set(entries.map((x) => x.field)).size > 1;

    const values = filterGroup.rules.map((rule) => {
      if (isMultiColumn) {
        return entries.find((entry) => _.isEqual(entry.field, (rule as RuleType).field))?.label ?? 'Unknown';
      }

      return entries.find((entry) => _.isEqual(entry.value, (rule as RuleType).value))?.label ?? 'Unknown';
    });

    const title = this.getGroupTitle(filterGroupId);
    return {
      title,
      values,
    };
  }

  private static interpretGeoRule(conditions: RuleGroupType, levelToUse?: MvdTypes.GeoLevelType): string[] {
    const results: { [areaType: string]: { [region: string]: Set<string> | 'All' } } = {};
    const resultContext: { [key: string]: any } = {};

    this.processGeoRule(conditions, resultContext, results);

    return Object.entries(results).flatMap(([areaType, regions]) => {
      if (areaType === levelToUse?.name && levelToUse.id === MvdTypes.States.id) {
        return Object.keys(regions).map((region) =>
          regions[region] === 'All' ? 'All States in the United States' : region
        );
      }

      if (areaType === levelToUse?.name && levelToUse?.id === MvdTypes.DMA.id) {
        return Object.keys(regions).flatMap((region) =>
          regions[region] === 'All' ? ['All DMAs in the United States'] : Array.from(regions[region] as Set<string>)
        ) as string[];
      }

      if (areaType === levelToUse?.name) {
        const areaTypePlural = pluralize(areaType);
        const regionsSelected = Object.keys(regions);

        if (regionsSelected.length === 52 && regionsSelected.every((region) => regions[region] === 'All')) {
          return [`All ${areaTypePlural} in the United States`];
        }

        return Object.entries(regions).flatMap(([region, areaValues]) => {
          if (areaValues === 'All') {
            return [`All ${areaTypePlural} in ${region}`];
          }

          const areaList = Array.from(areaValues);
          const lastItem = areaList.pop();
          const areaListString = areaList.length > 0 ? `${areaList.join(', ')} and ${lastItem}` : lastItem;
          return [`${areaListString} ${pluralize(areaTypePlural, areaValues.size)} in ${region}`];
        });
      }

      return [];
    });
  }

  public static labelForGeoRule(conditions: RuleGroupType, levelToUse?: MvdTypes.GeoLevelType): string {
    const results: { [areaType: string]: { [region: string]: Set<string> | 'All' } } = {};
    const resultContext: { [key: string]: any } = {};
    let allGeosInStateSelectedCount = 0;
    let notAllGeosInStateSelectedCount = 0;

    this.processGeoRule(conditions, resultContext, results);
    let result = '';
    Object.entries(results).forEach(([areaType, regionsByState]) => {
      if (areaType === levelToUse?.name && levelToUse.id === MvdTypes.States.id) {
        const states = Object.keys(regionsByState);
        if (states[0] === 'All') {
          result = 'All States in the United States';
        } else if (states.length === 1) {
          result = `${states[0]}`;
        } else {
          result = `${states.length} States`;
        }
        return;
      }

      if (areaType === levelToUse?.name && levelToUse.id === MvdTypes.DMA.id) {
        const dmaKeys = Object.keys(regionsByState);
        const firstDmaRegion = regionsByState[dmaKeys[0]];

        if (firstDmaRegion === 'All') {
          result = 'All DMAs in the United States';
        } else if ((firstDmaRegion as Set<string>).size === 1) {
          result = `${Array.from(firstDmaRegion as Set<string>)[0]}`;
        } else {
          result = `${(firstDmaRegion as Set<string>).size} DMAs`;
        }

        return;
      }

      if (areaType === levelToUse?.name) {
        const areaTypePlural = pluralize(areaType);
        const statesWithAllGeosSelected = Object.keys(regionsByState).filter(
          (state) => regionsByState[state] === 'All'
        );
        const statesWithSomeGeosSelected = Object.keys(regionsByState).filter(
          (state) => regionsByState[state] !== 'All'
        );

        if (statesWithAllGeosSelected.length === 52) {
          result = `All ${areaTypePlural} in the United States`;
        } else {
          statesWithAllGeosSelected.forEach((state) => {
            allGeosInStateSelectedCount++;
            result = `All ${areaTypePlural} in ${state}`;
          });

          statesWithSomeGeosSelected.forEach((state) => {
            notAllGeosInStateSelectedCount += (regionsByState[state] as Set<string>).size;
          });

          if (statesWithAllGeosSelected.length === 1 && notAllGeosInStateSelectedCount > 0) {
            result = `All ${areaTypePlural} in ${statesWithAllGeosSelected[0]} +${notAllGeosInStateSelectedCount}`;
          }
        }
      }
    });
    if (allGeosInStateSelectedCount > 1) {
      result = `All ${pluralize(levelToUse?.name ?? 'States')} in ${allGeosInStateSelectedCount} States ${
        notAllGeosInStateSelectedCount > 0 ? ` +${notAllGeosInStateSelectedCount}` : ''
      }`;
    }
    if (allGeosInStateSelectedCount === 0 && notAllGeosInStateSelectedCount > 0) {
      result = `${notAllGeosInStateSelectedCount} ${pluralize(
        levelToUse?.name ?? 'States',
        notAllGeosInStateSelectedCount
      )}`;
    }
    return result;
  }

  private static processGeoRule(
    ruleOrGroup: RuleGroupType | RuleType,
    geoContext: { [key: string]: string | 'All' },
    result: { [areaType: string]: { [region: string]: Set<string> | 'All' } }
  ): void {
    if ('field' in ruleOrGroup) {
      const rule = ruleOrGroup;
      const { field, operator, value } = rule;

      // Update context
      if (operator === 'notNull') {
        geoContext[field] = 'All';
      } else if (operator === '=') {
        geoContext[field] = value;
      }

      const areaType = MvdTypes.AllAvailableGeos.find((x) => x.field === field)?.name;
      if (areaType) {
        const region = geoContext['geo.region'] || 'United States';

        if (!result[areaType]) {
          result[areaType] = {};
        }

        if (!result[areaType][region]) {
          result[areaType][region] = new Set<string>();
        }

        if (geoContext[field] === 'All') {
          result[areaType][region] = 'All';
        } else if (result[areaType][region] !== 'All') {
          (result[areaType][region] as Set<string>).add(geoContext[field]);
        }
      }
    } else if ('combinator' in ruleOrGroup) {
      const ruleGroup = ruleOrGroup;
      const { combinator, rules } = ruleGroup;

      if (combinator === 'and') {
        // For 'and', process sub-rules with the same context
        for (const subRule of rules) {
          this.processGeoRule(subRule, geoContext, result);
        }
      } else if (combinator === 'or') {
        // For 'or', process sub-rules with copies of the context
        for (const subRule of rules) {
          const contextCopy = { ...geoContext };
          this.processGeoRule(subRule, contextCopy, result);
        }
      }
    }
  }
}

export default MasterMapping;

export interface IFilterOption {
  name: string;
  value: unknown;
  field: string;
  operator: string;
  checked: boolean;
  group: MvdTypes.FilterGroup;
  id?: string | number;
  description?: string;
}

export type MappedFilterValue = {
  label: string;
  title: string;
  field: string;
  operator: OperatorType;
  value: unknown;
  ordering: number;
  id?: string | number;
  description?: string;
};

type OperatorType = '=' | 'in' | null;

interface IEntry {
  column: string;
  value: unknown | unknown[];
  label: string;
  ordering: number;
  id?: string;
  description?: string;
}

interface IEntryGroup {
  id: MvdTypes.FilterGroup;
  operator: OperatorType;
  title: string;
  description?: string;
  columns: IEntry[];
}
