import { Hooks, Map as MapLibreMapComponent, Utils } from '@platform/maplibre';
import { Dropdowns, MapComponents } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { Feature, FeatureCollection } from 'geojson';
import * as maplibre from 'maplibre-gl';
import React, { useEffect, useState } from 'react';
import { useGeoJsonQuery } from '../../../../hooks';
import ExpandableGridItem, { GridPosition } from '../../../shared/ExpandableGridItem';
import useBasemapStyle from '../../../shared/useBasemapStyle';
import { useReadershipContext } from '../../ReadershipContext';
import useReadershipMapData from '../useReadershipMapData';
import Tooltip from './Tooltip';

const { ZoomControls } = MapComponents;

interface Props {
  grid: GridPosition;
}

const GEO_LAYER_ID = 'geo';

const MapWidget: React.FC<Props> = ({ grid }) => {
  const { filters, queryEnabled, availableGeoLevels, changeGeoLevel, geoLevel } = useReadershipContext();
  const [map, setMap] = useState<maplibre.Map | null>(null);
  const basemap = useBasemapStyle();

  const geoDataQuery = useReadershipMapData({ geoLevel, filters, queryEnabled });
  const geoJsonQuery = useGeoJsonQuery(geoLevel.geoJson);

  useEffect(() => {
    if (map == null) return;
    if (geoDataQuery.data == null) return;
    if (geoJsonQuery.data == null) return;

    try {
      const features = geoDataQuery.data.reduce((p: Feature[], c) => {
        const feature = geoJsonQuery.data.features.find((f) => {
          const matchedFeatureName = geoLevel.matchBy
            .map((x) => f.properties?.[x])
            .join()
            .toLowerCase();

          return matchedFeatureName.toLowerCase() === c.name.toLowerCase();
        }) as Feature;

        if (!feature) return p;

        return [
          ...p,
          {
            ...feature,
            properties: {
              ...feature.properties,
              count: c.count,
              percentage: c.percentage,
            },
          },
        ];
      }, []);

      const finalFeatureCollection: FeatureCollection = {
        type: 'FeatureCollection',
        features,
      };

      applyToMap(map, finalFeatureCollection, geoLevel, basemap.targetLayerId);

      const bounds = Utils.calculateBounds(finalFeatureCollection);
      if (bounds) {
        map.fitBounds(bounds, { padding: 80, maxZoom: 8, minZoom: 3, animate: false });
      }
    } catch (e) {
      console.error(e);
    }
  }, [geoLevel, geoJsonQuery.data, geoDataQuery.data, map, basemap.targetLayerId]);

  Hooks.useFeatureHighlightSingleMap(map, [GEO_LAYER_ID]);

  const handleChange = (opt: Dropdowns.DropdownOption) => {
    const newLevel = availableGeoLevels.find((x) => x.name === opt?.value);
    if (!newLevel) return;
    changeGeoLevel(newLevel);
  };

  const isLoading = geoDataQuery.isLoading || geoJsonQuery.isLoading || geoJsonQuery.isIdle;

  const currentZoom = map?.getZoom() ?? 0;
  const options: Dropdowns.DropdownOption[] = availableGeoLevels.map((x) => ({
    name: x.name,
    value: x.name,
    disabled: x.disabled,
  }));

  return (
    <ExpandableGridItem title="" loading={isLoading} grid={grid} full>
      <div className="relative -mx-2 -mt-2 flex flex-grow overflow-hidden rounded-xl">
        <MapLibreMapComponent
          maxBounds={[
            [-200, 0], // Southwest coordinates with more padding
            [-25, 85.0], // Northeast coordinates with more padding
          ]}
          onMount={(map) => setMap(map)}
          zoom={2}
          center={{ lng: -105, lat: 37.5 }}
          style={basemap.style}
        />
        {map && <Tooltip map={map} layerId={GEO_LAYER_ID} labelField={geoLevel.labelProp} />}
        <div className="z-1 absolute top-4 left-4">
          <Dropdowns.Dropdown
            disabled={isLoading}
            options={options}
            onSelect={handleChange}
            value={geoLevel.name}
            triggerClasses="bg-white"
          />
        </div>
        <div className="z-1 absolute bottom-4 right-4 rounded-md shadow-md">
          <ZoomControls
            zoom={currentZoom}
            onZoomIn={() => map?.setZoom(map?.getZoom() + 1)}
            onZoomOut={() => map?.setZoom(map?.getZoom() - 1)}
          />
        </div>
      </div>
    </ExpandableGridItem>
  );
};

export default MapWidget;

const applyToMap = (
  map: maplibre.Map,
  featureCollection: FeatureCollection,
  currentGeoLevel: MvdTypes.GeoLevelType,
  targetLayerId: string
) => {
  const PRIMARY_SOURCE_ID = 'geo-json-primary';

  Utils.cleanupSource(map, PRIMARY_SOURCE_ID);
  map.addSource(PRIMARY_SOURCE_ID, { type: 'geojson', data: featureCollection });

  if (currentGeoLevel.layerType === 'polygon') {
    map.addLayer(
      {
        id: GEO_LAYER_ID,
        source: PRIMARY_SOURCE_ID,
        type: 'fill',
        paint: {
          'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.7],
          'fill-color': [
            'case',
            ['!', ['has', 'count']],
            'rgba(225,225,225, 0.2)',
            [
              'interpolate',
              ['exponential', 1],
              ['get', 'percentage'],
              0,
              'rgb(215,230,233)',
              5,
              'rgb(165,194,201)',
              20,
              'rgb(117,158,170)',
            ],
          ],
        },
      },
      targetLayerId
    );
  } else if (currentGeoLevel.layerType === 'point') {
    map.addLayer({
      id: GEO_LAYER_ID,
      source: PRIMARY_SOURCE_ID,
      type: 'circle',
      paint: {
        'circle-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0.7],
        'circle-radius': [
          'interpolate',
          ['exponential', 1],
          ['get', 'percentage'],
          0.2,
          4, // percentage 0.2 -> radius 4
          2,
          20, // percentage 2 -> radius 20 etc
        ],
        'circle-color': 'rgb(117,158,170)',
      },
    });

    map.addLayer({
      id: 'labels',
      type: 'symbol',
      source: PRIMARY_SOURCE_ID,
      layout: {
        'text-field': ['get', currentGeoLevel.labelProp],
        'text-size': 10,
      },
      paint: {
        'text-color': '#000000',
        'text-halo-color': 'rgba(255, 255, 255, 0.5)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
    });
  }
};
