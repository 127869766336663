import { NumberFormatter } from '@platform/utils';
import pluralize from 'pluralize';
import React from 'react';
import { calculateDateDiff } from '../../helpers/helpers';
import { useActivationsContext } from './ActivationsContext';

const ActivationAuthorizePlatformSummary: React.FC = () => {
  const { activation, costAndRate } = useActivationsContext();
  const imagePath = activation.platform?.image; // Assuming this contains the image filename
  const imageUrl = imagePath ? require(`../../../assets/activation/${imagePath}`) : '';
  const dateDiff =
    activation.campaignStartDate && activation.campaignEndDate
      ? calculateDateDiff(activation.campaignStartDate, activation.campaignEndDate)
      : 0;

  return (
    <div className="flex flex-col items-start gap-3">
      <p className="font-regular text-sm">Activation Summary</p>
      <div className="flex w-full flex-col rounded-md border border-gray-200 px-6">
        <div className="flex w-full items-center justify-center justify-between border-b border-gray-200 py-6">
          <p className="text-sm">Platform</p>
          {imageUrl ? <img src={imageUrl} className="h-[48px] w-[229px]" alt="Platform Image" /> : 'Loading image...'}
        </div>
        <div className="flex w-full items-center justify-center justify-between border-b border-gray-200 py-6">
          <p className="text-sm">Campaign duration</p>
          {dateDiff >= 0 ? (
            <div className="flex flex-col items-end gap-2">
              <p className="text-sm font-semibold">{`${dateDiff} ${pluralize('days', dateDiff)}`}</p>
              <p className="text-sm text-gray-600">{`${new Date(
                activation.campaignStartDate as string
              ).toLocaleDateString()} - ${new Date(activation.campaignEndDate as string).toLocaleDateString()}`}</p>
            </div>
          ) : (
            <div>'N/A'</div>
          )}
        </div>
        <div className="flex w-full items-center justify-between border-b border-gray-200 py-6">
          <p className="text-sm">Billing rate</p>
          <p className="text-sm font-semibold">{`${NumberFormatter.format(costAndRate.rate, 'USD0,00.00')} CPM`}</p>
        </div>
        <div className="flex w-full items-center justify-between py-6 text-lg font-semibold">
          <p>Total cost</p>
          <p>{`${NumberFormatter.format(costAndRate.cost, 'USD0,00.00')}`}</p>
        </div>
      </div>
    </div>
  );
};
export default ActivationAuthorizePlatformSummary;
