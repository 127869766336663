import Highcharts from 'highcharts';
import React, { useEffect, useRef } from 'react';

const useResizableChart = (
  options: Highcharts.Options
): [JSX.Element, React.MutableRefObject<Highcharts.Chart | null>] => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<Highcharts.Chart | null>(null);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const resizableOptions: Highcharts.Options = {
      ...options,
      chart: {
        ...options.chart,
        events: {
          load: function () {
            if (!chartRef.current) return;
            if (!wrapperRef.current) return;

            const resizeObserver = new ResizeObserver(() => {
              if (!wrapperRef.current) return;
              if (!chartRef.current) return;
              chartRef.current.setSize(wrapperRef.current.clientWidth, wrapperRef.current.clientHeight);
            });
            resizeObserver.observe(wrapperRef.current);
          },
        },
      },
    };

    chartRef.current = Highcharts.chart(wrapperRef.current, resizableOptions);

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [wrapperRef, options]);

  return [<div className="-mx-2 -mb-3 -mt-1 flex flex-grow" ref={(r) => (wrapperRef.current = r)} />, chartRef];
};

export default useResizableChart;
