import classNames from 'classnames';
import React from 'react';

export interface ChipProps {
  leadingAction?: React.ReactNode;
  title: string | React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

function Chip({ leadingAction, title, className, children, onClick }: ChipProps) {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (onClick && (event.key === 'Enter' || event.key === ' ')) {
      event.preventDefault();
      onClick();
    }
  };

  return (
    <div
      className={classNames(
        'relative inline-flex h-9 max-w-[224px] items-center justify-center gap-2 rounded-full border border-gray-200 p-2 px-3',
        className,
        { 'cursor-pointer': onClick }
      )}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      onKeyDown={onClick ? handleKeyDown : undefined}
    >
      {leadingAction}
      <span className="truncate">{title}</span>
      {children}
    </div>
  );
}

export default Chip;
