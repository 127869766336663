import { Modal, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { useFilterGroup } from '../Filters/common';
import MoreFiltersSection from '../Filters/MoreFiltersSection';
import Chip from '../shared/Chip';
import { useAudienceContext } from './AudienceContext';

const { FilterGroup } = MvdTypes;

const MoreFilters: React.FC = () => {
  const { filters, applyFilters } = useAudienceContext();
  const [modalVisible, setModalVisible] = React.useState(false);

  const moreFilters = [
    useFilterGroup(FilterGroup.LANGUAGE, filters),
    useFilterGroup(FilterGroup.EDUCATION, filters),
    useFilterGroup(FilterGroup.VOTER_FREQUENCY, filters),
    useFilterGroup(FilterGroup.HOME_OWNERSHIP, filters),
    useFilterGroup(FilterGroup.HISPANIC_LANGUAGE, filters),
    useFilterGroup(FilterGroup.HISPANIC_ORIGIN, filters),
    useFilterGroup(FilterGroup.GENERATION, filters),
    useFilterGroup(FilterGroup.AGE, filters),
    useFilterGroup(FilterGroup.GENDER, filters),
    useFilterGroup(FilterGroup.EDUCATION_CONDENSED, filters),
    useFilterGroup(FilterGroup.ETHNICITY, filters),
    useFilterGroup(FilterGroup.HOUSEHOLD_INCOME, filters),
    useFilterGroup(FilterGroup.MARITAL_STATUS, filters),
    useFilterGroup(FilterGroup.PARTY, filters),
    useFilterGroup(FilterGroup.PRESENCE_OF_CHILDREN, filters),
    useFilterGroup(FilterGroup.LIKELY_VOTERS, filters),
  ];

  const applyNewFilters = () => {
    const newFilters = moreFilters.reduce((p: RuleGroupType | undefined, c) => c.apply(p), undefined);
    if (newFilters) {
      applyFilters(newFilters);
    }
  };

  const handleDoneClick = () => {
    applyNewFilters();
    setModalVisible(false);
  };

  const appliedFiltersCount = moreFilters.reduce((p, c) => p + c.appliedFiltersCount, 0);

  return (
    <>
      <Chip
        leadingAction={<MUIcon name="tune" />}
        title={'More filters'}
        className={classNames('text-sm font-semibold', appliedFiltersCount > 0 ? 'border border-gray-800' : '')}
        onClick={() => setModalVisible(true)}
      >
        {appliedFiltersCount > 0 ? (
          <>
            <div className="w-1"></div>
            <div className="absolute -top-[5px] -right-[5px] inline-flex h-5 w-5 flex-col items-center justify-center gap-2.5 rounded-[999px] bg-gray-800">
              <div className="text-[9px] font-semibold leading-3 text-white">{appliedFiltersCount}</div>
            </div>
          </>
        ) : null}
      </Chip>
      {modalVisible && (
        <Modal
          title="Filters"
          okLabel="Done"
          titleClasses="border-b pb-2"
          onDone={handleDoneClick}
          onCloseRequest={() => setModalVisible(false)}
          className="w-[700px]"
        >
          <div className="-mx-6 flex max-h-[600px] flex-col gap-2 overflow-auto pr-1 pl-6">
            <div className="flex flex-col gap-3 text-sm text-gray-800">
              {moreFilters.map((x) => (
                <MoreFiltersSection
                  key={x.title}
                  title={x.title}
                  options={x.options}
                  onOptionClick={(opt) => x.addOption(opt)}
                />
              ))}
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
export default MoreFilters;
