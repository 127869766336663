import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';
import NoResultsImage from '../../../assets/no-results-lamp.png';

interface NoResultsProps {
  title: string;
  message: string;
  onClear: () => void;
}

const NoResults: React.FC<NoResultsProps> = ({ title, message, onClear }) => {
  return (
    <div className="inline-flex h-full flex-col items-center justify-center gap-12 rounded-lg px-12 py-20">
      <div className="relative h-[200px] w-[148px]">
        {/* place image here */}
        <img src={NoResultsImage} alt="No results found" />
      </div>
      <div className="flex flex-col items-center justify-center gap-8 self-stretch">
        <div className="flex flex-col items-center justify-center gap-2 self-stretch">
          <div className=" text-lg font-semibold text-gray-800">{title}</div>
          <div className=" text-sm font-normal text-gray-700">{message}</div>
        </div>
        <Buttons.Primary
          className="bg-primary-800 justify-center rounded-full px-6 py-2.5 text-white"
          onClick={onClear}
          icon={<MUIcon name="refresh" />}
        >
          Clear all filters
        </Buttons.Primary>
      </div>
    </div>
  );
};

export default NoResults;
