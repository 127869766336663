import React from 'react';
import { Link } from 'react-router-dom';
import CinqLogo from '../../../assets/cinqdi-logo.png';
import linkedInLogo from '../../../assets/linkedin_symbol.png';

interface IProps {
  showLogoPart?: boolean;
  showInfoPart?: boolean;
}

const Footer: React.FC<IProps> = ({ showLogoPart = true, showInfoPart = true }) => {
  return (
    <footer className="flex w-full flex-col">
      {showLogoPart && (
        <div className="inline-flex w-full flex-col items-center justify-start gap-5 bg-slate-100 py-12">
          <div className="inline-flex flex-col items-center justify-start gap-3">
            <img src={CinqLogo} alt="CinqDI Logo" className="h-[38px]" />
            <a href="https://www.linkedin.com/company/cinqdi/">
              <img className="linked_in" alt="linkedin" src={linkedInLogo} />
            </a>
          </div>
          <div className="flex flex-col gap-1">
            <p className="text-sm font-normal text-black">
              CinqDI is a platform by{' '}
              <Link className="font-semibold text-teal-500" to={'https://www.mvdigitalgroup.com/'} target="_blank">
                MV Digital
              </Link>
            </p>
            <p className="text-center text-xs font-normal text-black">One World Trade Center</p>
            <p className="text-center text-xs font-normal text-black">New York, NY 10007 </p>
          </div>
        </div>
      )}

      {showInfoPart && (
        <div className="inline-flex w-full flex-col items-center justify-start gap-[43px] bg-slate-700 py-8">
          <div className="flex flex-col items-center justify-start gap-3 self-stretch">
            <div className="inline-flex items-center justify-center gap-1 self-stretch">
              <div className="text-[13px] font-normal text-slate-50">
                Use of and/or registration on any portion of this site constitutes acceptance of our
              </div>
              <Link
                to={'https://www.cinqdi.com/privacy-policy/'}
                target="_blank"
                className="text-xs font-semibold text-teal-500"
              >
                Privacy Policy
              </Link>
              <div className=" text-xs font-normal text-slate-50">and</div>
              <Link
                to={'https://www.cinqdi.com/terms-of-use/'}
                target="_blank"
                className=" text-xs font-semibold text-teal-500"
              >
                Terms & Conditions
              </Link>
            </div>
            <div className="w-[373px] text-center">
              <p className="text-xs font-normal text-slate-50 ">
                © 2024 Advance Local Media LLC. All rights reserved (
                <Link
                  to={'https://www.advancelocal.com/about/'}
                  className="text-xs font-semibold text-teal-500 underline "
                >
                  About Us
                </Link>
                )
              </p>
              <p className="text-xs font-normal text-slate-50">New York, NY 10007</p>
            </div>
            <div className="text-center  text-xs font-normal text-slate-50">
              The material on this site may not be reproduced, distributed, transmitted, cached or otherwise used,
              except with the prior written permission of MV Digital Group.
            </div>
            <div className="flex gap-1 text-center">
              <a className="text-xs font-normal text-teal-500" href="mailto:support@cinqdi.com">
                support@cinqdi.com
              </a>
              <p className="text-xs font-normal text-teal-500"> | </p>
              <a className="text-xs font-normal text-teal-500" href="/support">
                Support
              </a>
            </div>
          </div>
        </div>
      )}
    </footer>
  );
};
export default Footer;
