import classNames from 'classnames';
import React from 'react';
import Chip from '../shared/Chip';
import { IFilterOption } from './MvdMasterMapping';

interface IProps {
  title: string;
  options: IFilterOption[];
  onOptionClick: (option: IFilterOption) => void;
}

const MoreFiltersSection: React.FC<IProps> = ({ title, options, onOptionClick }) => (
  <div className="mr-6 flex flex-col gap-4 border-b border-gray-200 py-6 last:border-none">
    <div className="text-gray-700">{title}</div>
    <div className="flex flex-wrap gap-2">
      {options.map((option) => (
        <Chip
          key={option.name}
          title={option.name}
          onClick={() => onOptionClick(option)}
          className={classNames('text-sm font-semibold text-gray-800', option.checked && 'bg-secondary-100')}
        />
      ))}
    </div>
  </div>
);

export default MoreFiltersSection;
