import { Abacus } from '@platform/shared/hooks';
import { Buttons, Drawers, MUIcon, PanelHeader, Spinner } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import * as api from '../../../../mvd.api';

import { useLocation, useNavigate } from 'react-router-dom';
import { useApplicationContext } from '../../../ApplicationContext';
import { prepareFiltersForAbacus } from '../../../helpers/helpers';
import SaveAudience from '../../Audiences/SaveAudience';
import AudiencesDrawer from '../AudiencesDrawer';
import AudienceSizeButton from '../AudienceSizeButton';
import AppliedFilters from './AppliedFilters';
import Donut from './Donut';

const { FilterGroup } = MvdTypes;

const DATASET_ID = process.env['NX_ABACUS_VOTER_DATASET_ID'] as string;

const DEFAULT_NO_FILTERS: RuleGroupType = {
  combinator: 'and',
  rules: [],
};

interface IProps {
  filters: RuleGroupType;
  onApplyFilters: (filters: RuleGroupType) => void;
}

const Overview: React.FC<IProps> = ({ onApplyFilters, filters }) => {
  const { userInfo } = useApplicationContext();
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  const abacusApplicableFilters = prepareFiltersForAbacus(filters);

  const totalCountQuery = Abacus.useAbacusRowsCount({
    api: api.getRowCount,
    datasetId: DATASET_ID,
    dataQuery: DEFAULT_NO_FILTERS,
  });

  const filteredCountQuery = Abacus.useAbacusRowsCount({
    datasetId: DATASET_ID,
    api: api.getRowCount,
    dataQuery: abacusApplicableFilters,
  });

  const isLoading =
    totalCountQuery.isIdle || totalCountQuery.isLoading || filteredCountQuery.isIdle || filteredCountQuery.isLoading;
  const isError = totalCountQuery.isError || filteredCountQuery.isError;

  if (isError) return <MUIcon name="warning" />;

  const baseAudienceId = Number(
    (filters.rules.find((x) => x.id === FilterGroup.AUDIENCE_CODE) as RuleGroupType)?.rules?.[0]?.id
  );

  const audienceSelected = !Number.isNaN(baseAudienceId);
  const audienceEmpty = filteredCountQuery.data === 0;
  const canActivate = audienceSelected && userInfo?.org != null && !audienceEmpty;
  const canSave = audienceSelected && !audienceEmpty;

  return (
    <>
      <AudienceSizeButton size={filteredCountQuery.data} onClick={() => setIsOpen((p) => !p)} loading={isLoading} />
      {isOpen && (
        <Drawers.Drawer isOpen={isOpen} onClose={() => setIsOpen(false)} side="right">
          <div className="flex h-full w-[420px] flex-col gap-8 bg-white">
            <PanelHeader title="Audience overview" onClose={() => setIsOpen(false)} />
            {isLoading && (
              <div className="flex h-full w-full items-center justify-center">
                <Spinner fillColor="#A5B4FC" />
              </div>
            )}
            {filteredCountQuery.data != null && totalCountQuery.data != null && (
              <>
                <div className="flex w-full flex-grow flex-col gap-2 gap-6 overflow-auto text-sm">
                  <div className="flex flex-col gap-2 px-4">
                    <div className="flex items-center gap-3">
                      <MUIcon name="accessibility" />
                      Audience
                    </div>
                    <Donut filteredCount={filteredCountQuery.data} totalCount={totalCountQuery.data} />
                  </div>
                  <div className="flex px-4">
                    <div className="flex w-full flex-col gap-2">
                      <div className="flex items-center gap-3">
                        <MUIcon name="tune" />
                        <span>Filters</span>
                      </div>
                      <AppliedFilters filters={abacusApplicableFilters} />
                    </div>
                  </div>
                </div>
                <div className="flex w-full flex-col">
                  {!audienceSelected && (
                    <div className="flex flex-col gap-6 border-t border-b px-4 py-6">
                      <div className="inline-flex items-center justify-start gap-6">
                        <div className="flex shrink grow items-center justify-start gap-3">
                          <div className="shrink grow">
                            <span className="text-xs font-bold text-gray-800">Note:</span>
                            <span className="text-xs font-normal text-gray-800">
                              {' '}
                              To be able to save or activate a campaign you have to select an audience first.
                            </span>
                          </div>
                        </div>
                        <Buttons.Secondary
                          className="border-primary-200 inline-flex h-9 items-center rounded-full border bg-white py-2 pl-3 pr-4 shadow-none ring-0"
                          onClick={() => setDrawerOpen(true)}
                        >
                          <div className="flex items-center justify-center gap-2">
                            <MUIcon name="search" />
                            Browse audiences
                          </div>
                        </Buttons.Secondary>
                      </div>
                    </div>
                  )}
                  <div className="flex flex-row justify-between gap-2 px-6 pb-4 pt-3">
                    <SaveAudience
                      disabled={!canSave}
                      baseAudienceId={baseAudienceId}
                      filters={abacusApplicableFilters}
                      filteredCount={filteredCountQuery.data}
                      totalCount={totalCountQuery.data}
                      onDone={() => setIsOpen(false)}
                    />
                    <Buttons.Primary
                      icon={<MUIcon name="bolt" />}
                      className="rounded-full px-6"
                      disabled={!canActivate}
                      size="lg"
                      onClick={() => {
                        setIsOpen(false);
                        navigate(`${location.pathname}?activation=true`);
                      }}
                    >
                      Activate campaign
                    </Buttons.Primary>
                  </div>
                </div>
                {drawerOpen && (
                  <AudiencesDrawer
                    isOpen={drawerOpen}
                    onClose={() => {
                      setDrawerOpen(false);
                    }}
                    onSelectAudience={onApplyFilters}
                    filters={filters}
                  />
                )}
              </>
            )}
          </div>
        </Drawers.Drawer>
      )}
    </>
  );
};

export default Overview;
