import { generatePath, matchPath } from 'react-router-dom';

type ApplicationRoute =
  | 'audiences'
  | 'audience'
  | 'home'
  | 'readership'
  | 'activations'
  | 'myAudiences'
  | 'myAudience'
  | 'savedAudienceReadership'
  | 'help'
  | 'account'
  | 'login'
  | 'signup'
  | 'info'
  | 'forgotPassword'
  | 'resetPassword'
  | 'newInvite';

// Define your application's routes
const routes: Record<ApplicationRoute, string> = {
  home: '/',
  login: '/login',
  audiences: '/audiences',
  audience: '/audience',
  readership: '/readership',
  activations: '/activations',
  myAudiences: '/my-audiences',
  myAudience: '/audience/:audienceId',
  savedAudienceReadership: '/readership/:audienceId',
  help: '/help',
  account: '/account',
  signup: '/signup',
  info: '/info',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  newInvite: '/new-invite',
};

export const linkFor = (routeKey: ApplicationRoute): string => routes[routeKey] as string;

export const generateLink = (routeKey: ApplicationRoute, params?: Record<string, unknown>): string => {
  const pathTemplate = routes[routeKey];
  if (!pathTemplate) {
    throw new Error(`Route not found: ${routeKey}`);
  }

  // Check if all required params are provided
  const missingParams: string[] = [];
  const paramMatches = pathTemplate.match(/:\w+/g) || []; // Find all :param in the pathTemplate
  paramMatches.forEach((param) => {
    const cleanParam = param.replace(':', '');
    if (!params || !(cleanParam in params)) {
      missingParams.push(cleanParam);
    }
  });

  if (missingParams.length > 0) {
    throw new Error(`Missing required params for route "${routeKey}": ${missingParams.join(', ')}`);
  }

  return generatePath(pathTemplate, params as any);
};

export function matchRoute(pathToMatch: string): ApplicationRoute | null {
  const matchingRouteEntry = Object.entries(routes).find(([key, value]) => {
    return matchPath({ path: value }, pathToMatch);
  });
  return matchingRouteEntry ? (matchingRouteEntry[0] as ApplicationRoute) : null;
}
