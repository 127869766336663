import React from 'react';
import CinqLogo from '../../../assets/cinqdi-logo.png';

interface IProps {
  message?: string;
}

const Loader: React.FC<IProps> = ({ message }) => (
  <div className="flex h-full w-full max-w-[500px] flex-col items-center justify-center gap-2 text-center text-xl text-gray-400">
    <img src={CinqLogo} width={220} height={220} className="p-3" alt="CinQDI Logo" />
    <span>{message}</span>
    <div className="mt-8 h-1.5 w-40 overflow-hidden rounded bg-blue-100">
      <div className="animate-progress origin-left-right bg-primary-600 h-full w-full"></div>
    </div>
  </div>
);

export default Loader;
