import classNames from 'classnames';
import React from 'react';
import Spinner from '../../Readership/Widgets/Spinner';

interface Props {
  title: string;
  children: React.ReactNode;
  info?: React.ReactNode;
  loading?: boolean;
  actions?: React.ReactNode;
  actionsClassNames?: string;
}

const Container: React.FC<Props> = ({ title, children, info, loading, actions, actionsClassNames }) => {
  return (
    <div
      className="flex min-h-[175px] flex-shrink-0 flex-col gap-1 rounded-[16px] border border-gray-200 bg-white p-4"
      style={{
        pointerEvents: loading ? 'none' : 'initial',
      }}
    >
      <div className="pointer-events-none flex items-center justify-between">
        <div className="pointer-events-auto flex items-center gap-1 p-2">
          <div className="text-sm font-semibold">{title}</div>
          {info}
        </div>
        <div className="pointer-events-auto flex items-center gap-1 px-3">
          {loading ? (
            <Spinner />
          ) : (
            <div className={`flex items-center justify-center gap-3 ${actionsClassNames}`}>
              {actions && <div>{actions}</div>}
            </div>
          )}
        </div>
      </div>
      <div
        className={classNames('flex h-full min-h-0 w-full px-1 pt-2 transition-all')}
        style={{ filter: loading ? 'grayscale(100%)' : 'initial' }}
      >
        {children}
      </div>
    </div>
  );
};

export default Container;
