import { Buttons, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';
import pluralize from 'pluralize';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RuleGroupType, RuleType } from 'react-querybuilder';
import ExpandableGridItem, { GridPosition } from '../../shared/ExpandableGridItem';
import TopArticleItem from '../../shared/Overview/TopArticleItem';
import { useReadershipContext } from '../ReadershipContext';
import * as api from './../../../../mvd.api';
import useReadershipRequest from './useReadershipRequest';
import useReadershipWhereClause from './useReadershipWhereClause';
import WidgetInfo from './WidgetInfo';
import { WidgetQueryKeys } from './WidgetQueryKeys';

const DEFAULT_PAGE_SIZE = 5;
const FILTER_GROUP_ID = MvdTypes.FilterGroup.ARTICLES;

// TODO: Try to integrate into useReadershipData
export const PAGE_LOCATION_FIELD = 'page_location_fixed';
export const HEADLINE_FIELD = 'headline_fixed';

const HEADLINE_WHERE_CORRECTION = `${HEADLINE_FIELD} IS NOT NULL AND ${HEADLINE_FIELD} != '' AND ${HEADLINE_FIELD} != 'undefined'`;

interface Props {
  grid: GridPosition;
  expanded?: boolean;
  pageSize?: number;
}

const TopArticles: React.FC<Props> = ({ grid, expanded, pageSize = DEFAULT_PAGE_SIZE }) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [articles, setArticles] = useState<IArticle[] | null>(null);
  const { queryEnabled, filters, applyFilters } = useReadershipContext();
  const articleRuleGroup = filters.rules.find((ruleGroup) => ruleGroup.id === FILTER_GROUP_ID);
  const articlesCount = articleRuleGroup ? (articleRuleGroup as RuleGroupType).rules.length : 0;
  const mode = articleRuleGroup ? ((articleRuleGroup as RuleGroupType).not === true ? 'exclude' : 'single') : 'default';

  const handleApplyFilter = (newRule: RuleType, combinator: string, not: boolean) => {
    let ruleGroupExists = false;

    const updatedRules = filters.rules.map((ruleGroup) => {
      if (ruleGroup.id !== FILTER_GROUP_ID) return ruleGroup;
      ruleGroupExists = true;

      // If combinator and not are the same, just add the new rule
      if ((ruleGroup as RuleGroupType).combinator === combinator && (ruleGroup as RuleGroupType).not === not) {
        return {
          ...ruleGroup,
          rules: [...(ruleGroup as RuleGroupType).rules, newRule],
        };
      } else {
        // If combinator and not are different, update them and add the new rule
        return {
          ...ruleGroup,
          combinator,
          not,
          rules: [newRule],
        };
      }
    });

    // If the rule group doesn't exist, add it with the new rule
    const finalRules = ruleGroupExists
      ? updatedRules
      : [...filters.rules, { id: FILTER_GROUP_ID, combinator, not, rules: [newRule] }];

    const updatedFilters = {
      ...filters,
      rules: finalRules,
    };

    applyFilters(updatedFilters);
  };

  const handleArticleExclude = (url: string) => {
    const newRule = {
      field: PAGE_LOCATION_FIELD,
      operator: 'like',
      value: `${url}%`,
    };

    handleApplyFilter(newRule, 'or', true);
  };

  const handleSingleArticleSelect = (url: string) => {
    const newRule = {
      field: PAGE_LOCATION_FIELD,
      operator: 'like',
      value: `${url}%`,
    };

    handleApplyFilter(newRule, 'or', false);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [filters]);

  const whereClause = useReadershipWhereClause(filters);

  const sql = `
    SELECT
    DISTINCT ${PAGE_LOCATION_FIELD} AS url, 
    MAX(${HEADLINE_FIELD}) AS headline, 
    COUNT(DISTINCT visitorid) AS count, 
    MAX(topics) AS topicsRaw
    FROM
    WHERE (${whereClause}) AND ${HEADLINE_WHERE_CORRECTION}
    GROUP BY ${PAGE_LOCATION_FIELD}
    ORDER BY count DESC OFFSET ${currentPage * pageSize} 
    LIMIT ${pageSize}`;

  const pqsQuery = useReadershipRequest({ sql, filters });

  const articlesQuery = useQuery(
    [WidgetQueryKeys.ARTICLES, pqsQuery],
    () => api.getDataPqs<IResponseData>(pqsQuery, 'articles'),
    {
      enabled: queryEnabled,
    }
  );

  const totalCountSql = `
    SELECT COUNT(DISTINCT(${PAGE_LOCATION_FIELD})) AS count
    FROM
    WHERE (${whereClause}) AND ${HEADLINE_WHERE_CORRECTION};`;

  const totalCountRequest = useReadershipRequest({ sql: totalCountSql, filters });

  const totalCountQuery = useQuery(
    [WidgetQueryKeys.ARTICLES_COUNT, totalCountRequest],
    () => api.getDataPqs<ITotalCountResponseData>(totalCountRequest, 'articles-count'),
    {
      enabled: queryEnabled,
    }
  );

  const resetArticleFilters = () => {
    const newFiltersRules = filters.rules.filter((ruleGroup) => ruleGroup.id !== FILTER_GROUP_ID);
    const newFilters = {
      ...filters,
      rules: newFiltersRules,
    };
    applyFilters(newFilters);
  };

  useEffect(() => {
    if (!articlesQuery.data) return;

    setArticles(
      articlesQuery.data.map((x, idx) => ({
        ...x,
        index: currentPage * pageSize + idx,
      }))
    );
  }, [articlesQuery.data, pageSize, currentPage]);

  const isLoading =
    articlesQuery.isIdle || articlesQuery.isLoading || totalCountQuery.isIdle || totalCountQuery.isLoading;

  const totalItems = Number(totalCountQuery.data?.[0].count ?? 0);
  const totalPages = Math.ceil(totalItems / pageSize);
  const canGoToPrevPage = !isLoading && currentPage > 0;
  const canGoToNextPage = !isLoading && currentPage + 1 < totalPages;
  const startItem = currentPage * pageSize + 1;
  const endItem = Math.min((currentPage + 1) * pageSize, totalItems);
  const resetArticlesFilterButton = articleRuleGroup ? (
    <Buttons.Soft
      icon={<MUIcon name="refresh" />}
      className="bg-transparent shadow-none hover:bg-transparent"
      onClick={() => resetArticleFilters()}
    >
      Reset
    </Buttons.Soft>
  ) : null;
  const noteText = (
    <>
      {mode === 'exclude'
        ? `${articlesCount} ${pluralize('article', articlesCount)} ${pluralize(
            'was',
            articlesCount
          )} manually excluded from the
      analysis.`
        : `You see data only for the selected article.`}
      <br />
      <br />
      Click ‘Reset’ to clear the selection and return to ‘Top articles’.
    </>
  );

  return (
    <ExpandableGridItem
      title={articleRuleGroup ? 'Articles' : 'Top articles'}
      loading={isLoading}
      grid={grid}
      actions={resetArticlesFilterButton}
      actionsClassNames={'flex-row-reverse'}
      info={articleRuleGroup ? <WidgetInfo title={'Note'} description={noteText} /> : null}
    >
      <div className="flex h-full min-h-[200px] w-full flex-col justify-between gap-4">
        <div className="flex flex-grow flex-col overflow-y-auto">
          {articles?.slice(0, pageSize).map((item, idx) => (
            <TopArticleItem
              key={item.url}
              title={item.headline}
              idx={item.index}
              url={item.url}
              expanded={expanded}
              count={item.count}
              topicsRaw={item.topicsRaw}
              onArticleExclude={handleArticleExclude}
              onSingleArticleSelect={handleSingleArticleSelect}
              mode={mode}
            />
          ))}
        </div>
        <div className="flex items-center justify-between px-2 pb-2 text-[13px] text-gray-600">
          <div>{`${isLoading ? 'Loading' : 'Showing'} ${startItem} - ${endItem} of ${NumberFormatter.format(
            totalItems,
            '0,0'
          )}`}</div>
          <div className="flex gap-4">
            <Buttons.Secondary
              disabled={!canGoToPrevPage}
              className="rounded-full"
              icon={<MUIcon name="chevron_left" />}
              onClick={() => setCurrentPage((p) => Math.max(p - 1, 0))}
            />
            <Buttons.Secondary
              disabled={!canGoToNextPage}
              className="rounded-full"
              icon={<MUIcon name="chevron_right" />}
              onClick={() => setCurrentPage((p) => Math.min(p + 1, totalPages))}
            />
          </div>
        </div>
      </div>
    </ExpandableGridItem>
  );
};

export default TopArticles;

interface IArticle extends IResponseData {
  index: number;
}

interface IResponseData {
  url: string;
  headline: string;
  topicsRaw: string;
  count: string;
}

interface ITotalCountResponseData {
  count: string;
}
