import { Buttons, Inputs, Menu, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classnames from 'classnames';
import pluralize from 'pluralize';
import React, { useEffect, useMemo, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import Chip from '../../shared/Chip';
import { IFilterOption } from '../MvdMasterMapping';
import FilterActions from './FilterActions';
import useFilterGroup from './useFilterGroup';

interface IProps {
  filters: RuleGroupType;
  onDone: (value: RuleGroupType) => void;
  filterGroupId: MvdTypes.FilterGroup;
  customTitle?: string;
}

const Filter: React.FC<IProps> = ({ customTitle, filters, filterGroupId, onDone }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  const {
    title,
    addOption,
    addAllOptions,
    options,
    resetTempFilters,
    resetToDefault,
    apply,
    appliedFiltersCount,
    appliedOptionsNames,
    someOptionsSelected,
    allOptionsSelected,
    hasNotAppliedChanges,
  } = useFilterGroup(filterGroupId, filters);

  useEffect(() => {
    if (!isOpen) resetTempFilters();
  }, [isOpen, resetTempFilters]);

  const handleOptionClick = (option: IFilterOption) => {
    addOption(option);
  };
  const handleOnlyClick = (e: React.MouseEvent, option: IFilterOption) => {
    e.stopPropagation();
    addOption(option, true);
  };

  const handleDoneClick = () => {
    const newFilters = apply();
    onDone(newFilters);
    setIsOpen(false);
  };

  const handleResetClick = () => {
    const newFilters = resetToDefault();
    onDone(newFilters);
    setIsOpen(false);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleTriState = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      addAllOptions();
    } else {
      resetToDefault();
    }
  };

  const pluralizedTitle = pluralize(customTitle ?? title);
  const processedTitle = useMemo(() => {
    if (!appliedOptionsNames.length) return pluralizedTitle;
    if (appliedOptionsNames.length === 1) return appliedOptionsNames[0];
    if (appliedOptionsNames.length === options.length) return `All ${pluralizedTitle}`;
    return `${appliedOptionsNames[0]} + ${appliedOptionsNames.length - 1}`;
  }, [appliedOptionsNames, pluralizedTitle, options.length]);

  const filteredOptions = useMemo(() => {
    return options.filter((option: IFilterOption) => {
      if (searchTerm) {
        return option.name.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return true;
    });
  }, [options, searchTerm]);

  return (
    <Menu
      visible={isOpen}
      menuClasses="mt-4 shadow border border-grey-200 relative overflow-y-hidden"
      trigger={
        <div data-rh={processedTitle}>
          <Chip
            title={processedTitle}
            className={classnames('text-sm font-semibold', {
              'bg-secondary-100 pl-1': appliedFiltersCount,
              'bg-white-200': !appliedFiltersCount,
            })}
            leadingAction={
              appliedFiltersCount > 0 && (
                <div
                  className="hover:bg-secondary-50 flex h-6 w-6 items-center justify-center rounded-full bg-transparent"
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    handleResetClick();
                  }}
                >
                  <MUIcon name="close" className="text-gray-600" iconStyle="filled" />
                </div>
              )
            }
          >
            <MUIcon name={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'} className="text-gray-600" iconStyle="filled" />
          </Chip>
        </div>
      }
      onToggleVisibility={setIsOpen}
    >
      <div className="relative flex w-96 flex-grow flex-col items-center">
        {options.length > 5 && (
          <div className="sticky top-0 flex w-full items-center justify-start border-gray-200 bg-white">
            <Inputs.Input
              placeholder={`Search for ${title.toLowerCase()}`}
              autoFocus
              classes="flex inline-flex h-14 items-center justify-start ring-white px-4 py-3"
              roundedClasses="rounded-none"
              value={searchTerm}
              onChange={handleSearch}
              clearIcon={searchTerm ? <MUIcon name="close" /> : null}
              onClear={() => setSearchTerm('')}
            />
          </div>
        )}
        <div className="flex max-h-80 w-full flex-col gap-2 overflow-auto py-2">
          {filteredOptions.length > 0 && (
            <div
              className="hover:bg-primary-100 focus:bg-primary-100 flex w-full cursor-pointer select-none items-center justify-between py-2 px-4"
              onClick={() =>
                handleTriState({ target: { checked: !allOptionsSelected } } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              <div className="inline-flex flex-grow items-center justify-start gap-4 py-1">
                <Inputs.TriStateCheckBox
                  classes="flex items-center justify-start"
                  checked={allOptionsSelected}
                  className="text-primary-600"
                  intermediate={someOptionsSelected}
                  onChange={handleTriState}
                />
                <div className="flex items-center justify-start text-sm text-gray-800">
                  {`All ${pluralizedTitle.toLowerCase()}`}
                </div>
              </div>
            </div>
          )}
          <div className="flex h-[1px] w-full flex-shrink-0 bg-gray-200" />
          {filteredOptions.length > 0 ? (
            <div className="w-full select-none">
              {filteredOptions.map((opt) => (
                <div
                  key={opt.name}
                  className="hover:bg-primary-100 focus:bg-primary-100 group flex h-[44px] w-full flex-shrink-0 cursor-pointer items-center justify-between gap-4 px-4"
                  onClick={() => handleOptionClick(opt)}
                >
                  <div key={opt.name} className="inline-flex flex-grow items-center justify-start gap-4">
                    <Inputs.CheckBox
                      checked={opt.checked}
                      classes="flex items-center justify-start"
                      className="text-primary-700"
                      onChange={() => ({})}
                    />
                    <div className="flex items-center justify-start text-sm text-gray-800">{opt.name}</div>
                  </div>
                  <Buttons.Soft
                    className="hover:bg-primary-200 focus:bg-primary-200 disabled:bg-primary-200 rounded-full bg-transparent px-4 py-1.5 opacity-0 transition-opacity duration-300 disabled:text-gray-500 group-hover:opacity-100"
                    onClick={(e) => handleOnlyClick(e, opt)}
                  >
                    Only
                  </Buttons.Soft>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex h-12 w-full items-center justify-center px-4 py-3 text-sm font-normal text-gray-800">
              No results
            </div>
          )}
        </div>
      </div>
      <FilterActions
        onReset={handleResetClick}
        onDone={handleDoneClick}
        onCancel={() => setIsOpen(false)}
        disabledApply={!hasNotAppliedChanges}
        disabledReset={appliedFiltersCount === 0}
      />
    </Menu>
  );
};

export default Filter;
