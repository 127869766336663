import { MvdTypes } from '@platform/types';
import { useMemo } from 'react';
import { defaultRuleProcessorSQL, formatQuery, RuleGroupType, RuleProcessor } from 'react-querybuilder';

const READERSHIP_APPLICABLE_FILTERS: string[] = [
  MvdTypes.FilterGroup.GEO,
  MvdTypes.FilterGroup.TOPIC,
  MvdTypes.FilterGroup.TOPIC_DETAILS,
  MvdTypes.FilterGroup.ARTICLES,
  MvdTypes.FilterGroup.AUDIENCE_CODE,
  MvdTypes.FilterGroup.GENDER,
  MvdTypes.FilterGroup.AGE,
  MvdTypes.FilterGroup.EDUCATION_CONDENSED,
  MvdTypes.FilterGroup.ETHNICITY,
  MvdTypes.FilterGroup.HOUSEHOLD_INCOME,
  MvdTypes.FilterGroup.MARITAL_STATUS,
  MvdTypes.FilterGroup.PARTY,
  MvdTypes.FilterGroup.PRESENCE_OF_CHILDREN,
  MvdTypes.FilterGroup.LIKELY_VOTERS,
  MvdTypes.FilterGroup.KEYWORDS,
];

const ALLOWED_GEO_FIELDS: string[] = [MvdTypes.Cities.field, MvdTypes.States.field];

const useReadershipWhereClause = (ruleGroup: RuleGroupType): string =>
  useMemo(() => {
    const deepCopiedRules = JSON.parse(JSON.stringify(ruleGroup)) as RuleGroupType;

    // 1. keep only AWS ATHENA save filter groups
    const onlyApplicableFilterGroups: RuleGroupType = {
      ...deepCopiedRules,
      rules: deepCopiedRules.rules.filter((ruleGroup) => {
        if (!ruleGroup.id) return false;
        return READERSHIP_APPLICABLE_FILTERS.includes(ruleGroup.id);
      }),
    };
    // 2. handle GEO group more specifically by removing geographies which are not available in ATHENA (like counties, districts etc.)
    const geoFilterGroup = onlyApplicableFilterGroups.rules.find(
      (x) => x.id === MvdTypes.FilterGroup.GEO
    ) as RuleGroupType;

    if (geoFilterGroup?.rules) {
      // make sure we remove all the geographies but Cities and States
      geoFilterGroup.rules = filterRuleGroup(geoFilterGroup, ALLOWED_GEO_FIELDS).rules;
    }

    return formatQuery(onlyApplicableFilterGroups, {
      format: 'sql',
      parseNumbers: true,
      quoteFieldNamesWith: '"',
      ruleProcessor: customRuleProcessor,
    });
  }, [ruleGroup]);

const customRuleProcessor: RuleProcessor = (rule, options) => {
  switch (rule.operator) {
    case 'ilike':
      return `LOWER("${rule.field}") LIKE LOWER('%${rule.value}%')`;
    case 'not ilike':
      return `LOWER("${rule.field}") NOT LIKE LOWER('%${rule.value}%')`;
    default:
      return defaultRuleProcessorSQL(rule, options);
  }
};

export default useReadershipWhereClause;

const filterRuleGroup = (ruleGroup: RuleGroupType, allowedFields: string[]): RuleGroupType => ({
  ...ruleGroup,
  rules: ruleGroup.rules
    .map((rule) => {
      if ('rules' in rule) {
        // Recursively process nested rule groups
        return filterRuleGroup(rule as RuleGroupType, allowedFields);
      }
      // Return rule if its field is in the allowedFields array
      return allowedFields.includes(rule.field) ? rule : null;
    })
    .filter((rule) => rule !== null) as RuleGroupType['rules'], // Filter out null values
});
