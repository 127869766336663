import { MvdTypes } from '@platform/types';
import React, { useMemo } from 'react';
import useWidget, { IHighchartsData } from '../../../hooks/useWidget';
import ExpandableGridItem, { GridPosition } from '../../shared/ExpandableGridItem';
import { useReadershipContext } from '../ReadershipContext';
import useReadershipData, { IDataPoint } from './useReadershipData';
import WidgetInfo from './WidgetInfo';
import { WidgetQueryKeys } from './WidgetQueryKeys';

const { FilterGroup } = MvdTypes;

interface Props {
  grid: GridPosition;
}

const LikelyVoters: React.FC<Props> = ({ grid }) => {
  const { filters, queryEnabled } = useReadershipContext();

  const { isLoading, data, title } = useReadershipData({
    filters,
    queryEnabled,
    filterGroupId: FilterGroup.LIKELY_VOTERS,
    queryKey: WidgetQueryKeys.LIKELY_VOTERS,
  });

  const highchartsData = useMemo(() => convertToHighchartsData(data ?? []), [data]);
  const { chart, menu } = useWidget({ title, highchartsData });

  return (
    <ExpandableGridItem
      title={title}
      loading={isLoading}
      grid={grid}
      actions={menu}
      info={
        <WidgetInfo
          title={title}
          description="% of readers by likelihood to vote in each election within selected criteria/filters"
        />
      }
    >
      {chart}
    </ExpandableGridItem>
  );
};

export default LikelyVoters;
const convertToHighchartsData = (data: IDataPoint[]): IHighchartsData => ({
  data,
  categories: data.map((item) => item.name),
});
