import { INotification, SnackbarActionButton, useSnackbars } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import * as mvdApi from '../mvd.api';
import Loader from './components/shared/Loader';
import UnderMaintenance from './components/UnderMaintenance';
import { generateLink } from './LinkGenerator';
import useAudienceQuery from './queries/useAudienceQuery';

interface ContextState {
  jwtToken: string | null;
  isAuthenticated: boolean;
  userInfo: MvdTypes.IUser | undefined;
  login: (token: string) => void;
  logout: () => void;
  notify: (n: INotification) => () => void;
}

const DEFAULT_VALUE: ContextState = {
  jwtToken: null,
  userInfo: undefined,
  isAuthenticated: false,
  login: () => ({}),
  logout: () => ({}),
  notify: () => () => ({}),
};

const JWT_TOKEN = 'jwt';

const ApplicationContext = React.createContext<ContextState>(DEFAULT_VALUE);

interface Props {
  children: React.ReactNode;
}

export const ApplicationContextProvider: React.FC<Props> = ({ children }) => {
  const navigate = useNavigate();
  const [notify, Notifications] = useSnackbars();
  const [jwtToken, setJwtToken] = useState<string | null>(localStorage.getItem(JWT_TOKEN));

  const pingQuery = useQuery('ping', () => mvdApi.ping(), { refetchInterval: 10 * 1000 }); // 10 sec

  const userQuery = useQuery(['session', jwtToken], () => mvdApi.me(), {
    enabled: !!jwtToken,
    staleTime: Infinity,
    onSuccess: async (user) => {
      const isInvited = user.isInvited;
      if (isInvited) {
        const onClose = notify({
          hideAfterSec: 10,
          content: 'As this is your first login, please complete your account information',
          action: (
            <Link
              to={generateLink('account')}
              onClick={() => {
                onClose();
              }}
            >
              <SnackbarActionButton>Set up your account</SnackbarActionButton>
            </Link>
          ),
          dismissible: true,
        });
      }
    },
    onError: () => {
      setJwtToken(null);
      navigate(generateLink('login'));
    },
  });

  const audiencesQuery = useAudienceQuery(userQuery.data, null);

  useEffect(() => {
    if (jwtToken) {
      // Save JWT in localStorage form the memory
      localStorage.setItem(JWT_TOKEN, jwtToken);
    } else {
      localStorage.removeItem(JWT_TOKEN);
    }
  }, [jwtToken]);

  const logout = useCallback(() => {
    setJwtToken(null);
  }, []);

  const login = useCallback((t: string) => {
    setJwtToken(t);
  }, []);

  const memoizedValues = useMemo(
    () => ({
      jwtToken,
      logout,
      login,
      notify,
      userInfo: userQuery.data,
      isAuthenticated: userQuery.data != null,
    }),
    [jwtToken, logout, login, notify, userQuery.data]
  );

  if (pingQuery.isLoading || pingQuery.isIdle) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader message="Loading application" />
      </div>
    );
  }

  if (userQuery.isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader message="Loading user session" />
      </div>
    );
  }

  if (audiencesQuery.isLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader message="Loading user data" />
      </div>
    );
  }

  if (pingQuery.error) {
    return <UnderMaintenance />;
  }

  return (
    <ApplicationContext.Provider value={memoizedValues}>
      {children}
      <div className="fixed bottom-8 left-20 z-[9999]">
        <Notifications />
      </div>
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = (): ContextState => {
  const context = useContext(ApplicationContext);
  if (!context) {
    throw new Error(`useApplicationContext must be used within a ApplicationContextProvider`);
  }
  return context;
};
