import { LngLatBoundsLike } from 'maplibre-gl';
import { RuleGroupType, RuleType } from 'react-querybuilder';

export enum FilterGroup {
  AGE = 'age',
  ARTICLES = 'article',
  EDUCATION_CONDENSED = 'education_condensed',
  ETHNICITY = 'ethnicity',
  GENDER = 'gender',
  HOUSEHOLD_INCOME = 'household_income',
  INTEREST_OVER_TIME = 'interest-over-time',
  LIKELY_VOTERS = 'likely_voters',
  MARITAL_STATUS = 'marital_status',
  MEDIA_SOURCE = 'media-source',
  PARTY = 'party',
  PRESENCE_OF_CHILDREN = 'presence_of_children',
  AUDIENCE_CODE = 'audience_code',
  TIME = 'time',
  TOPIC = 'topic',
  TOPIC_DETAILS = 'topic-details',
  GEO = 'geo',
  HISPANIC_ORIGIN = 'hispanic_origin',
  LANGUAGE = 'language',
  GENERATION = 'generation',
  HISPANIC_LANGUAGE = 'hispanic_language',
  HOME_OWNERSHIP = 'home_ownership',
  VOTER_FREQUENCY = 'voter_frequency',
  EDUCATION = 'education_full',
  KEYWORDS = 'keywords',
  GUN_OWNERSHIP = 'gun_owner',
  RELIGIONS_DESCRIPTION = 'religions_description',
  PRO_CHOICE_VOTERS = 'pro_choice_voters',
}

export type AudienceCategoryType = 'MULTI-ISSUE' | 'ISSUE' | null;

export enum AudienceCategoryEnum {
  'MULTI-ISSUE' = 'MULTI-ISSUE',
  ISSUE = 'ISSUE',
  UNCATEGORIZED = 'UNCATEGORIZED',
}

export enum ActivationType {
  MULTI_FORMAT = 'MULTIFORMAT',
}

export enum ActivationStatus {
  PENDING = 'PENDING',
  SUBMITTED = 'SUBMITTED',
  CANCELLED = 'CANCELLED',
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED',
  DOWNLOADED = 'DOWNLOADED',
  COMPLETE = 'COMPLETE',
}

export enum UserRole {
  CLIENT = 'CLIENT',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN',
  PENDING = 'PENDING',
}

export interface IEmbeddedAuthPayload {
  token: string;
  username: string;
}

export type PreviousExportedFormats = Record<string, boolean>;

export interface IOrganisation {
  name: string;
  orgStatus: number;
  isFree: boolean;
  singleIssueCpm: number;
  multiIssueCpm: number;
  recordCount1kTo10kPrice: number;
  recordCount10kTo100kPrice: number;
  recordCount100kTo1mPrice: number;
  recordCount1mTo10mPrice: number;
  recordCount10mTo100mPrice: number;
  recordCount100mPlusPrice: number;
}

export interface IUser {
  age: string;
  companyName: string;
  demographic: string;
  dev: number;
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  interestStates: string;
  isInvited: number;
  jobFunction: string;
  jobLevel: string;
  orgId: number;
  orgType: string;
  permissions: string[];
  resetToken: string;
  role: UserRole;
  userStatus: number;
  org?: IOrganisation;
}

export interface ICreateUser {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  jobFunction: string;
  jobLevel: string;
}

export interface IChangePassword {
  newPassword: string;
}

export interface IResetPassword {
  id: string;
  newPassword: string;
  token: string;
}

export interface IForgotPassword {
  email: string;
}

export interface ICancelActivation {
  uniqueId: number;
  referenceId: number;
}

export interface IUserSale {
  cost: number;
  total: number;
  userAudienceReferenceId: number;
  platformActivationId: number;
}

export interface ILayerDefinition {
  id: string;
  label: string;
  url: string;
  geoId: string;
  groupBy: string;
  labelField: string;
  filter?: RuleGroupType;
  maxBounds?: LngLatBoundsLike;
}

export interface ITestAudience {
  id: string;
  datasetId: string;
  title: string;
  description: string;
  size: number;
  zoom: number;
  center: { lng: number; lat: number };
  layers: ILayerDefinition[];
  filter?: RuleGroupType;
}

export interface IActivation {
  activationType: string;
  accountId: string;
  platform: IPlatform | null;
  downloadType: string | null;
  campaignStartDate: string | null;
  campaignEndDate: string | null;
  termsAccepted: boolean;
  note: string;
  accountName: string;
}

export interface ICostAndRate {
  cost: number;
  rate: number;
}

export interface IActivationRequest {
  audienceId: number;
  datasetId: string;
  activationType: string;
  accountId: string;
  platform: IPlatform | null;
  downloadType: string | null;
  campaignStartDate: string | null;
  campaignEndDate: string | null;
  rate: number;
  cost: number;
  note?: string;
  accountName?: string;
}

export interface IPlatform {
  id: number;
  name: string;
  value: string;
  image: string;
  rate: number;
  enabled: number;
}

export interface IAudience {
  id: number;
  uuid: string;
  code: string;
  title: string;
  description: string;
  active: boolean;
  custom: boolean;
  topics: string[];
  category: string; // |'ISSUE' | 'MULTI-ISSUE'
  subCategory: string; // |'Education' | 'Role of Government' | 'Single issue' | 'Behavioral'
  cpm: number;
  query?: RuleGroupType;
  categories?: string[];
  size: number;
}

export interface IUserSavedAudience extends IAudience {
  referenceId: number;
}

export interface ICreateUserAudience {
  title: string;
  size: number;
  query: RuleGroupType;
  baseAudienceId: number;
  description?: string;
  requestType?: string;
  format?: string;
  custom?: number;
}

export interface ICreateUserAudienceResponse {
  error?: string;
  id?: number;
}

export interface IPqsDataRequest {
  projectName: string;
  sql: string;
  startDate: string;
  endDate: string;
}

export interface IPqsDataResponse {
  results: string;
  totalExecutionTime: number;
}

export interface IAudienceActivationRequest {
  accountId: string | null;
  accountName: string | null;
  activationStatus: string | null;
  assignedAdmin: string | null;
  audience: string;
  audienceCpm: number;
  audienceId: number;
  created: string;
  custom: number | null;
  description: string;
  endDate: string | null;
  filterQuery: RuleGroupType;
  firstName: string | null;
  lastName: string | null;
  note: string | null;
  platformActivationId: number | null;
  platformDesc: string | null;
  platformId: number | null;
  recordsUploaded: number;
  referenceId: number;
  requestType: string;
  s3Path: string | null;
  startDate: string | null;
  title: string;
  baseAudienceTitle: string;
  uniqueId: number | null;
  updated: string;
  userAudienceStatus: string;
  userId: number;
  username: string;
  uuid: string;
  cost: number | null;
  totalCost: number | null;
}

export interface IHeadlineDetails {
  title: string;
  description: string;
  img: string;
  body?: string;
  publishedAt: string;
  url: string;
}

export interface IGetAudiencesRequest {
  category: string | null;
  includeInactive?: boolean;
  includeCustom?: boolean;
}

export interface IUserCategory {
  id: number;
  name: string;
}

export interface BrainTreeClientToken {
  clientToken: string;
  brainTreeCustomId: string;
}

export interface IActivationPayload {
  timestamp: number;
  details: IActivationRequest;
  braintreeAuth: {
    nonce: string;
    deviceData: string;
    btCustomId: string;
  } | null;
}

export type GeoLevelType = {
  id: string;
  field: string;
  name: string;
  labelProp: string;
  parent: string;
  matchBy: string[];
  layerType: 'polygon' | 'point';
  geoJson: string;
  disabled?: boolean;
  limit?: number;
};

export interface AudienceLimitOption {
  name: string;
  value: number;
  nameClasses: string | undefined;
  label?: string;
}

export type IGeoSelection = {
  name: string;
  column: string;
};

export const States: GeoLevelType = {
  id: 'us-states-sl040',
  field: 'geo.region',
  name: 'States',
  layerType: 'polygon',
  labelProp: 'NAME',
  parent: '',
  matchBy: ['NAME'],
  geoJson: 'assets/geojson/states.json',
};

export const Counties: GeoLevelType = {
  id: 'us-counties-sl050',
  field: 'sl050',
  name: 'Counties',
  layerType: 'polygon',
  labelProp: 'name',
  parent: 'geo.region',
  matchBy: ['state', 'name'],
  geoJson: 'assets/geojson/us-counties.json',
};

export const StateSenateDistricts: GeoLevelType = {
  id: 'us-state-senate-districts-sl610-sldu',
  field: 'sl610',
  name: 'State Senate Districts',
  layerType: 'polygon',
  labelProp: 'name',
  parent: 'geo.region',
  matchBy: ['state', 'name'],
  geoJson: 'assets/geojson/us-state-senate-districts-sl610.json',
};

export const DMA: GeoLevelType = {
  id: 'us-dma-sl-951',
  field: 'sl951',
  name: 'DMA',
  layerType: 'polygon',
  labelProp: 'name',
  parent: '',
  matchBy: ['name'],
  geoJson: 'assets/geojson/us-dma.json',
};

export const CongressionalDistricts: GeoLevelType = {
  id: 'us-congressional-districts-sl500-118th',
  field: 'sl500',
  name: ' Congressional Districts',
  layerType: 'polygon',
  labelProp: 'name',
  parent: 'geo.region',
  matchBy: ['state', 'name'],
  geoJson: 'assets/geojson/us-congressional-districts.json',
};

export const Cities: GeoLevelType = {
  id: 'us-cities',
  field: 'geo.city',
  name: 'Cities',
  layerType: 'point',
  labelProp: 'name',
  parent: 'geo.region',
  matchBy: ['state', 'name'],
  geoJson: 'assets/geojson/us-cities.json',
  limit: 500,
};

export const StateHouseDistricts: GeoLevelType = {
  id: 'us-state-house-districts-sl620-sldl',
  field: 'sl620',
  name: 'State House Districts',
  layerType: 'polygon',
  labelProp: 'name',
  parent: 'geo.region',
  matchBy: ['state', 'name'],
  geoJson: 'assets/geojson/us-state-house-districts-sl620.json',
};

export const AllAvailableGeos = [
  States,
  DMA,
  Counties,
  Cities,
  StateHouseDistricts,
  StateSenateDistricts,
  CongressionalDistricts,
];

export const extractGeoLevelBasedRuleGroup = (geoFilterGroup: RuleGroupType, allowedLevels: GeoLevelType[]) => {
  try {
    const getUniqueFields = (ruleGroup: RuleGroupType): string[] => {
      let fields: string[] = [];

      ruleGroup.rules.forEach((rule) => {
        if ('rules' in rule) {
          // If the rule is a RuleGroupType, recursively call the function
          fields = fields.concat(getUniqueFields(rule as RuleGroupType));
        } else {
          // If the rule is a RuleType, push the field name
          fields.push((rule as RuleType).field);
        }
      });

      return Array.from(new Set(fields));
    };

    const allLevels = getUniqueFields(geoFilterGroup);
    const lastLevelCode = allLevels[allLevels.length - 1];
    return allowedLevels.find((x) => x.field.toLowerCase() === lastLevelCode.toLowerCase());
  } catch {
    return null;
  }
};

export const getDownloadTypeLabel = (downloadType: string | null) => {
  return downloadType === 'ids' ? 'LALIDS' : downloadType;
};

export type IFeedback = {
  page: string;
  userComment: string;
  level: number;
};

export const DownloadTypes = [
  { value: 'ids', label: 'LALID' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'walk', label: 'Walk List' },
  { value: 'phone', label: 'Phone List' },
  { value: 'mail', label: 'Mail' },
];

export const LiveRampPlatforms = [
  {
    id: 1,
    name: 'Facebook',
    value: 'Facebook',
    image: 'facebook.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 2,
    name: 'Pinterest',
    value: 'Pinterest',
    image: 'pinterest.jpg',
    rate: 2.5,
    enabled: 0,
  },
  {
    id: 3,
    name: 'Google Ad Manager',
    value: 'Google Ad Manager',
    image: 'google_admanager.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 4,
    name: 'Xandr',
    value: 'Xandr',
    image: 'xandr.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 5,
    name: 'Snapchat',
    value: 'Snapchat',
    image: 'snapchat.png',
    rate: 2.5,
    enabled: 0,
  },
  {
    id: 6,
    name: 'LinkedIn',
    value: 'LinkedIn',
    image: 'linkedin.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 7,
    name: 'TikTok',
    value: 'TikTok',
    image: 'tiktok.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 8,
    name: 'X',
    value: 'X',
    image: 'x.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 9,
    name: 'Lotame',
    value: 'Lotame',
    image: 'lotame.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 10,
    name: 'StackAdapt',
    value: 'StackAdapt',
    image: 'stackadapt.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 11,
    name: 'The Trade Desk',
    value: 'The Trade Desk',
    image: 'thetradedesk.jpg',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 12,
    name: 'Liveramp',
    value: 'Liveramp',
    image: 'liveramp.jpg',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 13,
    name: 'Display and Video 360',
    value: 'Display and Video 360',
    image: '360.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 14,
    name: 'Google Ads',
    value: 'Google Ads',
    image: 'google_ads.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 15,
    name: 'Basis',
    value: 'Basis',
    image: 'basis.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 16,
    name: 'Adobe',
    value: 'Adobe',
    image: 'adobe.png',
    rate: 2.5,
    enabled: 1,
  },
  {
    id: 17,
    name: 'adform',
    value: 'adform',
    image: 'adform.png',
    rate: 2.5,
    enabled: 1,
  },
];

export const ActivationOptions = [
  {
    value: 'platform',
    label: 'Platform activation',
  },
  {
    value: 'data',
    label: 'Data download',
  },
];
