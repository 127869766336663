import { Buttons, Dropdowns, Inputs, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { AxiosError } from 'axios';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { JOB_FUNCTIONS, SENIORITY_LEVELS } from '../../enums/accountOptions';
import { EMAIL_REGEX } from '../../helpers/helpers';
import { generateLink } from '../../LinkGenerator';
type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  jobFunction: string;
  seniorityLevel: string;
};

const Signup: React.FC = () => {
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);
  const { notify } = useApplicationContext();
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      jobFunction: undefined,
      seniorityLevel: undefined,
    },
  });
  const watchedFormData = watch();

  const passwordValid = useMemo(() => {
    const passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[ !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~])(?=.{8,})');
    return passwordRegex.test(watchedFormData.password);
  }, [watchedFormData.password]);

  const confirmPasswordNotValid = useMemo(
    () =>
      watchedFormData.confirmPassword !== '' &&
      watchedFormData.password !== '' &&
      watchedFormData.confirmPassword !== watchedFormData.password,
    [watchedFormData.confirmPassword, watchedFormData.password]
  );

  const isValid = useMemo(
    () =>
      watchedFormData.firstName &&
      watchedFormData.lastName &&
      watchedFormData.email &&
      watchedFormData.jobFunction &&
      watchedFormData.seniorityLevel &&
      passwordValid &&
      !confirmPasswordNotValid,
    [confirmPasswordNotValid, passwordValid, watchedFormData]
  );
  const createUserMutation = useMutation({
    mutationFn: (payload: MvdTypes.ICreateUser) => mvdApi.createUser(payload),

    onSuccess: async () => {
      navigate(generateLink('login'), { state: { accountSetupInProgress: true } });
    },
    onError: (error: AxiosError<any>) => {
      notify({
        icon: <MUIcon name="warning" />,
        content: error.response?.data || 'Something went wrong. Please try again.',
      });
    },
  });
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    const newUser: MvdTypes.ICreateUser = {
      email: data.email,
      password: data.password,
      firstName: data.firstName,
      lastName: data.lastName,
      jobFunction: data.jobFunction,
      jobLevel: data.seniorityLevel,
    };

    createUserMutation.mutate(newUser);
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <form className="inline-flex  w-[576px] flex-col items-start justify-start rounded-2xl border border-gray-200 bg-white px-12 pb-6 shadow">
        <div className="flex h-[68px] flex-col items-start justify-start gap-6 self-stretch pt-8">
          <div className="self-stretch text-2xl font-bold text-gray-800">Create an account</div>
        </div>
        <div className="inline-flex w-full items-start justify-between gap-4 pt-9 pb-3">
          <Controller
            control={control}
            name="firstName"
            render={({ field }) => (
              <div className="flex w-1/2 flex-col items-start gap-1">
                <label htmlFor="firstName" className="text-primary-800 text-xs font-semibold">
                  First name
                </label>
                <Inputs.Input
                  classes={classNames(
                    'h-[42px] w-full font-normal ring-gray-300 active:ring-gray-600 active:border-gray-600 focus-within:border-gray-600',
                    { 'ring-gray-600 border-gray-600': field.value }
                  )}
                  roundedClasses="rounded-lg"
                  inputClasses="text-sm font-normal w-full"
                  id="firstName"
                  placeholder="First name..."
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  data-cy="firstName-input"
                />
                {errors.firstName && <div className="text-xs font-normal text-red-500">{errors.firstName.message}</div>}
              </div>
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ field }) => (
              <div className="flex w-1/2 flex-col items-start gap-1">
                <label htmlFor="lastName" className="text-primary-800 text-xs font-semibold">
                  Last name
                </label>
                <Inputs.Input
                  classes={classNames(
                    'h-[42px] w-full font-normal ring-gray-300 active:ring-gray-600 active:border-gray-600 focus-within:border-gray-600',
                    { 'ring-gray-600 border-gray-600': field.value }
                  )}
                  roundedClasses="rounded-lg"
                  inputClasses="text-sm font-normal w-full"
                  id="lastName"
                  placeholder="Last name..."
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value)}
                  data-cy="lastName-input"
                />
                {errors.lastName && <div className="text-xs font-normal text-red-500">{errors.lastName.message}</div>}
              </div>
            )}
          />
        </div>
        <Controller
          control={control}
          name="email"
          rules={{
            required: 'Email is required',
            pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email address' },
          }}
          render={({ field }) => (
            <div className="flex w-full flex-col items-start justify-end gap-1 py-3">
              <label htmlFor="email" className="text-primary-800 text-xs font-semibold">
                Email
              </label>
              <Inputs.Input
                type="email"
                classes={classNames(
                  'h-[42px] px-0 font-normal ring-gray-300 active:ring-gray-600 active:border-gray-600 focus-within:border-gray-600',
                  { 'ring-gray-600 border-gray-600': field.value }
                )}
                roundedClasses="rounded-lg"
                inputClasses="text-sm font-normal"
                id="email"
                placeholder="Email"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
                data-cy="email-input"
              />
              {errors.email && <div className="text-xs font-normal text-red-500">{errors.email.message}</div>}
            </div>
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{ required: true }}
          render={({ field }) => (
            <div className="flex w-full flex-col items-start justify-end gap-1 py-3">
              <label htmlFor="pasword" className="text-primary-800 text-xs font-semibold">
                Password
              </label>
              <Inputs.Input
                type={passwordVisible ? 'text' : 'password'}
                placeholder="New Password"
                classes={classNames('h-11 ring-primary-300 focus-within:border-gray-600', {
                  'border-slate-500': field.value,
                })}
                roundedClasses="rounded-lg"
                inputClasses="text-sm font-normal"
                suffixComponent={
                  <button
                    type="button"
                    className="flex items-center justify-center"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    disabled={!watchedFormData.password.length}
                    data-rh={passwordVisible ? 'Hide password' : 'Show password'}
                  >
                    <MUIcon
                      name={passwordVisible ? 'visibility_off' : 'visibility'}
                      className={classNames({
                        'text-primary-400': watchedFormData.password.length === 0,
                        'text-primary-600': watchedFormData.password.length > 0,
                      })}
                    />
                  </button>
                }
                data-cy="newPassword-input"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
              <div
                className={classNames('text-xs font-normal', {
                  'text-green-600': passwordValid,
                  'text-primary-400': !passwordValid,
                })}
              >
                {passwordValid
                  ? 'Correct password'
                  : 'Please enter a valid password with a minimum of 8 characters of uppercase lowercase, and special character.'}
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name="confirmPassword"
          rules={{ required: true }}
          render={({ field }) => (
            <div className="flex w-full flex-col items-start justify-end gap-1 py-3">
              <label htmlFor="confirmPassword" className="text-primary-800 text-xs font-semibold">
                Confirm Password
              </label>
              <Inputs.Input
                type={confirmPasswordVisible ? 'text' : 'password'}
                placeholder="Confirm Password"
                classes={classNames('h-11 w-[230px] ring-primary-300', {
                  'border-slate-500': field.value,
                })}
                roundedClasses="rounded-lg"
                inputClasses="text-sm font-normal"
                suffixComponent={
                  <button
                    type="button"
                    className="flex items-center justify-center"
                    onClick={() => setConfirmPasswordVisible(!confirmPasswordVisible)}
                    disabled={!watchedFormData.confirmPassword.length}
                    data-rh={confirmPasswordVisible ? 'Hide password' : 'Show password'}
                  >
                    <MUIcon
                      name={confirmPasswordVisible ? 'visibility_off' : 'visibility'}
                      className={classNames({
                        'text-primary-400': watchedFormData.confirmPassword.length === 0,
                        'text-primary-600': watchedFormData.confirmPassword.length > 0,
                      })}
                    />
                  </button>
                }
                data-cy="confirmPassword-input"
                value={field.value}
                onChange={(e) => field.onChange(e.target.value)}
              />
              {confirmPasswordNotValid && <p className="pt-1 text-xs text-red-600">Passwords must match</p>}
            </div>
          )}
        />
        <Controller
          name="jobFunction"
          control={control}
          rules={{ required: 'Job function is required' }}
          render={({ field }) => (
            <div className="flex w-full flex-col items-start justify-end gap-1 py-3">
              <label htmlFor="confirmPassword" className="text-primary-800 text-xs font-semibold">
                Job function
              </label>
              <Dropdowns.Dropdown
                options={JOB_FUNCTIONS}
                value={field.value}
                menuClasses="!w-[478px]"
                triggerClasses={classNames('h-[42px] w-[478px] ring-gray-300 font-normal', {
                  'ring-gray-600 border-gray-600': field.value,
                })}
                triggerLabelClasses="mx-2"
                onSelect={(opt) => field.onChange(opt.value)}
                placeholder={field.value ?? 'Select job function'}
              />
              {errors.jobFunction && (
                <div className="text-xs font-normal text-red-500">{errors.jobFunction.message}</div>
              )}
            </div>
          )}
        />
        <Controller
          name="seniorityLevel"
          control={control}
          rules={{ required: 'Seniority level is required' }}
          render={({ field }) => (
            <div className="flex w-full flex-col items-start justify-end gap-1 py-3">
              <label htmlFor="confirmPassword" className="text-primary-800 text-xs font-semibold">
                Seniority / Level
              </label>
              <Dropdowns.Dropdown
                options={SENIORITY_LEVELS}
                value={field.value}
                menuClasses="!w-[478px]"
                triggerClasses={classNames('h-[42px] !w-[478px] w-full ring-gray-300 font-normal', {
                  'ring-gray-600 border-gray-600': field.value,
                })}
                triggerLabelClasses="mx-2"
                onSelect={(opt) => field.onChange(opt.value)}
                placeholder={field.value ?? 'Select seniority'}
              />
              {errors.seniorityLevel && (
                <div className="text-xs font-normal text-red-500">{errors.seniorityLevel.message}</div>
              )}
            </div>
          )}
        />
        <div className="w-full py-4">
          <Buttons.Primary
            type="submit"
            disabled={!isValid}
            data-cy="submit-button"
            className="bg-primary-800 inline-flex w-full items-center justify-center gap-2 px-6 py-2.5 text-center text-sm font-semibold disabled:bg-gray-200 disabled:text-gray-500"
            onClick={handleSubmit(onSubmit)}
          >
            Sign up
          </Buttons.Primary>
        </div>
      </form>
      <div className="inline-flex flex-col items-start justify-start gap-6 pt-6 pb-12">
        <div className="inline-flex items-start justify-center gap-1 px-12">
          <div className="text-[13px] font-normal text-gray-500">Already have an account?</div>
          <Link to={generateLink('login')} className="text-[13px] font-semibold text-teal-500">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Signup;
