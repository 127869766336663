import { ButtonGroup, MUIcon } from '@platform/shared/ui';
import { NumberFormatter } from '@platform/utils';
import Highcharts, { merge } from 'highcharts';
import { useCallback, useMemo, useState } from 'react';
import { PARTY_DEMOCRATS, PARTY_INDEPENDENT, PARTY_REPUBLICANS } from '../components/Audience/useAudienceMapData';
import { IDataSeries } from '../components/Audience/Widgets/useAudienceData';
import ChartOptionsMenu from '../components/shared/ChartOptionsMenu';
import useResizableChart from '../components/shared/useResizableChart';
import { ChartExportType, ChartType } from '../enums/widgetEnums';

const LABELS_COLORS = ['#436983', '#BF3927', '#6D7280'];
const COLORS = [PARTY_DEMOCRATS.color, PARTY_REPUBLICANS.color, PARTY_INDEPENDENT.color];

const defaultChartTypeOptions = [ChartType.bar];
const defaultExportTypeOptions = [ChartExportType.image, ChartExportType.csv];

const CHART_BAR_HEIGHT = 32;

export interface Props {
  title: string;
  data: IDataSeries[];
  initialType?: ChartType;
  chartTypeOptions?: ChartType[];
  exportTypeOptions?: ChartExportType[];
  overrideOptions?: Highcharts.Options;
  useDefaultOptions?: boolean;
  exportOptions?: Highcharts.Options;
  groupingOptions?: {
    label: string;
    value: string;
  }[];
}

const defaultGroupingOptions = [
  {
    label: 'Total',
    value: 'total',
  },
  {
    label: 'by Party',
    value: 'party',
  },
];

/**
 * @param {string} title - The title of the widget
 * @param {IDataSeries[]} data - The data to be displayed in the chart
 * @param {ChartType} [initialType=ChartType.bar] - The initial type of the chart
 * @param {ChartType[]} [chartTypeOptions=defaultChartTypeOptions] - The types of charts that can be selected
 * @param {ChartExportType[]} [exportTypeOptions=defaultExportTypeOptions] - The types of exports that can be selected
 * @param {Highcharts.Options} [overrideOptions] - The options to override the default options
 * @param {boolean} [useDefaultOptions=true] - Whether to use the default options or not
 * @param {Highcharts.Options} [exportOptions] - The options to append to the default options for exporting the chart as an image
 * @param {Highcharts.Options} [groupingOptions] - The options used to display a menu for grouping the data by a specific FilterGroup eg. gender
 */
const useAudienceInsightsWidget = ({
  title,
  data,
  initialType = ChartType.bar,
  chartTypeOptions = defaultChartTypeOptions,
  exportTypeOptions = defaultExportTypeOptions,
  overrideOptions,
  useDefaultOptions = true,
  exportOptions,
  groupingOptions = defaultGroupingOptions,
}: Props) => {
  const groupingEnabled = groupingOptions.length;
  const [grouping, setGrouping] = useState<string>(groupingOptions.find((x) => x.value === 'party')?.value || '*');
  const [chartType, setChartType] = useState<ChartType>(initialType);

  if (!useDefaultOptions && overrideOptions === undefined) {
    throw new Error('useDefaultOptions must be true if overrideOptions is undefined');
  }

  const filteredData = useMemo(() => {
    if (grouping === 'party') return data;
    const groupFilteredData = (data ?? []).map((item) => {
      if (grouping !== '*' && grouping !== 'total') {
        item.data = item.data.filter((x) => x.group === grouping);
      }
      return item;
    });

    const groupedByNameData: IDataSeries[] = data.reduce((acc, item) => {
      const dataMap = new Map<string, IDataPoint>();
      item.data.forEach((x) => {
        const key = x.name + item.name;
        const existing = dataMap.get(key);
        if (existing) {
          existing.y += x.y;
          existing.count += x.count;
          existing.percentage += x.percentage;
        } else {
          dataMap.set(key, x);
        }
      });
      const newData = Array.from(dataMap.values());
      acc.push({ name: item.name, data: newData });
      return acc;
    }, [] as IDataSeries[]);

    return grouping === '*' ? groupedByNameData : groupFilteredData;
  }, [data, grouping]);

  const generateSeriesData = useCallback(
    (data: IDataSeries[]): Highcharts.SeriesOptionsType[] => {
      if (chartType === ChartType.pie) {
        return [
          {
            type: 'pie',
            data: data.map((item) => {
              return {
                name: item.name,
                y: item.data[0]?.y,
                count: item.data[0]?.count,
                percentage: item.data[0]?.percentage,
              };
            }),
          },
        ];
      }
      const series = data.map((item, index) => {
        return {
          type: 'bar',
          data: item.data.map((x) => {
            return {
              xAxis: x.name,
              name: `${x.name}`,
              description: item.name,
              y: x.y,
              count: x.count,
              percentage: NumberFormatter.format(x.percentage, '0,0[.]00%'),
            };
          }),
          label: {
            enabled: true,
          },
          dataLabels: {
            enabled: true,
            formatter: dataLabelsFormatter,
            style: {
              color: '#000',
              textShadow: 'none',
              textOutline: 'none',
            },
          },
          yAxis: index,
          pointWidth: CHART_BAR_HEIGHT,
        };
      });
      return series as Highcharts.SeriesOptionsType[];
    },
    [chartType]
  );

  const generateXAxisCategories = (data: IDataSeries[]) => {
    const categories = data.map((item) => {
      return item.data.map((x) => x.name);
    });

    return Array.from(new Set(categories.flatMap((x) => x)));
  };

  const generateYAxisData = (data: IDataSeries[]) => {
    return data.map((item, index) => {
      return {
        offset: 0,
        max: 150,
        visible: true,
        width: `${100 / data.length}%`,
        left: index ? `${(100 / data.length) * index}%` : '0%',
        title: {
          textAlign: 'left',
          align: 'low',
          text: item.name,
          style: {
            color: LABELS_COLORS[index],
            fontWeight: '600',
            fontSize: '12px',
          },
        },
        labels: {
          enabled: false,
        },
        gridLineWidth: 0,
        opposite: true,
      };
    });
  };

  const mergeDataForTotal = (data: IDataSeries[]) => {
    const totalSum = data.reduce(
      (acc, partyItem) => acc + partyItem.data.reduce((innerSum, category) => innerSum + category.count, 0),
      0
    );
    const aggregatedData: Record<string, IDataPoint> = {};

    data.forEach((series) => {
      series.data.forEach((point) => {
        const { name, y, count, percentage } = point;

        if (!aggregatedData[name]) {
          aggregatedData[name] = {
            name: point.name,
            description: 'Total',
            y: y,
            count: count,
            percentage: percentage,
            group: 'Total',
          };
        } else {
          aggregatedData[name].y += y;
          aggregatedData[name].count += count;
          aggregatedData[name].percentage = (aggregatedData[name].count / totalSum) * 100;
        }
      });
    });

    return Object.values(aggregatedData);
  };

  const exportChart = () => {
    if (chartComponentRef.current) {
      chartComponentRef.current.exportChart(
        {
          filename: `${title.replace(/\s/g, '_').toLowerCase()}_chart`,
        },
        {
          ...options,
          title: { text: title },
          chart: {
            marginTop: 60,
            // we need to differentiate by grouping, since there is diff in data length, also using chart bar height for additional padding
            height:
              grouping === '*'
                ? calculateChartHeight(mergeDataForTotal(filteredData).length, 50) + CHART_BAR_HEIGHT
                : calculateChartHeight(data[0].data.length, 50) + CHART_BAR_HEIGHT,
          },
          plotOptions: {
            bar: {
              pointWidth: CHART_BAR_HEIGHT,
            },
            pie: {
              dataLabels: {
                enabled: true, // Ensure data labels are enabled
                formatter: function () {
                  return NumberFormatter.format(this.percentage, '0,0[.]00%');
                },
                //   distance: 20, // label's position
                style: {
                  fontSize: '12px',
                  color: '#000',
                },
                crop: false,
                padding: 2,
                softConnector: true,
              },
            },
          },
          ...exportOptions,
        }
      );
    }
  };

  const DEFAULT_MENU_OPTIONS: MenuGroup[] = [
    {
      groupName: 'View as',
      options: [{ type: ChartType.bar, label: 'Bar chart', onClick: () => setChartType(ChartType.bar) }],
    },
    {
      groupName: 'Export',
      options: [
        { type: ChartExportType.image, label: 'Image (.png)', icon: <MUIcon name="image" />, onClick: exportChart },
      ],
    },
  ];

  const filteredMenuOptions = DEFAULT_MENU_OPTIONS.map((group) => {
    const filteredOptions = group.options.filter((option) => {
      if (group.groupName === 'View as') {
        return chartTypeOptions.includes(option.type as ChartType);
      } else if (group.groupName === 'Export') {
        return exportTypeOptions.includes(option.type as ChartExportType);
      }
      return false;
    });

    return filteredOptions.length > 0 ? { ...group, options: filteredOptions } : null;
  }).filter((group): group is MenuGroup => group !== null);

  const options: Highcharts.Options = useMemo(() => {
    const defaultPartyOptions: Highcharts.Options = {
      chart: {
        type: chartType,
        height: calculateChartHeight(filteredData[0].data.length, 50),
      },
      legend: {
        enabled: chartType === 'pie',
      },
      xAxis: {
        categories: generateXAxisCategories(filteredData),
      },
      // @ts-ignore
      yAxis: generateYAxisData(filteredData),
      series: generateSeriesData(filteredData),
      colors: COLORS,
      tooltip: {
        useHTML: true,
        outside: true,
        formatter: function () {
          const point = this.point as Highcharts.Point & {
            percentage: number;
            count: number;
            description: string;
          };

          return (
            `<div style=" display: flex; flex-direction: column; justify-content: start; align-items: start; gap: 12px;">` +
            `<div style="color: #1F2937; font-size: 12px; font-weight: 600; font-family: 'Inter', sans-serif; line-height: 1;">${point.name}</div>` +
            `<div style="display: flex; flex-direction: column; justify-content: start; align-items: start; gap: 4px;">` +
            `<div style="color: #4B5563; font-size: 11px; font-weight: 400; font-family: 'Inter', sans-serif; line-height: 1.3;">${point.description}</div>` +
            `<div style="text-align: right; color: #1F2937; font-size: 14px; font-weight: 600; font-family: 'Inter', sans-serif; line-height: 1.3;">${NumberFormatter.format(
              point.percentage,
              '0,0[.]00%'
            )}</div>` +
            `<div style="color: #4B5563; font-size: 11px; font-weight: 400; font-family: 'Inter', sans-serif; line-height: 1.3;">(${NumberFormatter.format(
              point.count,
              '0,0'
            )})</div>` +
            `</div>` +
            `</div>`
          );
        },
      },
    };
    const defaultTotalOptions: Highcharts.Options = {
      chart: {
        type: 'bar',
        height: calculateChartHeight(mergeDataForTotal(filteredData).length, 30),
      },
      xAxis: {
        categories: generateXAxisCategories(filteredData),
      },
      yAxis: {
        visible: false,
      },
      series: [
        {
          type: 'bar',
          data: mergeDataForTotal(filteredData),
          pointWidth: CHART_BAR_HEIGHT,
        },
      ],
      tooltip: defaultPartyOptions.tooltip,
      colors: ['#b1b2b3'],
    };
    if (!useDefaultOptions && overrideOptions) {
      return overrideOptions;
    }
    if (grouping === 'total') {
      return merge({}, defaultTotalOptions, overrideOptions);
    }
    return merge({}, defaultPartyOptions, overrideOptions);
  }, [chartType, filteredData, generateSeriesData, grouping, overrideOptions, useDefaultOptions]);

  const [resizableChart, chartComponentRef] = useResizableChart(options);

  const handleChange = (value: string | number) => {
    setGrouping(value.toString());
  };

  const chart = useMemo(() => {
    const groupingButtons = groupingOptions && (
      <ButtonGroup
        onChange={handleChange}
        value={grouping}
        options={groupingOptions}
        className="!px-3 !py-1"
        selectedClassName="bg-secondary-100"
      />
    );

    return !groupingEnabled ? (
      <div className="flex w-full flex-col">{resizableChart}</div>
    ) : (
      <div className="flex h-full w-full flex-col gap-2">
        <div className="flex justify-end pr-2">{groupingButtons}</div>
        <div className="flex w-full flex-grow flex-col">{resizableChart}</div>
      </div>
    );
  }, [grouping, groupingEnabled, groupingOptions, resizableChart]);

  const menu = <ChartOptionsMenu groupedOptions={filteredMenuOptions} selectedChartType={chartType} />;

  return { chart, menu };
};

export default useAudienceInsightsWidget;

function dataLabelsFormatter(): string {
  // @ts-ignore
  return NumberFormatter.format((this as IDataPoint).percentage, '0,0[.]00%');
}

interface ChartTypeOption {
  type: ChartType;
  label: string;
  icon?: JSX.Element;
  onClick: () => void;
}

interface ChartExportTypeOption {
  type: ChartExportType;
  label: string;
  icon?: JSX.Element;
  onClick: () => void;
}

export interface IDataPoint {
  name: string;
  description?: string;
  y: number;
  count: number;
  percentage: number;
  categorized?: boolean;
  group: string;
}

export type MenuOption = ChartTypeOption | ChartExportTypeOption;

interface MenuGroup {
  groupName: string;
  options: MenuOption[];
}

const calculateChartHeight = (barsCount: number, magicNumber: number) => {
  // magic number that works well
  return barsCount * CHART_BAR_HEIGHT + magicNumber;
};
