import { MvdTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';
import { forwardRef } from 'react';
import { useActivationsContext } from './ActivationsContext';
import BraintreeDropIn from './BraintreeDropIn';

const ActivationBrainTree = forwardRef((_, ref) => {
  const { activation, costAndRate } = useActivationsContext();

  let activationName;
  if (activation.activationType === 'platform') {
    activationName = (
      <p className="font-semibold">
        {`${activation.platform?.name} - From ${activation.campaignStartDate} to ${activation.campaignEndDate}`}
      </p>
    );
  } else {
    activationName = (
      <p className="font-semibold capitalize">{MvdTypes.getDownloadTypeLabel(activation.downloadType)}</p>
    );
  }

  return (
    <div className="flex flex h-full flex-col justify-center gap-8">
      <div className="flex flex-col items-start gap-3">
        <p className="font-regular text-sm">Order total</p>
        <div className="flex h-[52px] w-full items-center justify-between rounded-lg border border-gray-200 px-4 text-sm">
          {activationName}
          <p className="text-sm font-semibold">{`${NumberFormatter.format(costAndRate.cost, 'USD0,00.00')}`}</p>
        </div>
      </div>
      <BraintreeDropIn ref={ref} />
    </div>
  );
});

export default ActivationBrainTree;
