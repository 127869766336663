import { MvdTypes } from '@platform/types';
import { RuleGroupType, RuleType } from 'react-querybuilder';

const { FilterGroup } = MvdTypes;

export function reduceRulesToObject(
  ruleGroup: RuleGroupType,
  parentField: string,
  childField: string
): Record<string, string[]> {
  const result: Record<string, string[]> = {};

  function traverse(group: RuleGroupType, currentRegion: string | null = null) {
    const childValues: string[] = [];

    group.rules.forEach((ruleOrGroup) => {
      if ('rules' in ruleOrGroup) {
        // Recursively traverse the subgroups, carrying the currentRegion forward
        traverse(ruleOrGroup as RuleGroupType, currentRegion);
      } else {
        const rule = ruleOrGroup as RuleType;
        if (rule.field === parentField) {
          currentRegion = rule.value === null ? '*' : (rule.value as string);
          if (!(currentRegion in result)) {
            result[currentRegion] = [];
          }
        } else if (rule.field === childField && currentRegion !== null) {
          const value = rule.operator === 'notNull' ? '*' : rule.value;
          childValues.push(value as string);
        }
      }
    });

    // Add child values to the result after processing all rules in this group
    if (currentRegion !== null && childValues.length > 0) {
      result[currentRegion].push(...childValues);
    } else if (currentRegion !== null && !(currentRegion in result)) {
      result[currentRegion].push('*');
    }
  }

  traverse(ruleGroup);

  return result;
}

export function createRuleGroupFromObject(
  data: Record<string, string[]>,
  parentField: string,
  childField: string
): RuleGroupType {
  const rules: RuleGroupType[] = Object.entries(data).map(([region, values]) => {
    const regionRule: RuleType = {
      field: parentField,
      operator: region === '*' ? 'notNull' : '=',
      value: region === '*' ? null : region,
    };

    const valueRules: RuleType[] = values
      .map((value) => {
        if (parentField === childField) {
          return null;
        }
        return {
          field: childField,
          operator: value === '*' ? 'notNull' : '=',
          value: value === '*' ? null : value,
        } as RuleType;
      })
      .filter(Boolean) as RuleType[];

    return {
      combinator: 'and',
      rules: [
        regionRule,
        ...(valueRules.length > 0 && parentField !== childField ? [{ combinator: 'or', rules: valueRules }] : []),
      ],
    };
  });

  return {
    id: FilterGroup.GEO,
    combinator: 'or',
    rules: rules,
  };
}
