import { Buttons } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import NoActivationsImage from '../../../assets/no-activation.png';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import Loader from '../shared/Loader';
import ActivationsTable from './ActivationsTable';

const Activations: React.FC = () => {
  const { userInfo } = useApplicationContext();
  const [active, setActive] = useState(true);
  const [finished, setFinished] = useState(false);
  const [audienceActivationRequests, setAudienceActivationRequests] = useState<MvdTypes.IAudienceActivationRequest[]>(
    []
  );

  const audienceRequestsQuery = useQuery(['audience-activation-requests', userInfo?.email], () =>
    mvdApi.getAudienceRequests()
  );

  useEffect(() => {
    if (!audienceRequestsQuery.data) return;
    if (audienceRequestsQuery.isSuccess) {
      const today = new Date();
      const filteredRecords = audienceRequestsQuery.data.filter((record) => {
        if (record.endDate) {
          return new Date(record.endDate) >= today;
        } else return true;
      });
      setAudienceActivationRequests(filteredRecords);
    }
  }, [audienceRequestsQuery.data]);

  if (!audienceRequestsQuery.data) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Loader />
      </div>
    );
  }

  const onActiveRequests = () => {
    setFinished(false);
    setActive(true);
    const today = new Date();
    const filteredRecords = audienceRequestsQuery.data.filter((record) => {
      if (record.endDate) {
        return new Date(record.endDate) >= today;
      } else return true;
    });
    setAudienceActivationRequests(filteredRecords);
  };

  const onFinishedRequests = () => {
    setFinished(true);
    setActive(false);
    const today = new Date();
    const filteredRecords = audienceRequestsQuery.data.filter((record) => {
      if (record.endDate) {
        return new Date(record.endDate) < today;
      } else return false;
    });
    setAudienceActivationRequests(filteredRecords);
  };

  // ToDo add pagination
  return (
    <div>
      <div className="sticky top-0 z-10 flex w-full flex-col border-b border-gray-200 bg-white px-8 py-6">
        <div className="flex flex-col gap-10">
          <div className="text-xl font-semibold">Activations</div>
        </div>
        <div className="ml-12 flex gap-4 pt-10">
          <Buttons.Secondary
            className={classNames('rounded-full px-4 py-2', active && 'bg-blue-100 hover:bg-blue-100')}
            onClick={onActiveRequests}
          >
            Active
          </Buttons.Secondary>
          <Buttons.Secondary
            className={classNames('rounded-full px-4 py-2', finished && 'bg-blue-100 hover:bg-blue-100')}
            onClick={onFinishedRequests}
          >
            Finished
          </Buttons.Secondary>
        </div>
      </div>
      {audienceActivationRequests.length > 0 ? (
        <ActivationsTable activationRequests={audienceActivationRequests} />
      ) : (
        <div className="mt-[10%] flex flex-col items-center justify-center">
          <div className="p-10">
            <img src={NoActivationsImage} alt="no activations" />
          </div>
          <div className="text-lg font-semibold text-gray-800">No Activation history existing</div>
          <div className="text-sm text-gray-700">After you activate an audience, it will be displayed here.</div>
        </div>
      )}
    </div>
  );
};

export default Activations;
