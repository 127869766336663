import React from 'react';
import { useParams } from 'react-router-dom';
import AudienceWrapper from '../components/Audience/AudienceWrapper';

const AudiencePage: React.FC = () => {
  const { audienceId } = useParams<{ audienceId: string | undefined }>();
  return <AudienceWrapper audienceId={audienceId} />;
};

export default AudiencePage;
