import { Buttons, Dropdowns, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';
import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import * as mvdApi from '../../../mvd.api';
import { generateLink } from '../../LinkGenerator';
import DeleteUserAudienceModal from './DeleteUserAudienceModal';

interface IProps {
  audience: MvdTypes.IUserSavedAudience;
  userId: number | undefined;
}

const DROPDOWN_OPTIONS: Dropdowns.DropdownOption[] = [
  {
    name: 'Delete',
    value: 'delete',
    icon: <MUIcon name="delete" />,
  },
];

const SavedAudience: React.FC<IProps> = ({ audience, userId }) => {
  const { id, size, title, referenceId } = audience;
  const [action, setAction] = React.useState<string | null>(null);
  //const [isActivationVisible, setIsActivationVisible] = React.useState<boolean>(false);
  const queryClient = useQueryClient();

  // const handleActivationsTabClose = () => {
  //   setIsActivationVisible(false);
  // };

  const deleteUserAudienceMutation = useMutation({
    mutationFn: async () => mvdApi.softDeleteUserAudience(referenceId),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['user-audiences', userId] });
      setAction(null);
    },
  });

  const handleSelectDropdownOption = (option: Dropdowns.DropdownOption) => {
    setAction(option.value as string);
  };

  const handleSavedAudienceDelete = () => {
    deleteUserAudienceMutation.mutate();
  };

  return (
    <div key={id} className="flex w-[49%] flex-col gap-2 rounded-xl border bg-white bg-gray-50 py-5">
      <div className="flex flex-col items-start gap-8 px-6 py-2">
        <div className="text-sm font-semibold">{title}</div>
        <div className="flex items-center justify-center gap-2 text-xs text-gray-500">
          <MUIcon name="person" />
          <span>{`${NumberFormatter.format(size, '0,000')} voters`}</span>
        </div>
      </div>
      <div className="flex justify-between gap-4 border-t border-gray-200 px-6 pt-5">
        <div className="flex gap-4">
          <Link to={generateLink('myAudience', { audienceId: id })} className="h-[36px]">
            <Buttons.Secondary
              className="h-full rounded-full border border-gray-200 text-sm text-gray-800 shadow-none ring-0"
              icon={<MUIcon name="conditions" className="text-gray-800" />}
            >
              Explore audience
            </Buttons.Secondary>
          </Link>
          <Link to={generateLink('savedAudienceReadership', { audienceId: id })} className="h-[36px]">
            <Buttons.Secondary
              className="rounded-full border border-gray-200 text-sm text-gray-800 shadow-none ring-0"
              icon={<MUIcon name="quick_reference_all" className="text-gray-800" />}
            >
              Readership
            </Buttons.Secondary>
          </Link>
          <div className="flex gap-4">
            <Link to={generateLink('myAudience', { audienceId: id }) + '?activation=true'} className="h-[36px]">
              <Buttons.Secondary
                className="h-[36px] w-[36px] rounded-full border border-gray-200 text-sm text-gray-800 shadow-none ring-0"
                icon={<MUIcon name="bolt" className="text-gray-800" />}
              />
            </Link>
          </div>
        </div>
        <div className="flex gap-4">
          <Dropdowns.Dropdown
            icon={<MUIcon name="more_vert" className="text-gray-600" />}
            iconClose={null}
            triggerClasses={'rounded-full h-[36px] w-[36px] justify-center'}
            options={DROPDOWN_OPTIONS}
            onSelect={handleSelectDropdownOption}
            hAlignment="right"
          />
        </div>
      </div>
      {action === 'delete' && (
        <DeleteUserAudienceModal
          title="Delete saved audience"
          message={`Are you sure you want to delete this audience?`}
          onCloseRequest={() => setAction(null)}
          onConfirm={() => handleSavedAudienceDelete()}
        />
      )}
    </div>
  );
};

export default SavedAudience;
