import { Abacus } from '@platform/shared/hooks';
import { MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import { ReactComponent as DatasetNotFoundSVG } from '../../../assets/dataset-not-found.svg';
import * as api from '../../../mvd.api';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { prepareFiltersForAbacus } from '../../helpers/helpers';
import ErrorPage from '../shared/ErrorPage';
import Loader from '../shared/Loader';

const DATASET_ID = process.env['NX_ABACUS_VOTER_DATASET_ID'] as string;

const DEFAULT_STATE: MvdTypes.IActivation = {
  activationType: MvdTypes.ActivationOptions[0].value,
  accountId: '',
  platform: null,
  downloadType: null,
  campaignStartDate: null,
  campaignEndDate: null,
  termsAccepted: false,
  note: '',
  accountName: '',
};

interface ContextState {
  audienceId: number;
  recordsCount: number;
  audience: MvdTypes.IAudience;
  requiresNewAudience: boolean;
  isActivating: boolean;
  filters: RuleGroupType;
  activation: MvdTypes.IActivation;
  setActivation: (activation: MvdTypes.IActivation) => void;
  runActivation: (braintreeAuth: BrainTreeAuth | null) => void;
  totalCount: number;
  onExitRequest: () => void;
  exportFormats: MvdTypes.PreviousExportedFormats;
  costAndRate: MvdTypes.ICostAndRate;
}

const ActivationsContext = createContext<ContextState | undefined>(undefined);

export const ActivationsProvider = ({
  audienceId,
  appliedFilters,
  children,
  onExitRequest,
}: {
  audienceId: number;
  appliedFilters: RuleGroupType;
  children: ReactNode;
  onExitRequest: () => void;
}) => {
  const queryClient = useQueryClient();
  const { notify, userInfo } = useApplicationContext();
  const [activation, setActivation] = useState<MvdTypes.IActivation>(DEFAULT_STATE);
  useEffect(() => {
    if (audienceId === null || isNaN(audienceId)) {
      onExitRequest();
    }
  }, [audienceId]);

  const audienceQuery = useQuery(['audience', audienceId], () => mvdApi.getAudienceById(audienceId.toString()), {
    enabled: !Number.isNaN(audienceId),
  });

  const filters = prepareFiltersForAbacus(appliedFilters);

  const filteredCountQuery = Abacus.useAbacusRowsCount({
    datasetId: DATASET_ID,
    api: api.getRowCount,
    dataQuery: filters,
  });

  const totalCountQuery = Abacus.useAbacusRowsCount({
    api: api.getRowCount,
    datasetId: DATASET_ID,
  });

  const exportTypes = MvdTypes.DownloadTypes.map((x) => x.value);
  const exportFormatsQuery = useQuery(
    ['exportFormatsQuery', audienceQuery.data?.uuid],
    () => mvdApi.checkExistingFormats(audienceQuery.data?.uuid as string, exportTypes),
    {
      cacheTime: 0,
      staleTime: 0,
      enabled: audienceQuery.data?.uuid != null,
    }
  );

  const activateMutation = useMutation({
    mutationFn: (payload: MvdTypes.IActivationPayload) => mvdApi.activate(payload),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['audience-activation-requests'] });

      onExitRequest();
      notify({
        hideAfterSec: 5,
        content: 'Audience submitted for activation.',
        icon: <MUIcon name="check" />,
      });
    },
    onError: () => {
      onExitRequest();
      notify({ hideAfterSec: 5, content: 'Failed to activate.' });
    },
  });

  if (audienceQuery.isError) {
    <ErrorPage
      imageComponent={<DatasetNotFoundSVG />}
      title="Audience not found"
      message="The audience you are trying to access does not exist, or you do not have the necessary permissions to view it."
    />;
  }

  if (
    audienceQuery.isLoading ||
    audienceQuery.isIdle ||
    filteredCountQuery.isLoading ||
    filteredCountQuery.isIdle ||
    totalCountQuery.isLoading ||
    totalCountQuery.isIdle ||
    exportFormatsQuery.isLoading ||
    exportFormatsQuery.isIdle
  ) {
    return (
      <div className="fixed top-0 left-0 z-[10000000000] flex h-full w-full flex-col items-center justify-center overflow-y-scroll bg-white">
        <Loader message="Preparing activation" />
      </div>
    );
  }

  if (audienceQuery.isError || filteredCountQuery.isError || totalCountQuery.isError || exportFormatsQuery.isError) {
    // TODO:
    return <div>Something went wrong</div>;
  }

  let costAndRate: MvdTypes.ICostAndRate;

  if (
    activation.activationType === 'platform' &&
    audienceQuery.data &&
    activation.campaignStartDate &&
    activation.campaignEndDate
  ) {
    costAndRate = calculatePlatformCostAndRate(
      audienceQuery.data,
      userInfo?.org,
      activation.campaignStartDate,
      activation.campaignEndDate,
      filteredCountQuery.data
    );
  } else if (activation.activationType === 'data') {
    costAndRate = calculateDataExportCostAndRate(userInfo?.org, filteredCountQuery.data);
  } else {
    costAndRate = { cost: 0, rate: 0 };
  }

  const runActivation = async (braintreeAuth: BrainTreeAuth | null) => {
    try {
      if (!audienceQuery.data) {
        console.error('audience not loaded');
        return;
      }

      if (!filteredCountQuery.data) {
        console.error('audience is empty');
        return;
      }

      if (!exportFormatsQuery.data) {
        console.error('export formats are empty');
        return;
      }

      const { title, id, description } = audienceQuery.data;

      let audienceIdToActivate = audienceId;

      if (requiresNewAudience) {
        const payload = {
          title,
          description,
          baseAudienceId: id,
          size: filteredCountQuery.data,
          query: filters,
          custom: 1,
          requestType: 'CUSTOM',
        };

        const saveAudienceResponse = await mvdApi.createUserAudience(payload);

        if (saveAudienceResponse.error) {
          throw new Error(`Failed to create user audience.`);
        }
        audienceIdToActivate = saveAudienceResponse.id as number;
      }

      if (activation.activationType === 'data' && activation.downloadType != null) {
        const hasBeenAlreadyExported = exportFormatsQuery.data[activation.downloadType];

        if (!hasBeenAlreadyExported) {
          const payload = {
            description,
            title: `${title} - ${activation.downloadType}`, // IMPORTANT: required my Incubates backend
            custom: 2,
            requestType: 'MULTIFORMAT',
            baseAudienceId: audienceIdToActivate,
            size: filteredCountQuery.data,
            query: filters,
            format: activation.downloadType,
          };
          const saveAudienceForDataExport = await mvdApi.createUserAudience(payload);
          if (saveAudienceForDataExport.error) {
            throw new Error(`Failed to create user audience for export.`);
          }

          audienceIdToActivate = saveAudienceForDataExport.id as number;
        }
      }

      const activationPayload: MvdTypes.IActivationPayload = {
        timestamp: Date.now(),
        details: {
          audienceId: audienceIdToActivate,
          datasetId: DATASET_ID,
          activationType: activation.activationType,
          accountId: activation.accountId,
          platform: activation.platform,
          downloadType: activation.downloadType,
          campaignStartDate: activation.campaignStartDate,
          campaignEndDate: activation.campaignEndDate,
          rate: costAndRate.rate,
          cost: costAndRate.cost,
          note: activation.note,
          accountName: activation.accountName,
        },
        braintreeAuth, // its null in case of "free orgs"
      };

      activateMutation.mutate(activationPayload);
    } catch (error) {
      notify({ hideAfterSec: 5, content: 'Failed to start activation process' });
    }
  };

  const requiresNewAudience = !audienceQuery.data.custom || !checkFiltersEqual(filters, audienceQuery.data.query);

  return (
    <ActivationsContext.Provider
      value={{
        filters,
        audienceId,
        activation,
        requiresNewAudience,
        runActivation,
        setActivation,
        onExitRequest,
        isActivating: activateMutation.isLoading,
        recordsCount: filteredCountQuery.data,
        totalCount: totalCountQuery.data,
        audience: audienceQuery.data,
        exportFormats: exportFormatsQuery.data,
        costAndRate,
      }}
    >
      {children}
    </ActivationsContext.Provider>
  );
};

export const useActivationsContext = (): ContextState => {
  const context = useContext(ActivationsContext);
  if (!context) {
    throw new Error(`useAudienceContext must be used within a AudienceContextProvider`);
  }
  return context;
};

const checkFiltersEqual = (a: RuleGroupType | undefined, b: RuleGroupType | undefined): boolean => {
  // TODO: Find a better way to compare filters
  if (!a && !b) return true;
  if (!a || !b) return false;
  return JSON.stringify(a) === JSON.stringify(b);
};

type BrainTreeAuth = { btCustomId: string; nonce: string; deviceData: string };

const calculateMonthsAvg = (startDate: string | Date, endDate: string | Date): number => {
  const diffInDays = Math.round((new Date(endDate).getTime() - new Date(startDate).getTime()) / (1000 * 3600 * 24));
  return diffInDays / 30;
};

const calculateCost = (records: number, rate: number, campaignStartDate: string, campaignEndDate: string): number => {
  const monthAvg = calculateMonthsAvg(campaignStartDate, campaignEndDate);
  return (records / 1000) * rate * monthAvg;
};

const calculatePlatformCostAndRate = (
  audience: MvdTypes.IAudience,
  org: MvdTypes.IOrganisation | undefined,
  campaignStartDate: string,
  campaignEndDate: string,
  recordsCount: number
) => {
  let rate;
  if (org) {
    // -- PLATFORM as ORGANISATION
    if (org.isFree) {
      if (audience.category === MvdTypes.AudienceCategoryEnum['MULTI-ISSUE']) {
        rate = org.multiIssueCpm;
      } else {
        rate = org.singleIssueCpm;
      }
    } else {
      rate = audience.cpm;
    }
  } else {
    // -- PLATFORM without ORGANISATION
    rate = audience.cpm;
  }
  const cost = calculateCost(recordsCount, rate, campaignStartDate, campaignEndDate);

  return { rate, cost };
};

const calculateDataExportCostAndRate = (org: MvdTypes.IOrganisation | undefined, recordsCount: number) => {
  if (org && org.isFree) {
    if (recordsCount <= 10000) {
      return { rate: org.recordCount1kTo10kPrice, cost: recordsCount * org.recordCount1kTo10kPrice };
    } else if (recordsCount <= 100000) {
      return { rate: org.recordCount10kTo100kPrice, cost: recordsCount * org.recordCount10kTo100kPrice };
    } else if (recordsCount <= 1000000) {
      return { rate: org.recordCount100kTo1mPrice, cost: recordsCount * org.recordCount100kTo1mPrice };
    } else if (recordsCount <= 10000000) {
      return { rate: org.recordCount1mTo10mPrice, cost: recordsCount * org.recordCount1mTo10mPrice };
    } else if (recordsCount <= 100000000) {
      return { rate: org.recordCount10mTo100mPrice, cost: recordsCount * org.recordCount10mTo100mPrice };
    } else {
      return { rate: org.recordCount100mPlusPrice, cost: recordsCount * org.recordCount100mPlusPrice };
    }
  } else {
    if (recordsCount <= 10000) {
      return { rate: 0.09, cost: recordsCount * 0.09 };
    } else if (recordsCount <= 100000) {
      return { rate: 0.08, cost: recordsCount * 0.08 };
    } else if (recordsCount <= 1000000) {
      return { rate: 0.07, cost: recordsCount * 0.07 };
    } else if (recordsCount <= 10000000) {
      return { rate: 0.05, cost: recordsCount * 0.05 };
    } else if (recordsCount <= 100000000) {
      return { rate: 0.03, cost: recordsCount * 0.03 };
    } else {
      return { rate: 0.02, cost: recordsCount * 0.02 };
    }
  }
};
