import { Buttons, Dropdowns, Inputs, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { JOB_FUNCTIONS, SENIORITY_LEVELS } from '../../enums/accountOptions';
import UpdatePasswordModal from './UpdatePasswordModal';

interface IProps {
  userInfo: MvdTypes.IUser;
}

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  jobFunction: string;
  seniorityLevel: string;
};
const CINQDI_URL = process.env.NX_CINQDI_CLIENT_ROOT + '/login';

const PersonalInfo: React.FC<IProps> = ({ userInfo }) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { notify } = useApplicationContext();
  const queryClient = useQueryClient();

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<Inputs>({
    defaultValues: {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      organization: userInfo.companyName,
      jobFunction: userInfo.jobFunction,
      seniorityLevel: userInfo.jobLevel,
    },
  });
  const updateAccountInfoMutation = useMutation({
    mutationFn: (payload: MvdTypes.IUser) => mvdApi.updateUserInfo(payload),
    onSuccess: async () => {
      notify({
        hideAfterSec: 5,
        content: 'Account details have been updated successfully.',
        icon: <MUIcon name="check" />,
      });
      await queryClient.invalidateQueries(['session']);
    },
  });
  const watchedFormData = watch();

  const isValid = useMemo(() => {
    return (
      watchedFormData.firstName &&
      watchedFormData.lastName &&
      watchedFormData.jobFunction &&
      watchedFormData.seniorityLevel
    );
  }, [
    watchedFormData.firstName,
    watchedFormData.jobFunction,
    watchedFormData.lastName,
    watchedFormData.seniorityLevel,
  ]);

  const handleAccountUpdate: SubmitHandler<Inputs> = (data) => {
    const newUserInfo: MvdTypes.IUser = {
      ...userInfo,
      jobLevel: data.seniorityLevel,
      ...data,
    };
    updateAccountInfoMutation.mutate(newUserInfo);
  };

  return (
    <div>
      <UpdatePasswordModal isOpen={modalOpen} onClose={() => setModalOpen(false)} />
      <div className="inline-flex h-20 w-full items-center justify-start gap-40 border-b border-gray-200 bg-white px-8 py-4">
        <div className="text-xl font-semibold text-black">Account</div>
      </div>
      <div className="p-8">
        <div className="inline-flex items-start justify-start gap-4 pb-6">
          <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-1">
            <div className="self-stretch text-base font-normal leading-normal text-gray-900">Account details</div>
          </div>
        </div>

        <form
          onSubmit={handleSubmit(handleAccountUpdate)}
          className="flex w-full flex-col rounded-2xl"
          data-cy="update-account-form"
          autoComplete={'off'}
        >
          <div className="inline-flex flex-col items-start justify-start gap-3 rounded-t-2xl border border-gray-200 bg-white">
            <div className="flex flex-col items-center justify-center gap-3 self-stretch px-6 pt-6">
              <div className="inline-flex h-12 w-full flex-col items-start justify-start gap-3">
                <div className="inline-flex h-9 items-center justify-start gap-4 py-3">
                  <label htmlFor="email" className="min-w-[226px] text-sm font-semibold text-gray-900">
                    Email / Username
                  </label>
                  <div className="text-sm font-normal leading-normal text-gray-900">{userInfo.email}</div>
                </div>
                <div className="h-[0px] self-stretch border border-slate-200"></div>
              </div>

              <div className="inline-flex h-12 w-full flex-col items-start justify-start gap-3">
                <div className="inline-flex h-9 items-center justify-start gap-4 py-3">
                  <label htmlFor="organization" className="min-w-[226px] text-sm font-semibold text-gray-900">
                    Organization
                  </label>
                  <div className="text-sm font-normal leading-normal text-gray-900">{userInfo.companyName}</div>
                </div>
                <div className="h-[0px] self-stretch border border-slate-200"></div>
              </div>

              <div className="inline-flex h-12 w-full flex-col items-start justify-start gap-3">
                <div className="inline-flex h-9 items-center justify-start gap-4 py-3">
                  <label htmlFor="firstName" className="min-w-[226px] text-sm font-semibold text-gray-900">
                    First name*
                  </label>
                  <Controller
                    control={control}
                    name="firstName"
                    rules={{ required: 'First name is required' }}
                    render={({ field }) => (
                      <Inputs.Input
                        classes={classNames(
                          'h-[42px] !w-[230px] px-0 font-normal ring-gray-300 active:ring-gray-600 active:border-gray-600 focus-within:border-gray-600',
                          { 'ring-gray-600 border-gray-600': field.value }
                        )}
                        roundedClasses="rounded-lg"
                        inputClasses="text-sm font-normal"
                        id="firstName"
                        placeholder="Enter first name"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        data-cy="firstName-input"
                      />
                    )}
                  />
                  {errors.firstName && <p className="pt-1 text-xs text-red-600">{errors.firstName.message}</p>}
                </div>
                <div className="h-[0px] self-stretch border border-slate-200"></div>
              </div>

              <div className="inline-flex h-12 w-full flex-col items-start justify-start gap-3">
                <div className="inline-flex h-9 items-center justify-start gap-4 py-3">
                  <label htmlFor="lastName" className="min-w-[226px] text-sm font-semibold text-gray-900">
                    Last name*
                  </label>
                  <Controller
                    control={control}
                    name="lastName"
                    rules={{ required: 'Last name is required' }}
                    render={({ field }) => (
                      <Inputs.Input
                        classes={classNames(
                          'h-[42px] !w-[230px] px-0 font-normal ring-gray-300 active:ring-gray-600 focus-within:border-gray-600',
                          { 'ring-gray-600 border-gray-600': field.value }
                        )}
                        roundedClasses="rounded-lg"
                        inputClasses="text-sm font-normal"
                        id="lastName"
                        placeholder="Enter last name"
                        value={field.value}
                        onChange={(e) => field.onChange(e.target.value)}
                        data-cy="lastName-input"
                      />
                    )}
                  />
                  {errors.lastName && <p className="pt-1 text-xs text-red-600">{errors.lastName.message}</p>}
                </div>
                <div className="h-[0px] self-stretch border border-slate-200"></div>
              </div>

              <div className="inline-flex h-12 w-full flex-col items-start justify-start gap-3">
                <div className="inline-flex h-9 items-center justify-start gap-4 py-3">
                  <label htmlFor="jobFunction" className="min-w-[226px] text-sm font-semibold text-gray-900">
                    Job function*
                  </label>
                  <Controller
                    name="jobFunction"
                    control={control}
                    rules={{ required: 'Job function is required' }}
                    render={({ field }) => (
                      <div>
                        <Dropdowns.Dropdown
                          options={JOB_FUNCTIONS}
                          value={field.value}
                          triggerClasses={classNames('h-[42px] w-[230px] ring-gray-300 font-normal', {
                            'ring-gray-600 border-gray-600': field.value,
                          })}
                          triggerLabelClasses="mx-2"
                          onSelect={(opt) => field.onChange(opt.value)}
                          placeholder={field.value ?? 'Select job function'}
                        />
                      </div>
                    )}
                  />
                  {errors.jobFunction && <p className="pt-1 text-xs text-red-600">{errors.jobFunction.message}</p>}
                </div>
                <div className="h-[0px] self-stretch border border-slate-200"></div>
              </div>

              <div className="inline-flex h-12 w-full flex-col items-start justify-start gap-3">
                <div className="inline-flex h-9 items-center justify-start gap-4 py-3">
                  <label htmlFor="seniorityLevel" className="min-w-[226px] text-sm font-semibold text-gray-900">
                    Seniority / Level*
                  </label>
                  <Controller
                    name="seniorityLevel"
                    control={control}
                    rules={{ required: 'Seniority / Level is required' }}
                    render={({ field }) => (
                      <div>
                        <Dropdowns.Dropdown
                          options={SENIORITY_LEVELS}
                          value={field.value}
                          triggerClasses={classNames('h-[42px] w-[230px] ring-gray-300 font-normal', {
                            'ring-gray-600 border-gray-600': field.value,
                          })}
                          triggerLabelClasses="mx-2"
                          onSelect={(opt) => field.onChange(opt.value)}
                          placeholder={field.value ?? 'Select seniority'}
                        />
                      </div>
                    )}
                  />
                  {errors.seniorityLevel && (
                    <p className="pt-1 text-xs text-red-600">{errors.seniorityLevel.message}</p>
                  )}
                </div>
                <div className="h-[0px] self-stretch border border-slate-200"></div>
              </div>

              <div className="inline-flex h-12 w-full items-start items-center justify-between gap-3 pb-1">
                <div className="inline-flex h-9 items-center items-center justify-start gap-4 py-3">
                  <label htmlFor="password" className="min-w-[226px] text-sm font-semibold text-gray-900">
                    Password*
                  </label>
                  <div className="text-sm font-normal leading-normal text-gray-900">************</div>
                </div>
                <Buttons.Link
                  className="h-9 self-stretch rounded-full bg-transparent px-3 py-2 shadow-none ring-0"
                  onClick={() => setModalOpen(true)}
                >
                  Change password
                </Buttons.Link>
              </div>
            </div>
          </div>
          <div className="inline-flex justify-between gap-5 rounded-b-lg border border-t-0 border-slate-200 bg-white px-6 py-4">
            {(userInfo.role === MvdTypes.UserRole.ADMIN || userInfo.role === MvdTypes.UserRole.SUPERADMIN) && (
              <Buttons.Link
                className="text-secondary-600 rounded-full bg-transparent py-2.5 pl-3  pr-4 text-center text-sm font-semibold shadow-none"
                onClick={() => window.open(CINQDI_URL, '_blank')}
                icon={<MUIcon name="open_in_new" className="text-secondary-600" />}
              >
                Go to My Account page
              </Buttons.Link>
            )}
            <Buttons.Primary
              type="submit"
              disabled={!isValid}
              data-cy="submit-button"
              className="bg-primary-800 ml-auto inline-flex items-center justify-center gap-2 rounded-full px-6 py-2.5 text-center text-sm font-semibold disabled:bg-gray-200 disabled:text-gray-500"
            >
              Submit
            </Buttons.Primary>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PersonalInfo;
