import { Buttons, MUIcon } from '@platform/shared/ui';
import classnames from 'classnames';
import React from 'react';

export type TView = 'dashboard' | 'articles';

interface IProps {
  active: TView;
  onChange: (view: TView) => void;
}

const LayoutSwitch: React.FC<IProps> = ({ active, onChange }) => {
  const CheckIcon = <MUIcon name="check" className="pt-1" />;

  // TODO: create and use shared/ui/buttons/SegmentedButton component
  return (
    <div className="flex h-[36px]">
      <Buttons.Secondary
        icon={<MUIcon name="dashboard" />}
        className={classnames(
          'rounded-full rounded-r-none border border-l-0 border-gray-300 pl-4 pr-3 text-sm shadow-none ring-0',
          { 'bg-secondary-100': active === 'dashboard' }
        )}
        onClick={() => onChange('dashboard')}
      >
        {active === 'dashboard' && CheckIcon}
      </Buttons.Secondary>
      <Buttons.Secondary
        icon={<MUIcon name="menu" />}
        className={classnames(
          'rounded-full rounded-l-none border border-l-0 border-gray-300 pl-3 pr-4 text-sm shadow-none ring-0',
          { 'bg-secondary-100': active === 'articles' }
        )}
        onClick={() => onChange('articles')}
      >
        {active === 'articles' && CheckIcon}
      </Buttons.Secondary>
    </div>
  );
};

export default LayoutSwitch;
