import { Buttons, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import moment from 'moment';
import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { generateAndDownloadCSV, transformFiltersDataForCSV } from '../../helpers/helpers';
import { useReadershipContext } from './ReadershipContext';
import useReadershipData from './Widgets/useReadershipData';
import useReadershipMapData from './Widgets/useReadershipMapData';
import { WidgetQueryKeys } from './Widgets/WidgetQueryKeys';

const { FilterGroup } = MvdTypes;

interface Props {
  audiencesLimit: number;
}

const ReadershipExport: React.FC<Props> = ({ audiencesLimit }) => {
  const { queryEnabled, filters, geoLevel } = useReadershipContext();

  const queries = [
    useReadershipData({
      filterGroupId: FilterGroup.TOPIC,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.TOPICS,
    }),
    useReadershipData({ filterGroupId: FilterGroup.AGE, filters, queryEnabled, queryKey: WidgetQueryKeys.AGE }),
    useReadershipData({
      filterGroupId: FilterGroup.GENDER,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.GENDER,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.EDUCATION_CONDENSED,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.EDUCATION_CONDENSED,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.ETHNICITY,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.ETHNICITY,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.PARTY,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.PARTY,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.LIKELY_VOTERS,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.LIKELY_VOTERS,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.HOUSEHOLD_INCOME,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.INCOME,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.MARITAL_STATUS,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.MARITAL_STATUS,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.PRESENCE_OF_CHILDREN,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.PRESENCE_OF_CHILDREN,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.MEDIA_SOURCE,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.MEDIA_SOURCE,
    }),
    useReadershipData({
      filterGroupId: FilterGroup.TOPIC_DETAILS,
      filters,
      queryEnabled,
      queryKey: WidgetQueryKeys.TOPIC_DETAILS,
    }),
    useReadershipMapData({ queryEnabled, filters, geoLevel }),
  ];

  const audienceQueries = [
    {
      ...useReadershipData({
        filterGroupId: FilterGroup.AUDIENCE_CODE,
        filters,
        queryEnabled,
        queryKey: WidgetQueryKeys.AUDIENCE,
      }),
      subtitle: 'Single-issue',
    },
  ];

  const isLoading = queries.some((x) => x.isLoading) || audienceQueries.some((x) => x.isLoading);

  const transformQueryDataForCSV = (
    queries: {
      title: string;
      data:
        | {
            name: string;
            count: number;
            percentage: number;
          }[]
        | undefined;
      isLoading: boolean;
      isError: boolean;
    }[]
  ) => {
    const transformedData: { [key: string]: any }[] = [];

    transformedData.push({ col1: '', col2: 'Count', col3: 'Percent' });

    queries.forEach((query) => {
      if (!query.isLoading && !query.isError) {
        const groupName = query.title;

        transformedData.push({
          col1: groupName,
          col2: '',
          col3: '',
        });

        query.data?.forEach((dataPoint) => {
          transformedData.push({
            col1: dataPoint.name,
            col2: dataPoint.count,
            col3: dataPoint.percentage.toFixed(2),
          });
        });
        transformedData.push({ col1: '', col2: '', col3: '' });
      }
    });

    return transformedData;
  };

  const transformAudienceQueryDataForCSV = (
    queries: {
      title: string;
      subtitle: string;
      data:
        | {
            name: string;
            count: number;
            percentage: number;
            categorized: boolean;
          }[]
        | undefined;
      isLoading: boolean;
      isError: boolean;
    }[]
  ) => {
    const transformedData: { [key: string]: any }[] = [];

    queries.forEach((query) => {
      if (!query.isLoading && !query.isError) {
        const groupName = `${query.title} - ${query.subtitle}`;

        transformedData.push({
          col1: groupName,
          col2: '',
          col3: '',
        });

        const data = query.data
          ? query.data.sort((a, b) => {
              if (a.categorized === b.categorized) {
                return b.count - a.count;
              } else {
                return Number(b.categorized) - Number(a.categorized);
              }
            })
          : [];

        data.slice(0, audiencesLimit || data.length).forEach((dataPoint) => {
          transformedData.push({
            col1: dataPoint.name,
            col2: dataPoint.count,
            col3: dataPoint.percentage.toFixed(2),
          });
        });
        transformedData.push({ col1: '', col2: '', col3: '' });
      }
    });

    return transformedData;
  };

  const handleExport = () => {
    const infoData = [
      {
        col1: 'Readership data',
        col2: '',
        col3: '',
      },
      { col1: 'Date:', col2: moment().format('MM-DD-YYYY HH-mm-ss'), col3: '' },
    ];
    const transformedFilters = transformFiltersDataForCSV(filters.rules as RuleGroupType[]);

    const transformedData = transformQueryDataForCSV(queries);
    const transformedAudienceData = transformAudienceQueryDataForCSV(audienceQueries);

    generateAndDownloadCSV(
      [...infoData, ...transformedFilters, ...transformedData, ...transformedAudienceData],
      'readership_csv'
    );
  };

  return (
    <Buttons.Soft
      className="h-fit rounded-full bg-white p-2 text-sm shadow-none ring-0"
      onClick={handleExport}
      data-rh="Download CSV"
      disabled={isLoading}
      icon={<MUIcon name="download" className="h-6 w-6" />}
    />
  );
};

export default ReadershipExport;
