import { LineLayerSpecification, StyleSpecification, SymbolLayerSpecification } from 'maplibre-gl';
import { useMemo } from 'react';
import baseMapJson from '../Audience/basemap.json';

const STATES_SOURCE_ID = 'geo-json-states';
const STATE_BOUNDARIES_LAYER_ID = 'us-states-boundaries';

const useBasemapStyle = (): { style: StyleSpecification; targetLayerId: string } => {
  const style = useMemo(() => {
    const retVal = {
      ...JSON.parse(JSON.stringify(baseMapJson)),
    } as StyleSpecification;

    let firstSymbolId = '';

    // Find the index of the first symbol layer in the map style
    for (const item of retVal.layers) {
      if (item.type === 'symbol') {
        firstSymbolId = item.id;
        break;
      }
    }

    const firstSymbolLayer = firstSymbolId
      ? retVal.layers.findIndex((x) => x.id === firstSymbolId)
      : retVal.layers.length;

    retVal.sources[STATES_SOURCE_ID] = {
      type: 'geojson',
      data: 'assets/geojson/states.json',
    };

    retVal.sources['state-centroid'] = {
      type: 'geojson',
      data: 'assets/geojson/states-centroid.json',
    };

    const statesLayer: LineLayerSpecification = {
      id: STATE_BOUNDARIES_LAYER_ID,
      type: 'line',
      source: STATES_SOURCE_ID,
      paint: {
        'line-color': '#fff',
        'line-opacity': 0.45,
        'line-width': 2,
        'line-dasharray': [2, 2], // Defines the dash pattern (3px dash, 3px gap)'
      },
    };

    const stateLabelsLayer: SymbolLayerSpecification = {
      id: 'state-labels',
      type: 'symbol',
      source: 'state-centroid',
      layout: {
        'text-field': ['get', 'name'],
        'text-size': 10,
        'text-allow-overlap': false, // Allow text to overlap
        'symbol-avoid-edges': true, //
      },
      paint: {
        'text-color': '#000000',
        'text-halo-color': 'rgba(255, 255, 255, 0.8)',
        'text-halo-width': 1,
        'text-halo-blur': 1,
      },
    };

    retVal.layers.splice(firstSymbolLayer, 0, statesLayer, stateLabelsLayer);

    return retVal;
  }, []);
  return { style, targetLayerId: STATE_BOUNDARIES_LAYER_ID };
};

export default useBasemapStyle;
