import { MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React from 'react';
import { useActivationsContext } from '../Activation/ActivationsContext';
import AppliedFilters from '../shared/Overview/AppliedFilters';
import Donut from '../shared/Overview/Donut';

const AudienceSummary: React.FC = () => {
  const { recordsCount, totalCount, filters, audience } = useActivationsContext();
  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <div className="flex flex-col items-start gap-3">
      <p className="font-regular text-sm">Audience</p>
      <div className="w-full">
        <button
          type="button"
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames(
            'flex h-[52px] w-full items-center justify-between gap-6 bg-gray-50 px-3 text-sm',
            { 'rounded-t-lg border border-b-0 border-gray-200': isExpanded },
            { 'rounded-lg border border-gray-200': !isExpanded }
          )}
        >
          <p className="truncate font-semibold">{audience.title}</p>
          <MUIcon name={isExpanded ? 'expand_less' : 'expand_more'} className="text-gray-600" />
        </button>
        {isExpanded ? (
          <div
            className={classNames('flex  flex-col gap-3  rounded-b-lg bg-gray-50 py-2 px-4', {
              'border border-t-0 border-gray-200': isExpanded,
            })}
          >
            <div className="flex w-full items-center justify-center gap-16">
              <p className="px-2 text-sm">Size</p>
              <Donut filteredCount={recordsCount} totalCount={totalCount} classes="border-none" />
            </div>
            <div className="h-[1px] w-full bg-gray-200 px-3"></div>
            <div className="flex w-full items-start justify-center gap-16">
              <p className="py-4 px-2 text-sm">Filters</p>
              <AppliedFilters filters={filters} itemClasses="border-b border-gray-200 last:border-0" />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AudienceSummary;
