import { Buttons, Modal, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import { useNavigate } from 'react-router-dom';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { generateLink } from '../../LinkGenerator';
import AppliedFilters from '../shared/Overview/AppliedFilters';
import Donut from '../shared/Overview/Donut';

interface Props {
  filters: RuleGroupType;
  totalCount: number;
  filteredCount: number;
  baseAudienceId: number;
  disabled: boolean;
  onDone: () => void;
}

const SaveAudience: React.FC<Props> = ({ disabled, baseAudienceId, totalCount, filteredCount, filters, onDone }) => {
  const { notify } = useApplicationContext();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  const queryClient = useQueryClient();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {},
  });

  const saveAudienceMutation = useMutation({
    mutationFn: (p: MvdTypes.ICreateUserAudience) => mvdApi.createUserAudience(p),
    onSuccess: async (response: MvdTypes.ICreateUserAudienceResponse) => {
      await queryClient.invalidateQueries({ queryKey: ['user-audiences'] });

      const title = watch('title');

      notify({
        hideAfterSec: 10,
        content: `Audience “${title}" created`,
      });

      navigate(
        generateLink('myAudience', {
          audienceId: response.id,
        })
      );
    },
    onError: () => {
      notify({
        icon: <MUIcon name="warning" />,
        content: 'Failed to create audience.',
      });
    },
  });

  const saveDisabled = !watch('title')?.trim() || !filteredCount || saveAudienceMutation.isLoading;

  const onSubmit: SubmitHandler<Inputs> = (data) =>
    saveAudienceMutation.mutate({ ...data, query: filters, size: filteredCount, baseAudienceId });

  return (
    <>
      <Buttons.Primary
        onClick={() => setModalOpen(true)}
        disabled={disabled}
        icon={<MUIcon name="bookmark" />}
        className="rounded-full px-6"
        size="lg"
      >
        Save audience
      </Buttons.Primary>
      {modalOpen && (
        <Modal
          className="w-[600px]"
          isVisible={true}
          title="Save audience"
          okLabel="Save audience"
          okDisabled={saveDisabled}
          cancelDisabled={saveAudienceMutation.isLoading}
          onCloseRequest={() => setModalOpen(false)}
          onDone={handleSubmit(onSubmit)}
        >
          <div className="-mr-6 flex max-h-[460px] flex-col gap-8 overflow-auto pr-4">
            <form onSubmit={handleSubmit(onSubmit)} className="flex grow flex-col gap-6">
              <div>
                <label htmlFor="title" className="mb-2 block text-sm text-gray-900">
                  Name
                </label>
                <input
                  autoFocus
                  id="title"
                  placeholder="Give your audience a name"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                  {...register('title', { required: 'Name is required' })}
                />
                {errors.title && <span className="text-red-400">{errors.title.message}</span>}
              </div>
              <div className="hidden">
                <label htmlFor="description" className="mb-2 block text-sm text-gray-900">
                  Description (optional)
                </label>
                <textarea
                  id="description"
                  rows={4}
                  placeholder="Describe your audience"
                  className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                  {...register('description')}
                />
              </div>
            </form>
            <div className="flex h-[1px] w-full shrink-0 bg-gray-200" />
            <div className="flex flex-col gap-4 text-sm">
              <div className="font-semibold">Summary</div>
              <div className="flex flex-col gap-2">
                <div>Audience size</div>
                <Donut filteredCount={filteredCount} totalCount={totalCount} />
              </div>
              <div className="flex flex-col gap-2">
                <div>Filters</div>
                <AppliedFilters filters={filters} />
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SaveAudience;

type Inputs = {
  title: string;
  description: string;
};
