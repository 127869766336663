import { MvdTypes } from '@platform/types';
import React, { useState } from 'react';
import { AUDIENCES_OPTIONS } from '../../enums/audienceOptions';
import ActivationWrapper from '../Activation/ActivationWrapper';
import NoResults from '../shared/NoResults';
import useDistinctVisitorsQuery from '../shared/useDistinctVisitorsQuery';
import LayoutSwitch, { TView } from './LayoutSwitch';
import ReadershipAudiences from './ReadershipAudiences';
import { useReadershipContext } from './ReadershipContext';
import ReadershipExport from './ReadershipExport';
import ReadershipFilters from './ReadershipFilters';
import ReadershipOverview from './ReadershipOverview';
import Age from './Widgets/Age.widget';
import EducationWidget from './Widgets/Education.widget';
import EthnicityWidget from './Widgets/Ethnicity.widget';
import GenderWidget from './Widgets/Gender.widget';
import Income from './Widgets/HouseholdIncome.widget';
import InterestOverTime from './Widgets/InterestOverTime.widget';
import LikelyVoters from './Widgets/LikelyVoters.widget';
import MapWidget from './Widgets/Map';
import MaritalStatus from './Widgets/MaritalStatus.widget';
import MediaSources from './Widgets/MediaSources.widget';
import PartyWidget from './Widgets/Party.widget';
import PresenceOfChildren from './Widgets/PresenceOfChildren.widget';
import TopArticles from './Widgets/TopArticles.widget';
import Topics from './Widgets/Topics.widget';

const Readership: React.FC = () => {
  const [activeView, setActiveView] = React.useState<TView>('dashboard');
  const [audiencesLimit, setAudiencesLimit] = useState<MvdTypes.AudienceLimitOption>(AUDIENCES_OPTIONS[0]);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLDivElement>(null);

  const { filters, queryEnabled, resetFilters } = useReadershipContext();
  const uniqueVisitorsQuery = useDistinctVisitorsQuery({ enabled: queryEnabled, filters });
  const hasNoResults = Number(uniqueVisitorsQuery.data?.[0]?.count) === 0;

  const handleScroll = () => {
    if (contentRef.current) {
      const containerTop = contentRef.current.getBoundingClientRect().top || 0;
      if (titleRef.current) {
        const isTitleVisible = containerTop >= 0;
        titleRef.current.style.display = isTitleVisible ? 'block' : 'none';
      }
    }
  };

  return (
    <div onScroll={handleScroll} className="relative flex h-full w-full flex-col overflow-y-auto">
      <div className="transition-height sticky top-0 z-10 flex w-full justify-between border-b border-gray-200 bg-white px-8 pt-6 transition-all duration-300 ease-in">
        <div className="flex flex-col gap-10">
          <div ref={titleRef} className="text-xl font-semibold">
            Readership Insights
          </div>
          <ReadershipFilters />
        </div>
        <div className="flex justify-end gap-4">
          <ReadershipExport audiencesLimit={audiencesLimit.value} />
          <ReadershipOverview />
        </div>
      </div>
      {hasNoResults && (
        <NoResults
          title="No data matching your filters"
          message="Try clearing your filters to see results"
          onClear={() => resetFilters()}
        />
      )}
      {!hasNoResults && (
        <div ref={contentRef} className="mb-8 flex flex-grow flex-col gap-4 bg-gray-50 px-8 py-4">
          <div className="flex w-full items-center justify-end py-3">
            <div className="flex items-center gap-4">
              <LayoutSwitch onChange={setActiveView} active={activeView} />
            </div>
          </div>
          {activeView === 'articles' && (
            <TopArticles
              expanded
              pageSize={20}
              grid={{
                row: [0, 0],
                col: [0, 0],
              }}
            />
          )}
          {activeView === 'dashboard' && (
            <>
              <div className="grid auto-rows-[140px] grid-cols-12 gap-4">
                <Topics
                  grid={{
                    row: [1, 2],
                    col: [1, 12],
                    expanded: { row: [1, 4], col: [1, 12] },
                  }}
                />
                <InterestOverTime grid={{ row: [3, 2], col: [1, 12] }} />
                <TopArticles
                  grid={{
                    row: [5, 4],
                    col: [1, 6],
                    expanded: { row: [5, 4], col: [1, 12] },
                  }}
                />
                <MapWidget
                  grid={{
                    row: [5, 4],
                    col: [7, 6],
                    expanded: { row: [5, 4], col: [1, 12] },
                  }}
                />
                <Age
                  grid={{
                    row: [9, 2],
                    col: [1, 6],
                  }}
                />
                <GenderWidget
                  grid={{
                    row: [9, 2],
                    col: [7, 3],
                  }}
                />
                <EducationWidget
                  grid={{
                    row: [9, 2],
                    col: [10, 3],
                  }}
                />
                <EthnicityWidget
                  grid={{
                    row: [11, 2],
                    col: [1, 6],
                  }}
                />
                <PartyWidget
                  grid={{
                    row: [11, 2],
                    col: [7, 3],
                  }}
                />
                <LikelyVoters
                  grid={{
                    row: [11, 2],
                    col: [10, 3],
                  }}
                />
                <Income
                  grid={{
                    row: [13, 2],
                    col: [1, 6],
                  }}
                />
                <MaritalStatus
                  grid={{
                    row: [13, 2],
                    col: [7, 3],
                  }}
                />
                <PresenceOfChildren
                  grid={{
                    row: [13, 2],
                    col: [10, 3],
                  }}
                />
                <MediaSources
                  grid={{
                    row: [15, 2],
                    col: [1, 6],
                  }}
                />
              </div>
              <ReadershipAudiences audiencesLimit={audiencesLimit} onAudienceLimitChange={setAudiencesLimit} />
            </>
          )}
        </div>
      )}
      <ActivationWrapper filters={filters} />
    </div>
  );
};
export default Readership;
