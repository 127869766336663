import React from 'react';
import CinqLogo from '../../../assets/cinqdi-logo.png';
export const Header: React.FC = () => {
  return (
    <header className="flex w-full items-center justify-between p-4">
      <div className="inline-flex items-start justify-start gap-6 px-12 pt-12">
        <img className="h-[38px]" alt="CinqDI Logo" src={CinqLogo} />
      </div>
    </header>
  );
};
export default Header;
