import { LoginForm } from '@platform/shared/ui';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { generateLink } from '../../LinkGenerator';
import Footer from '../Account/Footer';
import Header from '../Account/Header';

const Login: React.FC = () => {
  const { login, notify } = useApplicationContext();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.accountSetupInProgress) {
      notify({
        hideAfterSec: 10,
        content: 'Account setup in Progress. Check back in 2-4 hours. Thanks!',
      });
    }

    return () => {
      if (location.state && location.state.accountSetupInProgress) {
        location.state.accountSetupInProgress = false;
      }
    };
  }, [location.state, notify]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-between bg-gray-50">
      <Header />
      <div className="flex w-full flex-col items-center gap-8 bg-gray-50">
        <div className="items-left flex h-[460px] w-[460px] flex-col justify-center gap-10 rounded-xl border border-gray-200 bg-white shadow-md">
          <div className="px-10 text-2xl font-bold">Log In</div>
          <div className="flex w-full flex-col gap-8">
            <LoginForm
              onLoginSuccess={(t) => login(t)}
              loginMethod={mvdApi.login}
              additionalChildern={
                <div className="flex w-full justify-end pt-2">
                  <Link
                    to={generateLink('forgotPassword')}
                    className="w-full text-right text-xs font-semibold leading-none text-teal-500"
                  >
                    Forgot Password?
                  </Link>
                </div>
              }
            />
          </div>
        </div>
        <div className="inline-flex h-[89px] flex-col items-start justify-start gap-6 pt-6 pb-12">
          <div className="inline-flex items-start justify-center gap-1 px-12">
            <div className="text-[13px] font-normal leading-[16.90px] text-[#6d7280]">Don't have an account?</div>
            <Link to={generateLink('signup')} className="text-[13px] font-semibold  text-teal-500">
              Sign up
            </Link>
            <div className="text-[13px] font-normal">instead.</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
