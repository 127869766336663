import { MUIcon } from '@platform/shared/ui';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

interface Props {
  mappedFilters: { title: string; values: string[] }[];
}

const FiltersInfoTooltip: React.FC<Props> = ({ mappedFilters }: Props) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const iconRef = useRef<HTMLDivElement | null>(null);
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (tooltipRef.current && iconRef.current) {
      const iconRect = iconRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const left =
        iconRect.left + tooltipRect.width + 50 > window.innerWidth
          ? iconRect.left - tooltipRect.width - 10
          : iconRect.left + window.scrollX + 35;

      const top =
        iconRect.top + tooltipRect.height / 2 > window.innerHeight
          ? iconRect.top - tooltipRect.height - 10
          : iconRect.top - tooltipRect.height / 2;

      setTooltipPosition({
        top: top,
        left: left,
      });
    }
  }, [tooltipVisible]);

  return (
    <td className="relative min-w-[200px] px-2 py-4 text-sm text-gray-500 hover:cursor-pointer">
      {mappedFilters.length > 0 && (
        <div className="relative">
          <div
            ref={iconRef}
            onMouseEnter={() => setTooltipVisible(true)}
            onMouseLeave={() => setTooltipVisible(false)}
            className="w-[24px]"
          >
            <MUIcon name="info" className="!text-[24px] text-gray-600" />
          </div>
          {tooltipVisible &&
            createPortal(
              <div
                ref={tooltipRef}
                className={`absolute z-50 max-w-[300px] rounded-lg bg-white px-4 py-2 text-sm shadow-lg`}
                style={{ top: tooltipPosition.top, left: tooltipPosition.left }}
              >
                <div className="min-w-[282px]">
                  {mappedFilters.map((el) => (
                    <div key={el.title}>
                      <span className="font-semibold">{el.title} </span>: {el.values.join(', ')}
                    </div>
                  ))}
                </div>
              </div>,
              document.body
            )}
        </div>
      )}
    </td>
  );
};

export default FiltersInfoTooltip;
