import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { linkFor } from '../LinkGenerator';
import Loader from './shared/Loader';

const UnderMaintenance: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(linkFor('login'));
  }, []);

  return (
    <div className="flex h-full w-full items-center justify-center">
      <Loader message="Our site is temporarily down for maintenance. We appreciate your patience." />
    </div>
  );
};

export default UnderMaintenance;
