import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as NotFoundSVG } from '../../../assets/not-found.svg';
import { matchRoute } from '../../LinkGenerator';

interface Props {
  imageComponent?: JSX.Element;
  title?: string;
  message?: string;
}

const ErrorPage: React.FC<Props> = ({ imageComponent, title, message }) => {
  const location = useLocation();
  const to = location.pathname.replace(/\/\d+$/, ''); // remove audienceId
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex h-full w-1/2 max-w-[503px] flex-col items-center justify-center gap-12">
        {imageComponent ?? <NotFoundSVG />}
        <div className="inline-flex flex-col items-center justify-start gap-9 self-stretch">
          <div className="flex h-[100px] flex-col items-center justify-start gap-4">
            <div className="font-inter self-stretch text-center text-2xl font-bold leading-9 text-gray-800">
              {title ?? 'Oops! Something went wrong.'}
            </div>
            <div className="font-inter self-stretch text-center text-base font-normal leading-normal text-gray-600">
              {message ??
                'The page you are looking for might have been removed, had its name changed, or its temporary unavailable.'}
            </div>
          </div>

          <Link
            to={to}
            className="font-inter bg-primary-700 w-[364px] rounded-full py-3 text-center text-sm font-semibold text-white"
          >
            Go back to <span className="capitalize">{matchRoute(window.location.pathname.replace(/\/\d+$/, ''))}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
