import { MUIcon } from '@platform/shared/ui';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { generateLink } from '../../../LinkGenerator';

interface Props {
  isExpanded: boolean;
}

const Navigation: React.FC<Props> = ({ isExpanded }) => {
  const location = useLocation();

  const menuOptions = useMemo(
    () => [
      {
        icon: <MUIcon name="group" className="text-[24px]" />,
        label: 'Audiences',
        dataCy: 'navigation-menu-audiences',
        link: generateLink('audience', { audienceId: undefined }),
        active: location.pathname.includes(generateLink('audience')),
      },
      {
        icon: <MUIcon name="description" className="text-[24px]" />,
        label: 'Readership',
        dataCy: 'navigation-menu-readership',
        link: generateLink('readership'),
        active: location.pathname.includes(generateLink('readership')),
      },
      {
        icon: <MUIcon name="bolt" className="text-[24px]" />,
        label: 'Activations',
        dataCy: 'navigation-menu-activations',
        link: generateLink('activations'),
        active: location.pathname.includes(generateLink('activations')),
      },
      {
        icon: <MUIcon name="bookmark" className="text-[24px]" />,
        label: 'My audiences',
        dataCy: 'navigation-menu-saved-audiences',
        link: generateLink('myAudiences'),
        active: location.pathname.includes(generateLink('myAudiences')),
      },
    ],
    [location]
  );

  return (
    <div className="flex flex-col px-5">
      {menuOptions.map((menuOption) => {
        return (
          <Link
            key={menuOption.label}
            to={menuOption.link}
            className={classNames(
              'flex w-full select-none items-center gap-4 rounded-full py-3 px-3 text-sm font-normal text-gray-600 hover:cursor-pointer hover:bg-gray-200',
              {
                'bg-gray-800 text-white hover:bg-gray-800 ': menuOption.active,
                'justify-center': !isExpanded,
              }
            )}
          >
            {menuOption.icon}
            {isExpanded && <div className="font-semibold">{menuOption.label}</div>}
          </Link>
        );
      })}
    </div>
  );
};

export default Navigation;
