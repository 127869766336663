import { Buttons, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { generateLink } from '../../LinkGenerator';
import Footer from './Footer';
import Header from './Header';
import usePasswordChangeForm, { PasswordChangeInputs } from './usePasswordChangeForm';

const NewInvite: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { notify } = useApplicationContext();
  const resetPassword = useMutation({
    mutationFn: (payload: MvdTypes.IResetPassword) => mvdApi.resetPassword(payload),

    onSuccess: () => {
      notify({
        hideAfterSec: 5,
        content: 'Your password has been saved.',
        icon: <MUIcon name="check" />,
      });
      navigate(generateLink('login'));
    },
    onError: () => {
      notify({
        hideAfterSec: 5,
        content: 'User not found, or invalid token',
        icon: <MUIcon name="error" />,
      });
    },
  });

  const { form, handleSubmit, isValid } = usePasswordChangeForm();

  const handleChangePassword: SubmitHandler<PasswordChangeInputs> = (data) => {
    const id = searchParams.get('userId');
    const token = searchParams.get('token');
    if (!id || !token) {
      return;
    }
    const updateData: MvdTypes.IResetPassword = {
      id: id,
      newPassword: data.newPassword,
      token: token,
    };
    resetPassword.mutate(updateData);
  };
  return (
    <div className="flex h-full w-full flex-col items-center justify-between bg-gray-50">
      <Header />
      <div className="inline-flex w-[576px] flex-col items-start justify-start rounded-2xl border border-gray-200 bg-white pb-6 shadow">
        <div className="flex  flex-col items-start justify-start gap-3 self-stretch px-12 pt-8">
          <div className="self-stretch px-6 text-2xl font-bold text-gray-800">Welcome to CinqDI!</div>
          <div className="self-stretch px-6 text-sm text-gray-600">Please create a password for your account.</div>
        </div>
        {form}
        <div className="w-full py-4 px-12">
          <div className="px-6">
            <Buttons.Primary
              type="submit"
              disabled={!isValid || resetPassword.isLoading}
              data-cy="submit-button"
              className="bg-primary-800 inline-flex w-full items-center justify-center gap-2 py-2.5 text-center text-sm font-semibold disabled:bg-gray-200 disabled:text-gray-500"
              onClick={handleSubmit(handleChangePassword)}
            >
              Set password
            </Buttons.Primary>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewInvite;
