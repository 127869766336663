import { Hooks } from '@platform/maplibre';
import { MapComponents } from '@platform/shared/ui';
import { NumberFormatter } from '@platform/utils';
import { LngLatLike, Map, MapGeoJSONFeature, Point2D } from 'maplibre-gl';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

const { TooltipPositioner } = MapComponents;

interface Props {
  map: Map;
  labelField: string;
  layerId: string;
  title?: string;
  countField?: string;
  percentageField?: string;
  secondaryLabelField?: string;
}

const Tooltip: React.FC<Props> = ({
  labelField,
  secondaryLabelField,
  title,
  countField,
  map,
  layerId,
  percentageField = 'percentage',
}) => {
  const [featureUnderMouse, setFeatureUnderMouse] = useState<
    | {
        feature: MapGeoJSONFeature;
        latLong: LngLatLike;
      }
    | undefined
  >(undefined);

  useEffect(() => {
    const moveStartHandler = () => {
      setFeatureUnderMouse(undefined);
    };
    map.on('movestart', moveStartHandler);
    return () => {
      map.off('movestart', moveStartHandler);
    };
  }, [map]);

  const memoizedLayerId = useMemo(() => [layerId], [layerId]);

  const handleFeatureUnderMouse = useCallback(
    (featuresInEvent: MapGeoJSONFeature[], latLong: LngLatLike) => {
      if (featuresInEvent.length) {
        setFeatureUnderMouse({ feature: featuresInEvent[0], latLong });
      } else {
        setFeatureUnderMouse(undefined);
      }
    },
    [memoizedLayerId]
  );

  Hooks.useFeaturesUnderMouse(map, 'mousemove', handleFeatureUnderMouse, memoizedLayerId);

  if (!featureUnderMouse?.feature) return null;

  const projectedAnchor = map.project(featureUnderMouse.latLong) as Point2D;

  const { properties } = featureUnderMouse.feature;

  return (
    <div className="pointer-events-none absolute inset-0 z-0">
      <TooltipPositioner anchorPoint={projectedAnchor} showAnchor={false}>
        {featureUnderMouse && (
          <div className="z-1 pointer-events-none flex min-w-[220px] items-center justify-between gap-[40px] rounded-md border border-gray-100 bg-white p-3 text-xs shadow-xl">
            <div className="flex flex-col gap-0.5">
              {title && <div className="text-xs text-gray-400">{title}</div>}
              <div>{properties[labelField]}</div>
              {secondaryLabelField && <div>{properties[secondaryLabelField]}</div>}
            </div>
            <div className="text-right">
              {percentageField && (
                <div className="font-semibold">{NumberFormatter.format(properties[percentageField], '0,0[.]00%')}</div>
              )}
              {countField && <div>{NumberFormatter.format(properties[countField], '0,000')}</div>}
            </div>
          </div>
        )}
      </TooltipPositioner>
    </div>
  );
};

export default Tooltip;
