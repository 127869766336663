import { MvdTypes } from '@platform/types';
import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Activation from './Activation';
import { ActivationsProvider } from './ActivationsContext';

interface IProps {
  filters: RuleGroupType | undefined;
}

const ActivationWrapper: React.FC<IProps> = ({ filters }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const activate = queryParams.get('activation') === 'true';
  const { audienceId } = useParams();

  const handleActivationExit = () => {
    const searchParams = new URLSearchParams(location.search);

    searchParams.delete('activation');
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  if (!filters) return null;

  const audId =
    Number(audienceId) ||
    Number((filters.rules.find((x) => x.id === MvdTypes.FilterGroup.AUDIENCE_CODE) as RuleGroupType)?.rules?.[0]?.id);

  return (
    <>
      {activate && (
        <ActivationsProvider audienceId={audId} appliedFilters={filters} onExitRequest={handleActivationExit}>
          <Activation />
        </ActivationsProvider>
      )}
    </>
  );
};

export default ActivationWrapper;
