import { MvdTypes } from '@platform/types';
import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { Filter } from './common';

const { FilterGroup } = MvdTypes;

interface IProps {
  filters: RuleGroupType;
  onDone: (value: RuleGroupType) => void;
}

const TopicsFilter: React.FC<IProps> = ({ onDone, filters }) => (
  <Filter onDone={onDone} filters={filters} filterGroupId={FilterGroup.TOPIC} />
);

export default TopicsFilter;
