import { AbacusTypes, MvdTypes, ProjectTypes } from '@platform/types';
import axios from 'axios';

export const ApiBase = axios.create({
  baseURL: `${process.env['NX_SERVER']}/api`,
  withCredentials: true,
});

ApiBase.interceptors.request.use(function (config) {
  const jwt = localStorage.getItem('jwt');
  if (jwt) {
    config.headers.Authorization = `Bearer ${jwt}`;
  }
  return config;
});

export const ping = (): Promise<void> => ApiBase.get(`/ping`).then((r) => r.data);

export const authenticateEmbedded = (payload: MvdTypes.IEmbeddedAuthPayload): Promise<string> =>
  ApiBase.post<string>(`/public/auth/embedded`, payload).then((r) => r.data);

export const summarize = (
  datasetId: string,
  payload: AbacusTypes.SummarizeRequest,
  identifier?: string
): Promise<AbacusTypes.SummarizeResult> =>
  ApiBase.post<AbacusTypes.SummarizeResult>(
    `/abacus/${datasetId}/summarize`,
    { ...payload },
    { params: identifier ? { id: identifier } : undefined }
  ).then((r) => r.data);

export const login = (payload: ProjectTypes.LoginPayload): Promise<string> =>
  ApiBase.post<string>(`/public/login`, payload).then((r) => r.data);

export const forgotPassword = (payload: MvdTypes.IForgotPassword): Promise<void> =>
  ApiBase.post<void>(`/public/forgot-password`, payload).then((r) => r.data);

export const getRowCount = (datasetId: string, payload: AbacusTypes.CountDatasetRowsRequest): Promise<number> =>
  ApiBase.post<number>(`/abacus/${datasetId}/rows/count`, payload).then((r) => r.data);

export const getDataPqs = <T>(request: MvdTypes.IPqsDataRequest, id?: string, transpose = false): Promise<T[]> =>
  ApiBase.post<
    T[],
    {
      data: MvdTypes.IPqsDataResponse;
    }
  >(`/data/query-pqs`, request, { params: { id } }).then((response) => {
    const data = JSON.parse(response.data.results);

    const transposeDataRow = (jsonObject: Record<string, number>[], rowIdx = 0): { name: string; count: number }[] => {
      // Extract the first object from the array
      const object = jsonObject[rowIdx];

      // Create an array of {name, value} pairs
      return Object.keys(object).map((key) => ({
        name: key, // Key becomes the name
        count: object[key],
      }));
    };

    if (transpose) return transposeDataRow(data);
    return data;
  });

export const getHeadlineDetails = (url: string): Promise<MvdTypes.IHeadlineDetails> =>
  ApiBase.get<MvdTypes.IHeadlineDetails>(`/articles?url=${url}`).then((r) => r.data);

export const fetchArticleMetadata = (url: string): Promise<MvdTypes.IHeadlineDetails> =>
  ApiBase.post<MvdTypes.IHeadlineDetails>(`/articles/fetch-metadata`, { url }).then((r) => r.data);

export const getAudiences = (request: MvdTypes.IGetAudiencesRequest): Promise<MvdTypes.IAudience[]> =>
  ApiBase.get<MvdTypes.IAudience[]>(`/cinqdi/audiences`, { params: { ...request } }).then((r) => r.data);

export const getAudienceById = (audienceId: string): Promise<MvdTypes.IAudience> =>
  ApiBase.get<MvdTypes.IAudience>(`/cinqdi/audiences/${audienceId}`).then((r) => r.data);

export const me = (): Promise<MvdTypes.IUser> => ApiBase.get<MvdTypes.IUser>('/session/whoami').then((r) => r.data);

export const createUserAudience = (
  payload: MvdTypes.ICreateUserAudience
): Promise<MvdTypes.ICreateUserAudienceResponse> =>
  ApiBase.post<MvdTypes.ICreateUserAudienceResponse>(`/cinqdi/user-audiences`, payload).then((r) => r.data);

export const getUserAudiences = (): Promise<MvdTypes.IUserSavedAudience[]> =>
  ApiBase.get<MvdTypes.IUserSavedAudience[]>(`/cinqdi/user-audiences`).then((r) => r.data);

export const updateUserInfo = (newUserInfo: MvdTypes.IUser): Promise<void> =>
  ApiBase.post<void>(`/cinqdi/user-info`, newUserInfo).then((r) => r.data);

export const createUser = (newUser: MvdTypes.ICreateUser): Promise<void> =>
  ApiBase.post<void>(`/public/create-user`, newUser).then((r) => r.data);

export const changePassword = (payload: MvdTypes.IChangePassword): Promise<void> =>
  ApiBase.post<void>(`/cinqdi/change-password`, payload).then((r) => r.data);

export const resetPassword = (payload: MvdTypes.IResetPassword): Promise<void> =>
  ApiBase.post<void>(`/public/reset-password`, payload).then((r) => r.data);

export const softDeleteUserAudience = (referenceId: number): Promise<void> =>
  ApiBase.delete<void>(`/cinqdi/user-audiences/${referenceId}`).then((r) => r.data);

export const getBraintreeClientToken = (): Promise<MvdTypes.BrainTreeClientToken> =>
  ApiBase.get<MvdTypes.BrainTreeClientToken>(`/cinqdi/braintree/client-token`).then((r) => r.data);

export const activate = (payload: MvdTypes.IActivationPayload): Promise<void> =>
  ApiBase.post<void>(`/cinqdi/activate`, payload).then((r) => r.data);

export const getAudienceRequests = (): Promise<MvdTypes.IAudienceActivationRequest[]> =>
  ApiBase.get<MvdTypes.IAudienceActivationRequest[]>(`/cinqdi/user-audiences/activation-requests`).then((r) => r.data);

export const cancelActivation = (payload: MvdTypes.ICancelActivation): Promise<void> =>
  ApiBase.delete<void>(`/cinqdi/user-audiences/${payload.referenceId}/cancel-activation`, {
    data: { referenceId: payload.referenceId, uniqueId: payload.uniqueId },
  }).then((r) => r.data);

export const getUserSales = (): Promise<MvdTypes.IUserSale[]> =>
  ApiBase.get<MvdTypes.IUserSale[]>(`/cinqdi/user-sales`).then((r) => r.data);

export const getPresignedUrl = (s3Path: string): Promise<{ presignedUrl: string }> =>
  ApiBase.post<{ presignedUrl: string }>(`/cinqdi/presigned-url`, { s3Path }).then((r) => r.data);

export const updateAudienceStatus = (referenceId: number, status: string): Promise<void> =>
  ApiBase.post<void>(`/cinqdi/user-audiences/${referenceId}/update`, { status }).then((r) => r.data);

export const submitFeedback = (feedback: MvdTypes.IFeedback): Promise<void> =>
  ApiBase.post<void>(`/cinqdi/submit-feedback`, feedback).then((r) => r.data);

export const checkExistingFormats = (
  audienceUuid: string,
  downloadFormats: string[]
): Promise<MvdTypes.PreviousExportedFormats> =>
  ApiBase.get<MvdTypes.PreviousExportedFormats>('/cinqdi/audiences/check-existing-formats', {
    params: {
      audienceUuid,
      downloadFormats,
    },
  }).then((r) => r.data);
