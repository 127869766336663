import { Dropdowns } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { AUDIENCES_OPTIONS } from '../../enums/audienceOptions';
import { getAudienceMetaBySubCategory } from '../../helpers/helpers';
import { useReadershipContext } from './ReadershipContext';
import { AudienceWidget } from './Widgets/Audiences';
import useReadershipAudienceData, { IDataPoint } from './Widgets/useReadershipAudienceData';
import { WidgetQueryKeys } from './Widgets/WidgetQueryKeys';

const { FilterGroup } = MvdTypes;

interface Props {
  audiencesLimit: MvdTypes.AudienceLimitOption;
  onAudienceLimitChange: (arg: MvdTypes.AudienceLimitOption) => void;
}

const categoryByAudienceSubCategory: Record<string, string> = {
  Behavioral: MvdTypes.AudienceCategoryEnum.ISSUE,
  'Issue Oriented': MvdTypes.AudienceCategoryEnum.ISSUE,
  'Role of Government': MvdTypes.AudienceCategoryEnum['MULTI-ISSUE'],
  Education: MvdTypes.AudienceCategoryEnum['MULTI-ISSUE'],
};

const ReadershipAudiences: React.FC<Props> = ({ audiencesLimit, onAudienceLimitChange }) => {
  const { queryEnabled, filters } = useReadershipContext();

  const { isLoading, data: allAudiencesData } = useReadershipAudienceData({
    filters,
    queryEnabled,
    filterGroupId: FilterGroup.AUDIENCE_CODE,
    queryKey: WidgetQueryKeys.AUDIENCE,
  });

  const hasAudienceAsFilter =
    (filters.rules.find((x) => x.id === FilterGroup.AUDIENCE_CODE) as RuleGroupType)?.rules?.length > 0;

  if (hasAudienceAsFilter) {
    // the Readership audiences become redundant when audience is set as filter, thus we don't want it to be rendered
    return null;
  }

  const groupedBySubCategory =
    allAudiencesData?.reduce((p: Record<string, IDataPoint[]>, c) => {
      const retVal = { ...p };
      if (!retVal[c.group]) {
        retVal[c.group] = [];
      }

      retVal[c.group].push(c);
      return retVal;
    }, {}) ?? {};

  const handleAudienceLimitChange = (selectedOption: Dropdowns.DropdownOption) =>
    onAudienceLimitChange({
      name: selectedOption.name,
      nameClasses: selectedOption.nameClasses,
      value: Number(selectedOption.value),
      label: selectedOption.label,
    });

  // make the audience charts height according to limit
  let chartHeightInRows: number;
  switch (audiencesLimit.value) {
    case 5:
      chartHeightInRows = 2;
      break;
    case 10:
      chartHeightInRows = 4;
      break;
    case 0:
      chartHeightInRows = 6;
      break;
    default:
      chartHeightInRows = 2;
  }

  const getGridColsAndRow = (
    index: number,
    chartHeightInRows: number
  ): {
    row: [number, number];
    col: [number, number];
  } => {
    const isFirstColumn = index % 2 === 0;
    const rowStart = Math.floor(index / 2) * chartHeightInRows + 1;

    return {
      row: [rowStart, chartHeightInRows],
      col: isFirstColumn ? [1, 3] : [4, 3], // Columns 1-3 for first item, 4-6 for second
    };
  };

  return (
    <>
      <div className="mt-5 max-w-[260px] text-lg">
        <Dropdowns.Dropdown
          options={AUDIENCES_OPTIONS}
          onSelect={handleAudienceLimitChange}
          value={audiencesLimit.value}
          triggerClasses={
            'ring-0 hover:bg-gray-200 text-gray-900 text-base font-regular rounded-full pl-3 pr-2 py-1 gap-2 font-normal'
          }
          customLabel={audiencesLimit.label}
          header={<p className="text-xs font-semibold text-gray-500">VIEW AUDIENCES</p>}
          listClasses="font-regular"
          vAlignment="top"
          menuClasses="-mt-3"
        />
      </div>
      <div className="grid auto-rows-[140px] grid-cols-6 gap-4">
        {Object.entries(groupedBySubCategory).map(([subCategory, data], idx) => {
          const { title, description } = getAudienceMetaBySubCategory(subCategory);
          const { row, col } = getGridColsAndRow(idx, chartHeightInRows);
          const subtitle = categoryByAudienceSubCategory[subCategory];

          return (
            <AudienceWidget
              key={subCategory}
              grid={{
                row: row,
                col: col,
              }}
              isLoading={isLoading}
              limit={audiencesLimit.value}
              data={data}
              title={title}
              subtitle={subtitle}
              description={description}
            />
          );
        })}
      </div>
    </>
  );
};

export default ReadershipAudiences;
