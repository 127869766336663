import { Buttons, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import moment from 'moment';
import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { generateAndDownloadCSV, transformFiltersDataForCSV } from '../../helpers/helpers';
import MvdMasterMapping from '../Filters/MvdMasterMapping';
import { useAudienceContext } from './AudienceContext';
import useAudienceData, { IDataSeries } from './Widgets/useAudienceData';

const AudienceExport: React.FC = () => {
  const { filters, datasetId } = useAudienceContext();
  const queries = [
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [],
      uncategorizedLabel: 'Party',
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.VOTER_FREQUENCY],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.ETHNICITY],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.GENDER],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.AGE],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.GENERATION],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.EDUCATION],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.HOUSEHOLD_INCOME],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.PRESENCE_OF_CHILDREN],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.MARITAL_STATUS],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.HOME_OWNERSHIP],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.HISPANIC_ORIGIN],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.HISPANIC_LANGUAGE],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.LANGUAGE],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.GUN_OWNERSHIP],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.RELIGIONS_DESCRIPTION],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.PRO_CHOICE_VOTERS],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.EDUCATION_CONDENSED],
    }),
    useAudienceData({
      datasetId,
      filterGroupId: MvdTypes.FilterGroup.PARTY,
      filters,
      groupByFilterIds: [MvdTypes.FilterGroup.LIKELY_VOTERS],
    }),
  ];

  const isLoading = queries.some((x) => x.isLoading);

  const transformQueryDataForCSV = (
    queries: {
      title: string;
      data: IDataSeries[] | undefined;
      isLoading: boolean;
      isError: boolean;
    }[]
  ) => {
    const transformedData: { [key: string]: any }[] = [];

    transformedData.push({
      col1: '',
      col2: 'Democratic - Count',
      col3: 'Democratic - Percent',
      col4: 'Republican - Count',
      col5: 'Republican - Percent',
      col6: 'Independent - Count',
      col7: 'Independent - Percent',
    });

    queries.forEach((query) => {
      if (!query.isLoading && !query.isError) {
        const groupName = query.title;

        transformedData.push({
          col1: `Demographic: ${groupName}`,
          col2: '',
          col3: '',
        });

        query.data?.forEach((dataPoint) => {
          dataPoint.data.forEach((data) => {
            // Check if we already have an entry for the current age group
            let existingEntry = transformedData.find((entry) => entry.group === groupName + ' - ' + data.name);

            // If not, create an initial structure for this group
            if (!existingEntry) {
              existingEntry = {
                group: groupName + ' - ' + data.name,
                col1: data.name,
                col2: null,
                col3: null,
                col4: null,
                col5: null,
                col6: null,
                col7: null,
              };
              transformedData.push(existingEntry);
            }

            switch (dataPoint.name) {
              case 'Democratic':
                existingEntry.col2 = data.count;
                existingEntry.col3 = data.percentage.toFixed(2);
                break;
              case 'Republican':
                existingEntry.col4 = data.count;
                existingEntry.col5 = data.percentage.toFixed(2);
                break;
              case 'Independent':
                existingEntry.col6 = data.count;
                existingEntry.col7 = data.percentage.toFixed(2);
                break;
            }
          });
        });
        transformedData.push({ col1: '', col2: '', col3: '' });
      }
    });

    return transformedData;
  };

  const getSelectedAudienceCategory = (filterRules: RuleGroupType[]): string[] => {
    const audienceCodeRule = filterRules.find((rule) => rule.id === MvdTypes.FilterGroup.AUDIENCE_CODE);
    return audienceCodeRule ? MvdMasterMapping.groupToHumanReadableText(audienceCodeRule).values : ['Any Audience'];
  };

  const handleExport = () => {
    const selectedAudienceCategory = getSelectedAudienceCategory(filters.rules as RuleGroupType[]).join(', ');
    const infoData = [
      {
        col1: `Audience insights - ${selectedAudienceCategory}`,
        col2: '',
        col3: '',
        col4: '',
        col5: '',
        col6: '',
        col7: '',
        col8: '',
        // NOTE: need more columns in the file, add them here (in the first row)
      },
      { col1: 'Date:', col2: moment().format('MM-DD-YYYY'), col3: '' },
      { col1: '', col2: '', col3: '' },
      { col1: 'Category:', col2: selectedAudienceCategory, col3: '' },
      { col1: '', col2: '', col3: '' },
    ];

    const transformedFilters = transformFiltersDataForCSV(filters.rules as RuleGroupType[]);

    const transformedData = transformQueryDataForCSV(queries);

    generateAndDownloadCSV(
      [...infoData, ...transformedFilters, ...transformedData],
      `Audience Insights - ${selectedAudienceCategory} - ${moment().format('MM_DD_YYYY')}`
    );
  };

  return (
    <Buttons.Soft
      className="h-fit rounded-full bg-white p-2 text-sm shadow-none ring-0"
      onClick={handleExport}
      data-rh="Download CSV"
      disabled={isLoading}
      icon={<MUIcon name="download" className="h-6 w-6" />}
    />
  );
};

export default AudienceExport;
