import { Buttons, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';
import classNames from 'classnames';
import pluralize from 'pluralize';
import React from 'react';

export interface Props {
  audience: MvdTypes.IAudience;
  displayDescription?: boolean;
  displayTopics?: boolean;
  onClick?: () => void;
}

const MAX_DESCRIPTION_SIZE = 165;

const AudienceCard: React.FC<Props> = ({ audience, onClick, displayDescription, displayTopics }) => {
  const isDescriptionOverlapping = audience.description.length > MAX_DESCRIPTION_SIZE;

  return (
    <Buttons.Link
      className={classNames(
        'col-span-3 items-start justify-start rounded-2xl border border-gray-200 bg-white p-6 hover:cursor-pointer hover:bg-white hover:shadow-lg',
        {
          'max-h-[200px] min-h-[200px]': displayDescription || displayTopics,
        }
      )}
      onClick={onClick}
    >
      <div className="flex flex-col">
        <div className="flex flex-col gap-2">
          <p className="flex w-full items-start truncate text-sm font-semibold text-gray-900">{audience.title}</p>
          <div className="flex items-center justify-start gap-2">
            <MUIcon name="people" className="text-gray-500" />
            <p className="text-xs font-normal text-gray-500">
              {NumberFormatter.format(audience.size, '0,0')} {pluralize('voter', audience.size)}
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between gap-6">
          {displayDescription && (
            <div className="h-[60px] max-h-[60px] w-full whitespace-normal break-words py-4 text-left text-xs font-normal leading-normal text-gray-600">
              <p
                className={classNames('elipsis', { truncate: isDescriptionOverlapping })}
                data-rh={isDescriptionOverlapping ? audience.description : null}
              >
                {audience.description}
              </p>
            </div>
          )}
          {displayTopics && (
            <div className="inline-flex w-full items-center justify-start gap-2">
              {audience.topics.map((topic) => (
                <div
                  data-rh={topic}
                  key={topic}
                  className="white-space-nowrap elipsis flex items-center justify-center gap-2.5 overflow-hidden rounded-full bg-gray-100 py-1.5 px-2"
                >
                  <div className="flex-1 truncate text-xs font-semibold leading-none text-gray-800">{topic}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Buttons.Link>
  );
};

export default AudienceCard;
