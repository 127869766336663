import { useQuery } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import * as api from '../../../mvd.api';
import useReadershipRequest from '../Readership/Widgets/useReadershipRequest';
import useReadershipWhereClause from '../Readership/Widgets/useReadershipWhereClause';
import { WidgetQueryKeys } from '../Readership/Widgets/WidgetQueryKeys';

interface Props {
  enabled: boolean;
  filters: RuleGroupType;
}

const useDistinctVisitorsQuery = ({ filters, enabled }: Props) => {
  const whereClause = useReadershipWhereClause(filters);
  const sql = `SELECT COUNT(DISTINCT visitorid) AS count FROM WHERE ${whereClause};`;

  const pqsQuery = useReadershipRequest({ sql, filters });

  return useQuery(
    [WidgetQueryKeys.UNIQUE_VISITORS_COUNT, pqsQuery],
    () => api.getDataPqs<AudienceData>(pqsQuery, 'count'),
    {
      enabled,
    }
  );
};

export default useDistinctVisitorsQuery;

interface AudienceData {
  count: string;
}
