import { Buttons, DeleteConfirmationModal, Menu, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { ActivationStatus } from '../../../../../../../libs/types/src/lib/mvd.types';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { generateLink } from '../../LinkGenerator';

interface Props {
  item: MvdTypes.IAudienceActivationRequest;
}

const ActivationMenu: React.FC<Props> = ({ item }: Props) => {
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { userInfo } = useApplicationContext();

  const invalidateActivations = () => queryClient.invalidateQueries(['audience-activation-requests', userInfo?.email]);
  const downloadDataMutation = useMutation({
    mutationFn: async (s3Path: string) => await mvdApi.getPresignedUrl(s3Path),
    onSuccess: async (data) => {
      const link = document.createElement('a');
      link.href = data.presignedUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      try {
        await mvdApi.updateAudienceStatus(item.referenceId, 'DOWNLOADED');
        invalidateActivations();
      } catch {
        // TODO:
      }
    },
  });

  const cancelActivationMutation = useMutation({
    mutationFn: (payload: MvdTypes.ICancelActivation) => mvdApi.cancelActivation(payload),
    onSuccess: () => {
      invalidateActivations();
    },
  });

  const softDeleteAudienceMutation = useMutation({
    mutationFn: (referenceId: number) => mvdApi.softDeleteUserAudience(referenceId),
    onSuccess: () => {
      invalidateActivations();
    },
  });

  const onDelete = () => {
    setIsMenuVisible(false);
    setShowDeleteConfirmationModal(false);
    if (item.requestType === MvdTypes.ActivationType.MULTI_FORMAT) {
      softDeleteAudienceMutation.mutate(item.referenceId);
    } else {
      cancelActivationMutation.mutate({ uniqueId: item.uniqueId ?? 0, referenceId: item.referenceId });
    }
  };

  const onDownloadData = () => {
    if (!item.s3Path) return;
    downloadDataMutation.mutate(item.s3Path);
  };

  return (
    <>
      <Menu
        trigger={<Buttons.Link icon={<MUIcon name="more_vert" />} className="h-[36px] w-[36px] rounded-full border" />}
        visible={isMenuVisible}
        onToggleVisibility={() => setIsMenuVisible(!isMenuVisible)}
        menuMargin={3}
        hAlignment="right"
        menuClasses="shadow-lg"
      >
        <div className="h-full w-full">
          <div className="flex flex-col">
            {item.activationStatus === ActivationStatus.EXPIRED && (
              <Link
                to={generateLink('myAudience', { audienceId: item.audienceId }) + '?activation=true'}
                className="disabled:cursos-default flex w-full items-center justify-start gap-2 border-b p-4 hover:cursor-pointer hover:bg-gray-200"
              >
                <MUIcon name="add" />
                <p className="text-sm">Add platform</p>
              </Link>
            )}

            {item.s3Path && item.requestType === MvdTypes.ActivationType.MULTI_FORMAT && (
              <button
                className="flex w-full items-center justify-start gap-2 border-b p-4 hover:cursor-pointer hover:bg-gray-200"
                onClick={onDownloadData}
              >
                <MUIcon name="download" />
                <p className="text-sm">Download</p>
              </button>
            )}

            {item.activationStatus !== 'CANCELED' && (
              <button
                className="flex w-full items-center justify-start gap-2 border-b p-4 hover:cursor-pointer hover:bg-gray-200"
                onClick={() => setShowDeleteConfirmationModal(true)}
              >
                <MUIcon name="delete" />
                <p className="text-sm">Delete</p>
              </button>
            )}
          </div>
        </div>
      </Menu>
      {showDeleteConfirmationModal && (
        <DeleteConfirmationModal
          title="Delete activation"
          message={`Are you sure you want to cancel this ${
            item.requestType === MvdTypes.ActivationType.MULTI_FORMAT ? 'export' : 'activation'
          }?`}
          onConfirm={onDelete}
          onCloseRequest={() => {
            setShowDeleteConfirmationModal(false);
          }}
        />
      )}
    </>
  );
};

export default ActivationMenu;
