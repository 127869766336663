import { Buttons, Drawers, Inputs, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import Search from '../../../assets/search.png';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import AudienceCard from '../Audience/AudienceCard';
import { useFilterGroup } from '../Filters/common';
import Chip from './Chip';

const { FilterGroup } = MvdTypes;

interface AudiencesDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  onSelectAudience: (rule: RuleGroupType) => void;
  filters: RuleGroupType;
}

interface IAudienceCategory {
  category: string;
  subCategories: string[];
}

const MULTI_ISSUE_DESCRIPTION =
  'Multi-Issue Psychographic Personas are premium audiences that categorize voters into mutually-exclusive cohorts based on their opinions on various political, economic, and social issues.';

const AudiencesDrawer: React.FC<AudiencesDrawerProps> = ({ isOpen, onClose, onSelectAudience, filters }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>('');
  const [audienceCategories, setAudienceCategories] = useState<IAudienceCategory[]>([]);

  const { addOption, options } = useFilterGroup(FilterGroup.AUDIENCE_CODE, filters);
  const { userInfo } = useApplicationContext();
  const audienceQuery = useQuery(['audiences', userInfo?.id, null], () =>
    mvdApi.getAudiences({
      category: null,
      includeInactive: false,
      includeCustom: false,
    })
  );

  useEffect(() => {
    const category = audienceCategories.find((category) => category.category === selectedCategory);
    setSelectedSubCategory(category?.subCategories[0] ?? '');
  }, [selectedCategory]);

  useEffect(() => {
    if (!audienceQuery.data) return;
    const audienceCategories = audienceQuery.data.reduce<IAudienceCategory[]>((prev, curr) => {
      const existingCategory = prev.find((group) => group.category === curr.category);
      if (existingCategory) {
        if (!existingCategory.subCategories.find((s) => s === curr.subCategory)) {
          existingCategory.subCategories.push(curr.subCategory);
        }
      } else {
        prev.push({
          category: curr.category,
          subCategories: [curr.subCategory],
        });
      }
      return prev;
    }, []);

    const order: { [key in MvdTypes.AudienceCategoryEnum]: number } = {
      ISSUE: 1,
      'MULTI-ISSUE': 2,
      UNCATEGORIZED: 3,
    };

    audienceCategories.sort(
      (a, b) => (order[a.category as keyof typeof order] || 4) - (order[b.category as keyof typeof order] || 4)
    );

    setAudienceCategories(audienceCategories);
  }, [audienceQuery.data]);

  const sortedGroupedAudiences = useMemo(() => {
    if (!audienceQuery.data) return [];

    const filteredAudiences = audienceQuery.data.filter(
      (audience) =>
        (audience.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          audience.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          audience.categories?.some((category) => category.toLowerCase().includes(searchTerm.toLowerCase()))) &&
        (selectedCategory === '' ||
          (audience.category === selectedCategory &&
            (selectedSubCategory.length > 0 ? audience.subCategory === selectedSubCategory : true)))
    );

    const groupedAudiences = filteredAudiences.reduce<
      { category: string; subCategory: { title: string; data: MvdTypes.IAudience[] }[]; categoryDescription: string }[]
    >((prev, curr) => {
      const category = !curr.category ? 'UNCATEGORIZED' : curr.category;
      const subCategory = !curr.subCategory ? 'UNCATEGORIZED' : curr.subCategory;
      const existingGroup = prev.find((group) => group.category === curr.category);
      if (existingGroup) {
        const existingSubCategory = existingGroup.subCategory.find((sub) => sub.title === curr.subCategory);
        if (existingSubCategory) {
          existingSubCategory.data.push(curr);
        } else {
          existingGroup.subCategory.push({
            title: subCategory,
            data: [curr],
          });
        }
      } else {
        prev.push({
          category,
          subCategory: [
            {
              title: subCategory,
              data: [curr],
            },
          ],
          categoryDescription: category === MvdTypes.AudienceCategoryEnum['MULTI-ISSUE'] ? MULTI_ISSUE_DESCRIPTION : '',
        });
      }
      return prev;
    }, []);

    groupedAudiences.sort((a, b) => (a.category > b.category ? 1 : -1));

    return groupedAudiences;
  }, [audienceQuery.data, searchTerm, selectedCategory, selectedSubCategory]);

  const onAudienceSelect = (audienceId: number) => {
    const option = options.find((option) => option.id === audienceId.toString());

    if (option) {
      const updatedFilters = addOption(option, true, true);
      if (updatedFilters) onSelectAudience(updatedFilters);
    }

    onClose();
  };

  const categoryToLabel: { [key in MvdTypes.AudienceCategoryEnum]: string } = {
    ISSUE: 'Single-issue',
    'MULTI-ISSUE': 'Multi-issue psychographic',
    UNCATEGORIZED: 'Uncategorized',
  };

  const contentRef = React.useRef<HTMLDivElement>(null);
  const titleRef = React.useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    if (contentRef.current) {
      const containerTop = contentRef.current.getBoundingClientRect().top || 0;
      if (titleRef.current) {
        const isTitleVisible = containerTop >= 0;
        titleRef.current.style.display = isTitleVisible ? 'block' : 'none';
      }
    }
  };

  const handleAudienceSubcategoryClick = (subCategory: string) => {
    setSelectedSubCategory(subCategory === selectedSubCategory ? '' : subCategory);
  };

  const audienceCategory = audienceCategories.find((a) => a.category === selectedCategory);
  const showSubCategories = audienceCategory && audienceCategory.subCategories.length > 1;

  return (
    <Drawers.Drawer
      isOpen={isOpen}
      onClose={onClose}
      side="bottom"
      closeButton={true}
      className="h-full w-full rounded-t-lg border"
    >
      <div onScroll={handleScroll} className="relative flex h-full flex-col overflow-y-auto">
        <div className="sticky top-0 flex justify-between border-b border-gray-200 bg-white py-4 px-12">
          <div className="inline-flex flex-col items-start justify-between gap-12 rounded-t-lg">
            <div ref={titleRef} className="pt-2 text-xl font-semibold leading-[30px] text-black">
              Browse and select an audience
            </div>
            <div className="inline-flex items-center justify-start gap-4 bg-white">
              <div className="flex items-center justify-start gap-4">
                <Chip
                  title="All"
                  onClick={() => setSelectedCategory('')}
                  className={classNames(
                    'text-sm font-semibold text-gray-800',
                    selectedCategory === '' && 'bg-secondary-100'
                  )}
                />
                {audienceCategories.map((category, index) => (
                  <Chip
                    key={`${category.category}_${index}`}
                    title={categoryToLabel[category.category as MvdTypes.AudienceCategoryEnum] ?? category.category}
                    onClick={() => setSelectedCategory(category.category)}
                    className={classNames(
                      'text-sm font-semibold text-gray-800',
                      selectedCategory === category.category && 'bg-secondary-100'
                    )}
                  />
                ))}
                {showSubCategories && <div className="text-gray-300">|</div>}
                {showSubCategories &&
                  audienceCategory?.subCategories.map((subCategory, index) => (
                    <Chip
                      key={`$subCategory}_${index}`}
                      title={subCategory}
                      onClick={() => handleAudienceSubcategoryClick(subCategory)}
                      className={classNames(
                        'text-sm font-semibold text-gray-800',
                        selectedSubCategory === subCategory && 'bg-secondary-100'
                      )}
                    />
                  ))}
              </div>
            </div>
          </div>
          <Inputs.Input
            classes="!w-[448px] h-11 bg-gray-100 px-4 !shadow-none !ring-0"
            inputClasses="bg-transparent text-gray-600 text-sm font-normal"
            onClear={() => setSearchTerm('')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search for audiences"
            prefixComponent={<MUIcon name="search" className="text-gray-600" />}
          />
        </div>
        <div ref={contentRef} className="mb-10 h-max grow flex-col items-start justify-start bg-gray-50 px-12 pt-4">
          {sortedGroupedAudiences.length > 0 &&
            sortedGroupedAudiences?.map((group, index) => (
              <div className="mb-4 flex flex-col gap-2" key={`${group.category}_${group.categoryDescription}_${index}`}>
                <div className="items-center justify-start gap-4 pt-6">
                  <div className="flex gap-1 text-lg text-lg font-semibold text-gray-900">
                    {categoryToLabel[group.category as MvdTypes.AudienceCategoryEnum] ?? group.category}
                    {group.categoryDescription && (
                      <div
                        className="flex items-center justify-center"
                        data-rh={group.categoryDescription}
                        data-rh-at="right"
                      >
                        <MUIcon name="info" className="text-gray-500" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  {group.subCategory.map((subCategory) => (
                    <div className={'mt-3 flex flex-col gap-4'} key={subCategory.title}>
                      <div className="text-lg">{subCategory.title}</div>
                      <div className="grid w-full grid-cols-9 gap-6">
                        {subCategory.data.map((audience) => (
                          <AudienceCard
                            key={audience.id}
                            audience={audience}
                            displayDescription={true}
                            displayTopics={true}
                            onClick={() => onAudienceSelect(audience.id)}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          {searchTerm.length > 0 && sortedGroupedAudiences.length === 0 && (
            <NoAudiencesFound searchText={searchTerm} onClear={() => setSearchTerm('')} />
          )}
        </div>
      </div>
    </Drawers.Drawer>
  );
};

interface NoAudiencesFoundProps {
  searchText: string;
  onClear: () => void;
}

const NoAudiencesFound: React.FC<NoAudiencesFoundProps> = ({ searchText, onClear }) => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-12 rounded-lg px-12 py-20">
      <img src={Search} alt="No audiences found" />
      <div className="flex h-[97px] flex-col items-center justify-center gap-8 self-stretch">
        <div className="flex h-[27px] flex-col items-center justify-center gap-2 self-stretch">
          <div className="font-['Inter'] text-lg font-semibold leading-[27px] text-gray-800">
            No audiences found for '{searchText}'
          </div>
        </div>
        <Buttons.Primary className="bg-primary-800 rounded-full px-6 py-2.5 text-white" onClick={onClear}>
          <div className="flex items-center justify-center gap-2">
            <MUIcon name="refresh" />
            Clear your filters and try again
          </div>
        </Buttons.Primary>
      </div>
    </div>
  );
};

export default AudiencesDrawer;
