import { Hooks, Map as MapLibreMapComponent, Utils } from '@platform/maplibre';
import { Dropdowns, MapComponents } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import classnames from 'classnames';
import { Feature, FeatureCollection } from 'geojson';
import * as maplibre from 'maplibre-gl';
import { MapGeoJSONFeature } from 'maplibre-gl';
import React, { useEffect, useState } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { useGeoJsonQuery } from '../../../../hooks';
import useBasemapStyle from '../../../shared/useBasemapStyle';
import useAudienceMapData, { PARTY_DEMOCRATS, PARTY_INDEPENDENT, PARTY_REPUBLICANS } from '../../useAudienceMapData';
import Legend from './Legend';
import Tooltip from './Tooltip';

const { ZoomControls } = MapComponents;

const GEO_JSON_LAYER_ID = 'geographies';

interface Props {
  datasetId: string;
  availableGeoLevels: MvdTypes.GeoLevelType[];
  activeGeoLevel: MvdTypes.GeoLevelType;
  filters: RuleGroupType;
  activeGeoSelection: MvdTypes.IGeoSelection[];
  onChangeGeoLevel: (newGeoLevel: MvdTypes.GeoLevelType) => void;
  onChangeGeoSelection: (selection: MapGeoJSONFeature[]) => void;
}

const AudienceMap: React.FC<Props> = ({
  availableGeoLevels,
  datasetId,
  activeGeoLevel,
  onChangeGeoLevel,
  filters,
  onChangeGeoSelection,
}) => {
  const [map, setMap] = useState<maplibre.Map | null>(null);

  const basemap = useBasemapStyle();
  const geoDataQuery = useAudienceMapData({ geoLevel: activeGeoLevel, filters, datasetId });
  const geoJsonQuery = useGeoJsonQuery(activeGeoLevel.geoJson);

  // const handleMapClickSelection = useMemo(
  //   () => (featuresInSelectEvent: MapGeoJSONFeature[]) => {
  //     // console.log(featuresInSelectEvent);
  //     // onChangeGeoSelection(featuresInSelectEvent);
  //   },
  //   [onChangeGeoSelection]
  // );
  // Hooks.useFeatureSelection(map, [GEO_JSON_LAYER_ID], handleMapClickSelection);

  Hooks.useFeatureHighlightSingleMap(map, [GEO_JSON_LAYER_ID]);

  useEffect(() => {
    if (!map) return;
    if (geoDataQuery.data == null) return;
    if (geoJsonQuery.data == null) return;

    try {
      // flatten data from array into object for quicker lookup
      const flattenedAbacusData: Record<string, any> = geoDataQuery.data.reduce((p, c) => ({ ...p, [c.name]: c }), {});

      const features: Feature[] = geoJsonQuery.data.features.reduce(
        (prev: Feature[], current: Feature, idx: number) => {
          const matchedFeatureName = activeGeoLevel.matchBy
            .filter((x) => !!x)
            .map((x) => current.properties?.[x])
            .join()
            .toLowerCase();

          if (flattenedAbacusData[matchedFeatureName]?.count == null) return prev;
          const fromAbacus = flattenedAbacusData[matchedFeatureName];

          return [
            ...prev,
            {
              ...current,
              id: current.id ?? idx,
              properties: {
                ...fromAbacus,
                ...current.properties,
              },
            },
          ];
        },
        []
      );

      const mergedData: FeatureCollection = {
        type: 'FeatureCollection',
        features,
      };

      applyToMap(map, mergedData, basemap.targetLayerId);
    } catch (e) {
      console.error(e);
    }
  }, [map, activeGeoLevel, geoDataQuery.data, geoJsonQuery.data, basemap.targetLayerId]);

  const currentZoom = map?.getZoom() ?? 0;
  const handleChange = (opt: Dropdowns.DropdownOption) => {
    const newLevel = availableGeoLevels.find((x) => x.name === opt?.value);
    if (!newLevel) return;
    onChangeGeoLevel(newLevel);
  };

  const options: Dropdowns.DropdownOption[] = availableGeoLevels.map((x) => ({
    name: x.name,
    value: x.name,
    disabled: x.disabled,
  }));

  const isLoading = geoDataQuery.isLoading || geoJsonQuery.isLoading;

  return (
    <div
      className={classnames(
        'relative flex h-full w-full flex-grow overflow-hidden rounded-l-[16px] border border-gray-200',
        {
          'pointer-events-none grayscale': isLoading,
        }
      )}
    >
      <MapLibreMapComponent
        onMount={(map) => setMap(map)}
        maxBounds={[
          [-200, -15], // Southwest coordinates with more padding
          [-20, 75], // Northeast coordinates with more padding
        ]}
        zoom={3}
        maxZoom={10}
        center={{ lng: -110, lat: 45 }}
        style={basemap.style}
      />
      {map && (
        <Tooltip
          map={map}
          layerId={GEO_JSON_LAYER_ID}
          labelField={activeGeoLevel.labelProp}
          parentGeoLabelField={activeGeoLevel.parent ? activeGeoLevel.matchBy[0] : undefined}
        />
      )}
      <div className="z-1 absolute top-6 right-6">
        <Dropdowns.Dropdown
          disabled={isLoading}
          options={options}
          onSelect={handleChange}
          value={activeGeoLevel.name}
          triggerPrefix="by"
          triggerClasses="bg-white min-w-[180px] rounded-full px-4 py-2 shadow-md"
        />
      </div>
      <div className="z-1 absolute bottom-4 left-8 flex items-end gap-4">
        <div className="rounded-md shadow-md">
          <Legend />
        </div>
      </div>
      <div className="z-1 absolute bottom-4 right-8 flex items-end gap-4">
        <div className="rounded-md shadow-md">
          <ZoomControls
            zoom={currentZoom}
            onZoomIn={() => map?.setZoom(map?.getZoom() + 1)}
            onZoomOut={() => map?.setZoom(map?.getZoom() - 1)}
          />
        </div>
      </div>
    </div>
  );
};

export default AudienceMap;

const applyToMap = (map: maplibre.Map, featureCollection: FeatureCollection, targetLayerId: string) => {
  const PRIMARY_SOURCE_ID = 'geo-json-primary';

  Utils.cleanupSource(map, PRIMARY_SOURCE_ID);
  map.addSource(PRIMARY_SOURCE_ID, { type: 'geojson', data: featureCollection });

  // FIT BOUNDS test
  const bounds = Utils.calculateBounds(featureCollection);
  if (bounds) {
    map.fitBounds(bounds, { padding: 80, maxZoom: 8, minZoom: 3, animate: false });
  }

  map.addLayer(
    {
      id: GEO_JSON_LAYER_ID,
      source: PRIMARY_SOURCE_ID,
      type: 'fill',
      paint: {
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.8, 1],
        'fill-color': [
          'match',
          ['get', 'partyWinner'], // Access the 'party' property from the GeoJSON
          PARTY_REPUBLICANS.label,
          PARTY_REPUBLICANS.color,
          PARTY_DEMOCRATS.label,
          PARTY_DEMOCRATS.color,
          PARTY_INDEPENDENT.label,
          PARTY_INDEPENDENT.color,
          /* default */ '#FFFFFF', // Default color (white) for any other values
        ],
      },
    },
    targetLayerId
  );

  map.addLayer(
    {
      id: `${GEO_JSON_LAYER_ID}-line`,
      source: PRIMARY_SOURCE_ID,
      type: 'line',
      paint: {
        'line-color': '#fff',
        'line-opacity': 0.35,
      },
    },
    targetLayerId
  );
};
