export enum WidgetQueryKeys {
  GEO = 'readership:geo',
  GENDER = 'readership:gender',
  UNIQUE_VISITORS_COUNT = 'readership:unique-visitors-count',
  AGE = 'readership:age',
  ETHNICITY = 'readership:ethnicity',
  PARTY = 'readership:party',
  ARTICLES = 'readership:articles',
  ARTICLES_COUNT = 'readership:articles-count',
  INTEREST_OVER_TIME = 'readership:interest-over-time',
  TOPICS = 'readership:topics',
  TOPIC_DETAILS = 'readership:topic-details',
  EDUCATION_CONDENSED = 'readership:education-condensed',
  LIKELY_VOTERS = 'readership:likely-voters',
  INCOME = 'readership:income',
  MARITAL_STATUS = 'readership:marital-status',
  PRESENCE_OF_CHILDREN = 'readership:presence-of-children',
  MEDIA_SOURCE = 'readership:media-source',
  AUDIENCE = 'readership:audience',
}
