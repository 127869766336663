import dropin, { cardPaymentMethodPayload, Dropin, Options, PaymentMethodPayload } from 'braintree-web-drop-in';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { useQuery } from 'react-query';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import { useActivationsContext } from './ActivationsContext';

const ALLOWED_CARD_TYPES = ['MasterCard', 'Visa', 'American Express', 'Discover'];

const BraintreeDropIn = forwardRef((_, ref) => {
  const { runActivation } = useActivationsContext();
  const { notify } = useApplicationContext();

  const dropInInstance = useRef<Dropin | null>(null);
  const dropInContainerRef = useRef<HTMLDivElement>(null); // Reference to the container

  const braintreeClientTokenQuery = useQuery(['braintree-client-token'], () => mvdApi.getBraintreeClientToken(), {
    staleTime: 1000 * 15,
  });

  useEffect(() => {
    if (!braintreeClientTokenQuery.data?.clientToken) return;
    const initializeBraintree = () => {
      const options: Options = {
        authorization: braintreeClientTokenQuery.data.clientToken,
        container: dropInContainerRef.current!,
        dataCollector: true,
        vaultManager: true,
      };

      dropin.create(options, (err: object | null, instance: Dropin | undefined) => {
        if (err) {
          console.error('Error initializing Braintree Drop-in:', err);
          return;
        }
        dropInInstance.current = instance ?? null;
      });
    };

    // Check if there is an existing instance and teardown before creating a new one
    if (dropInInstance.current) {
      dropInInstance.current
        .teardown()
        .then(() => {
          console.log('Drop-in instance destroyed');
          initializeBraintree();
        })
        .catch((teardownErr: Error) => {
          console.error('Error tearing down Drop-in instance:', teardownErr);
        });
    } else {
      initializeBraintree();
    }
  }, [braintreeClientTokenQuery.data]);

  useImperativeHandle(ref, () => ({
    handlePayment: () => {
      if (!dropInInstance.current) {
        console.error('Braintree Drop-in instance is not available');
        return;
      }

      if (!braintreeClientTokenQuery.data?.brainTreeCustomId) {
        console.error('Braintree token not available');
        return;
      }

      dropInInstance.current.requestPaymentMethod(async (err: object | null, payload: PaymentMethodPayload) => {
        if (!payload) {
          console.error('No payload returned from Braintree');
          return;
        }

        if (err) {
          console.error('Error requesting payment method:', err);
          return;
        }

        const usedCardType = (payload as cardPaymentMethodPayload)?.details?.cardType;

        const usedCardIsAllowed = ALLOWED_CARD_TYPES.includes(usedCardType);

        if (!usedCardIsAllowed) {
          notify({
            dismissible: true,
            hideAfterSec: 10,
            content: (
              <span>Your payment option is not allowed. Accepted methods are: {ALLOWED_CARD_TYPES.join(', ')}</span>
            ),
          });
          return;
        }

        const btAuth = {
          btCustomId: braintreeClientTokenQuery.data.brainTreeCustomId,
          nonce: payload.nonce,
          deviceData: payload.deviceData || '',
        };

        runActivation(btAuth);
      });
    },
  }));

  return (
    <div>
      <div id="dropin-container" ref={dropInContainerRef} />
    </div>
  );
});

export default BraintreeDropIn;
