import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';
import { useQuery } from 'react-query';
import * as mvdApi from '../../../mvd.api';
import { useApplicationContext } from '../../ApplicationContext';
import Loader from '../shared/Loader';
import SavedAudience from './SavedAudience';

const SavedAudiences: React.FC = () => {
  const { userInfo } = useApplicationContext();
  const userAudienceQuery = useQuery(['user-audiences', userInfo?.id], () => mvdApi.getUserAudiences());
  if (userAudienceQuery.isLoading || userAudienceQuery.isIdle)
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Loader message="Loading saved audiences" />
      </div>
    );
  const audiences = userAudienceQuery.data;
  if (audiences == null) return <>Nothing here</>;

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex w-full flex-col">
        <div className="flex w-full items-center justify-between border-b border-gray-200 bg-white px-8 py-6">
          <div className="text-xl font-semibold">My audiences</div>
          <Buttons.Secondary
            icon={<MUIcon name="search" />}
            className="hidden rounded-full border border-gray-200 font-semibold shadow-none ring-0"
          >
            Browse all audiences
          </Buttons.Secondary>
        </div>
      </div>
      <div className="h-full px-8">
        <div className="h-1/2 gap-2 py-12">
          <div className="flex w-full flex-col gap-6 pb-4">
            {audiences.length > 0 ? (
              <div className="flex flex-wrap gap-2">
                {audiences.map((x) => (
                  <SavedAudience key={x.id} audience={x} userId={userInfo?.id} />
                ))}
              </div>
            ) : (
              <p className="flex text-sm text-gray-600">No saved audiences</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SavedAudiences;
