import { MvdTypes } from '@platform/types';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import * as api from '../../../../mvd.api';
import useDistinctVisitorsQuery from '../../shared/useDistinctVisitorsQuery';
import useReadershipRequest from './useReadershipRequest';
import useReadershipWhereClause from './useReadershipWhereClause';
import { WidgetQueryKeys } from './WidgetQueryKeys';

interface IParams {
  geoLevel: MvdTypes.GeoLevelType;
  filters: RuleGroupType;
  queryEnabled: boolean;
}

// TODO:
const GEO_CITY_FIELD = 'geo.city';
const GEO_CITY_CONCAT_FIELD = `CONCAT("geo.region", ',', "${GEO_CITY_FIELD}")`;

const useReadershipMapData = ({
  filters,
  geoLevel,
  queryEnabled,
}: IParams): {
  title: string;
  data: IDataPoint[] | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const [rawData, setRawData] = useState<IResponseRawData[] | null>(null);
  const distinctVisitorsCountQuery = useDistinctVisitorsQuery({ enabled: queryEnabled, filters });
  const whereClause = useReadershipWhereClause(filters);

  // TODO check this!! -> how we fetch geo data for nested geos on readership map, current workaround is this
  let select = `"${geoLevel.field}"`;
  let groupBy = `"${geoLevel.field}"`;
  if (geoLevel.field === GEO_CITY_FIELD) {
    select = GEO_CITY_CONCAT_FIELD;
    groupBy = GEO_CITY_CONCAT_FIELD;
  }
  const sql = `SELECT ${select} as geo, COUNT(DISTINCT visitorid) as count
    FROM
    WHERE ${whereClause} and "geo.region" != ''
    GROUP BY ${groupBy}
    ORDER BY count DESC ${geoLevel.limit ? `LIMIT ${geoLevel.limit}` : ''}`;

  const pqsQuery = useReadershipRequest({ filters, sql });

  const rawDataQuery = useQuery(
    [WidgetQueryKeys.GEO, pqsQuery],
    () => api.getDataPqs<IResponseRawData>(pqsQuery, MvdTypes.FilterGroup.GEO),
    {
      enabled: queryEnabled,
    }
  );

  useEffect(() => {
    if (rawDataQuery.data) setRawData(rawDataQuery.data);
  }, [rawDataQuery.data]);

  const data: IDataPoint[] | undefined = useMemo(() => {
    const totalCountOfViews = Number(distinctVisitorsCountQuery.data?.[0].count);
    return rawData?.map((x) => {
      const count = Number(x.count);
      const percentage = totalCountOfViews ? (count * 100) / totalCountOfViews : 0;

      return {
        name: x.geo,
        count,
        percentage,
      };
    });
  }, [rawData, distinctVisitorsCountQuery.data]);

  const isLoading = rawDataQuery.isIdle || rawDataQuery.isLoading;
  const isError = rawDataQuery.isError;

  return { data, isLoading, isError, title: geoLevel.name };
};

export default useReadershipMapData;

export interface IDataPoint {
  name: string;
  count: number;
  percentage: number;
}

interface IResponseRawData {
  geo: string;
  count: string;
}
