import { Buttons, MUIcon, Spinner } from '@platform/shared/ui';
import React, { ReactNode, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useApplicationContext } from '../../ApplicationContext';
import ActivationAuthorize from './ActivationAuthorize';
import ActivationAuthorizeCheckBox from './ActivationAuthorizeCheckBox';
import ActivationBrainTree from './ActivationBrainTree';
import ActivationNoteAndAccountName from './ActivationNoteAndAccountName';
import { useActivationsContext } from './ActivationsContext';
import ActivationType from './ActivationType';

const Activation: React.FC = () => {
  const { isActivating, onExitRequest, runActivation } = useActivationsContext();
  const { userInfo } = useApplicationContext();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const activationPlatformTypeRef = useRef<{ triggerSubmit: () => Promise<void> } | null>(null);
  const activationDataTypeRef = useRef<{ triggerSubmit: () => Promise<void> } | null>(null);
  const authorizeRef = useRef<{ triggerSubmit: () => Promise<void> } | null>(null);
  const braintreeRef = useRef<{ handlePayment: () => Promise<void> } | null>(null);

  const ActivationSteps: { stepTitle: string; stepContent: ReactNode | null; stepFooter?: ReactNode }[] = [
    {
      stepTitle: 'Choose a platform or data download',
      stepContent: <ActivationType platformRef={activationPlatformTypeRef} dataRef={activationDataTypeRef} />,
    },
    {
      stepTitle: 'Authorize and confirm',
      stepContent: <ActivationAuthorize />,
      stepFooter: <ActivationAuthorizeCheckBox ref={authorizeRef} />,
    },
    {
      stepTitle: 'Add details (optional)',
      stepContent: <ActivationNoteAndAccountName />,
    },
    {
      stepTitle: 'Enter billing information',
      stepContent: <ActivationBrainTree ref={braintreeRef} />,
    },
  ];

  const { stepTitle, stepContent, stepFooter } = ActivationSteps[currentStep];
  const isFirstStep = currentStep === 0;
  const isAuthorizeStep = currentStep === 1;
  const isDetailsStep = currentStep === 2;
  const isLastStep = currentStep === ActivationSteps.length - 1;
  // ByPass orgs have one step less - they have no payment step
  const stepsCount = userInfo?.org?.isFree ? ActivationSteps.length - 1 : ActivationSteps.length;

  const handleNextStep = () => {
    if (activationPlatformTypeRef.current && isFirstStep) {
      activationPlatformTypeRef.current.triggerSubmit().then(() => setCurrentStep((prevState) => prevState + 1));
    } else if (activationDataTypeRef.current && isFirstStep) {
      activationDataTypeRef.current.triggerSubmit().then(() => setCurrentStep((prevState) => prevState + 1));
    } else if (authorizeRef.current && isAuthorizeStep) {
      authorizeRef.current.triggerSubmit().then(() => setCurrentStep((prevState) => prevState + 1));
    } else if (isDetailsStep) {
      setCurrentStep((prevState) => prevState + 1);
    }
  };

  const handleActivate = async () => {
    if (userInfo?.org?.isFree) {
      // validate fields before activation
      runActivation(null);
    } else if (braintreeRef.current) {
      await braintreeRef.current.handlePayment();
    } else {
      console.error('Failed to start activation');
    }
  };

  const activateButton = (
    <Buttons.Primary
      className="h-[36px] w-[148px]"
      onClick={handleActivate}
      data-cy="import-activate-button"
      disabled={isActivating}
    >
      {isActivating ? (
        <div className="flex w-full items-center justify-center">
          <Spinner width={30} height={30} fillColor={'#FFFFFF'} />
        </div>
      ) : (
        'Activate'
      )}
    </Buttons.Primary>
  );

  let navigationButton: React.ReactNode;

  if (isLastStep) {
    navigationButton = activateButton;
  } else if (isFirstStep || isAuthorizeStep) {
    navigationButton = (
      <Buttons.Primary className="h-[36px] w-[80px]" onClick={handleNextStep} data-cy="import-next-button">
        Next
      </Buttons.Primary>
    );
  } else {
    navigationButton = userInfo?.org?.isFree ? (
      activateButton
    ) : (
      <Buttons.Primary className="h-[36px] w-[160px]" onClick={handleNextStep} data-cy="import-next-button">
        Continue to billing
      </Buttons.Primary>
    );
  }

  return createPortal(
    <div className="fixed top-0 left-0 z-20 flex h-full w-full flex-col bg-white">
      <div className="flex items-center justify-end px-8 pt-8">
        <button onClick={onExitRequest}>
          <MUIcon name="close" />
        </button>
      </div>
      <div className="flex w-full flex-col items-center justify-center border-b border-gray-200 pb-6">
        <div className="flex w-8/12 items-start py-3">
          <p className="text-sm font-semibold text-gray-500">{`Activate audience | Step ${
            currentStep + 1
          } of ${stepsCount}`}</p>
        </div>
        <div className="flex w-8/12 items-center justify-between">
          <p className="text-gray-950 text-2xl font-semibold" data-cy="import-step-title">
            {stepTitle}
          </p>
          <div className="flex items-center justify-center gap-3">
            {!isFirstStep && (
              <Buttons.Secondary
                className="h-[36px] w-[80px]"
                onClick={() => setCurrentStep((prevState) => prevState - 1)}
              >
                Back
              </Buttons.Secondary>
            )}
            {navigationButton}
          </div>
        </div>
      </div>
      <div className="flex w-full items-center justify-center overflow-y-auto">
        <div className="h-full w-8/12 pt-10 ">{stepContent}</div>
      </div>
      {stepFooter}
    </div>,
    document.body
  );
};

export default Activation;
