import { MUIcon } from '@platform/shared/ui';
import { NumberFormatter } from '@platform/utils';
import React from 'react';
import Spinner from '../Readership/Widgets/Spinner';

interface Props {
  size: number | undefined;
  loading?: boolean;
  onClick?: () => void;
}

const AudienceSizeButton: React.FC<Props> = ({ size, loading, onClick }) => {
  return (
    <div className="flex h-[40px] items-center gap-2">
      {loading && <Spinner />}
      <button
        className="bg-custom-gradient flex items-center gap-2 rounded-full border px-6 py-2 font-semibold"
        onClick={() => onClick?.()}
      >
        <MUIcon name="accessibility" />
        {size != null ? NumberFormatter.format(size, '0,0') : '-'}
      </button>
    </div>
  );
};

export default AudienceSizeButton;
