import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import AppliedFilterItem from './AppliedFilterItem';

interface Props {
  filters: RuleGroupType;
  itemClasses?: string;
}

const AppliedFilters: React.FC<Props> = ({ filters, itemClasses }) => {
  if (!filters.rules.length) return <>No filters applied</>;

  return (
    <div className="flex w-full flex-col gap-2">
      {filters.rules.map((x) => (
        <AppliedFilterItem key={x.id} filterGroup={x as RuleGroupType} itemClasses={itemClasses} />
      ))}
    </div>
  );
};

export default AppliedFilters;
