import React from 'react';
import { RuleGroupType } from 'react-querybuilder';

import { Buttons, MUIcon } from '@platform/shared/ui';
import AudienceFilter from '../Filters/Audience.filter';
import GeoFilter from '../Filters/Geo';
import { useAudienceContext } from './AudienceContext';
import MoreFiltersAudience from './MoreFilters.audience';

const AudienceFilters: React.FC = () => {
  const { filters, applyFilters, geoLevel, availableGeoLevels, changeGeoLevel, resetFilters } = useAudienceContext();

  const handleDone = (newFilters: RuleGroupType) => applyFilters(newFilters);

  return (
    <div className="flex items-center gap-3 pb-5">
      <AudienceFilter filters={filters} onDone={handleDone} />
      <div className="h-[16px] w-[1px] bg-gray-300" />
      <GeoFilter
        activeGeoLevel={geoLevel}
        filters={filters}
        onChangeGeoLevel={changeGeoLevel}
        availableGeoLevels={availableGeoLevels}
        onDone={handleDone}
      />
      <MoreFiltersAudience />
      <Buttons.Link
        className="rounded-full"
        data-rh="Reset all filters"
        icon={<MUIcon name="refresh" className="cursor-pointer" />}
        onClick={resetFilters}
      />
    </div>
  );
};

export default AudienceFilters;
