import axios from 'axios';
import { FeatureCollection } from 'geojson';
import { useQuery } from 'react-query';

const useGeoJsonQuery = (filePath: string) => useQuery(['geo-json', filePath], () => getGeoJson(filePath));

export default useGeoJsonQuery;

const getGeoJson = (fileName: string) =>
  axios.get<FeatureCollection>(fileName, { responseType: 'json' }).then((r) => r.data);
