import { Abacus } from '@platform/shared/hooks';
import { MvdTypes } from '@platform/types';
import { useMemo } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import * as api from '../../../mvd.api';

interface IParams {
  datasetId: string;
  geoLevel: MvdTypes.GeoLevelType;
  filters: RuleGroupType;
}

interface Party {
  label: string;
  color: string;
  column: string;
}

export const PARTY_DEMOCRATS: Party = {
  label: 'Democrats',
  color: '#a4bcdf',
  column: 'c3844',
};

export const PARTY_REPUBLICANS: Party = {
  label: 'Republicans',
  color: '#e4b3af',
  column: 'c3845',
};

export const PARTY_INDEPENDENT: Party = {
  label: 'Independent',
  color: '#d0d6de',
  column: 'c3846',
};

export const PARTIES: Party[] = [PARTY_DEMOCRATS, PARTY_REPUBLICANS, PARTY_INDEPENDENT];

const useAudienceMapData = ({
  datasetId,
  filters,
  geoLevel,
}: IParams): {
  title: string;
  data: IDataPoint[] | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const filteredCountQuery = Abacus.useAbacusRowsCount({
    datasetId,
    api: api.getRowCount,
    dataQuery: filters,
  });

  const rawDataQuery = Abacus.useAbacusSummarization({
    api: api.summarize,
    datasetId,
    request: {
      spec: {
        tables: [
          {
            name: 'T000',
            title: 'Count',
            variables: [
              {
                name: 'count',
                label: 'Count',
                indent: '0',
                formatting: '9',
                valueSql: 'COUNT(*)',
              },
            ],
          },
          {
            name: 'T001',
            title: 'Party',
            variables: PARTIES.map((p) => ({
              name: p.column,
              label: p.label,
              indent: '0',
              formatting: '9',
              valueSql: `SUM(${p.column})`,
            })),
          },
        ],
        groupBy: { value: `${[geoLevel.parent, geoLevel.field].filter((g) => !!g).map((g) => `"${g}"`)}` },
      },
      dataQuery: filters,
    },
  });
  const data: IDataPoint[] | undefined = useMemo(() => {
    if (!filteredCountQuery.data || !rawDataQuery.data) return undefined;

    // @ts-ignore
    const rawData: IResponseRawData[] = rawDataQuery.data.data.map((item) => {
      const flatName = [geoLevel.parent, geoLevel.field]
        .filter((x) => !!x)
        .map((x) => item[x])
        .join()
        .toLowerCase();

      return { geo: flatName, ...item };
    });

    const totalCountOfViews = Number(filteredCountQuery.data);
    return rawData?.map((x) => {
      const count = Number(x.count);
      const percentage = totalCountOfViews ? (count * 100) / totalCountOfViews : 0;
      const parties = PARTIES.reduce((p, c) => ({ ...p, [c.column]: Number(x[c.column]) }), {});

      return {
        count,
        percentage,
        name: x.geo,
        partyWinner: getWinningParty(x, PARTIES),
        ...parties,
      };
    });
  }, [filteredCountQuery.data, rawDataQuery.data, geoLevel.parent, geoLevel.field]);

  const isLoading = rawDataQuery.isIdle || rawDataQuery.isLoading;
  const isError = rawDataQuery.isError;

  return { data, isLoading, isError, title: geoLevel.name };
};

export default useAudienceMapData;

export interface IDataPoint extends Record<string, any> {
  name: string;
  count: number;
  percentage: number;
  partyWinner: string;
}

interface IResponseRawData extends Record<string, string> {
  geo: string;
  count: string;
}

const getWinningParty = (entry: Record<string, string>, parties: Party[]): string => {
  let winningParty: Party | null = null;
  let maxVotes = -Infinity;

  parties.forEach((party) => {
    const votes = parseInt(entry[party.column], 10);
    if (votes > maxVotes) {
      maxVotes = votes;
      winningParty = party;
    }
  });

  return winningParty ? (winningParty as Party).label : '?'; // Return '?' in case of a tie or undefined values
};
