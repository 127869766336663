import React from 'react';
import Footer from '../components/Account/Footer';
import Header from '../components/Account/Header';
import Signup from '../components/Account/Signup';

const SignUpPage: React.FC = () => {
  return (
    <div className="flex w-full flex-col bg-gray-50">
      <Header />
      <Signup />
      <Footer />
    </div>
  );
};

export default SignUpPage;
