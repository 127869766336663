import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { RuleGroupType } from 'react-querybuilder';
import * as api from '../../../../mvd.api';
import MvdMasterMapping from '../../Filters/MvdMasterMapping';
import useReadershipRequest from './useReadershipRequest';
import useReadershipWhereClause from './useReadershipWhereClause';

interface IParams {
  filterGroupId: string;
  filters: RuleGroupType;
  queryEnabled: boolean;
  queryKey: string;
  uncategorizedLabel?: string;
  excludeNotAvailableData?: boolean;
  limit?: number;
  offset?: number;
  transpose?: boolean;
}

const useReadershipAudienceData = ({
  filterGroupId,
  filters,
  queryEnabled,
  queryKey,
  uncategorizedLabel = 'Unknown',
  excludeNotAvailableData = false,
  transpose = false,
}: IParams): {
  title: string;
  description?: string;
  data: IDataPoint[] | undefined;
  isLoading: boolean;
  isError: boolean;
  unknownCount: number;
  knownCount: number;
  totalCount: number;
} => {
  const [rawData, setRawData] = useState<IResponseRawData[] | null>(null);
  const whereClause = useReadershipWhereClause(filters);
  const group = MvdMasterMapping.getGroup(filterGroupId);
  const audienceEntries = MvdMasterMapping.getMappingsByFilterGroup(filterGroupId);

  const sql = useMemo(() => {
    const columnsAsSql = audienceEntries
      .map((x) => `COUNT (DISTINCT CASE WHEN "${x.field}" = 1 THEN visitorid ELSE NULL END) AS "${x.label}"`)
      .join(', ');

    const audienceGroupWhere = audienceEntries.map((x) => `"${x.field}" = 1`).join(' OR ');
    return `
        SELECT ${columnsAsSql}
        FROM
        WHERE ${whereClause} AND (${audienceGroupWhere});`;
  }, [whereClause, filterGroupId]);

  const pqsQuery = useReadershipRequest({ filters, sql });

  const rawDataQuery = useQuery(
    [queryKey, pqsQuery],
    () => api.getDataPqs<IResponseRawData>(pqsQuery, filterGroupId, true),
    {
      staleTime: Infinity,
      enabled: queryEnabled,
    }
  );

  useEffect(() => {
    if (!rawDataQuery.data) return;
    setRawData(rawDataQuery.data);
  }, [rawDataQuery.data, transpose]);

  const unknownCount = Number(rawData?.find((x) => x.name === uncategorizedLabel)?.count ?? 0);
  const knownCount: number =
    rawData?.filter((x) => x.name !== uncategorizedLabel).reduce((acc: number, cur) => acc + Number(cur.count), 0) ?? 0;
  const totalCount = unknownCount + knownCount;

  const data: IDataPoint[] | undefined = useMemo(
    () =>
      rawData
        ?.filter((x: IResponseRawData) => (excludeNotAvailableData ? x.name !== uncategorizedLabel : true))
        ?.sort((a: IResponseRawData, b: IResponseRawData) => {
          const orderingA = group?.columns.find((x) => x.label === a.name)?.ordering ?? Number.MAX_SAFE_INTEGER;
          const orderingB = group?.columns.find((x) => x.label === b.name)?.ordering ?? Number.MAX_SAFE_INTEGER;
          return orderingA - orderingB;
        })
        ?.map((rawData) => {
          const group = audienceEntries.find((x) => x.label === rawData.name)?.description ?? '';
          return {
            group,
            name: rawData.name,
            y: totalCount ? (Number(rawData.count) * 100) / totalCount : 0,
            count: Number(rawData.count),
            percentage: totalCount ? (Number(rawData.count) * 100) / totalCount : 0,
            categorized: rawData.name !== uncategorizedLabel,
          };
        }),
    [group, excludeNotAvailableData, rawData, totalCount, uncategorizedLabel]
  );

  const isLoading = rawDataQuery.isIdle || rawDataQuery.isLoading;
  const isError = rawDataQuery.isError;

  return {
    unknownCount,
    knownCount,
    totalCount,
    data,
    isLoading,
    isError,
    title: group.title,
    description: group.description,
  };
};

export default useReadershipAudienceData;

export interface IDataPoint {
  name: string;
  group: string;
  y: number;
  count: number;
  percentage: number;
  categorized: boolean;
}

interface IResponseRawData {
  name: string;
  count: string;
}
