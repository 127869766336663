import { Abacus } from '@platform/shared/hooks';
import { AbacusTypes, MvdTypes } from '@platform/types';
import { RuleGroupType } from 'react-querybuilder';
import * as api from '../../../../mvd.api';
import MvdMasterMapping from '../../Filters/MvdMasterMapping';

interface IParams {
  datasetId: string;
  filterGroupId: MvdTypes.FilterGroup;
  groupByFilterIds: MvdTypes.FilterGroup[];
  filters: RuleGroupType;
  uncategorizedLabel?: string;
}

const useAudienceData = ({
  filterGroupId,
  datasetId,
  filters,
  groupByFilterIds,
  uncategorizedLabel = 'Unknown',
}: IParams): {
  title: string;
  data: IDataSeries[] | undefined;
  isLoading: boolean;
  isError: boolean;
} => {
  const valueEntries = MvdMasterMapping.getMappingsByFilterGroup(filterGroupId);
  const valueTitle = MvdMasterMapping.getGroupTitle(filterGroupId);
  const valueGroupIsMultiColumn = new Set(valueEntries.map((x) => x.field)).size > 1;

  let tableSpec: AbacusTypes.SummarizeTable;

  if (valueGroupIsMultiColumn) {
    tableSpec = {
      name: filterGroupId,
      title: valueTitle,
      displayName: valueTitle,
      variables: valueEntries.map((entry) => ({
        name: entry.field,
        formatting: '9',
        indent: '0',
        label: entry.label,
        valueSql: `SUM(CASE WHEN ${entry.field} = 1 THEN 1 ELSE 0 END)`,
      })),
    };
  } else {
    const [singleEntry] = valueEntries;

    tableSpec = {
      name: singleEntry.field,
      title: singleEntry.title,
      summarize: {
        function: 'SUM',
        includeTotal: false,
        rawVariables: [
          {
            name: singleEntry.field,
            values: [
              ...valueEntries.map((entry) => ({
                value: entry.value,
                label: entry.label,
              })),
              { value: "'','Unknown',[NULL]", label: uncategorizedLabel },
            ],
          },
        ],
      },
    };
  }

  const groupByValues = groupByFilterIds.reduce((p: string[], c) => {
    const groupByEntries = MvdMasterMapping.getMappingsByFilterGroup(c);
    if (!groupByEntries?.length) return p;
    return [...p, ...groupByEntries.map((x) => x.field)];
  }, []);

  const spec: AbacusTypes.SummarizePayload = {
    tables: [tableSpec],
    groupBy: {
      value: Array.from(new Set(groupByValues)).join(','),
    },
  };

  const summaryQuery = Abacus.useAbacusSummarization({
    api: api.summarize,
    datasetId,
    identifier: [filterGroupId, ...groupByFilterIds].join(),
    request: {
      spec,
      dataQuery: filters,
    },
  });

  const isMultiColumn = new Set(valueEntries.map((x) => x.field)).size > 1;
  const rawData: Record<string, string | number>[] = summaryQuery.data?.data || [];

  const allSeriesData: IDataSeries[] = valueEntries.map((entry) => {
    let data: IDataPoint[] = [];

    if (isMultiColumn) {
      const totalCount =
        groupByFilterIds.length > 0
          ? rawData.reduce((p, dataPoint) => {
              const rawCountFromAbacus = dataPoint[entry.field.toString()];
              return p + Number(rawCountFromAbacus);
            }, 0)
          : Object.values(rawData[0] ?? []).reduce((p: number, c) => p + Number(c), 0);

      data = extractSerieDataFromMultiColumnData(
        rawData,
        entry.field,
        groupByFilterIds,
        totalCount,
        uncategorizedLabel
      );
    } else {
      // TODO: handle when value is single column
      data = extractSerieDataFromSingleColumnData();
    }

    return {
      data,
      name: entry.label,
    };
  }, []);

  const isError: boolean = summaryQuery.isError;
  const isLoading: boolean = summaryQuery.isLoading || summaryQuery.isIdle;

  const title = groupByFilterIds.length
    ? MvdMasterMapping.getGroupTitle(groupByFilterIds[0])
    : MvdMasterMapping.getGroupTitle(filterGroupId);

  return { data: allSeriesData, isLoading, isError, title };
};

export default useAudienceData;

export interface IDataSeries {
  name: string;
  data: IDataPoint[];
}

export interface IDataPoint {
  name: string;
  group: string;
  y: number;
  percentage: number;
  count: number;
  categorized?: boolean;
  ordering?: number;
}

interface IHighchartsData {
  categories: string[];
  data: IDataPoint[];
}

export const convertToHighchartsData = (data: IDataPoint[]): IHighchartsData => ({
  data,
  categories: data.map((item) => item.name),
});

const extractSerieDataFromSingleColumnData = () => {
  return [];
};

const extractSerieDataFromMultiColumnData = (
  rawData: Record<string, string | number>[],
  fieldId: string,
  groupBy: MvdTypes.FilterGroup[],
  totalCount: number,
  uncategorizedLabel: string
): IDataPoint[] => {
  const retVal = rawData.map((dataPoint) => {
    const rawCountFromAbacus = dataPoint[fieldId.toString()];
    const count = Number(rawCountFromAbacus);
    const y = totalCount ? (count * 100) / totalCount : 0;

    let id = '*';
    let name = uncategorizedLabel;
    let group = '';
    let ordering = Number.MAX_SAFE_INTEGER;

    if (groupBy.length) {
      const [firstGrouping, secondGrouping] = groupBy;
      id = firstGrouping.toString();

      const groupingEntries = MvdMasterMapping.getMappingsByFilterGroup(firstGrouping);
      const groupingFilterIsMultiColumn = new Set(groupingEntries.map((x) => x.field)).size > 1;

      if (groupingFilterIsMultiColumn) {
        if (secondGrouping) {
          // TODO: what if second group is SINGLE column
          const nestedGrouping = MvdMasterMapping.getMappingsByFilterGroup(secondGrouping);
          group = nestedGrouping.reduce((p: string, currentEntry) => {
            if (dataPoint[currentEntry.field] === 1) return currentEntry.label;
            return p;
          }, group);
        }

        name = groupingEntries.reduce((p: string, currentEntry) => {
          if (dataPoint[currentEntry.field] === 1) {
            ordering = currentEntry.ordering;
            return currentEntry.label;
          }

          return p;
        }, name);
      } else {
        const mappings = MvdMasterMapping.getMappingsByFilterGroup(id);
        const match = mappings.find((mapping) => mapping.value === dataPoint[mappings[0].field]);
        if (match) {
          name = match.label;
          ordering = match.ordering;
        }
      }
    }

    return {
      id,
      y,
      count,
      name,
      group,
      ordering,
      percentage: y,
      categorized: true,
    };
  });

  return retVal.sort((a, b) => a.ordering - b.ordering);
};
