import { MUIcon } from '@platform/shared/ui';
import React from 'react';
import ReactHintFactory from 'react-hint';

const ReactHint = ReactHintFactory(React);

interface IProps {
  title: string;
  description?: string | React.ReactNode;
}

const WidgetInfo: React.FC<IProps> = ({ title, description = 'Description not available' }) => {
  const rhKey = `data-info-${title.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()}`;
  const rHint = {
    [rhKey]: true,
  };
  return (
    <>
      <ReactHint
        autoPosition
        events
        delay={{ show: 100, hide: 0 }}
        attribute={rhKey}
        onRenderContent={(target) => (
          <div className="flex min-w-[220px] flex-col gap-1 rounded-md bg-gray-800 p-3 text-white shadow-sm">
            <div className="font-semibold">{title}</div>
            {description && <div className="text-sm text-white/60">{description}</div>}
          </div>
        )}
      />
      <div {...rHint} className="flex cursor-pointer">
        <MUIcon name="Help" className="text-gray-500" />
      </div>
    </>
  );
};

export default WidgetInfo;
