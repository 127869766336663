import { Buttons, MUIcon } from '@platform/shared/ui';
import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import AudienceFilter from '../Filters/Audience.filter';
import GeoFilter from '../Filters/Geo';
import MoreFilters from '../Filters/MoreFilters';
import TimeRangeFilter from '../Filters/TimeRange.filter';
import TopicsFilter from '../Filters/Topics.filter';
import { useReadershipContext } from './ReadershipContext';

const ReadershipFilters: React.FC = () => {
  const { filters, applyFilters, resetFilters, availableGeoLevels, geoLevel } = useReadershipContext();
  const handleDone = (newFilters: RuleGroupType) => applyFilters(newFilters);

  return (
    <div className="flex items-center gap-4 pb-5">
      <div className="flex items-center gap-3">
        <TimeRangeFilter filters={filters} onDone={handleDone} />
        <TopicsFilter filters={filters} onDone={handleDone} />
      </div>
      <div className="h-[16px] w-[1px] bg-gray-300" />
      <div className="flex flex-wrap items-center gap-3">
        <AudienceFilter filters={filters} onDone={handleDone} />
        <GeoFilter
          filters={filters}
          onDone={handleDone}
          availableGeoLevels={availableGeoLevels}
          activeGeoLevel={geoLevel}
        />
        <MoreFilters />
        <Buttons.Link
          className="rounded-full"
          data-rh="Reset all filters"
          icon={<MUIcon name="refresh" className="cursor-pointer" />}
          onClick={resetFilters}
        />
      </div>
    </div>
  );
};

export default ReadershipFilters;
