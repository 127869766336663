import { Dropdowns, Inputs, MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import AudienceSummary from '../Audience/AudienceSummary';
import { useActivationsContext } from './ActivationsContext';

type ActivationFormInputs = {
  platform: MvdTypes.IPlatform | null;
  accountId: string;
  campaignStartDate: string;
  campaignEndDate: string;
  downloadType: string | null;
};

const ActivationPlatformType = forwardRef((_, ref) => {
  const { activation, setActivation, costAndRate } = useActivationsContext();
  const startDateInputRef = useRef<HTMLInputElement | null>(null);
  const endDateInputRef = useRef<HTMLInputElement | null>(null);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<ActivationFormInputs>({
    defaultValues: {
      platform: activation.platform || null,
      accountId: activation.accountId || '',
      campaignStartDate: activation.campaignStartDate || '',
      campaignEndDate: activation.campaignEndDate || '',
    },
  });

  const onSubmit = (data: ActivationFormInputs) => {
    const newActivation: MvdTypes.IActivation = {
      ...activation,
      platform: data.platform,
      accountId: data.accountId,
      campaignStartDate: data.campaignStartDate,
      campaignEndDate: data.campaignEndDate,
    };
    setActivation(newActivation);
  };

  const onPlatformChange = (platform: MvdTypes.IPlatform | undefined) => {
    if (platform) {
      const newActivation: MvdTypes.IActivation = {
        ...activation,
        platform: platform,
      };
      setActivation(newActivation);
    }
  };

  useImperativeHandle(ref, () => ({
    triggerSubmit: () =>
      new Promise<void>((resolve) => {
        handleSubmit((data) => {
          onSubmit(data);
          resolve();
        })();
      }),
  }));

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const startDate = e.target.value;
    if (startDate) {
      const newActivation: MvdTypes.IActivation = {
        ...activation,
        campaignStartDate: startDate,
      };
      setActivation(newActivation);
    }
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const endDate = e.target.value;
    if (endDate) {
      const newActivation: MvdTypes.IActivation = {
        ...activation,
        campaignEndDate: endDate,
      };
      setActivation(newActivation);
    }
  };

  const handleStartDateIconClick = () => {
    if (startDateInputRef.current) {
      startDateInputRef.current.showPicker();
    }
  };

  const handleEndDateIconClick = () => {
    if (endDateInputRef.current) {
      endDateInputRef.current.showPicker();
    }
  };

  const dropdownOptions: Dropdowns.DropdownOption[] = MvdTypes.LiveRampPlatforms.filter((p) => p.enabled)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((p) => ({ value: p.value, name: p.name }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex h-full w-full flex-col justify-center gap-8">
      <AudienceSummary />
      <div className="flex flex-col gap-3">
        <p className="font-regular text-sm">Platform</p>
        <Controller
          name="platform"
          control={control}
          rules={{ required: 'Platform is required' }}
          render={({ field }) => (
            <Dropdowns.Dropdown
              placeholder="Select a platform"
              triggerClasses="flex w-full h-[52px] ring-0 border border-gray-200 font-normal text-sm"
              menuWidth="full"
              options={dropdownOptions}
              value={field.value?.value ?? ''}
              onSelect={(option) => {
                const platform = MvdTypes.LiveRampPlatforms.find((p) => p.value === option.value);
                onPlatformChange(platform);
                field.onChange(platform);
              }}
            />
          )}
        />
        {errors.platform && <span className="text-xs text-red-400">{errors.platform.message}</span>}
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-regular text-sm">Account ID</p>
        <Controller
          name="accountId"
          control={control}
          rules={{ required: 'Account ID is required' }}
          render={({ field }) => (
            <Inputs.Input
              classes="h-[52px] border border-gray-200 ring-0 shadow-0 text-sm"
              roundedClasses="rounded-md"
              placeholder="Enter account ID"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        {errors.accountId && <span className="text-xs text-red-400">{errors.accountId.message}</span>}
      </div>
      <div className="flex flex-col gap-3">
        <p className="font-regular text-sm">Campaign duration</p>
        <div className="flex w-full justify-between gap-6">
          <div className="flex w-6/12 flex-col gap-3">
            <div className="flex h-[52px] items-center justify-start gap-2 rounded-md border border-gray-200 px-4">
              <button type="button" onClick={handleStartDateIconClick} className="flex items-center">
                <MUIcon name="calendar_today" className="text-gray-500" />
              </button>
              <Controller
                name="campaignStartDate"
                control={control}
                rules={{
                  required: 'Start date is required',
                }}
                render={({ field }) => (
                  <input
                    ref={startDateInputRef}
                    type="date"
                    className="border-none text-sm text-gray-500 focus:outline-none"
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e);
                      handleStartDateChange(e);
                    }}
                  />
                )}
              />
            </div>
            {errors.campaignStartDate && (
              <span className="text-xs text-red-400">{errors.campaignStartDate.message}</span>
            )}
          </div>
          <div className="flex w-6/12 flex-col gap-3">
            <div className="flex h-[52px] items-center justify-start gap-2 rounded-md border border-gray-200 px-4">
              <button type="button" onClick={handleEndDateIconClick} className="flex items-center">
                <MUIcon name="calendar_today" className="text-gray-500" />
              </button>
              <Controller
                name="campaignEndDate"
                control={control}
                rules={{
                  required: 'End date is required',
                  validate: (value) =>
                    value >= getValues('campaignStartDate') || 'End date can not be less then start date',
                }}
                render={({ field }) => (
                  <input
                    ref={endDateInputRef}
                    type="date"
                    className="border-none text-sm text-gray-500 focus:outline-none"
                    value={field.value}
                    onChange={(e) => {
                      handleEndDateChange(e);
                      field.onChange(e);
                    }}
                  />
                )}
              />
            </div>
            {errors.campaignEndDate && <span className="text-xs text-red-400">{errors.campaignEndDate.message}</span>}
          </div>
        </div>
      </div>
      {costAndRate.rate > 0 && (
        <div className="flex flex-col items-start gap-3">
          <p className="font-regular text-sm">Billing rate</p>
          <div className="flex h-[52px] w-full items-center justify-start rounded-lg border border-gray-200 bg-gray-50 px-3 text-sm">
            {`${NumberFormatter.format(costAndRate.rate, 'USD0,00.00')}` || (
              <p className="text-gray-500">It will be shown after you select a platform</p>
            )}
          </div>
        </div>
      )}
    </form>
  );
});

export default ActivationPlatformType;
