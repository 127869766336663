import { Inputs } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useActivationsContext } from './ActivationsContext';

type ActivationFormInputs = {
  termsAccepted: boolean;
};

const ActivationAuthorizeCheckBox = forwardRef((_, ref) => {
  const { activation, setActivation } = useActivationsContext();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ActivationFormInputs>({
    defaultValues: {
      termsAccepted: activation.termsAccepted,
    },
  });

  const onSubmit = (data: ActivationFormInputs) => {
    const newActivation: MvdTypes.IActivation = {
      ...activation,
      termsAccepted: data.termsAccepted,
    };
    setActivation(newActivation);
  };

  useImperativeHandle(ref, () => ({
    triggerSubmit: () =>
      new Promise<void>((resolve) => {
        handleSubmit((data) => {
          onSubmit(data);
          resolve();
        })();
      }),
  }));

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="shadow-custom-shadow flex h-[92px] w-full flex-col items-center justify-center py-6"
    >
      <div className="flex w-8/12 flex-col px-4">
        <div className="flex h-full items-center gap-5">
          <Controller
            name="termsAccepted"
            control={control}
            rules={{ required: 'You must accept usage and reporting terms' }}
            render={({ field }) => (
              <Inputs.CheckBox
                checked={field.value}
                readOnly
                classes="flex items-center justify-start"
                className="text-primary-700 h-[18px] w-[18px] rounded-sm hover:cursor-pointer"
                onChange={(option) => {
                  field.onChange(option.target.checked);
                }}
              />
            )}
          />
          <p className="font-regular text-sm">I agree to the usage and reporting terms</p>
        </div>
        <div>
          {errors.termsAccepted && <span className="text-xs text-red-400">{errors.termsAccepted.message}</span>}
        </div>
      </div>
    </form>
  );
});

export default ActivationAuthorizeCheckBox;
