import React from 'react';
import { RuleGroupType } from 'react-querybuilder';
import MvdMasterMapping from '../../Filters/MvdMasterMapping';

interface Props {
  filterGroup: RuleGroupType;
  itemClasses?: string;
}

const AppliedFilterItem: React.FC<Props> = ({ filterGroup, itemClasses = 'border border-gray-100 rounded-lg ' }) => {
  const { values, title } = MvdMasterMapping.groupToHumanReadableText(filterGroup);

  return (
    <div className={`flex items-start gap-8 bg-gray-50 p-4 text-sm ${itemClasses}`}>
      <div className="w-1/3">{title}</div>
      <div className="w-full font-semibold">{values.join('; ')}</div>
    </div>
  );
};

export default AppliedFilterItem;
