import React from 'react';
import { useApplicationContext } from '../ApplicationContext';
import PersonalInfo from '../components/Account/PersonalInfo';

const AccountPage: React.FC = () => {
  const { userInfo } = useApplicationContext();
  if (userInfo == null) return null;
  return <PersonalInfo userInfo={userInfo} />;
};
export default AccountPage;
