import React from 'react';
import { useActivationsContext } from './ActivationsContext';

const ActivationNoteAndAccountName: React.FC = () => {
  const { activation, setActivation } = useActivationsContext();

  const handleNoteChange = (note: string) => {
    setActivation({ ...activation, note });
  };

  const handleAccountNameChange = (accountName: string) => {
    setActivation({ ...activation, accountName });
  };

  return (
    <div className="flex flex h-full flex-col justify-center gap-8">
      <div className="flex flex-col items-start gap-3">
        <p className="font-regular text-sm">Notes</p>
        <textarea
          placeholder={'Enter note here...'}
          className="h-[150px] max-h-[150px] w-full resize-none rounded-lg border border-gray-200 px-3 text-sm focus:border-gray-200 focus:ring-0"
          onChange={(e) => handleNoteChange(e.target.value)}
          value={activation.note}
        ></textarea>
      </div>
      <div className="flex flex-col items-start gap-3">
        <p className="font-regular text-sm">Account name</p>
        <input
          placeholder={'Enter account name'}
          className="h-[50px] max-h-[50px] w-full rounded-lg border border-gray-200 px-3 text-sm focus:border-gray-200 focus:ring-0"
          onChange={(e) => handleAccountNameChange(e.target.value)}
          value={activation.accountName}
        ></input>
      </div>
    </div>
  );
};

export default ActivationNoteAndAccountName;
