import { Buttons } from '@platform/shared/ui';
import React from 'react';

interface IProps {
  onReset: () => void;
  onCancel: () => void;
  onDone: () => void;
  disabledApply?: boolean;
  disabledReset?: boolean;
}

const FilterActions: React.FC<IProps> = ({ onReset, onCancel, onDone, disabledApply, disabledReset }) => {
  return (
    <div className="flex items-center justify-between gap-4 border-t py-2 pl-2 pr-4">
      <div className="flex items-center">
        {!disabledReset && (
          <Buttons.Link className="rounded-full px-6 py-2.5 text-gray-800" onClick={onReset}>
            Reset
          </Buttons.Link>
        )}
      </div>
      <div className="flex items-center gap-2">
        <Buttons.Link
          className="border-primary-300 justify-center self-stretch rounded-full border px-6 py-2.5"
          onClick={onCancel}
        >
          Cancel
        </Buttons.Link>
        <Buttons.Primary
          className="bg-primary-800 justify-center self-stretch rounded-full px-6 py-2.5 text-white"
          onClick={onDone}
          disabled={disabledApply}
        >
          Apply
        </Buttons.Primary>
      </div>
    </div>
  );
};
export default FilterActions;
