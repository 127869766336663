import { MapComponents } from '@platform/shared/ui';
import React from 'react';

import { PARTY_DEMOCRATS, PARTY_INDEPENDENT, PARTY_REPUBLICANS } from '../../useAudienceMapData';

const { Legend: LegendComponents } = MapComponents;

const PARTIES = [PARTY_DEMOCRATS, PARTY_REPUBLICANS, PARTY_INDEPENDENT];

const Legend: React.FC = () => {
  return (
    <LegendComponents.CollapsibleWrapper>
      <div className="pointer-events-none flex w-[180px] flex-col gap-2 rounded-md border border-gray-100 bg-white p-3 py-2 text-xs">
        {PARTIES.map((p) => (
          <div key={p.color} className="flex gap-2">
            <div className="h-[16px] w-[16px] rounded-sm" style={{ backgroundColor: p.color }} />
            <span>{p.label}</span>
          </div>
        ))}
      </div>
    </LegendComponents.CollapsibleWrapper>
  );
};

export default Legend;
