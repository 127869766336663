import { Buttons, Inputs, MUIcon } from '@platform/shared/ui';
import React, { useEffect, useState } from 'react';
import { NestedOption, ParentOption } from './Geo.filter';

interface Props {
  option: ParentOption;
  searchTerm?: string;
  expandedByDefault: boolean;
  onClick: (parentOption: ParentOption, nestedOption?: NestedOption, only?: boolean) => void;
}

const TopLevelGeo: React.FC<Props> = ({ option, searchTerm, onClick, expandedByDefault = false }) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { nestedItems, name, checked } = option;
  const hasNestedItems = nestedItems.length > 0;
  const nestedSelectionCount = nestedItems.reduce((p, c) => p + (c.checked ? 1 : 0), 0);
  const allNestedSelected = nestedSelectionCount > 0 && nestedSelectionCount === nestedItems.length;
  const someNestedSelected = nestedSelectionCount > 0 && nestedSelectionCount < nestedItems.length;

  useEffect(() => {
    setExpanded(expandedByDefault);
  }, [expandedByDefault]);

  const handleExpandClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded((p) => !p);
  };

  const handleParentClick = (e: React.MouseEvent, only?: boolean) => {
    e.stopPropagation();
    if (only) {
      onClick(option, undefined, true);
    } else {
      onClick(option);
    }
  };

  const handleNestedItemClick = (e: React.MouseEvent, nestedItem: NestedOption, only?: boolean) => {
    e.stopPropagation();
    if (only) {
      onClick(option, nestedItem, true);
    } else {
      onClick(option, nestedItem);
    }
  };

  return (
    <div className="focus:bg-primary-100 flex w-full cursor-pointer flex-col items-center justify-between text-sm">
      <div
        onClick={handleParentClick}
        className="hover:bg-primary-100 group flex h-[44px] w-full flex-shrink-0 items-center justify-between px-4"
      >
        <div className="flex gap-4">
          <Inputs.TriStateCheckBox
            readOnly
            checked={checked || allNestedSelected}
            intermediate={someNestedSelected}
            classes="flex items-center justify-start"
            className="text-primary-700"
          />
          {name}
        </div>
        <div className="flex gap-2">
          <Buttons.Soft
            className="hover:bg-primary-200 focus:bg-primary-200 disabled:bg-primary-200 rounded-full bg-transparent px-4 py-1.5 opacity-0 transition-opacity duration-300 disabled:text-gray-500 group-hover:opacity-100"
            onClick={(e) => handleParentClick(e, true)}
          >
            Only
          </Buttons.Soft>
          {hasNestedItems && (
            <div className="flex gap-2">
              <button onClick={handleExpandClick}>
                <MUIcon name={expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} />
              </button>
            </div>
          )}
        </div>
      </div>
      {expanded && (
        <div className="flex w-full flex-col">
          {nestedItems
            .filter((x) => x.name.toLowerCase().startsWith(searchTerm?.toLowerCase() ?? ''))
            .map((nested, idx) => (
              <div key={`${nested.name}_${idx}`} onClick={(e) => handleNestedItemClick(e, nested)} className="group">
                <div className="flex flex-grow items-start justify-between gap-4 pr-2">
                  <div className="flex gap-3 py-2 pl-8">
                    <Inputs.CheckBox
                      checked={nested.checked}
                      readOnly
                      classes="flex items-center justify-start"
                      className="text-primary-700"
                    />
                    {nested.name}
                  </div>
                  <Buttons.Soft
                    className="hover:bg-primary-200 focus:bg-primary-200 disabled:bg-primary-200 rounded-full bg-transparent px-4 py-1.5 opacity-0 transition-opacity duration-300 disabled:text-gray-500 group-hover:opacity-100"
                    onClick={(e) => handleNestedItemClick(e, nested, true)}
                  >
                    Only
                  </Buttons.Soft>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default TopLevelGeo;
