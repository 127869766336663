import { MvdTypes } from '@platform/types';
import { NumberFormatter } from '@platform/utils';

import React from 'react';
import { useActivationsContext } from './ActivationsContext';

const ActivationAuthorizeDataSummary: React.FC = () => {
  const { activation, recordsCount, costAndRate } = useActivationsContext();
  return (
    <div className="flex flex-col items-start gap-3">
      <p className="font-regular text-sm">Activation Summary</p>
      <div className="flex w-full flex-col rounded-md border border-gray-200 px-6">
        <div className="flex w-full items-center justify-center justify-between border-b border-gray-200 py-6">
          <p className="text-sm">Export type</p>
          <p className="font-semibold capitalize">{MvdTypes.getDownloadTypeLabel(activation.downloadType)}</p>
        </div>
        <div className="flex w-full items-center justify-center justify-between border-b border-gray-200 py-6">
          <p className="text-sm">Number of records</p>
          <div className="flex flex-col items-end gap-2">
            <p className="text-sm font-semibold">{`${NumberFormatter.format(recordsCount, '0,000')}`}</p>
          </div>
        </div>
        <div className="flex w-full items-center justify-between border-b border-gray-200 py-6">
          <p className="text-sm">Billing rate</p>
          <p className="text-sm font-semibold">{`${NumberFormatter.format(
            costAndRate.rate,
            'USD0,000.00'
          )} per record`}</p>
        </div>
        <div className="flex w-full items-center justify-between py-6 text-lg font-semibold">
          <p>Total cost</p>
          <p>{`${NumberFormatter.format(costAndRate.cost, 'USD0,000.00')}`}</p>
        </div>
      </div>
    </div>
  );
};

export default ActivationAuthorizeDataSummary;
