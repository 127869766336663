import { MUIcon } from '@platform/shared/ui';
import { MvdTypes } from '@platform/types';
import Highcharts from 'highcharts';
import React, { useMemo } from 'react';
import { RuleGroupType } from 'react-querybuilder';
import { ChartExportType } from '../../../enums/widgetEnums';
import MvdMasterMapping from '../../Filters/MvdMasterMapping';
import ChartOptionsMenu from '../../shared/ChartOptionsMenu';
import ExpandableGridItem, { GridPosition } from '../../shared/ExpandableGridItem';
import useResizableChart from '../../shared/useResizableChart';
import { useReadershipContext } from '../ReadershipContext';
import useReadershipData, { IDataPoint } from './useReadershipData';
import WidgetInfo from './WidgetInfo';
import { WidgetQueryKeys } from './WidgetQueryKeys';

const { FilterGroup } = MvdTypes;

interface Props {
  grid: GridPosition;
}

const TopicsWidget: React.FC<Props> = ({ grid }) => {
  const { filters, queryEnabled } = useReadershipContext();
  const categoryRuleCount =
    (filters.rules.find((group) => group.id === FilterGroup.TOPIC) as RuleGroupType)?.rules?.length ?? 0;

  const { isLoading, data } = useReadershipData({
    filters,
    queryEnabled,
    filterGroupId: categoryRuleCount > 0 ? FilterGroup.TOPIC_DETAILS : FilterGroup.TOPIC,
    queryKey: categoryRuleCount > 0 ? WidgetQueryKeys.TOPIC_DETAILS : WidgetQueryKeys.TOPICS,
    uncategorizedLabel: 'Other',
  });

  const options: Highcharts.Options = useMemo(() => {
    const highchartsData = convertToHighchartsData(data ?? [], categoryRuleCount > 1);
    return {
      plotOptions: {
        treemap: {
          borderColor: '#FFFFFF',
          color: '#B7D7DC',
        },
        series: {
          dataLabels: {
            align: 'left',
            verticalAlign: 'top',
            x: 10,
            y: 10,
            formatter: function () {
              return this.point.name;
            },
            style: {
              fontSize: '11px',
              fontWeight: 'normal',
              textOutline: 'none',
              color: '#1F2937',
            },
          },
        },
      },
      series: [
        {
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          data: highchartsData,
        },
      ],
      colors: ['#CAEAE7'],
    };
  }, [data, categoryRuleCount]);

  const exportChart = () => {
    if (chartComponentRef.current) {
      chartComponentRef.current.exportChart(
        {
          filename: 'article_topics_chart',
        },
        { ...options, title: { text: 'Article Topics' }, chart: { marginTop: 40 } }
      );
    }
  };

  const [chart, chartComponentRef] = useResizableChart(options);
  const MENU_OPTIONS = [
    {
      groupName: 'Export',
      options: [
        { type: ChartExportType.image, label: 'Image (.png)', icon: <MUIcon name="image" />, onClick: exportChart },
      ],
    },
  ];
  const menu = <ChartOptionsMenu groupedOptions={MENU_OPTIONS} />;
  const title = 'Article Topics';

  return (
    <ExpandableGridItem
      title={title}
      loading={isLoading}
      grid={grid}
      actions={menu}
      info={
        <WidgetInfo
          title={title}
          description="Distribution of content topics across all headlines, based on selected timeframe and filters."
        />
      }
    >
      {chart}
    </ExpandableGridItem>
  );
};

export default TopicsWidget;

interface HighchartsData {
  id: string;
  name: string;
  value: number;
  colorValue?: number;
  color?: string;
  parent?: string;
}

const convertToHighchartsData = (data: IDataPoint[], showingMultipleTopics: boolean): HighchartsData[] => {
  const topicCategories = MvdMasterMapping.getMappingsByFilterGroup(FilterGroup.TOPIC);

  return data
    .filter((item) => item.categorized)
    .map((item) => {
      let parentCategory;
      if (showingMultipleTopics) {
        const category = topicCategories.find((category) => category.value?.toString().includes(item.name));
        parentCategory = category?.label;
      }

      return {
        name: item.name,
        description: parentCategory,
        id: item.name,
        value: item.percentage,
        colorValue: item.y,
        percentage: item.percentage,
        count: item.count,
      };
    });
};
